import React from 'react';
import _ from 'lodash';
import { styled } from '@this/constants/themes';
import type ExpensesType from '@this/domain/expenses/expenses_type';
import type ExpensesAccountType from '@this/src/domain/expenses/expenses_account_type';

type Props = {
  types: ExpensesType[];
  accountTypes: ExpensesAccountType[];
  onClickEdit: (type: ExpensesType) => void;
  onClickDelete: (type: ExpensesType) => void;
};

const Cards: React.FC<Props> = ({ types, accountTypes, onClickEdit, onClickDelete }) => {
  const expensesTypeCard = (type: ExpensesType) => (
    <Tr key={type.id}>
      <td className="organizationTdClass">{type.name}</td>
      <td className="organizationTdClass">{type.code}</td>
      <td className="organizationTdClass">{type?.expensesAccountType?.isDeletedName || '未設定'}</td>
      <td className="organizationTdClass">{type.categoryName()}</td>
      <td className="organizationTdClass">
        <input className="organization__button" type="submit" value="編集" onClick={() => onClickEdit(type)} />
      </td>
      <td className="organizationTdClass">
        <input
          className="organization__delete-button"
          type="submit"
          value="削除"
          onClick={() => onClickDelete(type)}
        />
      </td>
    </Tr>
  );

  return (
    <table className="organization__table">
      <thead>
        <tr>
          <th className="organization__th"> 経費科目名 </th>
          <th className="organization__th"> 経費科目コード </th>
          <th className="organization__th"> 対応させる勘定科目 </th>
          <th className="organization__th"> 入力フォーマット </th>
          <th className="organization__button-th"> 編集 </th>
          <th className="organization__button-th"> 削除 </th>
        </tr>
      </thead>
      <tbody>{types.map(type => expensesTypeCard(type))}</tbody>
    </table>
  );
};

const Tr = styled.tr`
  &:hover {
    background: #fff9ed;
  }
`;

export default Cards;
