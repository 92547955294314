import _ from 'lodash';
import type TravelerList from '@this/domain/traveler/traveler_list';
import type Department from '@this/domain/department/department';
import type User from '@this/domain/user/user';
import type OrganizationAddress from '@this/domain/department/organization_address';

interface Args {
  travelers: TravelerList;
  departments: Department[];
  user: User;
}

class ArrangementRequestShinkansenInfo {
  shinkansenAddressType: 'select' | 'text';

  postcode: string;

  shinkansenAddress: string;

  shinkansenAddressee: string;

  seat: 'reserve' | 'free' | 'green' | 'bulk_ticket' | 'ex' | undefined;

  travelers: TravelerList;

  readonly departments: Department[];

  readonly user: User;

  organizationAddress: OrganizationAddress | null;

  constructor(args: Args) {
    this.shinkansenAddressType = 'text';
    this.postcode = '';
    this.shinkansenAddress = '';
    this.shinkansenAddressee = '';
    this.travelers = args.travelers;
    this.departments = args.departments;
    this.user = args.user;
    this.organizationAddress = null;
  }

  setShinkansenAddressType = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => {
    this.shinkansenAddressType = e.target.value as 'select' | 'text';
    app.render();
  };

  setPostcode = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    this.postcode = e.target.value;
    app.render();
  };

  setShinkansenAddress = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    this.shinkansenAddress = e.target.value;
    app.render();
  };

  setShinkansenAddressee = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    this.shinkansenAddressee = e.target.value;
    app.render();
  };

  setOrganizationAddress = (department: Department) => {
    this.organizationAddress = department.organizationAddress;
    app.render();
  };

  setSeat = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    this.seat = e.target.value as 'reserve' | 'free' | 'green' | 'bulk_ticket' | 'ex';
    app.render();
  };

  validate(): string[] {
    const errors: string[] = [];
    if (this.seat === 'ex') return errors;
    if (this.shinkansenAddressType === 'text') {
      if (_.isEmpty(this.postcode)) errors.push('郵便番号を入力してください');
      if (_.isEmpty(this.shinkansenAddress)) errors.push('切符送り先住所を入力してください');
      if (_.isEmpty(this.shinkansenAddressee)) errors.push('切符送り先宛名を入力してください');
    } else if (this.shinkansenAddressType === 'select') {
      const value = this.organizationAddress && this.organizationAddress.location;
      if (!value || value.length === 0) {
        errors.push('選択された部署に住所が登録されていません。別の部署を選択するか、手動で入力してください');
      }
    }
    return errors;
  }

  submitParams() {
    const params: { [key: string]: any } = {
      shinkansen_address:
        this.shinkansenAddressType === 'text'
          ? this.shinkansenAddress
          : this.organizationAddress && this.organizationAddress.location,
      shinkansen_addressee:
        this.shinkansenAddressType === 'text'
          ? this.shinkansenAddressee
          : this.organizationAddress && this.organizationAddress.addressee,
      shinkansen_address_type: this.shinkansenAddressType,
      postcode:
        this.shinkansenAddressType === 'text'
          ? this.postcodeFormated()
          : this.organizationAddress && this.organizationAddress.postalCode,
      organization_address_id: this.organizationAddress && this.organizationAddress.id
    };
    if (this.seat) {
      params.sheet = this.seat;
    }
    return params;
  }

  private postcodeFormated() {
    if (!this.postcode.includes('-') && this.postcode !== '') {
      return this.insertHyphen(this.postcode, 3, '-');
    }

    return this.postcode;
  }

  private insertHyphen(postcode: string, index: 3, hyphen: '-') {
    this.postcode = postcode.slice(0, index) + hyphen + postcode.slice(index, postcode.length);
    return this.postcode;
  }
}

export default ArrangementRequestShinkansenInfo;
