import React, { useMemo } from 'react';
import { styled } from '@this/constants/themes';
import Button from '@this/components/shared/atoms/button';

import type FreeePostDealHistoryList from '@this/src/domain/freee/freee_post_deal_history_list';
import { Link } from '@this/src/components/shared/ui/navigations/link';
import SimpleLoading from '@this/src/components/shared/simple_loading/simple_loading';
import FreeeDealsSubmitProgress from './freee_deals_submit_progress';

type EnvType = 'production' | 'staging' | 'development';

interface Props {
  freeePostDealHistoryList: FreeePostDealHistoryList;
  listLoading: boolean;
  submitting: boolean;
  postError: string | null;
  method: 'separate' | 'combine';
  freeeCompanyId: number | null;
  env: EnvType | null;
  setMethod: (method: 'separate' | 'combine') => void;
  regenerate: (historyId: number) => void;
  stop: (historyId: number) => void;
  postFreeeDeals: () => void;
}

const FreeeDealsSubmit = ({
  freeePostDealHistoryList,
  listLoading,
  postError,
  method,
  freeeCompanyId,
  env,
  setMethod,
  regenerate,
  stop,
  postFreeeDeals,
  ...props
}: Props) => {
  const history = useMemo(() => freeePostDealHistoryList.lastAction, [freeePostDealHistoryList]);
  const submitting = props.submitting || freeePostDealHistoryList.submitting;

  return (
    <li>
      <h3>取引登録</h3>
      <p>請求内容をfreeeの「取引」として直接登録できます。</p>
      {listLoading ? (
        <SimpleLoading />
      ) : freeeCompanyId === null ? (
        <>
          <Description>
            ※取引登録をご利用頂くにはシステム設定の外部連携、freeeの設定から事業所を設定して頂く必要がございます。
          </Description>
          <ButtonArea>
            <Link color="primary" href="/organization/setting">
              freee連携の設定
            </Link>
          </ButtonArea>
        </>
      ) : (
        <>
          <ButtonArea>
            <Label>
              <input type="radio" checked={method === 'separate'} onChange={() => setMethod('separate')} />
              明細ごとに取引を作成
            </Label>
            <Label>
              <input type="radio" checked={method === 'combine'} onChange={() => setMethod('combine')} />
              1取引に明細を紐付け
            </Label>
          </ButtonArea>
          {method === 'combine' && (
            <Small>※ freee APIの制限のため、現在40明細ごとに1つの取引が作成されます。</Small>
          )}
          <ButtonArea>
            {submitting ? (
              <FreeeDealsSubmitProgress history={history} />
            ) : history ? (
              <>
                <TheButton onClick={() => postFreeeDeals()}>再登録</TheButton>
                <p>{history.statusText}</p>
              </>
            ) : (
              <>
                <TheButton onClick={() => postFreeeDeals()}>freeeに取引を登録</TheButton>
                {postError && (
                  <div className="red" style={{ marginTop: '5px' }}>
                    {postError}
                  </div>
                )}
              </>
            )}
          </ButtonArea>
          {history?.errorMessage && (
            <ButtonArea>
              <Small>{history.errorMessage}</Small>
            </ButtonArea>
          )}
          {env && env !== 'production' && submitting && history && (
            <>
              <h2>開発用オプション</h2>
              <p>ジョブ停止時の再開処理再現用の停止機能</p>
              <p>※本番環境では表示・利用されません</p>
              <ButtonArea>
                <TheButton onClick={() => regenerate(history.historyId)}>再開</TheButton>
                <TheButton onClick={() => stop(history.historyId)}>停止</TheButton>
              </ButtonArea>
            </>
          )}
        </>
      )}
    </li>
  );
};

const TheButton = styled(Button)`
  width: fit-content;
  margin-right: 10px;
`;

const ButtonArea = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

const Label = styled.label`
  margin-right: 10px;
`;

const Small = styled.div`
  font-size: 11px;
  color: ${props => props.theme.redColor};
`;

const Description = styled.div`
  font-size: 11px;
  color: ${props => props.theme.grayColor};
  max-width: 360px;
  padding-top: 10px;
`;

export default FreeeDealsSubmit;
