export const HotelElementProvider = {
  ean: 'EAN',
  rakuten: '(旧)楽天トラベル',
  new_rakuten: '楽天トラベル',
  jaran: 'じゃらん',
  expedia: 'Expedia',
  eps_rapid: 'Eps Rapid',
  jr: 'JR',
  ana_tabisaku: 'ANA旅作',
  racco: 'Racco',
  agoda: 'Agoda'
} as const;

export type HotelElementProviderKey = keyof typeof HotelElementProvider;

export const HotelElementProviderAvailable: readonly HotelElementProviderKey[] = [
  'ean',
  'rakuten',
  'new_rakuten',
  'jaran',
  'expedia',
  'eps_rapid',
  'jr',
  'ana_tabisaku',
  'racco',
  'agoda'
];
