import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { rgba } from 'polished';
import { TableCell, TableRow } from '@this/src/components/shared/ui/data_displays/table';
import type JalReservationTodo from '@this/src/domain/arrangement/jal_reservation_todo';
import { Link } from '@this/src/components/shared/ui/navigations/link';
import { Button } from '@this/components/shared/ui/inputs/button';
import _ from 'lodash';
import { OrderItemStatusForm } from '@this/components/arrangement/shared/price_change_form';
import { Checkbox } from '@this/src/components/shared/ui/inputs/checkbox';
import { Fetcher } from '@this/src/util';
import LogModal from '../log_modal';

interface Props {
  todo: JalReservationTodo;
  checked: boolean;
  onInventory: () => void;
  onToggle: (checked: boolean) => void;
}

const AirAutoJalReservationTodoListTableRow = observer(({ todo, checked, onInventory, onToggle }: Props) => {
  const [showLogModal, setShowLogModal] = useState<boolean>(false);

  const handleInventory = async () => {
    await Fetcher.put(`/arrangement/air_auto_reservation_order_item_todos/${todo.orderItemId}/inventory.json`, {});
    onInventory();
  };

  const handleCancelInventory = async () => {
    await Fetcher.put(
      `/arrangement/air_auto_reservation_order_item_todos/${todo.orderItemId}/cancel_inventory.json`,
      {}
    );
    onInventory();
  };

  return (
    <TableRow style={{ background: !todo.orderItemId ? rgba('red', 0.3) : 'auto' }}>
      {/* 一括棚卸用チェック */}
      <TableCell>
        <Checkbox
          checked={checked}
          disabled={!todo.orderItemId || Boolean(todo.inventoriedAt)}
          onChange={e => onToggle(e.target.checked)}
        />
      </TableCell>
      <TableCell>{todo.id}</TableCell>
      {/* 予約No */}
      <TableCell>{todo.reservationNumber}</TableCell>
      {/* 搭乗日 */}
      <TableCell>{todo.targetDate && todo.targetDate.format('YYYY-MM-DD')}</TableCell>
      {/* 便名 */}
      <TableCell>{todo.flightName}</TableCell>
      {/* 金額 */}
      <TableCell>{todo.price?.toLocaleString() || 0}</TableCell>
      {/* 予約日 */}
      <TableCell>{todo.reservationDate && todo.reservationDate.format('YYYY-MM-DD')}</TableCell>
      {/* order_itemステータス */}
      <TableCell>
        {todo.orderItemId && (
          <OrderItemStatusForm
            orderItemId={todo.orderItemId}
            jobType={todo.jobType}
            status={todo.status}
            onJobTypeChange={value => {
              todo.jobType = value;
            }}
            onStatusChange={value => {
              todo.status = value;
            }}
            showLabel={false}
            fetchData={handleInventory}
          />
        )}
      </TableCell>
      {/* 棚卸ステータス */}
      <TableCell>{todo.isInventoried ? '済' : '未'}</TableCell>
      {/* 棚卸実施者 */}
      <TableCell>{todo.inventoriedBy}</TableCell>
      {/* 棚卸日時 */}
      <TableCell>{todo.inventoriedAt}</TableCell>
      <TableCell>
        <Link href={`/arrangement/virtual_counter?trip_id=${todo.tripId}`} target="_blank" rel="noopener noreffer">
          {todo.tripId}
        </Link>
      </TableCell>
      <TableCell>{todo.traceId}</TableCell>
      {/* 最新のログメッセージ */}
      <TableCell>
        {todo.lastLogMessage && <span>{todo.lastLogMessage}</span>}
        {todo.lastLogMessageArranger && <span>({todo.lastLogMessageArranger})</span>}
        {todo.orderItemId && (
          <p>
            (<Link onClick={() => setShowLogModal(true)}>ログ</Link>)
          </p>
        )}
      </TableCell>

      {/* アクション */}
      <TableCell>
        {todo.orderItemId &&
          (todo.inventoriedAt ? (
            <Button size="small" onClick={() => handleCancelInventory()}>
              取消
            </Button>
          ) : (
            <Button size="small" onClick={() => handleInventory()}>
              棚卸完了
            </Button>
          ))}
      </TableCell>
      {showLogModal && todo.traceId && <LogModal traceId={todo.traceId} onClose={() => setShowLogModal(false)} />}
    </TableRow>
  );
});

export default AirAutoJalReservationTodoListTableRow;
