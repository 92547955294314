export interface BulkActionStatusResponse {
  bulk_action_status: BulkActionStatus;
  organization: Organization;
}

export interface BulkActionStatus {
  id: number;
  filename: string;
  kicked_by_user_id: number;
  status: string;
  created_at: string;
  updated_at: string;
  action_total: number;
  action_type: string;
  change_list: Changes[];
  kicked_by_user: KickedByUser;
  action_users: ActionUser[];
}

export interface Changes {
  [x: string]: {
    before: string;
    after: string;
  };
}

export interface KickedByUser {
  id: number;
  email: string;
  created_at: string;
  updated_at: string;
  name: string;
  tel: string;
  gmo_id?: any;
  name_kana: string;
  birthday: string;
  gender: string;
  first_name: string;
  first_name_kana: string;
  last_name: string;
  last_name_kana: string;
  first_name_roman: string;
  last_name_roman: string;
  organization_id: number;
  department_id: number;
  invitation_token?: any;
  invitation_created_at?: any;
  invitation_sent_at?: any;
  invitation_accepted_at?: any;
  invitation_limit?: any;
  invited_by_id?: any;
  invited_by_type?: any;
  invitations_count: number;
  employee_number: string;
  domestic_approval_required: boolean;
  foreign_approval_required: boolean;
  admin_notification: number;
  grade_id?: any;
  show_other_members: boolean;
  show_hotel_of_over_limit: boolean;
  show_foreign_hotel_of_over_limit: boolean;
  disabled: boolean;
}

export interface ActionUser {
  id: number;
  email: string;
  status: string;
  bulk_action_status_id: number;
  created_at: string;
  updated_at: string;
  error_message: string;
  uploaded_data: string;
  user_revision_id?: any;
}

export interface Organization {
  id: number;
  name: string;
  domain?: any;
  address: string;
  tel: string;
  created_at: string;
  updated_at: string;
  show_fee: boolean;
  include_tax: boolean;
  use_bulk_ticket: boolean;
  person_in_charge_name: string;
  person_in_charge_email: string;
  billing_email: string;
  bank_account_info: string;
  agency_bank_account_info: string;
  request_form_approval_required: boolean;
  plan_id?: any;
  url: string;
  show_ex: boolean;
  service_id: string;
  rakuten_inner_fee_rate: number;
  revenue_reference_date_type: string;
}

export interface BulkActionAttr {
  name: string;
  summary: string;
  example: string;
  required: boolean;
  serviceType?: BulkActionAttrServiceType;
}

export enum BulkActionAttrServiceType {
  ALL,
  AI_TRAVEL,
  BIZTRA
}
