User = require('../user/user')
BulkActionProject = require('./bulk_action_project').default
BulkActionDepartment = require('./bulk_action_department').default
BulkActionApprover = require('./bulk_action_approver').default
BulkActionProjectApprover = require('./bulk_action_project_approver').default
BulkActionUser = require('./bulk_action_user')
BulkActionAccountType = require('./bulk_action_account_type').default
BulkActionExpensesType = require('./bulk_action_expenses_type').default
BulkActionAdminDepartment = require('./bulk_action_admin_department').default

class BulkInvitation
  constructor: (raw) ->
    @id = dig(raw, 'id')
    @filename = dig(raw, 'filename')
    @status = dig(raw, 'status')
    @kickedByUser = new User(dig(raw, 'kicked_by_user'))
    actionUsers = _.map dig(raw, 'action_users'), (u) -> new BulkActionUser(u, dig(raw, 'action_type'))
    @actionUsers = actionUsers || []
    actionProjects = _.map dig(raw, 'action_projects'), (p) -> new BulkActionProject(p)
    @actionProjects = actionProjects || []
    @actionDepartments = (dig(raw, 'action_departments') || []).map((d) -> new BulkActionDepartment(d))
    @actionApprovers = (dig(raw, 'action_approvers') || []).map((d) -> new BulkActionApprover(d))
    @actionProjectApprovers = (dig(raw, 'action_project_approvers') || []).map((d) -> new BulkActionProjectApprover(d))
    @actionExpensesAccountTypes = (dig(raw, 'action_account_types') || []).map((d) -> new BulkActionAccountType(d))
    @actionExpensesTypes = (dig(raw, 'action_expenses_types') || []).map((d) -> new BulkActionExpensesType(d))
    @actionAdminDepartments = (dig(raw, 'action_admin_departments') || []).map((d) -> new BulkActionAdminDepartment(d))
    @actionTotal = dig(raw, 'action_total')
    @actionType = dig(raw, 'action_type')
    @finishedCount = dig(raw, 'finished_count')
    @actionsStatusCount = dig(raw, 'actions_status_count')
    @createdAt = moment(dig(raw, 'created_at')).format('YYYY-MM-DD HH:mm:ss')
    @updatedAt = moment(dig(raw, 'updated_at')).format('YYYY-MM-DD HH:mm:ss')

  statusMessage: ->
    switch @status
      when 'enqueued'
        '未着手'
      when 'running'
        @runningMessage()
      else
        @completeMessage()

  runningMessage: ->
    "実行中（#{@finishedCount}/#{@actionTotal}）"

  completeMessage: ->
    message = '完了'
    if @actionsStatusCount
      success = @actionsStatusCount['success']
      fail = @actionsStatusCount['fail']
      skip = @actionsStatusCount['skip']
      message +="（成功：#{success}件、失敗：#{fail}件、スキップ#{skip}件）"
    message

  isEnqueued: ->
    @status == 'enqueued'

  isRunning: ->
    @status == 'running'

  isSuccess: ->
    @status == 'success'

  isFail: ->
    @status == 'fail'

  isCreateAction: ->
    @actionType == 'create'

  isUpdateAction: ->
    @actionType == 'update'

  isCreateProjectsAction: ->
    @actionType == 'create_projects'

  isUpsertDepartments: ->
    @actionType == 'upsert_departments'

  isUpdateApprovers: ->
    @actionType == 'update_approvers'

  isUpdateProjectApprovers: ->
    @actionType == 'update_project_approvers'

  isUpsertUsers: ->
    @actionType == 'upsert_users'

  isDeleteUsers: ->
    @actionType == 'delete_users'

  isUpsertAccountTypes: ->
    @actionType == 'upsert_account_types'

  isUpsertExpensesTypes: ->
    @actionType == 'upsert_expenses_types'

  isUpsertAdminDepartments: ->
    @actionType == 'upsert_admin_departments'

module.exports = BulkInvitation
