import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import _ from 'lodash';
import type { Moment } from 'moment';
import Notification from '@this/src/notification';
import type { HotelOrderItemTodoResponseArgs } from '@this/src/domain/arrangement/hotel_order_item_todo';
import HotelOrderItemTodo from '@this/src/domain/arrangement/hotel_order_item_todo';
import { Loading } from '@this/shared/ui/feedbacks/loading';
import type { OrderItemJobTypeKey } from '@this/src/domain/order_item/order_item_job_type';
import { OrderItemJobTypeNumKeys } from '@this/src/domain/order_item/order_item_job_type';
import type { OrderItemStatusKey } from '@this/src/domain/order_item/order_item_status';
import { OrderItemStatusDone, OrderItemStatusOngoing } from '@this/src/domain/order_item/order_item_status';
import moment from 'moment';
import type { HotelElementProviderKey } from '@this/src/domain/hotel_element_provider';
import { HotelElementProviderAvailable } from '@this/src/domain/hotel_element_provider';
import type { HotelOrderItemRakutenTodoResponseArgs } from '@this/src/domain/arrangement/hotel_order_item_rakuten_todo';
import HotelOrderItemRakutenTodo from '@this/src/domain/arrangement/hotel_order_item_rakuten_todo';
import type { HotelOrderItemRakutenTotalArgs } from '@this/src/domain/arrangement/hotel_order_item_rakuten_total';
import HotelOrderItemRakutenTotal, {
  convertHotelOrderItemRakutenTotal
} from '@this/src/domain/arrangement/hotel_order_item_rakuten_total';
import type { ArrangerArgs } from '@this/src/domain/arranger/arranger';
import Arranger from '@this/src/domain/arranger/arranger';
import useOrganizationSelector from '../use_organization_selector';
import HotelOrderItemTodoListAll from './hotel_order_item_todo_list_all';
import HotelOrderItemTodoListRakuten from './hotel_order_item_todo_list_rakuten';
import SelectTab from './share/SelectTab';
import HotelOrderItemTodoSearchArea from './search/hotel_order_item_todo_search_area';
import HotelOrderItemTodoRakutenTotalSearchArea from './search/hotel_order_item_todo_rakuten_total_search_area';
import HotelOrderItemTodoListRakutenTotal from './hotel_order_item_todo_list_rakuten_total';

export type InventoryStatus = 'done' | 'not_yet' | 'all';
export type AutoApprovedStatus = 'auto' | 'manual' | 'all';
export type SortKey = 'received_at' | 'started_at';
export type SortDirection = 'asc' | 'desc';

interface Response {
  order_item_todos: HotelOrderItemTodoResponseArgs[] | HotelOrderItemRakutenTodoResponseArgs[];
  total_count: number;
  total_page: number;
}

interface RakutenTotalResponse {
  records: HotelOrderItemRakutenTotalArgs[];
  arrangers: ArrangerArgs[];
  supplied_item_id: number | null;
  supplied_item_name: string | null;
  current_arranger: ArrangerArgs;
  total_count: number;
  total_page: number;
}

interface CountResponse {
  total_count: number;
  diff_count: number;
  not_diff_count: number;
}

const Tab = {
  ALL: 'すべて',
  RAKUTEN: '楽天'
} as const;
export type TabType = keyof typeof Tab;

const RakutenTab = {
  LIST: '全件',
  TOTAL: '総数'
} as const;
export type RakutenTabType = keyof typeof RakutenTab;

const DEFAULT = 'DEFAULT';

export type SuppliedItemIdType = typeof DEFAULT | number | undefined;

const getDefaultPage = (): number => {
  const str = utils.getParam('page');
  return str ? parseInt(str, 10) : 1;
};

const getDefaultInventoryStatus = (): InventoryStatus => {
  const str = utils.getParam('inventory_status');
  return str || 'not_yet';
};

const getDefaultAutoApprovedStatus = (): AutoApprovedStatus => {
  const str = utils.getParam('auto_approved_status');
  return str || 'auto';
};

const getDefaultJobTypes = (): OrderItemJobTypeKey[] => {
  return (utils
    .getParam('job_types')
    ?.split(',')
    .map((str: string) => parseInt(str, 10)) || OrderItemJobTypeNumKeys) as OrderItemJobTypeKey[];
};

const getDefaultStatuses = (): OrderItemStatusKey[] => {
  return (utils
    .getParam('statuses')
    ?.split(',')
    .map((str: string) => parseInt(str, 10)) || [
    ...OrderItemStatusOngoing,
    ...OrderItemStatusDone
  ]) as OrderItemStatusKey[];
};

const getDefaultProviders = (): HotelElementProviderKey[] => {
  return (utils.getParam('providers')?.split(',') || HotelElementProviderAvailable) as HotelElementProviderKey[];
};

const getDefaultTripId = (): number | undefined => {
  const str = utils.getParam('trip_id');
  if (!str) {
    return undefined;
  }
  return parseInt(str, 10);
};

const getDefaultStartDateFrom = (): Moment | undefined => {
  const str = utils.getParam('start_date_from');
  return str ? moment(str) : moment();
};

const getDefaultStartDateTo = (): Moment | undefined => {
  const str = utils.getParam('start_date_to');
  return str ? moment(str) : undefined;
};

const getDefaultReceiveDateFrom = (): Moment | undefined => {
  const str = utils.getParam('receive_date_from');
  return str ? moment(str) : undefined;
};

const getDefaultReceiveDateTo = (): Moment | undefined => {
  const str = utils.getParam('receive_date_to');
  return str ? moment(str) : undefined;
};

const getDefaultSortKey = (): SortKey => {
  return (utils.getParam('sort_key') || 'started_at') as SortKey;
};

const getDefaultSortDirection = (): SortDirection => {
  return (utils.getParam('sort_direction') || 'asc') as SortDirection;
};

const HotelOrderItemTodoList = observer(() => {
  const history = useHistory();
  const [currentTab, setCurrentTab] = useState<TabType>((utils.getParam('sub_tab') as TabType) || 'ALL');
  const [rakutenTab, setRakutenTab] = useState<RakutenTabType>(
    (utils.getParam('rakuten_tab') as RakutenTabType) || 'LIST'
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isCountLoading, setIsCountLoading] = useState(false);
  const [todos, setTodos] = useState<HotelOrderItemTodo[]>([]);
  const [rakutenTodos, setRakutenTodos] = useState<HotelOrderItemRakutenTodo[]>([]);
  const [rakutenTotals, setRakutenTotals] = useState<HotelOrderItemRakutenTotal[]>([]);
  const [arrangers, setArrangers] = useState<Arranger[]>([]);
  const [currentArranger, setCurrentArranger] = useState<Arranger | null>(null);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [totalDiffCount, setTotalDiffCount] = useState<number>(0);
  const [totalNotDiffCount, setTotalNotDiffCount] = useState<number>(0);
  const [totalPage, setTotalPage] = useState(1);
  const [page, setPage] = useState(getDefaultPage());

  const [inventoryStatus, setInventoryStatus] = useState<InventoryStatus>(getDefaultInventoryStatus());
  const [autoApprovedStatus, setAutoApprovedStatus] = useState<AutoApprovedStatus>(getDefaultAutoApprovedStatus());
  const [jobTypes, setJobTypes] = useState<OrderItemJobTypeKey[]>(getDefaultJobTypes());
  const [statuses, setStatuses] = useState<OrderItemStatusKey[]>(getDefaultStatuses());
  const [providers, setProviders] = useState<HotelElementProviderKey[]>(getDefaultProviders());
  const [tripId, setTripId] = useState<number | undefined>(getDefaultTripId());
  const [suppliedItemId, setSuppliedItemId] = useState<number | undefined>(undefined);
  const [suppliedItemName, setSuppliedItemName] = useState<string>('');
  const [startDateFrom, setStartDateFrom] = useState<Moment | undefined>(getDefaultStartDateFrom());
  const [startDateTo, setStartDateTo] = useState<Moment | undefined>(getDefaultStartDateTo());
  const [receiveDateFrom, setReceiveDateFrom] = useState<Moment | undefined>(getDefaultReceiveDateFrom());
  const [receiveDateTo, setReceiveDateTo] = useState<Moment | undefined>(getDefaultReceiveDateTo());
  const [organizationIds, renderOrganizationSelector] = useOrganizationSelector();
  const [logExists, setLogExists] = useState('');
  const [sortKey, setSortKey] = useState<SortKey>(getDefaultSortKey());
  const [sortDirection, setSortDirection] = useState<SortDirection>(getDefaultSortDirection());
  const [showDescription, setShowDescription] = useState(false);
  const [isExternal, setIsExternal] = useState(false);
  const [isPaginate, setIsPaginate] = useState(true);
  const [isDiff, setIsDiff] = useState(false);
  const [isNotDiff, setIsNotDiff] = useState(true);
  const fetchingRef = useRef(false);

  const fetchTodos = useCallback(() => {
    if (fetchingRef.current) {
      fetchingRef.current = false;
      return;
    }
    setIsLoading(true);
    setTodos([]);
    setRakutenTodos([]);
    setRakutenTotals([]);
    setTotalCount(0);
    setTotalPage(1);

    const params = {
      sub_tab: currentTab,
      rakuten_tab: rakutenTab,
      page,
      inventory_status: inventoryStatus,
      auto_approved_status: autoApprovedStatus,
      job_types: jobTypes,
      statuses,
      providers,
      trip_id: tripId,
      supplied_item_type: currentTab === 'RAKUTEN' && rakutenTab === 'TOTAL' ? 'rakuten' : undefined,
      start_date_from: startDateFrom?.format('YYYY-MM-DD'),
      start_date_to: startDateTo?.format('YYYY-MM-DD'),
      receive_date_from: receiveDateFrom?.format('YYYY-MM-DD'),
      receive_date_to: receiveDateTo?.format('YYYY-MM-DD'),
      organization_ids: organizationIds.join(','),
      log_exists: logExists,
      sort_key: sortKey,
      sort_direction: sortDirection,
      external: isExternal ? 'true' : 'false',
      pagination: isPaginate ? 'true' : 'false'
    };
    _.each(params, (value, key) => {
      if (value === undefined) {
        delete params[key as keyof typeof params];
      }
    });
    const query = _.map(params, (value, key) => `${key}=${value}`).join('&');
    history.push(`/arrangement/todo_list?tab=INVENTORY_HOTEL&${query}`);

    utils
      .jsonPromise<Response | RakutenTotalResponse>('/arrangement/hotel_order_item_todos.json', params)
      .then(res => {
        if (currentTab === 'ALL') {
          const data = res as Response;
          setTodos(
            (data.order_item_todos as HotelOrderItemTodoResponseArgs[]).map(args => new HotelOrderItemTodo(args))
          );
        } else if (currentTab === 'RAKUTEN') {
          if (rakutenTab === 'LIST') {
            const data = res as Response;
            setRakutenTodos(
              (data.order_item_todos as HotelOrderItemRakutenTodoResponseArgs[]).map(args =>
                HotelOrderItemRakutenTodo.fromResponse(args)
              )
            );
          } else if (rakutenTab === 'TOTAL') {
            const data = res as RakutenTotalResponse;
            setRakutenTotals(
              data.records.map(args => new HotelOrderItemRakutenTotal(convertHotelOrderItemRakutenTotal(args)))
            );
            setArrangers(data.arrangers.map(args => new Arranger(args)));
            setSuppliedItemId(data.supplied_item_id || undefined);
            setSuppliedItemName(data.supplied_item_name || '');
            setCurrentArranger(new Arranger(data.current_arranger));
          }
        }
        setTotalCount(res.total_count);
        setTotalPage(res.total_page);
        if (res.total_page < page) {
          setPage(1);
        }
      })
      .catch(e => {
        Notification.error('データの取得に失敗しました');
        utils.sendErrorObject(e);
      })
      .finally(() => setIsLoading(false));
  }, [
    currentTab,
    rakutenTab,
    page,
    inventoryStatus,
    autoApprovedStatus,
    jobTypes,
    statuses,
    providers,
    tripId,
    startDateFrom,
    startDateTo,
    receiveDateFrom,
    receiveDateTo,
    organizationIds,
    logExists,
    sortKey,
    sortDirection,
    isExternal,
    isPaginate,
    fetchingRef
  ]);

  const fetchTodoCount = useCallback(() => {
    if (currentTab !== 'RAKUTEN' || rakutenTab !== 'LIST') return;

    setIsCountLoading(true);

    const params = {
      sub_tab: currentTab,
      rakuten_tab: rakutenTab,
      inventory_status: inventoryStatus,
      auto_approved_status: autoApprovedStatus,
      job_types: jobTypes,
      statuses,
      providers,
      trip_id: tripId,
      start_date_from: startDateFrom?.format('YYYY-MM-DD'),
      start_date_to: startDateTo?.format('YYYY-MM-DD'),
      receive_date_from: receiveDateFrom?.format('YYYY-MM-DD'),
      receive_date_to: receiveDateTo?.format('YYYY-MM-DD'),
      organization_ids: organizationIds.join(','),
      log_exists: logExists,
      external: isExternal ? 'true' : 'false',
      count: 'true'
    };

    utils
      .jsonPromise<CountResponse>('/arrangement/hotel_order_item_todos.json', params)
      .then(res => {
        setTotalCount(res.total_count);
        setTotalDiffCount(res.diff_count);
        setTotalNotDiffCount(res.not_diff_count);
      })
      .finally(() => setIsCountLoading(false));
  }, [
    currentTab,
    rakutenTab,
    inventoryStatus,
    autoApprovedStatus,
    jobTypes,
    statuses,
    providers,
    tripId,
    startDateFrom,
    startDateTo,
    receiveDateFrom,
    receiveDateTo,
    organizationIds,
    logExists,
    isExternal
  ]);

  useEffect(() => {
    fetchTodos();
  }, [fetchTodos]);

  useEffect(() => {
    fetchTodoCount();
  }, [fetchTodoCount]);

  return (
    <>
      <SelectTab tabs={Tab} currentTab={currentTab} onChange={setCurrentTab} />
      {currentTab === 'RAKUTEN' && (
        <SelectTab tabs={RakutenTab} currentTab={rakutenTab} onChange={setRakutenTab} />
      )}
      {currentTab !== 'RAKUTEN' || rakutenTab === 'LIST' ? (
        <HotelOrderItemTodoSearchArea
          isRakutenTab={currentTab === 'RAKUTEN'}
          jobTypes={jobTypes}
          statuses={statuses}
          autoApprovedStatus={autoApprovedStatus}
          inventoryStatus={inventoryStatus}
          providers={providers}
          tripId={tripId}
          startDateFrom={startDateFrom}
          startDateTo={startDateTo}
          receiveDateFrom={receiveDateFrom}
          receiveDateTo={receiveDateTo}
          showDescription={showDescription}
          logExists={logExists}
          sortKey={sortKey}
          sortDirection={sortDirection}
          isPaginate={isPaginate}
          isExternal={isExternal}
          isDiff={isDiff}
          isNotDiff={isNotDiff}
          setJobTypes={setJobTypes}
          setStatuses={setStatuses}
          setAutoApprovedStatus={setAutoApprovedStatus}
          setInventoryStatus={setInventoryStatus}
          setProviders={setProviders}
          setTripId={setTripId}
          setStartDateFrom={setStartDateFrom}
          setStartDateTo={setStartDateTo}
          setReceiveDateFrom={setReceiveDateFrom}
          setReceiveDateTo={setReceiveDateTo}
          setShowDescription={setShowDescription}
          setLogExists={setLogExists}
          setSortKey={setSortKey}
          setSortDirection={setSortDirection}
          setIsPaginate={setIsPaginate}
          setIsExternal={setIsExternal}
          setIsDiff={setIsDiff}
          setIsNotDiff={setIsNotDiff}
          renderOrganizationSelector={renderOrganizationSelector}
        />
      ) : (
        <HotelOrderItemTodoRakutenTotalSearchArea
          startDateFrom={startDateFrom}
          startDateTo={startDateTo}
          suppliedItemName={suppliedItemName}
          setStartDateFrom={setStartDateFrom}
          setStartDateTo={setStartDateTo}
        />
      )}
      {isLoading ? (
        <Loading />
      ) : currentTab === 'ALL' ? (
        <HotelOrderItemTodoListAll
          todos={todos}
          totalCount={totalCount}
          totalPage={totalPage}
          page={page}
          showDescription={showDescription}
          fetchTodos={fetchTodos}
          setPage={setPage}
        />
      ) : currentTab === 'RAKUTEN' ? (
        rakutenTab === 'LIST' ? (
          <HotelOrderItemTodoListRakuten
            todos={rakutenTodos}
            totalCount={totalCount}
            totalDiffCount={totalDiffCount}
            totalNotDiffCount={totalNotDiffCount}
            totalPage={totalPage}
            page={page}
            showDescription={showDescription}
            isCountLoading={isCountLoading}
            isDiff={isExternal || isDiff}
            isNotDiff={isExternal || isNotDiff}
            fetchTodos={fetchTodos}
            setPage={setPage}
          />
        ) : rakutenTab === 'TOTAL' ? (
          <HotelOrderItemTodoListRakutenTotal
            hotelOrderItemRakutenTotals={rakutenTotals}
            arrangers={arrangers}
            currentArranger={currentArranger}
            suppliedItemId={suppliedItemId || null}
          />
        ) : null
      ) : null}
    </>
  );
});

export default HotelOrderItemTodoList;
