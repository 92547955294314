import { observable } from 'mobx';

export type InvoiceSettingArgs = {
  id: number;
  from_month: string;
  from_day: number;
  to_month: string;
  to_day: number;
  auto_create_month?: string;
  auto_create_day?: number;
  payment_expired_month?: string;
  payment_expired_day?: number;
  closing_day?: number;
  memo?: string;
};

export default class InvoiceSetting {
  private id: number;

  @observable
  fromMonth: string;

  @observable
  fromDay: number;

  @observable
  toMonth: string;

  @observable
  toDay: number;

  @observable
  autoCreateMonth?: string;

  @observable
  autoCreateDay?: number;

  @observable
  paymentExpiredMonth?: string;

  @observable
  paymentExpiredDay?: number;

  @observable
  closingDay?: number;

  @observable
  memo?: string;

  constructor(args: InvoiceSettingArgs) {
    this.id = args.id;
    this.fromMonth = args.from_month;
    this.fromDay = args.from_day;
    this.toMonth = args.to_month;
    this.toDay = args.to_day;
    this.autoCreateMonth = args.auto_create_month;
    this.autoCreateDay = args.auto_create_day;
    this.paymentExpiredMonth = args.payment_expired_month;
    this.paymentExpiredDay = args.payment_expired_day;
    this.closingDay = args.closing_day;
    this.memo = args.memo;
  }

  submitParams() {
    return {
      id: this.id,
      from_month: this.fromMonth,
      from_day: this.fromDay,
      to_month: this.toMonth,
      to_day: this.toDay,
      auto_create_month: this.autoCreateMonth ?? '',
      auto_create_day: this.autoCreateDay ?? '',
      payment_expired_month: this.paymentExpiredMonth,
      payment_expired_day: this.paymentExpiredDay,
      closing_day: this.closingDay,
      memo: this.memo
    };
  }
}
