import React from 'react';
import _ from 'lodash';
import { styled } from '@this/constants/themes';
import { Loading } from '@this/src/components/shared/ui/feedbacks/loading';
import type { SelectTransportBoxProps, SelectTransportBoxState } from './transport_box';
import SelectTransportBoxRightInOutlineShortdistanceTemplate from './right/right_in_outline_shortdistance.template';
import SelectTransportBoxRightInListTemplate from './right/right_in_list.template';
import SelectTransportBoxRightInDomesticAirListTemplate from './right/right_in_domestic_air_list.template';
import type SelectStore from '../../../../domain/select_store';
import type SegmentClass from '../../../../domain/transit/segment';
import { LegTime, LegTimeSpace, BoxTitle, Summary, Segment, Name } from './transport_box_shortdistance.template';

type Props = SelectTransportBoxProps &
  SelectTransportBoxState & {
    handleDomesticAirPriceChange: (index: number) => void;
    handleChange: () => void;
    store: SelectStore;
    segment: SegmentClass;
    segmentIdx: number;
    direction?: string;
  };

const SelectTransportBoxShortdistancePlaneTemplate: React.SFC<Props> = ({
  transport,
  inList,
  selected,
  domesticAirPriceIndex,
  loading,
  handleDomesticAirPriceChange,
  handleChange,
  store,
  isRequestForm,
  segment,
  segmentIdx
}) => (
  <div className="select-transport-box__button_exist_leg_shortdistance">
    <Left>
      {segment.legs.map((leg, i) => (
        <Segment key={`plane_leg_short_distance_${i}`} inList={inList}>
          <Summary>
            <BoxContent>
              <LegTime>{leg.from.time}</LegTime>
              <BoxTitle>出発</BoxTitle>
              <Space
                className={
                  !segment.isUseSelectTransportBox() &&
                  (transport.airExists() || leg.type === 'shin' || leg.type === 'express')
                    ? 'select-transport-box__button_exist_leg_station'
                    : ''
                }
              >
                {leg.from.name === '' ? <Loading size="small" /> : <Name>{leg.from.name}</Name>}
              </Space>
            </BoxContent>
            <BoxContent>
              <LegTimeSpace />
              {segment.type === 'shin' && <Image src="/images/train.png" />}
              {segment.type === 'air' && <Image src="/images/plane.png" />}
              <Space>
                {leg.airline_name && `${leg.airline_name} `}
                {leg.name}
              </Space>
              <ImageBox>
                <Image src={segment.imagePathByIndex(segmentIdx)} />
              </ImageBox>
            </BoxContent>
            <BoxContent>
              <LegTime>{leg.to.time}</LegTime>
              <BoxTitle>到着</BoxTitle>
              <Space
                className={
                  !segment.isUseSelectTransportBox() &&
                  (transport.airExists() || leg.type === 'shin' || leg.type === 'express')
                    ? 'select-transport-box__button_exist_leg_station'
                    : ''
                }
              >
                {leg.to.name === '' ? <Loading size="small" /> : <Name>{leg.to.name}</Name>}
              </Space>
            </BoxContent>
            <div className="select-transport-box__left__boarding-arrival">
              {segment.shouldShowBoardingStation() &&
                i === 0 && [`乗車駅：${segment.boardingName()}`, <br key={0} />]}
              {segment.shouldShowArrivalStation() &&
                i === segment.legs.length - 1 &&
                `降車駅：${segment.arrivalName()}`}
            </div>
            {!inList && !transport.package && transport.airExists() && (
              <>
                {transport.prices && transport.prices[domesticAirPriceIndex] && (
                  <BoxContent>
                    <LegTime />
                    <BoxTitle>備考</BoxTitle>
                    <Space>
                      {transport.prices[domesticAirPriceIndex].ticket_category === 'changeable'
                        ? '変更可'
                        : '変更不可'}
                    </Space>
                    <Space>{transport.prices[domesticAirPriceIndex].seat_type}</Space>
                    <Space>
                      {transport.prices[domesticAirPriceIndex].seat_count !== 'unknown' && (
                        <>
                          {transport.prices[domesticAirPriceIndex].seat_count === '○' ||
                          Number(transport.prices[domesticAirPriceIndex].seat_count) >= 10
                            ? '10席以上'
                            : `${transport.prices[domesticAirPriceIndex].seat_count}席`}
                        </>
                      )}
                    </Space>
                  </BoxContent>
                )}
              </>
            )}
          </Summary>
        </Segment>
      ))}
    </Left>
    {!segment.isUseSelectTransportBox() && transport.airExists() && (
      <>
        {segment.useSelectTransportBox()}
        {!inList ? (
          <SelectTransportBoxRightInOutlineShortdistanceTemplate
            price={((): number | undefined => {
              // ボタン押下後に呼ばれる。
              if (transport.package && store.result.packageProvider === 'wbf') {
                // パッケージ
                return transport.additionalPrice || 0;
              }
              // 個別
              if (transport.prices && transport.prices[domesticAirPriceIndex]) {
                // 国内航空券
                return transport.prices[domesticAirPriceIndex].price;
              }
              // 新幹線
              return transport.price;
            })()}
            isAddtional={transport.package === true && store.result.packageProvider === 'wbf'}
            priceRange={store.result.type === 'airPackage' ? transport.price_range : undefined}
            includeTax={transport.includeTax}
            selected={selected}
            onClick={handleChange}
            showsPricePerPersonText={store.result.type !== 'airPackage'}
            showsIncludeTax={store.result.type !== 'airPackage'}
            isRequestForm={isRequestForm || false}
            isRakutenPackage={transport.isRakutenPackage}
          />
        ) : (
          <div className="select-transport-box__right-wrapper-shortdistance">
            {transport.package ? (
              // パッケージ
              <SelectTransportBoxRightInListTemplate
                loading={loading}
                selected={selected}
                hovered={transport.hovered}
                price={store.result.packageProvider === 'wbf' ? transport.additionalPrice || 0 : undefined}
                isAddtional={store.result.packageProvider === 'wbf'}
                priceRange={store.result.type === 'airPackage' ? transport.price_range : undefined}
                showsPricePerPersonText={store.result.type !== 'airPackage'}
                onClick={handleChange}
                onTouchStart={transport.handleTouched}
                onMouseOver={transport.setHovered(true)}
                onMouseLeave={transport.setHovered(false)}
                isPackage={transport.package}
              />
            ) : !transport.airExists() ? (
              // 個別/新幹線
              <SelectTransportBoxRightInListTemplate
                loading={loading}
                selected={selected}
                hovered={transport.hovered}
                price={transport.price}
                onClick={handleChange}
                onTouchStart={transport.handleTouched}
                onMouseOver={transport.setHovered(true)}
                onMouseLeave={transport.setHovered(false)}
              />
            ) : (
              // 個別/航空券 : 変更可、不可 x 席種のボタンを表示
              <SelectTransportBoxDomesticAirRightWrapper>
                {transport.prices.map((p, i) => (
                  <div key={i} onClick={() => handleDomesticAirPriceChange(i)}>
                    <SelectTransportBoxRightInDomesticAirListTemplate
                      key={i}
                      loading={loading}
                      selected={selected && i === domesticAirPriceIndex}
                      price={p}
                    />
                  </div>
                ))}
              </SelectTransportBoxDomesticAirRightWrapper>
            )}
          </div>
        )}
      </>
    )}
  </div>
);

export const BoxContent = styled.div`
  display: flex;
  padding: 5px;
`;

export const Left = styled.div`
  flex-shrink: 10;
  width: 100%;
`;

export const ImageBox = styled.div`
  display: flex;
  min-width: 20px;
  margin-right: 5px;
`;

export const Image = styled.img`
  height: 20px;
  margin-left: 5px;
`;

export const BoxSpace = styled.div`
  width: 25px;
  margin-right: 10px;
`;

export const Space = styled.div`
  margin-right: 10px;
  min-width: 15px;
`;

export const SpaceLeft = styled.div`
  margin-left: 20px;
`;

export const TitleSpace = styled.div`
  margin-left: 35px;
  margin-right: 10px;
`;

const SelectTransportBoxDomesticAirRightWrapper = styled.div`
  display: block;
  margin-left: auto;
  min-width: 242px;
  background: #f8f6f1;
  padding: 12px;
`;

export default SelectTransportBoxShortdistancePlaneTemplate;
