import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import _ from 'lodash';
import type { Moment } from 'moment';
import Notification from '@this/src/notification';
import { Loading } from '@this/shared/ui/feedbacks/loading';
import type { OrderItemJobTypeKey } from '@this/src/domain/order_item/order_item_job_type';
import { OrderItemJobTypeNumKeys } from '@this/src/domain/order_item/order_item_job_type';
import type { OrderItemStatusKey } from '@this/src/domain/order_item/order_item_status';
import { OrderItemStatusDone, OrderItemStatusOngoing } from '@this/src/domain/order_item/order_item_status';
import moment from 'moment';
import type { JalReservationTodoArgs } from '@this/src/domain/arrangement/jal_reservation_todo';
import JalReservationTodo, { convertJalReservationTodo } from '@this/src/domain/arrangement/jal_reservation_todo';
import type { JalReservationTotalArgs } from '@this/src/domain/arrangement/jal_reservation_total';
import JalReservationTotal, {
  convertJalReservationTotal
} from '@this/src/domain/arrangement/jal_reservation_total';
import type { ArrangerArgs } from '@this/src/domain/arranger/arranger';
import { Fetcher } from '@this/src/util';
import useOrganizationSelector from '../use_organization_selector';
import AirAutoJalReservationTodoList from './air_auto_jal_reservation_todo_list';
import SelectTab from './share/SelectTab';
import TodoSearchArea from './search/todo_search_area';
import TotalSearchArea from './search/total_search_area';
import AirAutoJalReservationTotal from './air_auto_jal_reservation_total';

export type InventoryStatus = 'done' | 'not_yet' | 'all';
export type AutoApprovedStatus = 'auto' | 'manual' | 'all';
export type SortKey = 'received_at' | 'started_at';
export type SortDirection = 'asc' | 'desc';

interface Response {
  jal_reservations: JalReservationTodoArgs[];
  total_count: number;
  total_page: number;
}

interface TotalResponse {
  jal_reservations: JalReservationTotalArgs[];
  arrangers: ArrangerArgs[];
  current_arranger: ArrangerArgs;
  total_count: number;
  total_page: number;
}

const Tab = {
  JAL: 'JAL自動予約'
} as const;
export type TabType = keyof typeof Tab;

const JalTab = {
  LIST: '全件',
  TOTAL: '総数'
} as const;
export type JalTabType = keyof typeof JalTab;

const getDefaultPage = (): number => {
  const str = utils.getParam('page');
  return str ? parseInt(str, 10) : 1;
};

const getDefaultInventoryStatus = (): InventoryStatus => {
  const str = utils.getParam('inventory_status');
  return str || 'not_yet';
};

const getDefaultJobTypes = (): OrderItemJobTypeKey[] => {
  return (utils
    .getParam('job_types')
    ?.split(',')
    .map((str: string) => parseInt(str, 10)) || OrderItemJobTypeNumKeys) as OrderItemJobTypeKey[];
};

const getDefaultStatuses = (): OrderItemStatusKey[] => {
  return (utils
    .getParam('statuses')
    ?.split(',')
    .map((str: string) => parseInt(str, 10)) || [
    ...OrderItemStatusOngoing,
    ...OrderItemStatusDone
  ]) as OrderItemStatusKey[];
};

const getDefaultTripId = (): number | undefined => {
  const str = utils.getParam('trip_id');
  if (!str) {
    return undefined;
  }
  return parseInt(str, 10);
};

const getDefaultStartDateFrom = (): Moment | undefined => {
  const str = utils.getParam('reservation_date_from');
  return str ? moment(str) : moment();
};

const getDefaultStartDateTo = (): Moment | undefined => {
  const str = utils.getParam('reservation_date_to');
  return str ? moment(str) : undefined;
};

const getDefaultReceiveDateFrom = (): Moment | undefined => {
  const str = utils.getParam('target_date_from');
  return str ? moment(str) : undefined;
};

const getDefaultReceiveDateTo = (): Moment | undefined => {
  const str = utils.getParam('target_date_to');
  return str ? moment(str) : undefined;
};

const getDefaultSortKey = (): SortKey => {
  return (utils.getParam('sort_key') || 'reservation_date') as SortKey;
};

const getDefaultSortDirection = (): SortDirection => {
  return (utils.getParam('sort_direction') || 'asc') as SortDirection;
};

const AirAutoReservationOrderItemTodoList = observer(() => {
  const history = useHistory();
  const [currentTab, setCurrentTab] = useState<TabType>('JAL');
  const [jalTab, setJalTab] = useState<JalTabType>((utils.getParam('jal_tab') as JalTabType) || 'LIST');
  const [isLoading, setIsLoading] = useState(false);
  const [jalReservationTodos, setJalReservationTodos] = useState<JalReservationTodo[]>([]);
  const [jalReservationTotals, setJalReservationTotals] = useState<JalReservationTotal[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [totalPage, setTotalPage] = useState(1);
  const [page, setPage] = useState(getDefaultPage());

  // 検索条件
  const [inventoryStatus, setInventoryStatus] = useState<InventoryStatus>(getDefaultInventoryStatus());
  const [jobTypes, setJobTypes] = useState<OrderItemJobTypeKey[]>(getDefaultJobTypes());
  const [statuses, setStatuses] = useState<OrderItemStatusKey[]>(getDefaultStatuses());
  const [tripId, setTripId] = useState<number | undefined>(getDefaultTripId());
  const [startDateFrom, setStartDateFrom] = useState<Moment | undefined>(getDefaultStartDateFrom());
  const [startDateTo, setStartDateTo] = useState<Moment | undefined>(getDefaultStartDateTo());
  const [receiveDateFrom, setReceiveDateFrom] = useState<Moment | undefined>(getDefaultReceiveDateFrom());
  const [receiveDateTo, setReceiveDateTo] = useState<Moment | undefined>(getDefaultReceiveDateTo());
  const [organizationIds, renderOrganizationSelector] = useOrganizationSelector();
  const [sortKey, setSortKey] = useState<SortKey>(getDefaultSortKey());
  const [sortDirection, setSortDirection] = useState<SortDirection>(getDefaultSortDirection());
  const [isPaginate, setIsPaginate] = useState(true);
  const fetchingRef = useRef(false);

  const fetchTodos = useCallback(() => {
    if (fetchingRef.current) {
      fetchingRef.current = false;
      return;
    }
    setIsLoading(true);
    setJalReservationTodos([]);
    setJalReservationTotals([]);
    setTotalCount(0);
    setTotalPage(1);

    const params = buildParams();
    _.each(params, (value, key) => {
      if (value === undefined) {
        delete params[key as keyof typeof params];
      }
    });
    const query = _.map(params, (value, key) => `${key}=${value}`).join('&');
    history.push(`/arrangement/todo_list?tab=AIR_AUTO_RESERVATION_INVENTORY&${query}`);

    Fetcher.get<Response>('/arrangement/air_auto_reservation_order_item_todos.json', params)
      .then(res => {
        if (currentTab === 'JAL') {
          if (jalTab === 'LIST') {
            const data = res as Response;
            setJalReservationTodos(
              data.jal_reservations.map(args => new JalReservationTodo(convertJalReservationTodo(args)))
            );
          } else if (jalTab === 'TOTAL') {
            const data = res as unknown as TotalResponse;
            setJalReservationTotals(
              data.jal_reservations.map(args => new JalReservationTotal(convertJalReservationTotal(args)))
            );
          }
        }
        setTotalCount(res.total_count);
        setTotalPage(res.total_page);
        if (res.total_page < page) {
          setPage(1);
        }
      })
      .catch(e => {
        Notification.error('データの取得に失敗しました');
        utils.sendErrorObject(e);
      })
      .finally(() => setIsLoading(false));
  }, [
    currentTab,
    jalTab,
    page,
    inventoryStatus,
    jobTypes,
    statuses,
    tripId,
    startDateFrom,
    startDateTo,
    receiveDateFrom,
    receiveDateTo,
    organizationIds,
    sortKey,
    sortDirection,
    isPaginate,
    fetchingRef
  ]);

  const buildParams = () => {
    return {
      sub_tab: currentTab,
      jal_tab: jalTab,
      page,
      inventory_status: inventoryStatus,
      job_types: jobTypes,
      statuses,
      trip_id: tripId,
      reservation_date_from: startDateFrom?.format('YYYY-MM-DD'),
      reservation_date_to: startDateTo?.format('YYYY-MM-DD'),
      target_date_from: receiveDateFrom?.format('YYYY-MM-DD'),
      target_date_to: receiveDateTo?.format('YYYY-MM-DD'),
      organization_ids: organizationIds.join(','),
      sort_key: sortKey,
      sort_direction: sortDirection,
      pagination: isPaginate ? 'true' : 'false'
    };
  };

  const handleCsvDownload = useCallback(() => {
    if (fetchingRef.current) {
      return;
    }
    fetchingRef.current = true;

    const params = buildParams();
    params.pagination = 'false'; // CSVダウンロードの場合はページネーション不要

    const query = Object.entries(params)
      .reduce<string[]>((acc, [key, value]) => {
        if (value !== undefined && value !== null && value !== '') {
          // lintでエラーが出るための理由
          let stringValue: string;
          if (Array.isArray(value)) {
            stringValue = value.join(',');
          } else {
            stringValue = String(value);
          }
          acc.push(`${encodeURIComponent(key)}=${encodeURIComponent(stringValue)}`);
        }
        return acc;
      }, [])
      .join('&');
    window.location.href = `/arrangement/air_auto_reservation_order_item_todos/download_csv?${query}`;
    // fetch完了後にfetchingRefをリセット
    setTimeout(() => {
      fetchingRef.current = false;
    }, 1000);
  }, [
    currentTab,
    jalTab,
    page,
    inventoryStatus,
    jobTypes,
    statuses,
    tripId,
    startDateFrom,
    startDateTo,
    receiveDateFrom,
    receiveDateTo,
    organizationIds,
    sortKey,
    sortDirection,
    isPaginate,
    fetchingRef
  ]);

  useEffect(() => {
    fetchTodos();
  }, [fetchTodos]);

  return (
    <>
      <SelectTab tabs={Tab} currentTab={currentTab} onChange={setCurrentTab} />
      {currentTab === 'JAL' && <SelectTab tabs={JalTab} currentTab={jalTab} onChange={setJalTab} />}
      {jalTab === 'LIST' ? (
        <TodoSearchArea
          jobTypes={jobTypes}
          statuses={statuses}
          inventoryStatus={inventoryStatus}
          tripId={tripId}
          startDateFrom={startDateFrom}
          startDateTo={startDateTo}
          receiveDateFrom={receiveDateFrom}
          receiveDateTo={receiveDateTo}
          sortKey={sortKey}
          sortDirection={sortDirection}
          isPaginate={isPaginate}
          setJobTypes={setJobTypes}
          setStatuses={setStatuses}
          setInventoryStatus={setInventoryStatus}
          setTripId={setTripId}
          setStartDateFrom={setStartDateFrom}
          setStartDateTo={setStartDateTo}
          setReceiveDateFrom={setReceiveDateFrom}
          setReceiveDateTo={setReceiveDateTo}
          setSortKey={setSortKey}
          setSortDirection={setSortDirection}
          setIsPaginate={setIsPaginate}
          renderOrganizationSelector={renderOrganizationSelector}
        />
      ) : (
        <TotalSearchArea
          startDateFrom={startDateFrom}
          startDateTo={startDateTo}
          setStartDateFrom={setStartDateFrom}
          setStartDateTo={setStartDateTo}
        />
      )}
      {isLoading ? (
        <Loading />
      ) : currentTab === 'JAL' ? (
        jalTab === 'LIST' ? (
          <AirAutoJalReservationTodoList
            todos={jalReservationTodos}
            totalCount={totalCount}
            totalPage={totalPage}
            page={page}
            fetchTodos={fetchTodos}
            setPage={setPage}
            onCsvDownload={handleCsvDownload}
          />
        ) : jalTab === 'TOTAL' ? (
          <AirAutoJalReservationTotal jalReservationTotals={jalReservationTotals} fetchTodos={fetchTodos} />
        ) : null
      ) : null}
    </>
  );
});

export default AirAutoReservationOrderItemTodoList;
