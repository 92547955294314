import React from 'react';
import { styled } from '@this/constants/themes';
import type { Moment } from 'moment';

import type SelectStore from '@this/domain/select_store';
import type SearchQuery from '@this/domain/search_query';
import OrderItem from '@this/domain/order_item';
import NonOrderItem from '@this/domain/non_order_item';
import type TransportElement from '@this/domain/transport_element';
import HotelElement from '@this/domain/hotel_element';
import type SearchResultItem from '@this/domain/search_result_item';
import type SelectRepository from '@this/domain/select_repository';
import SearchTripForm from '@this/shared/search/search_trip_form';
import SelectOutlineItem from '../../../reserve_trip/select/outline_item/outline_item';
import SelectOutlineItemShortdistance from '../../../reserve_trip/select/outline_item/outline_item_shortdistance';
import SelectListArea from '../../../reserve_trip/select/list_area/list_area';
import SelectListAreaShortdistance from '../../../reserve_trip/select/list_area/list_area_shortdistance';
import TransportBlockTemplate from '../transport_block/transport_block.template';
import HotelBlockTemplate from '../hotel_block/hotel_block.template';
import OrderItemBlock from '../order_item_block/order_item_block';
import TripItemFormTemplate from '../trip_item_form.template';

export interface AirlineName {
  code: string;
  name: string;
}

interface Props {
  serviceId: number | undefined;
  user: any;
  trip: any;
  query: SearchQuery | undefined;
  store: SelectStore | undefined;
  repository: SelectRepository | undefined;
  domesticAirlines: AirlineName[];
  internationalAirlines: AirlineName[];
  addedItems: any;
  editingNonOrderItem: NonOrderItem | null;
  creatingNonOrderItem: NonOrderItem | null;
  selectedItemType: string | null;
  selectItemType: (elementType: string) => void;
  deselectItemType: (e: React.MouseEvent<HTMLElement>) => void;
  isSelectItemTypeClass: (elementType: string) => string;
  startEditingNonOrderItem: (item: NonOrderItem) => void;
  toggleNonOrderItemNeed: (item: NonOrderItem) => void;
  handleSelectItem: () => void;
  handleOpenShowSearchBox: () => void;
  handleShowItemForm: () => void;
  toggleShowSearchBox: () => void;
  changeSmartSearch: () => void;
  handleSmartSearch: (query: SearchQuery) => void;
  addNonOrderItemToTrip: (e: React.FormEvent<HTMLFormElement>) => void;
  handleTimeChange: (item: NonOrderItem, type: 'from' | 'to', time: Moment) => void;
  tripItemFormTemplateErrMsg: string;
  isSmartSearching: boolean;
  showItemForm: boolean;
  showSearchBox: boolean;
  shortdistance: boolean;
  formToggleCheck: boolean;
  useSmartSearch: boolean;
}

const TripsFormItemformTemplate: React.SFC<Props> = ({
  serviceId,
  user,
  trip,
  query,
  store,
  repository,
  domesticAirlines,
  internationalAirlines,
  addedItems,
  editingNonOrderItem,
  creatingNonOrderItem,
  selectedItemType,
  selectItemType,
  isSelectItemTypeClass,
  deselectItemType,
  startEditingNonOrderItem,
  toggleNonOrderItemNeed,
  handleSelectItem,
  handleOpenShowSearchBox,
  handleShowItemForm,
  toggleShowSearchBox,
  changeSmartSearch,
  handleSmartSearch,
  addNonOrderItemToTrip,
  handleTimeChange,
  tripItemFormTemplateErrMsg,
  isSmartSearching,
  showItemForm,
  showSearchBox,
  shortdistance,
  formToggleCheck,
  useSmartSearch
}) => (
  <>
    {useSmartSearch && (
      <ItemsFormHead>
        <ItemForm visible={showItemForm && (!store || store.currentTab === 'none')}>
          <div className="trip_form_toggle">
            <div className="trip_form_toggle_button">
              <input
                id="trip_form_toggle"
                className="trip_form_toggle_input"
                type="checkbox"
                defaultChecked={formToggleCheck}
                onClick={changeSmartSearch}
              />
              <label htmlFor="trip_form_toggle" className="trip_form_toggle_label" />
            </div>
            <p>AI Travel Smart Search モード</p>
          </div>
        </ItemForm>
      </ItemsFormHead>
    )}
    <ItemsFormBody>
      <ItemsFormLeft>
        <Outline>
          {addedItems.length === 0 && <ItemsFormStart>1</ItemsFormStart>}
          {addedItems.length > 0 ? (
            addedItems.map((item: NonOrderItem | OrderItem | SearchResultItem, i: number) => (
              <ItemsFormItemWrap key={i}>
                {item instanceof NonOrderItem ? (
                  <>
                    {item.elements[0] instanceof HotelElement ? (
                      <HotelBlockTemplate
                        icon={i + 1}
                        item={item}
                        element={item.elements[0] as HotelElement}
                        editing={item === editingNonOrderItem}
                        editable={trip === null || trip.draft || trip.tripDetailEditable()}
                        deselectItemType={deselectItemType}
                        startEditingNonOrderItem={startEditingNonOrderItem}
                        toggleNonOrderItemNeed={toggleNonOrderItemNeed}
                      />
                    ) : (
                      <TransportBlockTemplate
                        icon={i + 1}
                        item={item}
                        element={item.elements[0] as TransportElement}
                        editing={item === editingNonOrderItem}
                        editable={trip === null || trip.draft || trip.tripDetailEditable()}
                        deselectItemType={deselectItemType}
                        startEditingNonOrderItem={startEditingNonOrderItem}
                        toggleNonOrderItemNeed={toggleNonOrderItemNeed}
                      />
                    )}
                  </>
                ) : item instanceof OrderItem ? (
                  <OrderItemBlock item={item} icon={i + 1} />
                ) : store && handleSelectItem ? (
                  <>
                    {shortdistance ? (
                      <SelectOutlineItemShortdistance
                        key={item.index}
                        icon={i + 1}
                        item={item}
                        handleClickTab={store.handleClickTab}
                        handleSelectItem={handleSelectItem}
                        selected={store.currentTab === item.index}
                        hotelPriceLimit={item.hotelPriceLimit}
                        store={store}
                        direction=""
                        isRequestForm={false}
                        isTripsForm
                        handleOpenShowSearchBox={handleOpenShowSearchBox}
                      />
                    ) : (
                      <SelectOutlineItem
                        key={item.index}
                        icon={i + 1}
                        item={item}
                        handleClickTab={store.handleClickTab}
                        handleSelectItem={handleSelectItem}
                        selected={store.currentTab === item.index}
                        hotelPriceLimit={item.hotelPriceLimit}
                        store={store}
                        direction=""
                        isRequestForm={false}
                        isTripsForm
                        handleOpenShowSearchBox={handleOpenShowSearchBox}
                      />
                    )}
                  </>
                ) : null}
              </ItemsFormItemWrap>
            ))
          ) : addedItems.length === 0 ? (
            <>
              <ItemsFormItemWrap key={1} />
              <ItemsFormInstruction>
                <ItemsFormInstructionTitle>1つ目の旅程</ItemsFormInstructionTitle>
                登録する項目を右記から選択してください。
              </ItemsFormInstruction>
            </>
          ) : null}
          {(trip === null || trip.draft || trip.tripDetailEditable()) && addedItems.length > 0 && (
            <ItemsFormInstructionNext>
              <ItemsFormInstructionTitle>{addedItems.length + 1}つ目の旅程</ItemsFormInstructionTitle>
              <ItemsFormInstructionButton onClick={() => handleShowItemForm()}>
                旅程を追加する
              </ItemsFormInstructionButton>
            </ItemsFormInstructionNext>
          )}
          <ItemsFormGoal>+</ItemsFormGoal>
        </Outline>
      </ItemsFormLeft>
      <ItemsFormRight>
        {serviceId &&
          query &&
          repository &&
          store &&
          store.result.items.map((item, i) => (
            <>
              {shortdistance ? (
                <SelectListAreaShortdistance
                  key={item.index}
                  hotelPriceLimit={item.hotelPriceLimit}
                  icon={String(i + 1)}
                  repository={repository}
                  store={store}
                  listWrapper={item.elementList}
                  type={item.elementType()}
                  subtype={item.flightIndex}
                  queryItem={query.items[i]}
                  resultItem={item}
                  query={query}
                  serviceId={serviceId}
                  show={store.result.type === 'separate' && store.currentTab === item.index}
                  showSearchBox={showSearchBox}
                  toggleShowSearchBox={toggleShowSearchBox}
                />
              ) : (
                <SelectListArea
                  key={item.index}
                  icon={String(i + 1)}
                  repository={repository}
                  store={store}
                  listWrapper={item.elementList}
                  type={item.elementType()}
                  subtype={item.flightIndex}
                  queryItem={query.items[i]}
                  resultItem={item}
                  query={query}
                  serviceId={serviceId}
                  show={store.result.type === 'separate' && store.currentTab === item.index}
                  showSearchBox={showSearchBox}
                  toggleShowSearchBox={toggleShowSearchBox}
                />
              )}
            </>
          ))}
        <ItemForm visible={showItemForm && (!store || store.currentTab === 'none')}>
          <SearchBox>
            {useSmartSearch && isSmartSearching ? (
              <SmartSearchForm>
                <SearchTripForm
                  query={query}
                  user={user}
                  defaultSeatClasses={user ? user.defaultSeatClasses : []}
                  handleSearch={handleSmartSearch}
                />
              </SmartSearchForm>
            ) : trip === null || trip.draft || trip.tripDetailEditable() ? (
              <ItemTypeList>
                <ItemTypeListGroup>
                  <ItemIcon src="/images/trip_form_icon_train.png" />
                  <ItemTypeButton
                    onClick={() => selectItemType('railway_ticket')}
                    className={isSelectItemTypeClass('railway_ticket')}
                  >
                    在来線
                  </ItemTypeButton>
                  <ItemTypeButton onClick={() => selectItemType('bus')} className={isSelectItemTypeClass('bus')}>
                    バス
                  </ItemTypeButton>
                  <ItemTypeButton
                    onClick={() => selectItemType('shinkansen')}
                    className={isSelectItemTypeClass('shinkansen')}
                  >
                    新幹線
                  </ItemTypeButton>
                </ItemTypeListGroup>
                <ItemTypeListGroup>
                  <ItemIcon src="/images/trip_form_icon_car.png" />
                  <ItemTypeButton onClick={() => selectItemType('taxi')} className={isSelectItemTypeClass('taxi')}>
                    タクシー
                  </ItemTypeButton>
                  <ItemTypeButton
                    onClick={() => selectItemType('rental_car')}
                    className={isSelectItemTypeClass('rental_car')}
                  >
                    レンタカー
                  </ItemTypeButton>
                </ItemTypeListGroup>
                <ItemTypeListGroup>
                  <ItemIcon src="/images/trip_form_icon_plane.png" />
                  <ItemTypeButton
                    onClick={() => selectItemType('domestic_air')}
                    className={isSelectItemTypeClass('domestic_air')}
                  >
                    国内航空券
                  </ItemTypeButton>
                  <ItemTypeButton
                    onClick={() => selectItemType('foreign_air')}
                    className={isSelectItemTypeClass('foreign_air')}
                  >
                    海外航空券
                  </ItemTypeButton>
                </ItemTypeListGroup>
                <ItemTypeListGroup>
                  <ItemIcon src="/images/trip_form_icon_hotel.png" />
                  <ItemTypeButton
                    onClick={() => selectItemType('hotel')}
                    className={isSelectItemTypeClass('hotel')}
                  >
                    ホテル
                  </ItemTypeButton>
                </ItemTypeListGroup>
              </ItemTypeList>
            ) : null}
            {!isSmartSearching && selectedItemType && creatingNonOrderItem && (
              <TripItemFormTemplate
                item={creatingNonOrderItem}
                itemType={selectedItemType}
                editing={editingNonOrderItem && editingNonOrderItem.id === creatingNonOrderItem.id}
                domesticAirlines={domesticAirlines}
                internationalAirlines={internationalAirlines}
                deselectItemType={deselectItemType}
                addNonOrderItemToTrip={addNonOrderItemToTrip}
                handleTimeChange={handleTimeChange}
                tripItemFormTemplateErrMsg={tripItemFormTemplateErrMsg}
              />
            )}
          </SearchBox>
        </ItemForm>
      </ItemsFormRight>
    </ItemsFormBody>
  </>
);

const ItemsFormHead = styled.div`
  padding: 0px 20px;
  text-align: right;

  @media screen and (min-width: 768px) {
    margin-bottom: -15px;
  }
`;

const ItemsFormBody = styled.div`
  display: flex;
  padding: 0px 20px 100px;

  @media screen and (max-width: 767px) {
    display: block;
  }
`;

const ItemsFormLeft = styled.div`
  @media screen and (min-width: 768px) {
    width: 50%;
  }
`;

const ItemsFormRight = styled.div`
  padding-top: 15px;

  @media screen and (min-width: 768px) {
    width: 50%;
  }
`;

const Outline = styled.div`
  position: relative;
  margin: 25px 20px 25px 8px;
  border-left: solid 4px ${props => props.theme.iconColor};
  padding: 0 0 10px 10px;

  @media screen and (max-width: 767px) {
    margin-right: 0;
  }
`;

const ItemsFormCircle = styled.div`
  position: absolute;
  left: -12px;
  width: 20px;
  min-width: 20px;
  height: 20px;
  background: ${props => props.theme.iconColor};
  border-radius: 10px;
  color: white;
  line-height: 1;
  font-weight: bold;
  text-align: center;
  font-size: 13px;
  padding: 3px 0;
`;

const ItemsFormStart = styled(ItemsFormCircle)`
  top: -20px;
`;

const ItemsFormGoal = styled(ItemsFormCircle)`
  bottom: -20px;
`;

const ItemsFormItemWrap = styled.div`
  margin-left: -22px;
`;

const ItemsFormInstruction = styled.div`
  background-color: #f8f6f1;
  border: solid 2px #8f7b4a;
  padding: 15px;
  font-size: 14px;
`;

const ItemsFormInstructionNext = styled.div`
  bottom: 30px;
  left: 30px;
  background-color: #ffffff;
  border: solid 2px #8f7b4a;
  padding: 15px;
  font-size: 14px;
  width: 100%;
`;

const ItemsFormInstructionTitle = styled.h4`
  font-weight: bold;
  color: #8f7b4a;
`;

const ItemsFormInstructionButton = styled.div`
  padding: 10px;
  border: solid 2px #8f7b4a;
  font-size: 14px;
  text-align: center;
  border-radius: 10px;
  cursor: pointer;
  background-color: #8f7b4a;
  color: #ffffff;
`;

const ItemForm = styled.div<{ visible: boolean }>`
  display: none;

  ${props =>
    props.visible &&
    `
    display: block;
  `}
`;

const SmartSearchForm = styled.div``;

const SearchBox = styled.div`
  border: solid 1px #ccc;
`;

const ItemTypeList = styled.div`
  background-color: #f8f6f1;
  padding: 20px;
`;

const ItemTypeListGroup = styled.div`
  display: flex;
  flex-flow: wrap;
  justify-content: start;
  align-items: center;
  gap: 8px;
  margin-bottom: 10px;
`;

const ItemTypeButton = styled.div`
  padding: 10px 5px;
  border: solid 1px #8f7b4a;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
  width: 120px;
  border-radius: 7px;
  color: #ab9966;
  background-color: #ffffff;

  &:first-child {
    border-top-width: 2px;
  }

  @media screen and (max-width: 767px) {
    width: 100px;
  }
`;

const ItemIcon = styled.img`
  max-width: 24px;
  max-height: 24px;
`;

export default TripsFormItemformTemplate;
