/* eslint max-lines: 0 */
import React, { useState } from 'react';
import { styled } from '@this/constants/themes';
import { darken } from 'polished';
import Paper from '@material-ui/core/Paper';
import A from '@this/components/shared/atoms/a';

import HotelList from '@this/domain/hotel/hotel_list';
import FlightList from '@this/domain/flight/flight_list';
import TransitList from '@this/domain/transit/transit_list';

import HotelMap from '@this/shared/hotel_map/hotel_map';
import type { FlightType } from '@this/domain/search_query';
import { FLIGHT_TYPES } from '@this/domain/search_query';
import { Button } from '@this/src/components/shared/ui/inputs/button';
import { ModalBody, Modal, MODAL_SIZE } from '@this/components/shared/ui/feedbacks/modal';
import { useMediaQuery } from '@material-ui/core';
import SelectListAreaSearchTemplate from './list_area_search/list_area_search.template';
import SelectTransportList from './transport_list/transport_list';
import SelectHotelList from './hotel_list/hotel_list';
import SelectFlightList from './flight_list/flight_list';
import type { SelectListAreaProps, SelectListAreaState } from './list_area';
import PopupListSelector from '../popup_list_selector/popup_list_selector';
import PopupListMultiSelector from '../popup_list_multi_selector/popup_list_multi_selector';
import FlightCondition from '../flight_condition/flight_condition';
import ListAreaHotelFilter from './list_area_hotel_filter';

const DRAWER_WIDTH = 232;
const DRAWER_HEIGHT = 480;

type Props = SelectListAreaProps &
  SelectListAreaState & {
    // SelectListArea.methods
    handleTransportSegmentClick: (i: number) => () => void;
    toggleShowSearchBox: () => void;
    handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
    direction?: string;
    handleFlightConditionSubmit: (
      filteredUpgradeSeat: boolean,
      carrierIds: string[],
      changeablePrices: string[],
      showConnectingAir: boolean
    ) => void;
    openFlightCondition: () => void;
    closeFlightCondition: () => void;
    isOpenFlightCondition: boolean;
  };
const SelectListAreaTemplate: React.SFC<Props> = ({
  query,
  store,
  repository,
  serviceId,

  icon,
  listWrapper,
  type,
  subtype,
  show,
  queryItem,
  resultItem,
  direction,

  transportSegment,
  errors,
  showSearchBox,

  handleTransportSegmentClick,
  toggleShowSearchBox,
  handleSubmit,
  handleFlightConditionSubmit,
  openFlightCondition,
  closeFlightCondition,
  isOpenFlightCondition
}) => {
  const [shownFightFilter, setShownFightFilter] = useState(false);
  const isMobile = useMediaQuery('(max-width:768px)');

  return (
    <ListArea className={`select-list-area ${show ? '' : 'hidden'}`}>
      {store.result.type === 'separate' &&
        (store.result.isRoundTripSearchResult() || store.result.isMultipleLocationsSearchResult()) &&
        store.result.items.length > 0 &&
        query &&
        queryItem &&
        // 海外旅券は往復で1セットなことが多いため、往路のみ、復路のみで再検索できないようにする
        type !== 'flight' && (
          <SelectListAreaSearchTemplate
            showSearchBox={showSearchBox}
            toggleShowSearchBox={toggleShowSearchBox}
            handleSubmit={handleSubmit}
            queryItem={queryItem}
            query={query}
            errors={errors}
          />
        )}

      {listWrapper instanceof HotelList && listWrapper.filter.isShowFilter && (
        <>
          {isMobile ? (
            <Modal
              open={listWrapper.filter.isShowFilter}
              onClose={() => listWrapper.toggleShowFilter()}
              size={MODAL_SIZE.small}
            >
              <ModalBody>
                <ListAreaHotelFilter
                  hotels={listWrapper}
                  hotelPriceLimit={resultItem.hotelPriceLimit}
                  isMobile={isMobile}
                />
              </ModalBody>
            </Modal>
          ) : (
            <Drawer>
              <SearchArea>
                <ListAreaHotelFilter
                  hotels={listWrapper}
                  hotelPriceLimit={resultItem.hotelPriceLimit}
                  isMobile={isMobile}
                />
              </SearchArea>
            </Drawer>
          )}
        </>
      )}

      {resultItem && resultItem.loading ? (
        <>
          <img className="select__loading" src="/images/loading.gif" width={50} height={50} />
          {type === 'transport' ? (
            <div className="select__loading-text">経路を検索中です</div>
          ) : type === 'flight' ? (
            <div className="select__loading-text">航空券を検索中です</div>
          ) : type === 'hotel' ? (
            <div className="select__loading-text">ホテルを検索中です</div>
          ) : null}
        </>
      ) : !listWrapper ? null : (
        <>
          <Header>
            <span>
              <Icon>{icon}</Icon>
              {type === 'hotel' && listWrapper instanceof HotelList ? (
                <Title>
                  <span>表示中の候補：</span>
                  <span data-wovn-ignore>{listWrapper.filterLength}</span>
                  <span>件</span>
                  <span>（すべての候補：</span>
                  <span data-wovn-ignore>{listWrapper.allLength}</span>
                  <span>件）</span>
                </Title>
              ) : (
                <Title>
                  <span>すべての候補(</span>
                  <span data-wovn-ignore>{listWrapper.length({ subtype: subtype || 0 })}</span>
                  <span>件)</span>
                </Title>
              )}
              {type === 'hotel' && resultItem.hotelPriceLimit && resultItem.hotelPriceLimit > 0 ? (
                <Note>
                  <span>※規程金額：</span>
                  <span data-wovn-ignore>{utils.digits(resultItem.hotelPriceLimit)}</span>
                  <span>円</span>
                </Note>
              ) : (
                <></>
              )}
            </span>
            {type === 'transport' && listWrapper instanceof TransitList && !listWrapper.package ? (
              <>
                <TabButtons>
                  <TabButton
                    type="submit"
                    className="select-list-area__header__button"
                    onClick={handleTransportSegmentClick(0)}
                    data-active={transportSegment === 0}
                  >
                    飛行機
                  </TabButton>
                  <TabButton
                    type="submit"
                    className="select-list-area__header__button"
                    onClick={handleTransportSegmentClick(1)}
                    data-active={transportSegment === 1}
                  >
                    鉄道
                  </TabButton>
                </TabButtons>
                {transportSegment === 0 && (
                  <FlightConditions>
                    <ConditionLink onClick={() => openFlightCondition()}>
                      <ConditionIconImg src="/images/filter_icon.png" />
                      絞り込み
                    </ConditionLink>
                  </FlightConditions>
                )}
              </>
            ) : type === 'flight' && listWrapper instanceof FlightList && subtype !== undefined ? (
              <ForeignFlightConditions>
                <A onClick={() => setShownFightFilter(!shownFightFilter)}>
                  {shownFightFilter ? '詳細絞り込みを非表示' : '詳細絞り込みを表示'}
                </A>
              </ForeignFlightConditions>
            ) : null}

            {store.result.type === 'jrPackage' && type === 'transport' && (
              <Small>
                ※パッケージ商品の新幹線チケットは日時の変更が一切できません
                <br />
                通常の個別新幹線チケットとは異なる種類のチケットですのでご注意ください
              </Small>
            )}
          </Header>
          {type === 'hotel' && listWrapper instanceof HotelList && (
            <Filter>
              <Button
                color={listWrapper.filter.isShowFilter ? 'primary' : 'sub'}
                size="small"
                endIcon={<img src="/images/filter_icon.png" />}
                style={listWrapper.filter.isShowFilter ? filteredButtonStyles : filterButtonStyles}
                onClick={() => listWrapper.toggleShowFilter()}
              >
                {listWrapper.filter.isShowFilter ? '絞り込みを非表示' : '絞り込みを表示'}
              </Button>
              <PriceDistanceRangeNote>{listWrapper.priceRange}</PriceDistanceRangeNote>
              <PriceDistanceRangeNote>{listWrapper.distanceRange}</PriceDistanceRangeNote>
            </Filter>
          )}
          {type === 'hotel' && listWrapper instanceof HotelList && (
            <SortTabs>
              <SortTab
                active={listWrapper.sortKey === 'orderedIndex'}
                onClick={listWrapper.handleSortKeyChange('orderedIndex')}
              >
                おすすめ順
              </SortTab>
              <SortTab
                active={listWrapper.sortKey === 'average_price_with_tax'}
                onClick={listWrapper.handleSortKeyChange('average_price_with_tax')}
              >
                料金が安い順
              </SortTab>
              <SortTab
                active={listWrapper.sortKey === 'distance'}
                onClick={listWrapper.handleSortKeyChange('distance')}
              >
                目的地に近い順
              </SortTab>
              <SortTab
                active={listWrapper.sortKey === 'station_distance'}
                onClick={listWrapper.handleSortKeyChange('station_distance')}
              >
                最寄り駅に近い順
              </SortTab>
              <SortTab active={listWrapper.showMap} onClick={listWrapper.handleToggleMap}>
                {listWrapper.showMap ? 'リストから確認' : '地図から確認'}
              </SortTab>
            </SortTabs>
          )}
          {type === 'flight' && listWrapper instanceof FlightList && subtype !== undefined && (
            <>
              {shownFightFilter && (
                <>
                  <div className="select-pc__popup-list-wrapper">
                    <PopupSubWrapper>
                      <PopupListMiniTitle>+航空会社</PopupListMiniTitle>
                      <PopupListMultiSelector
                        items={(store.result.query.carrierList || store.result.flightList!.carrierList).map(
                          carrier => {
                            return { label: carrier.name, value: carrier.id };
                          }
                        )}
                        headerLabel="航空会社"
                        selectableAll
                        selectedValues={(queryItem && queryItem.carrierIds) || []}
                        onChange={(values: string[]) => {
                          if (queryItem) {
                            queryItem.setCarrierIds(values);
                          }
                          listWrapper.handleAirlinesChange(subtype, values);
                        }}
                        onSelectAll={() => {
                          if (queryItem) {
                            queryItem.setCarrierIds(['all']);
                          }
                          listWrapper.resetAirlines(subtype);
                        }}
                      />
                    </PopupSubWrapper>
                    {queryItem && queryItem.airports && (
                      <>
                        <PopupSubWrapper>
                          <PopupListMiniTitle>+出発空港</PopupListMiniTitle>
                          <PopupListSelector
                            items={queryItem.airports.origin}
                            headerLabel="出発空港"
                            name="originCode"
                            currentValue={queryItem.originCode || queryItem.airports.origin[0].value}
                            onChange={(value: string) => {
                              queryItem.setOriginCode(value);
                              if (queryItem.airports && queryItem.airports.origin[0].value === value) {
                                listWrapper.resetOriginCode(subtype);
                              } else {
                                listWrapper.handleOriginCodeChange(subtype, value);
                              }
                            }}
                          />
                        </PopupSubWrapper>
                        <PopupSubWrapper>
                          <PopupListMiniTitle>+到着空港</PopupListMiniTitle>
                          <PopupListSelector
                            items={queryItem.airports.destination}
                            headerLabel="到着空港"
                            name="destCode"
                            currentValue={queryItem.destCode || queryItem.airports.destination[0].value}
                            onChange={(value: string) => {
                              queryItem.setDestCode(value);
                              if (queryItem.airports && queryItem.airports.destination[0].value === value) {
                                listWrapper.resetDestCode(subtype);
                              } else {
                                listWrapper.handleDestCodeChange(subtype, value);
                              }
                            }}
                          />
                        </PopupSubWrapper>
                      </>
                    )}
                    <PopupSubWrapper>
                      <PopupListMiniTitle>+シートクラス</PopupListMiniTitle>
                      <PopupListMultiSelector
                        items={[
                          { label: 'エコノミークラス', value: 'M' },
                          { label: 'プレミアムエコノミー', value: 'W' },
                          { label: 'ビジネスクラス', value: 'C' },
                          { label: 'ファーストクラス', value: 'F' }
                        ]}
                        headerLabel="シートクラス"
                        selectableAll
                        selectedValues={store.result.query.cabin}
                        defaultSeatClasses={store.result.query.defaultSeatClassesForFilter()}
                        onChange={(values: string[]) => {
                          repository.updateCabin(values);
                          listWrapper.handleCabinsChange(values);
                        }}
                        onSelectAll={() => {
                          repository.updateCabin(['all']);
                        }}
                      />
                    </PopupSubWrapper>
                  </div>
                  <div className="select-pc__popup-list-wrapper">
                    <PopupSubWrapper>
                      <PopupListMiniTitle>+乗り継ぎ条件</PopupListMiniTitle>
                      <PopupListSelector
                        items={[
                          { label: 'すべて', value: '' },
                          { label: '乗り継ぎのみ', value: 'C' },
                          { label: '直行便のみ', value: 'N' }
                        ]}
                        headerLabel="乗り継ぎ条件"
                        name="flightType"
                        currentValue={store.result.query.flightType || ''}
                        onChange={(key: string) => {
                          if (!FLIGHT_TYPES.some(f => f === key)) {
                            return;
                          }

                          const flightType = key as FlightType;
                          repository.updateFlightType(flightType);
                          listWrapper.handleFlightType(subtype, flightType);
                        }}
                      />
                    </PopupSubWrapper>
                    <PopupSubWrapper>
                      <PopupListMiniTitle>+変更・キャンセル条件</PopupListMiniTitle>
                      <PopupListSelector
                        items={[
                          { label: 'すべて', value: 'all' },
                          { label: '変更可のみ', value: 'changeable' },
                          { label: 'キャンセル可のみ', value: 'cancelable' },
                          { label: '変更＆キャンセル可のみ', value: 'both' }
                        ]}
                        headerLabel="変更・キャンセル条件"
                        name="extraKey"
                        currentValue={listWrapper.minirule}
                        onChange={(key: string) => {
                          if (key === 'all' || key === 'changeable' || key === 'cancelable' || key === 'both') {
                            listWrapper.handleMiniruleChange(key);
                          }
                        }}
                      />
                    </PopupSubWrapper>
                  </div>
                </>
              )}
              <SortTabs>
                <SortTab
                  active={listWrapper.sortKey === 'orderedIndex'}
                  onClick={listWrapper.handleSortKeyChange('orderedIndex')}
                >
                  おすすめ順
                </SortTab>
                <SortTab
                  active={listWrapper.sortKey === 'price'}
                  onClick={listWrapper.handleSortKeyChange('price')}
                >
                  料金が安い順
                </SortTab>
                <SortTab
                  active={listWrapper.sortKey === 'departure'}
                  onClick={listWrapper.handleSortKeyChange('departure')}
                >
                  出発時刻が早い順
                </SortTab>
                <SortTab active={listWrapper.sortKey === 'time'} onClick={listWrapper.handleSortKeyChange('time')}>
                  所要時間が短い順
                </SortTab>
              </SortTabs>
            </>
          )}
          {type === 'transport' && listWrapper instanceof TransitList && !listWrapper.package && (
            <FlightCondition
              queryItem={queryItem}
              listWrapper={listWrapper}
              showUpgradeSeatBox={store.result.query.firstTravelerDomesticAirSeatUpgrade()}
              open={isOpenFlightCondition}
              onSubmit={handleFlightConditionSubmit}
              onAbort={closeFlightCondition}
            />
          )}
          <Body>
            {type === 'transport' && listWrapper instanceof TransitList ? (
              <SelectTransportList
                transits={listWrapper}
                resultItem={resultItem}
                repository={repository}
                store={store}
                onChange={store.setTab('none')}
                direction={direction}
                loading={false}
              />
            ) : type === 'hotel' && listWrapper instanceof HotelList ? (
              listWrapper.showMap ? (
                <div>
                  <HotelMap
                    hotels={listWrapper}
                    destLocation={resultItem ? resultItem.destLocation : null}
                    height="66vh"
                    serviceId={serviceId}
                    hotelPriceLimit={resultItem.hotelPriceLimit}
                    searchQueryId={store.result.searchQueryId}
                    onHotelSelect={(i: number) => listWrapper.selectByIndex(i)}
                    onChange={store.setTab('none')}
                    repository={store.result.type === 'separate' ? repository : undefined}
                  />
                </div>
              ) : (
                <SelectHotelList
                  hotels={listWrapper}
                  hotelPriceLimit={resultItem.hotelPriceLimit}
                  searchQueryId={store.result.searchQueryId}
                  onChange={store.setTab('none')}
                />
              )
            ) : type === 'flight' && listWrapper instanceof FlightList && subtype !== undefined ? (
              <SelectFlightList
                flights={listWrapper}
                type={subtype}
                repository={repository}
                store={store}
                onChange={store.setTab('none')}
              />
            ) : null}
          </Body>
          <Footer>
            {type === 'hotel' &&
            resultItem.hotelPriceLimit &&
            resultItem.hotelPriceLimit > 0 &&
            resultItem.hotelPriceLimitOverCount &&
            resultItem.hotelPriceLimitOverCount > 0 ? (
              <div>
                検索結果
                {listWrapper.length({ subtype: subtype || 0 }) + resultItem.hotelPriceLimitOverCount}
                件のうち、{resultItem.hotelPriceLimitOverCount}件は旅費規定（
                {utils.digits(resultItem.hotelPriceLimit)}円）を超過していました。
                <br />
                ※<br />
                管理者の設定により、旅費規定を超えるホテルの予約は禁止されています。
                <br />
                やむを得ず旅費規定を超えるホテルの予約が必要な場合は、管理者にお問い合わせください。
              </div>
            ) : null}
          </Footer>
        </>
      )}
    </ListArea>
  );
};

const ListArea = styled.div`
  flex-grow: 99999;
  background: ${props => (location.pathname.match(/trips\/\d+\/edit/) ? '#fff' : props.theme.accentColorLight)};
  padding: 10px;
  position: relative;

  &.hidden {
    display: none;
  }
`;

const Drawer = styled.div`
  position: absolute;
  top: 0;
  left: -${DRAWER_WIDTH}px;
  width: ${DRAWER_WIDTH}px;
  height: 100%;
  min-height: ${DRAWER_HEIGHT}px;
  z-index: 10;
`;

const SearchArea = styled(Paper)`
  height: 100%;
  padding: 20px;
  border-radius: 0;
  box-shadow: -10px 0 20px rgba(0, 0, 0, 0.2);
`;

const Header = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const Filter = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
  margin-bottom: 10px;
`;

const filterButtonStyles = {
  background: 'white',
  borderRadius: '2em',
  paddingRight: '16px',
  paddingLeft: '16px'
};

const filteredButtonStyles = {
  borderRadius: '2em',
  paddingRight: '16px',
  paddingLeft: '16px'
};

const Icon = styled.div`
  display: inline-block;
  text-align: center;
  width: 20px;
  height: 20px;
  background: ${props => props.theme.iconColor};
  border-radius: 10px;
  color: white;
  font-weight: bold;
  margin-right: 10px;
`;

const Title = styled.div`
  display: inline-block;
  font-weight: bold;
  margin-right: 10px;
`;

const Footer = styled.div`
  margin-top: 10px;
`;

const Note = styled.div`
  display: inline-block;
`;

const PriceDistanceRangeNote = styled.div`
  display: inline-block;
  font-size: 11px;
`;

const TabButtons = styled.div`
  display: flex;
  align-items: center;
  width: 172px;
  border: 1px solid ${props => props.theme.linkColor};
  border-radius: ${props => props.theme.buttonRadius};
`;

const TabButton = styled.button`
  display: inline-block;
  padding: 5px 0;
  background-color: ${props => props.theme.linkColor};
  border-radius: 0;
  width: 50%;
  outline: none;

  &:hover,
  &:focus {
    background-color: ${props => darken(0.1, props.theme.linkColor)};
  }

  &[data-active='false'] {
    color: ${props => props.theme.linkColor};
    background-color: #fff;
  }
`;

const FlightConditions = styled.div`
  display: flex;
  justify-content: space-between;
  flex-basis: 100%;
  font-size: 12px;
  font-weight: bold;
  margin-top: 10px;
`;

const ForeignFlightConditions = styled.div`
  font-size: 12px;
`;

const ConditionLink = styled.a`
  color: #404040;
`;

const ConditionIconImg = styled.img`
  margin: auto 2px auto 0;
  vertical-align: middle;
`;

const Small = styled.span`
  font-size: 10px;
`;

const SortTabs = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SortTab = styled.div<{ active: boolean }>`
  width: calc((100% - 5px * 3) / 4);
  cursor: pointer;
  margin-bottom: 15px;
  padding-bottom: 3px;
  font-size: 10px;
  font-weight: bold;
  border-bottom: 2px solid ${({ theme, active }) => (active ? theme.linkColor : theme.grayTextColor)};
  color: ${({ theme, active }) => (active ? theme.linkColor : theme.grayTextColor)};
  text-align: center;

  &:hover {
    border-color: ${props => props.theme.linkColor};
    color: ${props => props.theme.linkColor};
  }
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;

  max-height: 66vh;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
`;

const PopupListMiniTitle = styled.span`
  font-size: 10px;
  color: #404040;
  margin-bottom: 3px;
`;

const PopupSubWrapper = styled.div`
  flex-direction: row;
`;

export const RightButton = styled.div<{ selected: boolean; isPackage?: true | null; isDomestic?: boolean }>`
  display: flex;
  align-items: center;
  flex-shrink: 0;

  border: 2px solid ${props => props.theme.linkColor};
  border-radius: 3px;
  padding: 2px;
  margin: 2px;
  color: ${props => props.theme.linkColor};
  font-weight: bold;
  cursor: pointer;
  min-width: 87px;
  height: ${props => (props.isDomestic ? '3rem' : '70px')};
  &.flight {
    min-width: 116px;
  }

  &.disabled {
    border-color: ${props => props.theme.grayBorderColor};
    color: ${props => props.theme.grayTextColor};
  }

  ${props =>
    props.selected &&
    `
      background: ${props.theme.linkColor};
      color: white;
  `}

  ${props =>
    props.isPackage &&
    `
      min-width: 145px;
      min-height: 45px;
      text-align: center;
  `}
`;

export const RightDomesticAirButton = styled.div<{ selected: boolean }>`
  border: 2px solid ${props => props.theme.linkColor};
  border-radius: 3px;
  margin-bottom: 5px;
  width: 220px;
  color: ${props => props.theme.linkColor};
  font-weight: bold;
  cursor: pointer;
  &.flight {
    min-width: 116px;
  }
  background-color: #fff;

  &.disabled {
    border-color: ${props => props.theme.grayBorderColor};
    color: ${props => props.theme.grayTextColor};
  }

  ${props =>
    props.selected &&
    `
      background: ${props.theme.linkColor};
      color: white;
  `}
  @media screen and (max-width: 768px) {
    width: 175px;
  }
`;

export const RightButtonPriceOnly = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;

  padding: 2px;
  margin: 2px;
  color: ${props => props.theme.linkColor};
  font-weight: bold;
  cursor: pointer;
  min-width: 87px;
  height: 70px;
  &.flight {
    min-width: 116px;
  }
`;

export const RightBody = styled.div`
  text-align: center;
  margin-left: auto;
  margin-right: auto;
`;

export const RightChangeable = styled.div`
  font-size: 11px;
  text-align-last: center;
`;

export const RightPrice = styled.span`
  font-size: 14px;
`;

export const RightPeopleNum = styled.span`
  font-size: 11px;
`;

export const Yen = styled.span`
  font-size: 11px;
  margin-left: 3px;
`;

export default SelectListAreaTemplate;
