import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@this/src/components/shared/ui/data_displays/table';
import type JalReservation from '@this/src/domain/arrangement/jal_reservation_total';
import AirAutoJalReservationTotalTableRow from './air_auto_jal_reservation_total_table_row';

interface Props {
  jalReservationTotals: JalReservation[];
  fetchTodos: () => void;
}

const AirAutoJalReservationTotal: React.FC<Props> = ({ jalReservationTotals, fetchTodos }) => {
  return (
    <Table stickyHeader>
      <TableHead>
        <TableRow>
          <TableCell>予約日</TableCell>
          <TableCell>予約対象アイテム件数</TableCell>
          <TableCell>予約完了件数</TableCell>
          <TableCell>合計金額</TableCell>
          <TableCell>総数承認日</TableCell>
          <TableCell>総数承認者</TableCell>
          <TableCell>承認</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {jalReservationTotals.map(jalReservationTotal => (
          <AirAutoJalReservationTotalTableRow
            key={jalReservationTotal.reservationDate.format('YYYY-MM-DD')}
            jalReservationTotal={jalReservationTotal}
            onApprove={() => fetchTodos()}
          />
        ))}
      </TableBody>
    </Table>
  );
};

export default AirAutoJalReservationTotal;
