import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { styled } from '@this/constants/themes';
import { Button } from '@this/shared/ui/inputs/button';
import { Link } from '@this/shared/ui/navigations/link';
import { Input } from '@this/shared/ui/inputs/input';
import { FormControl, FormHelperText } from '@this/shared/ui/inputs/form_control';
import type { KnowledgeResponseArgs } from '@this/src/domain/knowledge';
import Knowledge, { convertKnowledgeResponseToArgs } from '@this/src/domain/knowledge';
import { Box } from '@material-ui/core';
import { getColor, getSpacing } from '@this/shared/ui/theme';
import { Text } from '@this/shared/ui/data_displays/typography';
import type { SigninFormData } from './types';
import type { signInResponse } from './user_signin';
import { Modal, ModalBody, ModalHeader } from '../shared/ui/feedbacks/modal';

interface Props {
  navigateToPasswordReset: () => void;
  signin: ({ email, password }: { email: string; password: string }) => Promise<signInResponse>;
  serviceId: number;
}

interface KnowledgeResponse {
  knowledges: KnowledgeResponseArgs[];
}

const UserSigninForm = observer(({ navigateToPasswordReset, signin, serviceId }: Props) => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [errorText, setErrorText] = useState<signInResponse>(null);
  const [knowledges, setKnowledges] = useState<Knowledge[]>([]);
  const [showKnowledges, setShowKnowledges] = useState<boolean>(false);

  // urlからログインを失敗したという情報を渡して初回アクセス時にその情報を反映できる機構を入れる。
  // SSOのIDPInitiatedでアクセスがあったが、AIトラベル上で、ユーザ登録されていない
  // もしくはログイン方法がパスワードになっている場合に使われることを想定している。
  const [errorTypeFromUrl] = useState<string | undefined>(utils.getParam('error_type'));

  const fetchKnowledges = useCallback(() => {
    utils.jsonPromise<KnowledgeResponse>('/knowledges?show_login=true').then(res => {
      setKnowledges(res.knowledges.map(raw => new Knowledge(convertKnowledgeResponseToArgs(raw))));
    });
  }, []);

  useEffect(() => {
    fetchKnowledges();
  }, [fetchKnowledges]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const form: SigninFormData = {
      email,
      password
    };
    const result = await signin(form);
    setErrorText(result);
  };

  return (
    <FormContainer>
      {location.pathname === '/admins/sign_in' ? (
        <Title>管理者ログイン</Title>
      ) : location.pathname === '/arrangers/sign_in' ? (
        <Title>手配者ログイン</Title>
      ) : null}
      {errorText === 'LoginFailed' ? (
        <Error>
          ログインに失敗しました。
          <br />
          メールアドレス・パスワードを
          <br />
          ご確認ください。
        </Error>
      ) : errorText === 'AccountLocked' ? (
        <Error>
          アカウントがロックされました。
          <br />
          30分後以降に再度ログインをお試しください。
        </Error>
      ) : errorTypeFromUrl === 'NotRegisteredIDP' ? (
        <Error>
          ログインに失敗しました。
          <br />
          登録されていないユーザです。
        </Error>
      ) : location.search === '?token=expired' ? (
        <Error>
          このURLは無効です。
          <br />
          既に登録がお済みの方は<a href="/users/sign_in">コチラ</a>から <br />
          ログインしてください。
          <br />
          登録がまだお済みで無い方はお手数ですが <br />
          一度貴社管理者の方にお問合せください。
        </Error>
      ) : null}
      <Form name="signinForm" action="/users/sign_in" method="post" onSubmit={e => handleSubmit(e)}>
        <input type="hidden" name="user[service_id]" value={serviceId} />

        <Input
          type="email"
          placeholder="メールアドレス"
          name="user[email]"
          value={email}
          onChange={e => setEmail(e.target.value)}
          required
          fullWidth
        />

        <FormControl margin="dense" fullWidth>
          <Input
            type="password"
            placeholder="パスワード"
            name="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
          {location.pathname === '/users/sign_in' && (
            <FormHelperText>
              SAML認証をご利用のお客様は、メールアドレスのみ入力してログインボタンをクリックしてください
            </FormHelperText>
          )}
        </FormControl>

        <Button className="signin-button" type="submit" disabled={email.length === 0} fullWidth>
          ログイン
        </Button>
      </Form>

      <Link className="reset-password-link" size="small" onClick={navigateToPasswordReset}>
        パスワードをお忘れですか？
      </Link>
      {serviceId === 1 && (
        <>
          <Link style={{ fontSize: '12px' }} onClick={() => setShowKnowledges(true)}>
            ログインでお困りの方はこちら
          </Link>
          <Modal size="medium" open={showKnowledges} onClose={() => setShowKnowledges(false)}>
            <ModalHeader>ログインでお困りの方へ</ModalHeader>
            <ModalBody>
              <Box fontWeight="bold" marginBottom="10px">
                よくあるご質問
              </Box>
              <ul style={{ listStyleType: 'disc', marginLeft: '20px' }}>
                {knowledges.map(knowledge => (
                  <li key={knowledge.salesforceId} style={{ fontSize: '15px', marginBottom: '5px' }}>
                    <Link href={`/knowledges/${knowledge.salesforceId}`} target="_blank">
                      {knowledge.title}
                    </Link>
                  </li>
                ))}
              </ul>
              <Box marginTop="20px">
                上記で解決しない場合、
                <Link href="/inquiries/new" target="_blank">
                  お問い合わせフォーム
                </Link>
                よりご連絡ください。
              </Box>
            </ModalBody>
          </Modal>
        </>
      )}
      <Link
        style={{ fontSize: '12px', marginTop: '6px', display: 'block' }}
        href="https://aitravel.cloud/lp/dl"
        isExternal
        target="_blank"
        rel="noopener noreferrer"
      >
        導入をご検討中の企業様はこちら
      </Link>
    </FormContainer>
  );
});

export const FormContainer = styled.div`
  width: 100%;
  background-color: ${getColor('background', 'primary')};
  padding: ${getSpacing(8)}px;
`;

const Title = styled(Text)`
  margin-bottom: ${getSpacing(2)}px;
`;

const Error = styled(Text).attrs({ color: 'danger' })`
  text-align: center;
  margin-bottom: ${getSpacing(2)}px;
`;

export const Form = styled.form`
  margin-bottom: ${getSpacing(2)}px;
`;

export default UserSigninForm;
