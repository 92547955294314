import React, { useCallback, useRef } from 'react';

import { styled } from '@this/constants/themes';
import type Trip from '@this/domain/trip/trip';
import type { TicketOprResponseArgs } from '@this/domain/ticket_opr_response';

import { getColor, getTypogragy } from '@this/shared/ui/theme';
import type Order from '@this/domain/order';
import { VirtualCounterHeader } from './virtual_counter_header';
import { VirtualCounterBody } from './virtual_counter_body';
import { VirtualCounterAside } from './virtual_counter_aside';

type Props = {
  initialTripId: string | null;
  selectedTrip: Trip | null;
  editingOrder: Order | null;
  ticketOprResponse: TicketOprResponseArgs[];
  tripNotFindMessage: string;
  loadingSelectedTrip: boolean;
  loadingMessages: boolean;
  searchByTripId: (tripId: string) => Promise<void>;
  reloadSelectedTrip: (tripId: string) => Promise<void>;
} & Pick<React.ComponentProps<typeof VirtualCounterBody>, 'messages' | 'messageTemplates' | 'fetchMessages'> &
  Omit<React.ComponentProps<typeof VirtualCounterAside>, 'selectedTrip' | 'editingOrder' | 'onReloadTrip'>;

const asideMinWidth = 400;

export const VirtualCounterTemplate = ({
  initialTripId,
  selectedTrip,
  ticketOprResponse,
  tripNotFindMessage,
  searchByTripId,
  loadingSelectedTrip,
  loadingMessages,
  messageTemplates,
  fetchMessages,
  messages,
  planName,
  reservationJson,
  serviceId,
  editingOrder,
  shareholderTickets,
  bulkTickets,
  taxTypes,
  suppliedItems,
  paymentMethods,
  shareholderInfos,
  cabinOptions,
  individualTargetSuppliedItems,
  hotelElementProviderOptions,
  orderItemStatusLogInfo,
  reloadSelectedTrip
}: Props) => {
  return (
    <Root>
      <StyledVirtualCounterHeader
        initialTripId={initialTripId}
        trip={selectedTrip}
        errorMessage={tripNotFindMessage}
        onSubmitSearch={searchByTripId}
      />
      <Main>
        <StyledVirtualCounterBody
          loading={loadingSelectedTrip || loadingMessages}
          messages={messages}
          messageTemplates={messageTemplates}
          selectedTrip={selectedTrip}
          fetchMessages={fetchMessages}
        />
        {selectedTrip && editingOrder && (
          <Resizable width={asideMinWidth} minWidth={asideMinWidth}>
            <AsideContainer>
              <StyledVirtualCounterAside
                selectedTrip={selectedTrip}
                ticketOprResponse={ticketOprResponse}
                planName={planName}
                reservationJson={reservationJson}
                serviceId={serviceId}
                editingOrder={editingOrder}
                shareholderTickets={shareholderTickets}
                bulkTickets={bulkTickets}
                taxTypes={taxTypes}
                suppliedItems={suppliedItems}
                paymentMethods={paymentMethods}
                shareholderInfos={shareholderInfos}
                cabinOptions={cabinOptions}
                individualTargetSuppliedItems={individualTargetSuppliedItems}
                hotelElementProviderOptions={hotelElementProviderOptions}
                orderItemStatusLogInfo={orderItemStatusLogInfo}
                onReloadTrip={() => reloadSelectedTrip(selectedTrip.id.toString())}
              />
            </AsideContainer>
          </Resizable>
        )}
      </Main>
    </Root>
  );
};

const Root = styled.div`
  font-size: ${getTypogragy('fontSizes', 'body')}px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const Main = styled.main`
  display: flex;
  flex-grow: 1;
`;

const StyledVirtualCounterHeader = styled(VirtualCounterHeader)``;
const StyledVirtualCounterBody = styled(VirtualCounterBody)``;
const StyledVirtualCounterAside = styled(VirtualCounterAside)`
  flex-basis: 0;
  flex-grow: 1;
  overflow-y: scroll;
  overflow-x: hidden;
`;

const AsideContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const HorizontalResizableContainer = ({
  className,
  children,
  minWidth,
  maxWidth
}: {
  className?: string;
  children: React.ReactNode;
  minWidth?: number;
  maxWidth?: number;
}) => {
  const resizeRef = useRef<HTMLDivElement>(null);

  const resize = useCallback(
    (e: MouseEvent) => {
      if (!resizeRef.current) {
        return;
      }
      const handleWidth = 2;

      let width = window.innerWidth - e.clientX - handleWidth;

      if (minWidth) {
        width = Math.max(minWidth, width);
      }
      if (maxWidth) {
        width = Math.min(maxWidth, width);
      }
      // stateで更新すると大量のレンダリングが発生してしまうため
      resizeRef.current.style.width = `${width}px`;
    },
    [minWidth, maxWidth]
  );

  const handleonDragStart = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (!resizeRef.current) {
        return;
      }

      e.preventDefault();
      document.addEventListener('mousemove', resize);
      document.addEventListener('mouseup', () => {
        document.removeEventListener('mousemove', resize);
      });
    },
    [resize]
  );

  return (
    <ResizableContainerRoot className={className} ref={resizeRef}>
      <ResizeHandle onMouseDown={handleonDragStart} />
      {children}
    </ResizableContainerRoot>
  );
};

const ResizableContainerRoot = styled.div`
  display: flex;
`;
const ResizeHandle = styled.div`
  width: 2px;
  background-color: ${getColor('border', 'divider')}
  cursor: col-resize;
  transition: width 0.2s;
  
  &:hover {
    width: 4px;
  }
`;

const Resizable = styled(HorizontalResizableContainer)<{ width: number }>`
  width: ${({ width }) => width}px;
`;
