import React from 'react';
import _ from 'lodash';
import type { Moment } from 'moment';
import { Radio } from '@this/src/components/shared/ui/inputs/radio';
import { Select } from '@this/src/components/shared/ui/inputs/select';
import { Checkbox } from '@this/src/components/shared/ui/inputs/checkbox';
import { Input } from '@this/src/components/shared/ui/inputs/input';
import type { OrderItemJobTypeKey } from '@this/src/domain/order_item/order_item_job_type';
import type { OrderItemStatusKey } from '@this/src/domain/order_item/order_item_status';
import { SearchArea, SearchBlock, SearchLabel } from '../../search_area';
import OrderItemStatusSelector from '../../order_item_status_selector';
import DateRangePicker from '../../date_range_picker';
import type { InventoryStatus, SortDirection, SortKey } from '../air_auto_reservation_order_item_todo_list';

interface Props {
  jobTypes: OrderItemJobTypeKey[];
  statuses: OrderItemStatusKey[];
  inventoryStatus: InventoryStatus;
  tripId?: number;
  startDateFrom?: Moment;
  startDateTo?: Moment;
  receiveDateFrom?: Moment;
  receiveDateTo?: Moment;
  sortKey: SortKey;
  sortDirection: SortDirection;
  isPaginate: boolean;
  setJobTypes: (jobTypes: OrderItemJobTypeKey[]) => void;
  setStatuses: (statuses: OrderItemStatusKey[]) => void;
  setInventoryStatus: (inventoryStatus: InventoryStatus) => void;
  setTripId: (tripId?: number) => void;
  setStartDateFrom: (startDateFrom?: Moment) => void;
  setStartDateTo: (startDateTo?: Moment) => void;
  setReceiveDateFrom: (receiveDateFrom?: Moment) => void;
  setReceiveDateTo: (receiveDateTo?: Moment) => void;
  setSortKey: (sortKey: SortKey) => void;
  setSortDirection: (sortDirection: SortDirection) => void;
  setIsPaginate: (isPaginate: boolean) => void;
  renderOrganizationSelector: () => JSX.Element;
}

const TodoSearchArea: React.FC<Props> = ({
  jobTypes,
  statuses,
  inventoryStatus,
  tripId,
  startDateFrom,
  startDateTo,
  receiveDateFrom,
  receiveDateTo,
  sortKey,
  sortDirection,
  isPaginate,
  setJobTypes,
  setStatuses,
  setInventoryStatus,
  setTripId,
  setStartDateFrom,
  setStartDateTo,
  setReceiveDateFrom,
  setReceiveDateTo,
  setSortKey,
  setSortDirection,
  setIsPaginate,
  renderOrganizationSelector
}) => {
  return (
    <SearchArea>
      <SearchBlock>
        <SearchLabel>商品ステータス</SearchLabel>
        <OrderItemStatusSelector
          selectedOrderItemJobTypes={jobTypes}
          onChangeSelectedOrderItemJobTypes={setJobTypes}
          selectedOrderItemStatuses={statuses}
          onChangeSelectedOrderItemStatuses={setStatuses}
        />
      </SearchBlock>
      <SearchBlock>
        <SearchLabel>棚卸ステータス</SearchLabel>
        <Select
          value={inventoryStatus}
          onChange={e => setInventoryStatus(e.target.value as InventoryStatus)}
          style={{ marginBottom: 0, marginRight: '5px' }}
        >
          <option value="not_yet">未</option>
          <option value="done">済</option>
          <option value="all">全て</option>
        </Select>
      </SearchBlock>
      <SearchBlock>
        <SearchLabel>Trip ID</SearchLabel>
        <Input
          value={tripId || ''}
          onChange={e => {
            if (_.isEmpty(e.target.value)) {
              setTripId(undefined);
            } else {
              setTripId(parseInt(e.target.value, 10));
            }
          }}
          style={{ width: '80px' }}
        />
      </SearchBlock>
      <SearchBlock>
        <SearchLabel>搭乗日</SearchLabel>
        <DateRangePicker
          from={receiveDateFrom}
          to={receiveDateTo}
          onFromChange={d => setReceiveDateFrom(d)}
          onToChange={d => setReceiveDateTo(d)}
        />
      </SearchBlock>
      <SearchBlock>
        <SearchLabel>予約日</SearchLabel>
        <DateRangePicker
          from={startDateFrom}
          to={startDateTo}
          onFromChange={d => setStartDateFrom(d)}
          onToChange={d => setStartDateTo(d)}
        />
      </SearchBlock>
      <SearchBlock>
        <SearchLabel>法人</SearchLabel>
        {renderOrganizationSelector()}
      </SearchBlock>
      <SearchBlock>
        <SearchLabel>並び順</SearchLabel>
        <Select
          value={sortKey}
          onChange={e => setSortKey(e.target.value as SortKey)}
          style={{ marginBottom: 0, marginRight: '5px' }}
        >
          <option value="started_at">搭乗日</option>
          <option value="received_at">予約日</option>
        </Select>
        <Radio
          checked={sortDirection === 'asc'}
          onChange={() => setSortDirection('asc')}
          style={{ marginRight: '5px' }}
        >
          昇順▲
        </Radio>
        <Radio checked={sortDirection === 'desc'} onChange={() => setSortDirection('desc')}>
          降順▼
        </Radio>
      </SearchBlock>
      <>
        <SearchBlock>
          <Checkbox checked={isPaginate} onChange={e => setIsPaginate(e.target.checked)}>
            <b style={{ fontSize: '12px' }}>ページネーションを利用</b>
          </Checkbox>
        </SearchBlock>
      </>
    </SearchArea>
  );
};

export default TodoSearchArea;
