import type { Bar } from 'react-chartjs-2';
import type { DashboardProgress } from '.';

export type ColorTheme = 'default' | 'old';
export type Order = 'asc' | 'desc';

export const COLOR_THEMES = {
  default: {
    gray: {
      backgroundColor: '#D4D8E3'
    },
    blue: {
      backgroundColor: '#5E75AF'
    },
    green: {
      backgroundColor: '#9DAF5E'
    },
    yellow: {
      backgroundColor: '#D1B717'
    },
    darkYellow: {
      backgroundColor: '#AF985E'
    },
    transparentYellow: {
      backgroundColor: 'rgba(175, 152, 94, 0.6)',
      borderColor: 'rgb(175, 152, 94)'
    },
    red: {
      backgroundColor: '#AF705E'
    }
  },
  // 元々はuserのダッシュボードで使用していた定義。userもadminと同じカラーコードにした為現在は使用してない
  old: {
    gray: {
      // backgroundColor: '#959595'
      backgroundColor: '#F1CA3A'
    },
    purple: {
      backgroundColor: '#AF6EE8'
    },
    blue: {
      backgroundColor: '#5596E6'
    },
    green: {
      backgroundColor: '#5AA700'
    },
    yellow: {
      // backgroundColor: '#F1CA3A'
      backgroundColor: '#AF6EE8'
    },
    orange: {
      backgroundColor: '#FF9F40'
    },
    red: {
      // backgroundColor: '#FF6384'
      backgroundColor: '#959595'
    }
  }
};

export const SUMMARY_COLOR_THEMES = {
  default: {
    transparentYellow: COLOR_THEMES.default.transparentYellow,
    darkYellow: COLOR_THEMES.default.darkYellow,
    blue: COLOR_THEMES.default.blue,
    green: COLOR_THEMES.default.green
  },
  // 元々はuserのダッシュボードで使用していた定義。userもadminと同じ設定にした為現在は使用してない
  old: {
    blue: {
      backgroundColor: '#297BC2'
    },
    green: {
      backgroundColor: 'rgba(189, 234, 189, 0.2)',
      borderColor: 'rgb(189, 234, 189)'
    }
  }
};

export const BORDER_STYLES = {
  default: {
    borderRadius: 5,
    borderWidth: 0
  },
  old: {}
};

export interface BarPrices {
  label: string;
  prices: Record<Exclude<DashboardProgress, 'all'> | 'total', number>;
}

export const chartOptions: React.ComponentProps<typeof Bar>['options'] = {
  scales: {
    xAxes: {
      stacked: true,
      ticks: {
        callback: (value: any) => utils.formatPrice(value)
      }
    },
    yAxes: {
      grid: { display: false },
      stacked: true
    }
  },
  maintainAspectRatio: false,
  responsive: false,
  plugins: {
    tooltip: {
      callbacks: {
        label: (tooltipItem: any) => {
          return utils.formatPrice(Math.round(tooltipItem.raw));
        }
      }
    }
  }
};

export const getBarHeight = (prices: BarPrices[]) => {
  return 120 + 25 * prices.length;
};

export const getBarDataPrices = (prices: BarPrices[], theme: ColorTheme) => {
  const labels = prices.map(({ label }) => label);
  const values = prices.map(({ prices }) => prices);
  const colorTheme = COLOR_THEMES[theme];
  const borderStyle = BORDER_STYLES[theme];

  const datasets = [
    {
      label: '社内承認待ち',
      data: values.map(value => value.reserved),
      ...colorTheme.red,
      ...borderStyle
    },
    {
      label: '手配依頼済',
      data: values.map(value => value.approved),
      ...colorTheme.yellow,
      ...borderStyle
    },
    {
      label: '請求確定',
      data: values.map(value => value.confirmed),
      ...colorTheme.blue,
      ...borderStyle
    },
    {
      label: '出張完了',
      data: values.map(value => value.finished),
      ...colorTheme.green,
      ...borderStyle
    },
    {
      label: 'インポート',
      data: values.map(value => value.imported),
      ...colorTheme.gray,
      ...borderStyle
    }
  ];

  return { labels, datasets };
};

export const sortBarPrices = (prices: BarPrices[], order: Order) => {
  return prices.sort((a, b) => {
    const val1 = Object.values(a.prices).reduce((acc, cur) => {
      return acc + cur;
    }, 0);

    const val2 = Object.values(b.prices).reduce((acc, cur) => {
      return acc + cur;
    }, 0);

    const diff = val1 - val2;
    return order === 'asc' ? diff : -diff;
  });
};
