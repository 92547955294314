import React, { useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import { styled } from '@this/src/components/constants/themes';
import Notification from '@this/src/notification';
import { Button } from '@this/src/components/shared/ui/inputs/button';
import { TableCell, TableRow } from '@this/src/components/shared/ui/data_displays/table';
import type HotelOrderItemRakutenTotal from '@this/src/domain/arrangement/hotel_order_item_rakuten_total';
import type Arranger from '@this/src/domain/arranger/arranger';
import type { ArrangerArgs } from '@this/src/domain/arranger/arranger';
import { Fetcher } from '@this/src/util';

interface Props {
  hotelOrderItemRakutenTotal: HotelOrderItemRakutenTotal;
  arrangers: Arranger[];
  currentArranger: Arranger | null;
  suppliedItemId: number | null;
}

interface ApproveResponse {
  hotel_order_item_rakuten_total_approval: {
    reservation_date: string;
    approved_at: string;
    approved_by: ArrangerArgs;
    supplied_item_id: number | null;
  };
}

const HotelOrderItemRakutenTotalTableRow: React.FC<Props> = observer(
  ({ hotelOrderItemRakutenTotal, arrangers, currentArranger, suppliedItemId }) => {
    const [loading, setLoading] = useState(false);

    const handleApprove = useCallback(() => {
      setLoading(true);
      const params = {
        reservation_date: hotelOrderItemRakutenTotal.reservationDate.format('YYYY-MM-DD'),
        supplied_item_id: suppliedItemId
      };
      Fetcher.post<ApproveResponse>('/arrangement/hotel_order_item_rakuten_total_approvals', params)
        .then(res => {
          const { reservation_date, approved_at, approved_by, supplied_item_id } =
            res.hotel_order_item_rakuten_total_approval;
          hotelOrderItemRakutenTotal.setApprovedAt(approved_at);
          hotelOrderItemRakutenTotal.setApprovedBy(approved_by?.id as number);
          hotelOrderItemRakutenTotal.setSuppliedItemId(supplied_item_id as number);
          Notification.success(`${reservation_date}の承認が完了しました`);
        })
        .finally(() => setLoading(false));
    }, [hotelOrderItemRakutenTotal, suppliedItemId, setLoading]);

    const handleCancel = useCallback(() => {
      const params = { reservation_date: hotelOrderItemRakutenTotal.reservationDate.format('YYYY-MM-DD') };
      Fetcher.delete('/arrangement/hotel_order_item_rakuten_total_approvals/cancel', params).then(() => {
        hotelOrderItemRakutenTotal.setApprovedAt(undefined);
        hotelOrderItemRakutenTotal.setApprovedBy(undefined);
        Notification.success('承認を取り消しました');
      });
    }, [hotelOrderItemRakutenTotal]);

    return (
      <TableRow>
        <TableCell>{hotelOrderItemRakutenTotal.reservationDate.format('YYYY-MM-DD')}</TableCell>
        <TableCell align="right">{hotelOrderItemRakutenTotal.hotelElementTotal?.toLocaleString() || 0}</TableCell>
        <TableCell align="right">
          {hotelOrderItemRakutenTotal.orderItemOriginalPriceTotal?.toLocaleString() || 0}
        </TableCell>
        <TableCell align="right">
          {hotelOrderItemRakutenTotal.rakutenReservationTotal?.toLocaleString() || 0}
        </TableCell>
        <TableCell align="right">
          {hotelOrderItemRakutenTotal.orderItemPriceDetailsPriceTotal?.toLocaleString() || 0}
        </TableCell>
        <TableCell align="right">
          {hotelOrderItemRakutenTotal.orderItemOriginalPricePriceTotal?.toLocaleString() || 0}
        </TableCell>
        <TableCell align="right">
          {hotelOrderItemRakutenTotal.rakutenReservationPriceTotal?.toLocaleString() || 0}
        </TableCell>
        <TableCell>{hotelOrderItemRakutenTotal.exactMatch ? '○' : '×'}</TableCell>
        <TableCell>{hotelOrderItemRakutenTotal.approvedAt?.format('YYYY-MM-DD') || '─'}</TableCell>
        <TableCell>
          {hotelOrderItemRakutenTotal.approvedBy ? (
            <Actions>
              <Flex>
                <ApprovedBy>
                  {arrangers.find(arranger => arranger.id === hotelOrderItemRakutenTotal.approvedBy)?.name}
                </ApprovedBy>
                {currentArranger?.id === hotelOrderItemRakutenTotal.approvedBy && (
                  <DeleteButton onClick={handleCancel}>×</DeleteButton>
                )}
              </Flex>
              {hotelOrderItemRakutenTotal.suppliedItemId &&
                hotelOrderItemRakutenTotal.suppliedItemId !== suppliedItemId && (
                  <Warning>承認時と仕入商品が異なります</Warning>
                )}
            </Actions>
          ) : (
            <Button onClick={handleApprove} loading={loading}>
              承認
            </Button>
          )}
        </TableCell>
      </TableRow>
    );
  }
);

const Actions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Warning = styled.div`
  font-size: 11px;
  color: ${({ theme }) => theme.redColor};
`;

const ApprovedBy = styled.div`
  display: inline-flex;
  align-items: center;
  min-height: 35.5px;
`;

const DeleteButton = styled(Button)`
  padding: 0px;
  margin-left: 20px;
  min-width: 20px;
  height: 20px;
  border-radius: 10px;
`;

export default HotelOrderItemRakutenTotalTableRow;
