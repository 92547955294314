import React from 'react';
import _ from 'lodash';
import { styled } from '@this/constants/themes';
import type ExpensesAccountType from '@this/domain/expenses/expenses_account_type';
import type FreeeAccountItem from '@this/domain/organization/freee_account_item';

type Props = {
  types: ExpensesAccountType[];
  isFreeeConnected: boolean;
  freeeAccountItems: FreeeAccountItem[];
  onClickEdit: (type: ExpensesAccountType) => void;
  onClickDelete: (type: ExpensesAccountType) => void;
};

const Cards: React.FC<Props> = ({ types, isFreeeConnected, freeeAccountItems, onClickEdit, onClickDelete }) => {
  const findFreeeItemFromItemId = (id: string | null | undefined) => {
    if (!id) {
      return null;
    }
    return _.find(freeeAccountItems, item => item.id.toString() === id);
  };

  const expensesAccountTypeCard = (type: ExpensesAccountType) => (
    <Tr key={type.id}>
      <td className="organizationTdClass">{type.name}</td>
      <td className="organizationTdClass">{type.code}</td>
      {isFreeeConnected && (
        <td className="organizationTdClass">{findFreeeItemFromItemId(type.freeeAccountItemId)?.name}</td>
      )}
      <td className="organizationTdClass">
        <input className="organization__button" type="submit" value="編集" onClick={() => onClickEdit(type)} />
      </td>
      <td className="organizationTdClass">
        <input
          className="organization__delete-button"
          type="submit"
          value="削除"
          onClick={() => onClickDelete(type)}
        />
      </td>
    </Tr>
  );

  return (
    <table className="organization__table">
      <thead>
        <tr>
          <th className="organization__th"> 勘定科目名 </th>
          <th className="organization__th"> 勘定科目コード </th>
          {isFreeeConnected && <th className="organization__th"> freee勘定科目名 </th>}
          <th className="organization__button-th"> 編集 </th>
          <th className="organization__button-th"> 削除 </th>
        </tr>
      </thead>
      <tbody>{types.map(type => expensesAccountTypeCard(type))}</tbody>
    </table>
  );
};

const Tr = styled.tr`
  &:hover {
    background: #fff9ed;
  }
`;

export default Cards;
