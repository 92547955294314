import type { Dispatch, SetStateAction } from 'react';
import React, { useCallback, useEffect, useState } from 'react';
import { Popover, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import type OrderItemStepTodo from '@this/src/domain/arrangement/order_item_step_todo';
import type MasterStepSequence from '@this/src/domain/master_step/master_step_sequence';
import { styled } from '@this/src/components/constants/themes';
import { Button } from '@this/src/components/shared/ui/inputs/button';
import { Modal, ModalBody, ModalFooter, ModalHeader } from '@this/src/components/shared/ui/feedbacks/modal';
import type MasterStep from '@this/domain/master_step/master_step';

interface Props {
  todo: OrderItemStepTodo | null;
  anchorEl: Element | null;
  onChange: (todo: OrderItemStepTodo, masterStepSequence: MasterStepSequence, masterStep: MasterStep) => void;
  onClose: () => void;
}

interface State {
  masterStepSequences: MasterStepSequence[];
  selectedMasterStepSequence: MasterStepSequence | null;
  query: string;
  masterSteps: MasterStep[];
  selectedMasterStep: MasterStep | null;
  masterStepQuery: string;
  modalOpen: boolean;
}

interface Request extends State {
  setState: Dispatch<SetStateAction<State>>;
}

interface Response {
  master_step_sequences: MasterStepSequence[];
}

interface ResponseMasterStep {
  master_steps: MasterStep[];
}

const fetchMasterStepSequences = ({
  masterStepSequences,
  selectedMasterStepSequence,
  query,
  setState
}: Request) => {
  const params = {
    q: query,
    ids: selectedMasterStepSequence ? [selectedMasterStepSequence.id] : []
  };
  utils.jsonPromise<Response>('/arrangement/master_step_sequences/autocomplete.json', params).then(res => {
    if (
      res.master_step_sequences.map(mss => mss.id).join(',') !== masterStepSequences.map(mss => mss.id).join(',')
    ) {
      setState(state => ({ ...state, masterStepSequences: res.master_step_sequences }));
    }
  });
};

const fetchMasterSteps = ({
  selectedMasterStep,
  selectedMasterStepSequence,
  masterStepQuery,
  setState
}: Request) => {
  const params = {
    q: masterStepQuery,
    selected_master_step_sequence_id: selectedMasterStepSequence?.id,
    ids: selectedMasterStep ? [selectedMasterStep.id] : []
  };
  utils.jsonPromise<ResponseMasterStep>('/arrangement/master_steps/autocomplete.json', params).then(res => {
    if (res.master_steps && selectedMasterStepSequence) {
      setState(state => ({ ...state, masterSteps: res.master_steps }));
      if (!selectedMasterStep) {
        setState(state => ({ ...state, selectedMasterStep: res.master_steps[0] || null }));
      }
    }
  });
};

const OrderItemStepSequenceUpdateSelector: React.FC<Props> = ({ todo, anchorEl, onChange, onClose }) => {
  const [state, setState] = useState<State>({
    masterStepSequences: [],
    selectedMasterStepSequence: null,
    query: '',
    masterSteps: [],
    selectedMasterStep: null,
    masterStepQuery: '',
    modalOpen: false
  });
  const {
    masterStepSequences,
    selectedMasterStepSequence,
    query,
    masterSteps,
    selectedMasterStep,
    masterStepQuery,
    modalOpen
  } = state;

  useEffect(() => {
    fetchMasterStepSequences({ ...state, setState });
    fetchMasterSteps({ ...state, selectedMasterStepSequence, setState });
  }, [query, selectedMasterStepSequence]);

  useEffect(() => {
    if (todo) {
      setState({
        ...state,
        selectedMasterStepSequence: state.masterStepSequences.find(mss => mss.id === todo.sequenceId) || null,
        selectedMasterStep: state.masterSteps.find(mss => mss.id === todo.step?.masterStepId) || null
      });
    }
  }, [todo]);

  const handleClose = useCallback(() => {
    onClose();
    setState(state => ({ ...state, query: '' }));
  }, [onClose]);

  const handleModalClose = useCallback(() => {
    setState(state => ({ ...state, modalOpen: false }));
  }, []);

  const handleChange = useCallback((_event: unknown, value: MasterStepSequence | null) => {
    setState(state => ({ ...state, selectedMasterStepSequence: value, selectedMasterStep: null }));
    fetchMasterSteps({ ...state, selectedMasterStepSequence: value, setState });
  }, []);

  const handleInputChange = useCallback((_event: unknown, value: string) => {
    setState(state => ({ ...state, query: value }));
  }, []);

  const handleSubmit = useCallback(() => {
    if (!todo) return;

    setState(state => {
      if (!state.modalOpen) return { ...state, modalOpen: true };
      if (state.selectedMasterStepSequence === null) return state;
      if (state.selectedMasterStep === null) return state;

      onChange(todo, state.selectedMasterStepSequence, state.selectedMasterStep);
      onClose();
      return { ...state, query: '', masterStepQuery: '', modalOpen: false };
    });
  }, [todo, onChange, onClose]);

  const handleChangeMasterStep = useCallback((_event: unknown, value: MasterStep | null) => {
    setState(state => ({ ...state, selectedMasterStep: value }));
  }, []);

  const handleInputChangeMasterStep = useCallback((_event: unknown, value: string) => {
    setState(state => ({ ...state, masterStepQuery: value }));
  }, []);

  return (
    <>
      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <Content>
          <Autocomplete
            options={masterStepSequences}
            getOptionLabel={getOptionLabel}
            filterOptions={filterOptions}
            onChange={handleChange}
            onInputChange={handleInputChange}
            inputValue={query}
            value={selectedMasterStepSequence}
            size="small"
            renderInput={renderInput}
            style={{ minWidth: '320px', maxWidth: '90%' }}
          />
          {selectedMasterStepSequence && (
            <Autocomplete
              options={masterSteps}
              getOptionLabel={getOptionLabelMasterStep}
              filterOptions={filterOptionsMasterStep}
              onChange={handleChangeMasterStep}
              onInputChange={handleInputChangeMasterStep}
              inputValue={masterStepQuery}
              value={selectedMasterStep}
              size="small"
              renderInput={renderInput}
              style={{ minWidth: '320px', maxWidth: '90%' }}
            />
          )}
          <Actions>
            <Button size="small" color="sub" onClick={handleClose}>
              キャンセル
            </Button>
            <Button size="small" color="primary" onClick={handleSubmit}>
              保存
            </Button>
          </Actions>
        </Content>
      </Popover>
      <Modal open={modalOpen} size="medium" onClose={handleModalClose}>
        <ModalHeader>シーケンスの変更</ModalHeader>
        <ModalBody>
          <p>進行しているステップを破棄して新しいシーケンスを開始します。</p>
        </ModalBody>
        <ModalFooter>
          <Button color="sub" onClick={handleModalClose}>
            キャンセル
          </Button>
          <Button color="primary" onClick={handleSubmit}>
            保存
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

const getOptionLabel = (mss: MasterStepSequence) => mss.name ?? '';

const filterOptions = (options: MasterStepSequence[], state: { inputValue: string }) => {
  const q: string = state.inputValue;
  return options.filter(mss => mss.name && mss.name.toLocaleLowerCase().indexOf(q.toLocaleLowerCase()) > -1);
};

const getOptionLabelMasterStep = (mss: MasterStep) => mss.name ?? '';

const filterOptionsMasterStep = (options: MasterStep[], state: { inputValue: string }) => {
  const q: string = state.inputValue;
  return options.filter(mss => mss.name && mss.name.toLocaleLowerCase().indexOf(q.toLocaleLowerCase()) > -1);
};

const renderInput = (params: any) => (
  <TextField {...params} variant="outlined" style={{}} InputLabelProps={{ shrink: true }} size="small" />
);

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px;
`;

const Actions = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
`;

export default OrderItemStepSequenceUpdateSelector;
