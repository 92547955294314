ApproverList = require('../approver/approver_list')
OrganizationBase = require("../organization_base/organization_base").OrganizationBase
Teiki = require('../teiki').Teiki
UserForwardedEmails = require('../user_forwarded_emails').UserForwardedEmails
RemittanceDestinationAccount = require('../expenses/remittance_destination_account').default

class User
  constructor: (args = {}) ->
    _.assign @, args
    @organizationId = args.organization_id
    @birthdayMoment = moment(@birthday) if args.birthday
    @birthday ||= ''
    @nationalizedTel = args.nationalizedTel || args.nationalized_tel
    if (!_.isUndefined args.department) && (!_.isEmpty args.department)
      if typeof args.department.approvers != 'undefined'
        approvers = _.sortBy args.department.approvers, 'approve_stage'
        @department_approvers = new ApproverList approvers
    @teiki = new Teiki @teiki if @teiki
    @organizationBase = new OrganizationBase(args.organization_base) if args.organization_base
    @organizationRoleId = args.organization_role_id
    @gradeId = args.grade_id
    @hotelPriceLimit = args.hotel_price_limit
    @foreignHotelPriceLimit = args.foreign_hotel_price_limit
    @defaultSeatClasses = args.default_seat_classes || []

    @firstName = args.first_name
    @lastName = args.last_name
    @firstNameRoman = args.first_name_roman
    @lastNameRoman = args.last_name_roman
    @firstNameKana = args.first_name_kana
    @lastNameKana = args.last_name_kana
    @mileageNumbers = args.mileage_numbers
    @browsingDepartments = args.browsing_departments
    @signInMethod = args.sign_in_method
    @exicId = args.exic_id || ''
    @exicPassword = args.exic_password || ''
    @passportNumber = args.passport_number
    @passportExpire = args.passport_expire

    @adminNotification = args.admin_notification
    @isOrganizationAdmin = args.is_organization_admin
    @aicalendarAvailable = args.aicalendar_available
    @aicalendarAdmin = args.aicalendar_admin
    @nationalities = args.nationalities
    @nationalityId = args.nationality_id
    @ignoreIpAddressRestriction = args.ignore_ip_address_restriction
    @aiTravelSlackToken = args.ai_travel_slack_token
    @useChatbot = args.use_chatbot || false
    @useSlackApp = args.use_slack_app || false
    if !args.user_forwarded_emails || args.user_forwarded_emails.length == 0
      @userForwardedEmails = [new UserForwardedEmails({ email: '' })]
    else
      @userForwardedEmails =
        _.map(args.user_forwarded_emails, (e) -> new UserForwardedEmails(e))

    @remittanceDestinationAccount =
      if args.remittance_destination_account
        new RemittanceDestinationAccount args.remittance_destination_account
      else if args.needAccount
        RemittanceDestinationAccount.build()

    @disabled = args.disabled
    @needAppointDashboardCoachMark = args.need_appoint_dashboard_coach_mark
    @acceptedChatbotTerms = args.accepted_chatbot_terms
    @domesticAirSeatUpgrade = args.domestic_air_seat_upgrade

  organizationRoleName: (organizationRoles) ->
    for role in organizationRoles
      if role.id == @organizationRoleId
        return role.name

  gradeName: (grades) ->
    for grade in grades
      if String(grade.id) == @gradeId
        return grade.name

  nameRoman: ->
    "#{@last_name_roman} #{@first_name_roman}"

  nameKana: ->
    "#{@last_name_kana || ''} #{@first_name_kana || ''}"

  organizationBaseId: ->
    dig(@, 'organizationBase', 'id')

  organizationBaseName: ->
    dig(@, 'organizationBase', 'name')

  departmentId: ->
    dig(@, 'department', 'id')

  departmentName: ->
    dig(@, 'department', 'name')

  approver: ->
    dig(@, 'department', 'approvers', 0)

  approverName: ->
    dig(@, 'department', 'approvers', 0, 'name')

  approvers: ->
    dig(@, 'department', 'approvers')

  approverNames: ->
    if @approvers()
      _.map @approvers(), (a) -> a.name
    else
      ['-']

  hasOrganizationBase: ->
    !_.isNil(@organizationBase)

  isMember: ->
    !_.isNil(@organizationId)

  emailAndName: ->
    @name + '(' + @email + ')'

  setEmployeeNumber: (value) ->
    @employee_number = value
    app.render()

  setLastName: (value) ->
    @last_name = value
    @lastName = value
    @name = "#{@last_name} #{@first_name}"
    app.render()

  setFirstName: (value) ->
    @first_name = value
    @firstName = value
    @name = "#{@last_name} #{@first_name}"
    app.render()

  setLastNameRoman: (value) ->
    @last_name_roman = value
    @lastNameRoman = value
    app.render()

  setFirstNameRoman: (value) ->
    @first_name_roman = value
    @firstNameRoman = value
    app.render()

  setLastNameKana: (value) ->
    @last_name_kana = value
    @lastNameKana = value
    app.render()

  setFirstNameKana: (value) ->
    @first_name_kana = value
    @firstNameKana = value
    app.render()

  setGender: (value) ->
    @gender = value
    app.render()

  setBirthday: (value) ->
    @birthday = value
    app.render()

  setBirthdayMoment: (value) ->
    @birthdayMoment = value
    if value
      @birthday = value.format('YYYY-MM-DD')
    app.render()

  setEmail: (value) ->
    @email = value
    app.render()

  setPassword: (value) ->
    @password = value
    app.render()

  setTel: (value) ->
    @tel = value
    app.render()

  setDomesticApprovalRequired: (value) ->
    @domestic_approval_required =
      if typeof value == 'string'
        value == 'true'
      else
        value
    app.render()

  setForeignApprovalRequired: (value) ->
    @foreign_approval_required =
      if typeof value == 'string'
        value == 'true'
      else
        value
    app.render()

  setAdminNotification: (value) ->
    @admin_notification = parseInt(value, 10) || 0
    app.render()

  setNationalityId: (value) ->
    @nationalityId = value
    @nationality_id = value
    app.render()

  setUserForwardedEmails: (value, i) ->
    @userForwardedEmails[i].email = value
    app.render()

  setNeedAppointDashboardCoachMark: (value) ->
    @needAppointDashboardCoachMark = value
    app.render()

  filterNationalities: (nationality) ->
    _.filter nationality, (n) =>
      !_.includes @nationalities n.id

  approvalRequired: (is_foreign) ->
    if is_foreign == undefined
      @foreign_approval_required || @domestic_approval_required
    else
      is_foreign && @foreign_approval_required || !is_foreign && @domestic_approval_required

  organizationError: ->
    _.isNil @approver()

  inAdvanceOrganizationError: (is_foreign) ->
    @approvalRequired(is_foreign) && @organizationError()

  setOrganizationBase: (ob) ->
    @organizationBase = ob
    app.render()

  setDepartment: (d) ->
    @department = d
    app.render()

  setEmploymentStatus: (value) ->
    @employment_status = value
    app.render()

  setOrganizationRoleId: (value) ->
    @organizationRoleId = value
    app.render()

  setGradeId: (value) ->
    @gradeId = value
    app.render()

  setBrowsingScope: (value) ->
    @browsing_scope = value
    app.render()

  setShowOtherMembers: (value) ->
    @show_other_members =
      if typeof value == 'string'
        value == 'true'
      else
        value
    app.render()

  setShowHotelOfOverLimit: (value) ->
    @show_hotel_of_over_limit =
      if typeof value == 'string'
        value == 'true'
      else
        value
    app.render()

  setShowForeignHotelOfOverLimit: (value) ->
    @show_foreign_hotel_of_over_limit =
      if typeof value == 'string'
        value == 'true'
      else
        value
    app.render()

  setDisabled: (value) ->
    @disabled =
      if typeof value == 'string'
        value == 'true'
      else
        value
    app.render()

  setSp: (value) ->
    @sp =
      if typeof value == 'string'
        value == 'true'
      else
        value
    app.render()

  addTeiki: ->
    @teiki = new Teiki()
    app.render()

  removeTeiki: ->
    if @teiki
      @teiki.remove()

  teikiToDisplay: ->
    if @teiki && !@teiki.isRemoved()
      @teiki

  addMileageNumber: ->
    @mileageNumbers.push({ carrier: 'ANA', number: '' })
    app.render()

  removeMileageNumber: (index) ->
    @mileageNumbers = @mileageNumbers.filter((m, i) -> i != index)
    app.render()

  setMilageCarrier: (index, value) ->
    @mileageNumbers[index].carrier = value
    app.render()

  setMilageNumber: (index, value) ->
    @mileageNumbers[index].number = value
    app.render()

  setSignInMethod: (value) ->
    @signInMethod = value
    app.render()

  setExicId: (value) ->
    @exicId = value
    app.render()

  setExicPassword: (value) ->
    @exicPassword = value
    app.render()

  setPassportNumber: (value) ->
    @passportNumber = value
    app.render()

  setPassportExpire: (value) ->
    @passportExpire = value
    app.render()

  setAicalendarAvailable: (value) ->
    @aicalendarAvailable = value
    app.render()

  setAicalendarAdmin: (value) ->
    @aicalendarAdmin = value
    app.render()

  setIgnoreIpAddressRestriction: (value) ->
    @ignoreIpAddressRestriction = value
    app.render()

  setAcceptedChatbotTerms: (value) ->
    @acceptedChatbotTerms = value
    app.render()

  userForwardedEmailsParams: () ->
    params = []
    _.map @userForwardedEmails, (userForwardedEmail, i) ->
      if (userForwardedEmail.email)
        params[i] = userForwardedEmail.params()
    params

  invitationAcceptParams: ->
    params =
      last_name: @lastName
      first_name: @firstName
      last_name_roman: @lastNameRoman
      first_name_roman: @firstNameRoman
      last_name_kana: @lastNameKana
      first_name_kana: @firstNameKana
      birthday: @birthday
      gender: @gender
      tel: @tel
      mileage_numbers_attributes: @mileageNumbers
      password: @password
      nationality_id: @nationalityId
      user_forwarded_emails_attributes: @userForwardedEmailsParams()
    params

  insufficientAcceptParams: ->
    params =
      last_name: @lastName
      first_name: @firstName
      last_name_roman: @lastNameRoman
      first_name_roman: @firstNameRoman
      last_name_kana: @lastNameKana
      first_name_kana: @firstNameKana
      birthday: @birthday
      gender: @gender
      tel: @tel
      mileage_numbers_attributes: @mileageNumbers
      nationality_id: @nationalityId
      user_forwarded_emails_attributes: @userForwardedEmailsParams()
    params

  updateParams: ->
    params =
      employee_number: @employee_number
      last_name: @last_name
      first_name: @first_name
      last_name_roman: @last_name_roman
      first_name_roman: @first_name_roman
      last_name_kana: @last_name_kana
      first_name_kana: @first_name_kana
      gender: @gender
      birthday: @birthday
      email: @email
      tel: @tel
      grade_id: @gradeId
      domestic_approval_required: @domestic_approval_required
      foreign_approval_required: @foreign_approval_required
      organization_base_id: @organizationBaseId()
      department_id: @departmentId()
      employment_status: @employment_status
      organization_role_id: @organizationRoleId
      browsing_scope: @browsing_scope
      show_other_members: @show_other_members
      show_hotel_of_over_limit: @show_hotel_of_over_limit
      show_foreign_hotel_of_over_limit: @show_foreign_hotel_of_over_limit
      disabled: @disabled
      mileage_numbers_attributes: @mileageNumbers
      sign_in_method: @signInMethod
      exic_id: @exicId
      exic_password: @exicPassword
      aicalendar_available: @aicalendarAvailable
      aicalendar_admin: @aicalendarAdmin
      ignore_ip_address_restriction: @ignoreIpAddressRestriction
      admin_notification: @admin_notification
      sp: @sp
    params['browsing_departments_attributes'] = @browsingDepartments
    params['teiki_attributes'] = @teiki.params() if @teiki
    params['user_forwarded_emails_attributes'] = @userForwardedEmailsParams()
    if @remittanceDestinationAccount
      params['remittance_destination_account_attributes'] = @remittanceDestinationAccount.submitParams()
    params

module.exports = User
