import React, { useCallback, useMemo, useState } from 'react';
import _ from 'lodash';
import { styled } from '@this/src/components/constants/themes';
import type JalReservationTodo from '@this/src/domain/arrangement/jal_reservation_todo';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow
} from '@this/src/components/shared/ui/data_displays/table';
import { Button } from '@this/src/components/shared/ui/inputs/button';
import Notification from '@this/src/notification';
import { Checkbox } from '@this/src/components/shared/ui/inputs/checkbox';
import { Fetcher } from '@this/src/util';
import AirAutoJalReservationTodoListTableRow from './air_auto_jal_reservation_todo_list_table_row';

interface Props {
  todos: JalReservationTodo[];
  totalCount: number;
  totalPage: number;
  page: number;
  fetchTodos: () => void;
  setPage: (page: number) => void;
  onCsvDownload: () => void;
}

const AirAutoJalReservationTodoList: React.FC<Props> = ({
  todos,
  totalCount,
  totalPage,
  page,
  fetchTodos,
  setPage,
  onCsvDownload
}) => {
  const [loading, setLoading] = useState(false);
  const [selectedOrderItemIds, setSelectedOrderItemIds] = useState<number[]>([]);

  const bulkTargetTodos = useMemo(
    () => todos.filter(todo => todo.orderItemId && todo.inventoriedBy === null),
    [todos]
  );

  const handleToggleAll = useCallback(
    (checked: boolean) => {
      if (checked) {
        setSelectedOrderItemIds(bulkTargetTodos.map(todo => todo.orderItemId));
      } else {
        setSelectedOrderItemIds([]);
      }
    },
    [bulkTargetTodos]
  );

  const handleToggle = useCallback(
    (checked: boolean, orderItemId: number) => {
      if (checked) {
        setSelectedOrderItemIds([...selectedOrderItemIds, orderItemId]);
      } else {
        setSelectedOrderItemIds(_.without(selectedOrderItemIds, orderItemId));
      }
    },
    [selectedOrderItemIds]
  );

  const handleBulkInventory = useCallback(
    async (orderItemIds: number[]) => {
      if (loading) return;

      try {
        setLoading(true);
        const params = { order_item_ids: orderItemIds };
        await Fetcher.put('/arrangement/air_auto_reservation_order_item_todos/bulk_inventory', params);
        fetchTodos();
        Notification.success('一括棚卸を実行しました');
      } catch {
        Notification.error('一括棚卸に失敗しました');
      } finally {
        setLoading(false);
      }
    },
    [loading, fetchTodos, setLoading]
  );

  return (
    <>
      <Flex>
        <div>検索結果:{totalCount}件</div>
        <Button
          onClick={() => handleBulkInventory(selectedOrderItemIds)}
          disabled={totalCount === 0 || loading || selectedOrderItemIds.length === 0}
          loading={loading}
        >
          一括棚卸
        </Button>
        <Button onClick={onCsvDownload} disabled={totalCount === 0 || loading} loading={loading}>
          CSVダウンロード
        </Button>
      </Flex>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>
              <Checkbox
                checked={selectedOrderItemIds.length === bulkTargetTodos.length}
                onChange={e => handleToggleAll(e.target.checked)}
              />
            </TableCell>
            <TableCell>id</TableCell>
            <TableCell>予約番号</TableCell>
            <TableCell>搭乗日</TableCell>
            <TableCell>便名</TableCell>
            <TableCell>金額</TableCell>
            <TableCell>予約日</TableCell>
            <TableCell>ステータス</TableCell>
            <TableCell>棚卸ステータス</TableCell>
            <TableCell>棚卸実施者</TableCell>
            <TableCell>棚卸日時</TableCell>
            <TableCell>Trip ID</TableCell>
            <TableCell>Trace ID</TableCell>
            <TableCell>最新のログメッセージ</TableCell>
            <TableCell>アクション</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {todos.map((todo, i) => (
            <AirAutoJalReservationTodoListTableRow
              key={todo.traceId || i}
              todo={todo}
              checked={selectedOrderItemIds.includes(todo.orderItemId)}
              onInventory={() => fetchTodos()}
              onToggle={(checked: boolean) => handleToggle(checked, todo.orderItemId)}
            />
          ))}
        </TableBody>
      </Table>
      <TablePagination
        count={totalPage}
        page={page}
        onChange={(_e: unknown, newPage: number) => setPage(newPage)}
      />
    </>
  );
};

const Flex = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export default AirAutoJalReservationTodoList;
