import React, { Fragment } from 'react';
import _ from 'lodash';
import type moment from 'moment';

import { css } from 'styled-components';
import { styled } from '@this/constants/themes';
import A from '@this/shared/atoms/a';

import type User from '../../../../domain/user/user';
import type SearchQuery from '../../../../domain/search_query';
import Traveler from '../../../../domain/traveler/traveler';

import { InLabel, FieldsWrapper, Field } from '../../search/search';
import SelectTravelersBlock from './select_travelers_block/select_travelers_block';

interface Props {
  user: User;
  query: SearchQuery;
  handleChange: (name: 'peoplenum') => (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

class SearchTravelersBlock extends React.Component<Props> {
  componentDidMount() {
    const { query, user } = this.props;
    if (!query.travelers) {
      query.setInitialTravelers(user);
    }
  }

  handleTravelerTypeChange = (i: number, type: string) => {
    const travelers = this.props.query.travelers;
    if (!travelers) {
      return;
    }
    if (type === 'self') {
      const user = new Traveler(this.props.user);
      travelers.setTravelerAtIndex(i, user);
    } else {
      travelers.setTravelerTypeAtIndex(i, type);
    }
  };

  handleTravelerSelect = (i: number, traveler: Traveler, type: string) => {
    const travelers = this.props.query.travelers;
    if (!travelers) {
      return;
    }
    travelers.setTravelerTypeAtIndex(i, type);
    travelers.setTravelerAtIndex(i, traveler);
  };

  handleTravelerInfoChange = (i: number, method: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const travelers = this.props.query.travelers;
    if (!travelers) {
      return;
    }
    (travelers.list[i] as any)[method](e.target.value);
  };

  handleTravelerBirthDayChange = (i: number, method: string) => (birthday: moment.Moment) => {
    const travelers = this.props.query.travelers;
    if (!travelers) {
      return;
    }
    (travelers.list[i] as any)[method](birthday.format('YYYY-MM-DD'));
  };

  handlePeoplenumChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    if (!this.props.user) {
      return;
    }
    this.props.query.setPeoplenumWithTraveler(Number(e.target.value), this.props.user);
  };

  render() {
    try {
      const { user, query } = this.props;
      return (
        <>
          {!location.pathname.match(/trips\/\d+\/edit/) && (
            <FieldsWrapper className="search__block__body__fields-wrapper">
              <Field>
                <InLabel htmlFor="peoplenum_registration">人数 / 出張者</InLabel>
                <TravelersBlockBody>
                  <InputArea wrapped>
                    <SelectWrap>
                      <Select
                        name="peoplenum"
                        id="search__input-peoplenum"
                        value={query.peoplenum}
                        onChange={this.handlePeoplenumChange}
                      >
                        {_.times(8, i => (
                          <option value={i + 1} key={i}>{`${i + 1}人`}</option>
                        ))}
                      </Select>
                    </SelectWrap>
                  </InputArea>
                  {query.travelers && (
                    <TravelersBlockSection>
                      {query.travelers.list.map((traveler, i) => (
                        <Fragment key={i}>
                          {i > 0 && <TravelersBlockSectionSeparator />}
                          <TravelerForm key={i}>
                            {(traveler.type === 'self' || traveler.type === 'member') && (
                              <>
                                <TravelerFromMember>
                                  <SelectTravelersBlock
                                    user={user}
                                    traveler={traveler}
                                    index={i}
                                    onSelect={this.handleTravelerSelect}
                                    excludeUserIds={query.travelers!.list.map(t => t.id)}
                                  />
                                </TravelerFromMember>
                                <TravelerTypeLinkWrap>
                                  <TravelerTypeLink
                                    className="traveler-type-link"
                                    onClick={_ => this.handleTravelerTypeChange(i, 'companion')}
                                  >
                                    手入力
                                  </TravelerTypeLink>
                                </TravelerTypeLinkWrap>
                              </>
                            )}
                            {traveler.type === 'companion' && (
                              <>
                                <TravelerFromMember>
                                  <TravelerInputArea>
                                    <Label>氏名（ローマ字）</Label>
                                    <InputAreaRight>
                                      <InputName
                                        id="traveler-input-area_english-last-name"
                                        className="last-name"
                                        type="text"
                                        placeholder="Yamada"
                                        value={traveler.lastNameRoman}
                                        onChange={this.handleTravelerInfoChange(i, 'setLastNameRoman')}
                                      />
                                      <InputName
                                        id="traveler-input-area_english-first-name"
                                        className="first-name"
                                        type="text"
                                        placeholder="Ichiro"
                                        value={traveler.firstNameRoman}
                                        onChange={this.handleTravelerInfoChange(i, 'setFirstNameRoman')}
                                      />
                                    </InputAreaRight>
                                  </TravelerInputArea>
                                  <TravelerInputArea>
                                    <Label>氏名（カナ）</Label>
                                    <InputAreaRight>
                                      <InputName
                                        id="traveler-input-area_japanese-last-name"
                                        className="last-name"
                                        type="text"
                                        placeholder="ヤマダ"
                                        value={traveler.lastNameKana}
                                        onChange={this.handleTravelerInfoChange(i, 'setLastNameKana')}
                                      />
                                      <InputName
                                        id="traveler-input-area_japanese-first-name"
                                        className="first-name"
                                        type="text"
                                        placeholder="イチロウ"
                                        value={traveler.firstNameKana}
                                        onChange={this.handleTravelerInfoChange(i, 'setFirstNameKana')}
                                      />
                                    </InputAreaRight>
                                  </TravelerInputArea>
                                  <TravelerInputArea>
                                    <Label>性別</Label>
                                    <InputAreaRight>
                                      <RadioLabel>
                                        <input
                                          id="traveler-input-area_radio-male"
                                          className="reserve-confirm__radio-input"
                                          type="radio"
                                          value="male"
                                          checked={traveler.flightGender === 'male'}
                                          onChange={this.handleTravelerInfoChange(i, 'setFlightGender')}
                                        />
                                        <span>男性</span>
                                      </RadioLabel>
                                      <RadioLabel>
                                        <input
                                          className="reserve-confirm__radio-input"
                                          type="radio"
                                          value="female"
                                          checked={traveler.flightGender === 'female'}
                                          onChange={this.handleTravelerInfoChange(i, 'setFlightGender')}
                                        />
                                        <span>女性</span>
                                      </RadioLabel>
                                    </InputAreaRight>
                                  </TravelerInputArea>
                                </TravelerFromMember>
                                <TravelerTypeLinkWrap>
                                  <TravelerTypeLink
                                    className="traveler-type-link"
                                    onClick={_ => this.handleTravelerTypeChange(i, 'self')}
                                  >
                                    リスト
                                  </TravelerTypeLink>
                                </TravelerTypeLinkWrap>
                              </>
                            )}
                          </TravelerForm>
                        </Fragment>
                      ))}
                    </TravelersBlockSection>
                  )}
                </TravelersBlockBody>
              </Field>
            </FieldsWrapper>
          )}
        </>
      );
    } catch (e) {
      utils.sendErrorObject(e);
      return null;
    }
  }
}

const TravelerForm = styled.div`
  flex-wrap: nowrap;
  display: flex;
  padding: 5px;
`;

export const TravelerFromMember = styled.div`
  justify-content: space-between;
  flex-grow: 9999;
  min-width: 0;

  .reserve-confirm__traveler__label-name,
  .reserve-confirm__traveler__label-birthday {
    line-height: 26px;
  }
`;

const TravelerInputArea = styled.div`
  flex-wrap: wrap;
  display: flex;
  margin-bottom: 5px;
  min-width: 0;
`;

const Label = styled.div`
  font-size: 13px;
  width: 110px;
  line-height: 30px;
`;

const InputAreaRight = styled.div`
  flex-display: wrap;
  line-height: 30px;
  display: flex;
`;

const InputNamedisabled = css`
  background-color: #aaa !important;
`;

const InputName = styled.input`
  display: flex;
  max-width: 120px;
  min-width: 60px;
  margin-right: 10px;
  border: 0 !important;
  border-bottom: 1px solid ${props => props.theme.linkColor} !important;
  box-shadow: none !important;
  ${props => props.disabled && InputNamedisabled}

  &&& {
    margin-bottom: 0;
    background: ${props => props.theme.fieldBgColor};
  }
`;

const RadioLabel = styled.label`
  display: flex;
  align-items: center;
  margin-right: 10px;
`;

const TravelerTypeLinkWrap = styled.div`
  flex-grow: 2;
`;

const TravelerTypeLink = styled(A)`
  display: block;
  min-width: 50px;
  text-align: right;
  line-height: 30px;
  font-size: 12px;
  font-weight: bold;
`;

const SelectWrap = styled.div`
  flex-basis: 26px;
`;

const Select = styled.select`
  border: 0;
  padding: 0 5px 0 0;
  margin-bottom: 0;
  box-shadow: none;
  line-height: 1;
  height: 20px;
  cursor: pointer;
  appearance: none;
  background: ${props => props.theme.fieldBgColor};

  &:focus {
    outline: 0;
  }

  option {
    appearance: none;
  }
`;

const TravelersBlockBody = styled.div`
  display: flex;
`;

const inputAreaWidth = '30px';

const InputArea = styled.div<{ wrapped: boolean }>`
  display: flex;
  align-items: flex-top;
  flex-wrap: ${props => (props.wrapped ? 'wrap' : 'nowrap')};
  width: ${inputAreaWidth};
  margin-top: 5px;
`;

const TravelersBlockSection = styled.div`
  flex-grow: 9999;
  margin-top: -5px;
  width: calc(100% - ${inputAreaWidth});
`;

const TravelersBlockSectionSeparator = styled.hr`
  margin: -4px 0 4px 0;
  width: 100%;
  size: 2px;
  color: ${props => props.theme.grayBgColor};
`;

export default SearchTravelersBlock;
