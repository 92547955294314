import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { styled } from '@this/constants/themes';

import type { AvailableRepository } from '@this/domain/available_repository';
import TripsChat from '@this/components/trips_management/trips/trips_chat/trips_chat';
import { ReceiptPage } from '@this/components/trips_management/trips/reciept';
import TripsSimpleRequestForm from '@this/components/trips_management/trips/trips_simple_request_form';
import Trips from './trips/trips';
import TripReport from './trip_report/trip_report';
import TripReports from './trip_reports/trip_reports';
import TripReportDetail from './trip_reports/trip_report_detail/trip_report_detail';
import TripReportsNew from './trip_reports/trip_reports_new/trip_reports_new';
import DraftTripsNew from './draft_trips/draft_trips_new/draft_trips_new';
import DraftTripsEdit from './draft_trips/draft_trips_edit/draft_trips_edit';
import TripsForm from './trips/trips_form';
import TripsDetailForm from './trips/trips_detail_form';
import TripsConfirm from './trips/trips_confirm/trips_confirm';
import type User from '../../domain/user/user';
import { TripReportNew } from './trip_report/trip_report_new';
import { TripReportShow } from './trip_report/trip_report_show';
import { TripReportEdit } from './trip_report/trip_report_edit';
import TripReportExpenses from './trip_report/trip_report_expenses';
import TripReportApproval from './trip_report/trip_report_approval';
import TripReportApprovals from './trip_report/trip_report_approvals';

export const TripsManagementPaths = [
  '/trips',
  '/trips/new',
  '/trips/:id/edit',
  '/trips/:id/confirm',
  '/trips/:id/detail',
  '/trips/:id',
  '/trips/:id/receipts',
  '/trip_reports',
  '/trip_report',
  '/trip_report/expenses',
  '/trip_report/approvals',
  '/trip_report/approvals/:id',
  '/trip_report/:id',
  '/draft_trips/new',
  '/draft_trips/:id/edit'
];

interface Props {
  user: User | null;
  serviceId: number;
  availableRepos: AvailableRepository[];
  availableOptions: string[];
}

class TripsManagement extends React.Component<Props> {
  render() {
    try {
      const { user, serviceId, availableRepos, availableOptions } = this.props;
      const allowanceAvailable = availableOptions.includes('daily_allowance');
      return (
        <Switch>
          <Route path="/trips/:id/receipts" component={ReceiptPage} />
          <Route
            exact
            path={['/trips/new', '/trips/:id/edit']}
            render={props => (
              <>{user ? <TripsForm {...{ user, serviceId, availableRepos }} {...props} /> : <Spacer />}</>
            )}
          />
          <Route
            exact
            path="/trips/simple_request"
            render={props => (
              <>
                {user && user?.organization?.show_exic_seisan_trips ? (
                  <TripsSimpleRequestForm {...{ user, serviceId, availableRepos }} {...props} />
                ) : (
                  <Spacer />
                )}
              </>
            )}
          />
          <Route
            exact
            path={['/trips/:id/detail']}
            render={props => (
              <>{user ? <TripsDetailForm {...{ user, serviceId, availableRepos }} {...props} /> : <Spacer />}</>
            )}
          />
          <Route
            exact
            path={['/trips/confirm', '/trips/:id/confirm']}
            render={props => <TripsConfirm {...props} serviceId={serviceId} />}
          />
          <Route path="/trips/:id" render={props => <TripsChat {...props} serviceId={serviceId} />} />
          <Route
            exact
            path="/trips"
            render={props => <Trips {...{ user, serviceId, availableRepos, availableOptions }} {...props} />}
          />

          <Route path="/trip_reports/:id/application" component={TripReportsNew} />
          <Route path="/trip_reports/:id" component={TripReportDetail} />
          <Route
            exact
            path="/trip_report"
            render={props => (
              <TripReport {...props} serviceId={serviceId} allowanceAvailable={allowanceAvailable} />
            )}
          />
          <Route
            exact
            path="/trip_report/expenses"
            render={props => (
              <TripReportExpenses {...props} serviceId={serviceId} allowanceAvailable={allowanceAvailable} />
            )}
          />
          <Route
            exact
            path="/trip_report/approvals"
            render={props => (
              <TripReportApprovals {...props} serviceId={serviceId} allowanceAvailable={allowanceAvailable} />
            )}
          />
          <Route
            exact
            path="/trip_report/approvals/:id"
            render={props => (
              <TripReportApproval {...props} serviceId={serviceId} allowanceAvailable={allowanceAvailable} />
            )}
          />
          <Route
            exact
            path="/trip_report/new"
            render={props => (
              <TripReportNew {...props} serviceId={serviceId} allowanceAvailable={allowanceAvailable} />
            )}
          />
          <Route
            exact
            path="/trip_report/:id"
            render={props => (
              <TripReportShow {...props} serviceId={serviceId} allowanceAvailable={allowanceAvailable} />
            )}
          />
          <Route
            exact
            path="/trip_report/:id/edit"
            render={props => (
              <TripReportEdit {...props} serviceId={serviceId} allowanceAvailable={allowanceAvailable} />
            )}
          />
          <Route exact path="/trip_reports" component={TripReports} />
          <Route path="/draft_trips/:id/edit" component={DraftTripsEdit} />
          <Route exact path="/draft_trips/new" component={DraftTripsNew} />
        </Switch>
      );
    } catch (e) {
      utils.sendErrorObject(e);
      return null;
    }
  }
}

const Spacer = styled.div`
  flex-grow: 9999;
  background: white;
`;

export default TripsManagement;
