import React, { useState, useCallback } from 'react';
import { styled } from '@this/constants/themes';
import A from '@this/shared/atoms/a';
import Button, { ButtonBase } from '@this/shared/atoms/button';
import useApi from '@this/components/shared/hooks/use_api';
import SimpleLoading from '@this/shared/simple_loading/simple_loading';

import type { OrganizationRoleResponse } from '@this/domain/organization_role/organization_role';
import OrganizationRole from '@this/domain/organization_role/organization_role';
import { OrganizationResource } from '@this/domain/organization_role/organization_resource';
import { OrganizationRoleForm } from '@this/components/organization/organization_roles/organization_role_form';
import { OrganizationBody, OrganizationTitle } from '../organization.style';

const Roles = () => {
  const [showModal, setShowModal] = useState(false);
  const [saving, setSaving] = useState(false);
  const [targetRole, setTragetRole] = useState<OrganizationRole | null>(null);
  const { data, isLoading } = useApi<OrganizationRoleResponse>('/organization/roles.json');
  const roles = data ? data.roles.map(args => new OrganizationRole(args)) : [];

  const skeletonRole = () => {
    const organizationRole = new OrganizationRole({ id: 0, name: '', organization_role_resources: [] });
    // 便宜上、1つめのロールのリソース一覧から、リソースID, リソース名を得る
    roles[0].resources.forEach(r => {
      const organization_resource = new OrganizationResource({
        id: 0,
        resource_id: r.resourceId,
        resource_name: r.resourceName,
        resource_path: r.resourcePath,
        read_permission: false,
        write_permission: false
      });
      organizationRole.resources.push(organization_resource);
    });
    return organizationRole;
  };
  const createRole = () => {
    setTragetRole(skeletonRole());
    setShowModal(true);
  };
  const editRole = (role: OrganizationRole) => {
    setTragetRole(role);
    setShowModal(true);
  };

  const updateRoleResource = useCallback(async (role: OrganizationRole) => {
    setSaving(true);
    try {
      await utils.jsonPromise<any>('/organization/roles.json', role, 'put');
      // 権限を変えたタイミングでサイドバーに表示される情報も変更したいため、
      // データを読み込みなおす目的でリロードを行う
      location.reload();
    } catch (e) {
      utils.sendErrorObject(e);
    } finally {
      setSaving(false);
    }
  }, []);

  return (
    <div>
      <OrganizationTitle>役割・権限設定</OrganizationTitle>
      <OrganizationBody>
        <RoleButton onClick={() => createRole()}>役割を追加</RoleButton>
        {isLoading ? (
          <SimpleLoading />
        ) : (
          <Table>
            <thead>
              <tr>
                <Th />
                {roles.map((role, i) => (
                  <Th key={i} colSpan={1}>
                    {role.name}
                    {/*
                    アドホックな対応だが、デフォルトで作成される管理者、一般ユーザーについては、編集のリンクを表示しない。
                    */}
                    {role.name !== '管理者' && role.name !== '一般ユーザー' && (
                      <A onClick={() => editRole(role)}>(編集)</A>
                    )}
                  </Th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                <Td />
                {roles.map((_, i) => (
                  <React.Fragment key={i}>
                    <ResourceTd>利用権限</ResourceTd>
                    {/*
                      <ResourceTd>編集権限</ResourceTd>
                    */}
                  </React.Fragment>
                ))}
              </tr>
              {roles[0].resources.map((r, i) => (
                <tr key={i}>
                  <Td>{r.resourceName}</Td>
                  {roles.map((role, j) => (
                    <React.Fragment key={j}>
                      <ResourceTd>{role.resources[i].readPermission ? '◯' : 'ｘ'}</ResourceTd>
                      {/*
                        <ResourceTd>{role.resources[i].writePermission ? '◯' : 'ｘ'}</ResourceTd>
                      */}
                    </React.Fragment>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </OrganizationBody>

      {targetRole && (
        <OrganizationRoleForm
          open={showModal}
          role={targetRole}
          submitting={saving}
          onAbort={() => setShowModal(false)}
          onSubmit={newRole => updateRoleResource(newRole)}
        />
      )}
    </div>
  );
};

const Table = styled.table`
  margin: 0;
  font-size: 12px;
  width: auto;
`;

const Th = styled.th`
  padding: 8px;
`;

const Td = styled.td`
  padding: 8px;
`;

const ResourceTd = styled.td`
  padding: 8px;
  text-align: center;
`;

const RoleButton = styled(Button)`
  &,
  &:hover,
  &:focus {
    display: block;
    width: 90px;
    margin: 0 15px;
    padding: 5px 0;
  }
`;

export const SubmitButton = styled.button`
  ${ButtonBase};
  width: 60px;
  margin-top: 10px;
  margin-left: 10px;
  padding: 5px;
  height: 32px;
`;

export const RoleNameInput = styled.input`
  width: 50%;
`;

export const ResourceWrapper = styled.div`
  display: flex;
`;

export const Left = styled.div`
  width: 50%;
`;

export const Right = styled.div`
  width: 50%;
`;

export const InputWrapper = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

export default Roles;
