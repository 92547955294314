import React from 'react';
import { styled } from '@this/constants/themes';
import { media } from '@this/shared/atoms/media';
import type {
  Message,
  MessagesResponse,
  TripResponse
} from '@this/components/trips_management/trips/trips_chat/types';
import type { RouteComponentProps } from 'react-router-dom';
import { ButtonBase } from '@this/shared/atoms/button';
import { Link } from 'react-router-dom';

import TripChatHeader from '@this/components/trips_management/trips/trips_chat/trip_chat_header';
import Modal from '@this/src/components/shared/modal/modal';
import TripChatForm from '@this/components/trips_management/trips/trips_chat/trip_chat_form';
import { Fetcher, HTTPError } from '@this/src/util';
import Notification from '../../../../notification';
import TripChatInformation from './trip_chat_information';
import TripChatMessage from './trip_chat_message';

interface Props extends RouteComponentProps {
  serviceId: number;
}

interface State {
  trip?: any;
  messages: Message[];
  firstTrip: boolean;
  windowFocused: boolean;
  receiptIssuable: boolean;
  showReciptModal: boolean;
  businessHoursMessage?: string;
  enabledChat: boolean;
}

class TripsChat extends React.Component<Props, State> {
  state: State = {
    messages: [],
    firstTrip: false,
    windowFocused: true,
    receiptIssuable: false,
    showReciptModal: false,
    enabledChat: false
  };

  private fetchMessageOnce = false;

  private scrollMessageRef = React.createRef<HTMLParagraphElement>();

  private messageContainerRef = React.createRef<HTMLDivElement>();

  private initProps?: TripResponse;

  private pusher: any;

  private channel: any;

  componentDidUpdate(): void {
    if (this.messageContainerRef.current) {
      $(this.messageContainerRef.current).on('scroll', this.handleScroll.bind(this));
    }
  }

  async componentDidMount() {
    utils.appendScript('https://js.pusher.com/3.0/pusher.min.js');
    this.startTimeChecker();

    const splitedPath = location.href.split('/');
    const tripId = splitedPath[splitedPath.length - 1];
    try {
      const result = await Fetcher.get<TripResponse>(`/trips/${tripId}.json`, utils.getParams());

      if (!result.enabledChat) {
        await this.showEnabledChatAlert(tripId);
        return;
      }

      this.initProps = result;
      this.initWebsocket();
      this.fetchMessages();

      this.setState({
        trip: result.trip,
        messages: result.messages,
        firstTrip: !!result.firstTrip,
        receiptIssuable: result.receiptIssuable,
        enabledChat: result.enabledChat
      });
    } catch (e) {
      if (e.status === 404) {
        Notification.error('このページの閲覧権限がありません。');
      } else {
        utils.sendErrorObject(e);
      }
    }
  }

  showEnabledChatAlert = async (tripId: string) => {
    Notification.error('旅程が承認されていないため\nチャットをご利用いただけません。\n詳細画面へ移動しました。');
    this.props.history.push(`/trips/${tripId}/detail`);
  };

  handleReceiptClick = () => {
    const url = `/trips/${this.state.trip.id}/receipts`;

    if (this.state.receiptIssuable) {
      this.props.history.push(url);
    } else {
      this.setState({
        showReciptModal: true
      });
    }
  };

  handleCloseFlash = () => {
    return this.setState({ firstTrip: false });
  };

  handleMessageSubmit = async (
    text: string,
    files: File[],
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();

    const formData = new FormData();
    if (files.length) {
      files.forEach(file => {
        formData.append('files[]', file);
      });
    }
    formData.append('message', text);
    formData.append('type', 'user');

    try {
      await Promise.all([
        Fetcher.upload(`/trips/${this.state.trip.id}/messages`, formData).catch(e => {
          if (e instanceof HTTPError && e.response?.data.errors) {
            Notification.error(`送信に失敗しました。${e.response.data.errors}`);
          }
        }),
        Fetcher.put(`/trips/${this.state.trip.id}/order_item_snoozed_todos/cancel`, {})
      ]);
      await this.fetchMessages();
    } catch {
      Notification.error('送信に失敗しました');
    }
  };

  hideReciptModal = () => {
    this.setState({
      showReciptModal: false
    });
  };

  private handleScroll(e: JQuery.ScrollEvent) {
    const m_window = e.target;
    if (this.scrollMessageRef.current && m_window.scrollTop === m_window.scrollHeight - m_window.clientHeight) {
      $(this.scrollMessageRef.current).hide(300);
    }
  }

  private async startTimeChecker() {
    const result = await Fetcher.get<{ message: string }>('/business_hours_messages.json');
    this.setState({ businessHoursMessage: result.message });
    setTimeout(this.startTimeChecker.bind(this), 1000 * 60);
  }

  private initWebsocket() {
    if (typeof Pusher !== 'undefined') {
      this.pusher = new Pusher(this.initProps!.pusherKey, { encrypted: true });
      this.pusher.connection.bind('connected', this.subscribeTrip.bind(this));
    } else {
      setTimeout(this.initWebsocket, 100);
    }
  }

  private subscribeTrip() {
    const tripId = this.state.trip ? this.state.trip.id : this.initProps!.trip.id;
    this.channel = this.pusher.subscribe(this.initProps!.pusherChannelPrefix + tripId.toString());
    this.channel.bind('new_message', this.fetchMessages.bind(this));
  }

  private async fetchMessages() {
    const tripId = this.state.trip ? this.state.trip.id : this.initProps!.trip.id;
    try {
      const result = await Fetcher.get<MessagesResponse>(`/trips/${tripId}/messages.json`, {
        type: 'user'
      });
      const oldMessageCount = (this.state.messages || []).length;

      // スクロールするか否かの判断はajaxの結果をもって判断できるが、
      // スクロールアクション自体はビューが更新された後に行う
      let doScroll = false;

      const m_window = this.messageContainerRef.current;

      // 現在のスクロール位置が一番下にある時スクロールする
      if (m_window && m_window.scrollHeight === m_window.scrollTop + m_window.offsetHeight) {
        doScroll = true;
      } else {
        if (this.scrollMessageRef.current && oldMessageCount < result.messages.length) {
          // 新しいメッセージがあったら
          // スクロールしないが、新着のメッセージがある場合は
          // 「新しいメッセージをみるにはスクロールダウンして下さい」というメッセージを表示する
          $(this.scrollMessageRef.current).show(300);
        }

        // 初回描画時のみ
        if (!this.fetchMessageOnce) {
          this.fetchMessageOnce = true;
          // 確認画面から遷移があった時、それが初めての遷移であればスクロールしない
          if (document.referrer !== `${location.origin}/reserve_confirm` || oldMessageCount !== 1) {
            doScroll = true;
          }
        }
      }

      this.setState({
        trip: result.trip,
        messages: result.messages,
        receiptIssuable: result.receiptIssuable
      });

      if (this.state.windowFocused) {
        this.notifyReadMessage();
      }

      if (doScroll) {
        this.scrollToBottom();
      }
    } catch (e) {
      Notification.error('通信環境が不安定です。\n時間をおいてもう一度お試しください。');
      utils.sendErrorObject(e);
    }
  }

  private notifyReadMessage() {
    const partner_messages: Message[] = [];
    this.state.messages.forEach(m => {
      partner_messages.push(m);
    });

    const partnerMessageIds = partner_messages.filter(m => m.user_read === false).map(m => m.id);

    const params = {
      partner_message_ids: partnerMessageIds
    };
    Fetcher.post('messages/read.json', params);
  }

  private scrollToBottom() {
    if (this.messageContainerRef.current) {
      const node = this.messageContainerRef.current;
      node.scrollTop = node.scrollHeight;
    }
  }

  render() {
    const { trip, firstTrip, messages, businessHoursMessage, showReciptModal, enabledChat } = this.state;

    return (
      <>
        <TripChatContainer>
          <TripChatHeader billed={trip && !trip.billing} handleReceiptClick={this.handleReceiptClick} />
          <TripChatBody>
            <ScrollMessage ref={this.scrollMessageRef}>
              新しいメッセージを見るにはスクロールダウンして下さい
            </ScrollMessage>
            <TripChatMessageContainer ref={this.messageContainerRef}>
              <TripChatInformation shown={firstTrip} handleClose={this.handleCloseFlash}>
                旅程管理に移動しました
              </TripChatInformation>
              {messages.map((m, i) => (
                <TripChatMessage key={i} message={m} onSubmitConfirmation={() => this.fetchMessages()} />
              ))}
            </TripChatMessageContainer>
            {enabledChat && <TripChatForm handleSubmit={this.handleMessageSubmit} />}
            {businessHoursMessage && (
              <BussinessHourMessage>
                <span>※現在は営業時間外です。</span>
                <span data-wovn-ignore>{businessHoursMessage}</span>
                <span>以降にご返信いたします。</span>
              </BussinessHourMessage>
            )}
            {utils.isAiTravel(this.props.serviceId) && (
              <LinkText to="/knowledge_categories/1" rel="noopener noreferrer" target="_blank">
                よくあるご質問
              </LinkText>
            )}
            {utils.isAiTravel(this.props.serviceId) && (
              <LinkText to="/knowledges/000001075" rel="noopener noreferrer" target="_blank">
                弊社営業時間外または出張当日の変更・キャンセルの対応方法について
              </LinkText>
            )}
          </TripChatBody>
        </TripChatContainer>

        <Modal show={showReciptModal} hideModal={this.hideReciptModal}>
          <p>領収書は全ての手配完了後に発行できます</p>
          <ReciptModalCloseButton type="button" onClick={this.hideReciptModal}>
            閉じる
          </ReciptModalCloseButton>
        </Modal>
      </>
    );
  }
}

const TripChatContainer = styled.div`
  background: ${props => props.theme.contentBgColor};
  width: 1150px;
  padding: 0 20px 20px;
  margin: 0 auto;
  flex-grow: 1;
  ${media.sp`
    padding: 0 10px 10px;
    width: auto;
    max-width: 900px;
  `}
`;

const TripChatBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 200px);
`;

const TripChatMessageContainer = styled.div`
  flex: 1;
  padding-top: 10px;
  margin-bottom: 10px;
  overflow-x: hidden;
  overflow-y: scroll;
`;

const ScrollMessage = styled.p`
  font-size: 12px;
  padding: 3px;
  color: #7d7d7d;
  display: none;
`;

const BussinessHourMessage = styled.p`
  font-size: 12px;
  color: #7d7d7d;
`;

const LinkText = styled(Link)`
  font-size: 12px;
  &:hover {
    color: ${props => props.theme.linkColor};
    text-decoration: underline;
  }
`;

const ReciptModalCloseButton = styled.button`
  ${ButtonBase}
`;

export default TripsChat;
