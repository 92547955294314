/* eslint-disable max-lines */
import React from 'react';
import _ from 'lodash';
import axios from 'axios';
import type { RouteComponentProps } from 'react-router-dom';
import type User from '@this/domain/user/user';

import { Select } from '@this/shared/search/search';
import Place from '@this/domain/place/place';
import type { PlaceEntity } from '@this/domain/place/place.type';
import type { Moment } from 'moment';
import moment from 'moment';
import Text from '@this/shared/text/text';
import type { AvailableRepository } from '@this/domain/available_repository';
import { Loading } from '@this/components/shared/ui/feedbacks/loading/loading';
import { doubleDigits } from '@this/src/util';
import { SearchFlightsInputArea, SearchArea, Required, SearchButton, Errors } from './search_flight.style';
import Flight from '../../../domain/flight/flight';
import DatetimePicker from '../../shared/datetime_picker/datetime_picker';
import FlightListBlock from './search_flight_block/search_flight_block';

enum SearchType {
  Amadeus = 'amadeus',
  TravelPort = 'travelport'
}

interface ArrangementFlightsResponse {
  airports: [];
  carrier_ids: [];
  flight_type: string;
  flights: [];
}

interface Props extends RouteComponentProps<any> {
  user: User;
  serviceId: number;
}

interface State {
  flights: Flight[];
  loading: boolean;
  repositoryLoading: boolean;
  error?: string;
  validateError: { [key: string]: string };
  searchType: SearchType | null;
  peoplenum: string;
  cabin: string;
  flightType: string;
  carrierId: string;
  orgLat: string;
  orgLng: string;
  destLat: string;
  destLng: string;
  origin: string;
  destination: string;
  orgGeocode: any;
  destGeocode: any;
  date: Moment;
  hour: string;
  min: string;
  arrivalType: string;
  dateTo?: Moment;
  hourTo: string;
  minTo: string;
  arrivalTypeTo: string;
  canSearchAmadeus: boolean;
  canSearchTravelport: boolean;
}

interface Args {
  search_type: string | null;
}

class SearchFlights extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const date: string | undefined = utils.getParam('date');
    const to = utils.dig(utils.getParams(), 'dateTo');
    const dateTo = to && to !== 'undefined' ? moment(to) : undefined;

    this.state = {
      validateError: {},
      flights: [],
      loading: false,
      repositoryLoading: true,
      searchType: null,
      peoplenum: '1',
      cabin: '',
      flightType: '',
      carrierId: '',
      orgLat: '',
      orgLng: '',
      destLat: '',
      destLng: '',
      origin: '',
      destination: '',
      orgGeocode: {},
      destGeocode: {},
      date: date ? moment(date) : moment(),
      hour: '',
      min: '',
      arrivalType: '',
      dateTo: dateTo ? moment(dateTo) : undefined,
      hourTo: '',
      minTo: '',
      arrivalTypeTo: '',
      canSearchAmadeus: false,
      canSearchTravelport: false
    };
  }

  private async fetch() {
    const res = await axios.get<AvailableRepository[]>('/arrangement/available_repositories');

    let canSearchAmadeus = false;
    let canSearchTravelport = false;
    _.each(res.data, repo => {
      if (repo.category === 'foreign_flight' && repo.repository === 'amadeus') {
        canSearchAmadeus = true;
      }
      if (repo.category === 'foreign_flight' && repo.repository === 'travelport') {
        canSearchTravelport = true;
      }
    });
    this.setState({
      repositoryLoading: false,
      canSearchAmadeus,
      canSearchTravelport
    });
  }

  componentDidMount() {
    this.fetch();
  }

  buildArgs(): Args {
    let args = { search_type: this.state.searchType };
    if (this.state.peoplenum.length > 0) {
      args = _.merge(args, { peoplenum: this.state.peoplenum });
    }
    if (this.state.cabin.length > 0) {
      args = _.merge(args, { cabin: this.state.cabin });
    }
    if (this.state.flightType.length > 0) {
      args = _.merge(args, { flight_type: this.state.flightType });
    }
    const searchQueries = [];
    searchQueries.push(this.searchQuery());
    if (this.state.dateTo) {
      searchQueries.push(this.searchQueryReturn());
    }
    args = _.merge(args, { queries: searchQueries });

    return args;
  }

  searchQuery() {
    return {
      org_lat: this.state.orgGeocode?.location?.lat,
      org_lng: this.state.orgGeocode?.location?.lng,
      dest_lat: this.state.destGeocode?.location?.lat,
      dest_lng: this.state.destGeocode?.location?.lng,
      date: this.state.date && this.state.date.format('YYYY-MM-DD'),
      hour: this.state.hour,
      min: this.state.min,
      type: this.state.arrivalType,
      carrier_id: this.state.carrierId,
      origin: this.state.origin,
      destination: this.state.destination
    };
  }

  searchQueryReturn() {
    return {
      org_lat: this.state.destGeocode?.location?.lat,
      org_lng: this.state.destGeocode?.location?.lng,
      dest_lat: this.state.orgGeocode?.location?.lat,
      dest_lng: this.state.orgGeocode?.location?.lng,
      date: this.state.dateTo && this.state.dateTo.format('YYYY-MM-DD'),
      hour: this.state.hourTo,
      min: this.state.minTo,
      type: this.state.arrivalTypeTo,
      carrier_id: this.state.carrierId,
      origin: this.state.destination,
      destination: this.state.origin
    };
  }

  validate() {
    const errors: { [key: string]: string } = {};
    if (this.state.searchType === null) {
      errors.searchType = '検索するGDSを選んでください';
    }
    if (_.isEmpty(this.state.orgLat) && _.isEmpty(this.state.orgLng) && _.isEmpty(this.state.origin)) {
      errors.origin = '出発地を入力してください';
    }
    if (_.isEmpty(this.state.destLat) && _.isEmpty(this.state.destLng) && _.isEmpty(this.state.destination)) {
      errors.destination = '目的地を入力してください';
    }

    if (_.keys(errors).length > 0) {
      return errors;
    }
    return null;
  }

  async fetchFlights(): Promise<void> {
    this.setState({ validateError: {}, loading: true });

    const validate = this.validate();
    if (validate !== null) {
      this.setState({
        validateError: validate,
        loading: false
      });
      return;
    }

    const [orgGeocode, destGeocode] = await Promise.all([
      SearchFlights.placePromise('', this.state.origin, 'origin'),
      SearchFlights.placePromise('', this.state.destination, 'destination')
    ]);
    this.setState({ orgGeocode, destGeocode });

    const args = this.buildArgs();
    const queries = _.map(args, (v, k) => `${k}=${v}`);
    const query = _.join(queries, '&');
    try {
      this.props.history.push(`/arrangement/search_flights?${query}`);
      const result = await utils.jsonPromise<ArrangementFlightsResponse>(
        `/arrangement/search_flights?${Date.now()}`,
        args
      );
      this.setState({
        // eslint-disable-next-line
        // @ts-ignore
        flights: result.flights.map((flight: Flight) => new Flight(flight)),
        loading: false
      });
    } catch (error) {
      this.setState({
        error:
          error.status === 400
            ? utils.dig(error, 'responseJSON', 'errors')
            : '通信エラーが発生しました。時間をおいて再度お試しください。',
        loading: false
      });
    }
  }

  handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    this.fetchFlights();
  }

  private static placePromise(address: string, place: string, type: 'origin' | 'destination') {
    return SearchFlights.fetchGeocodeDetail(address, place, type);
  }

  private static async fetchGeocodeDetail(
    address: string,
    place: string,
    type: 'origin' | 'destination'
  ): Promise<Place | null> {
    try {
      if (!(address || place)) {
        return null;
      }
      const result = await utils.jsonPromise<PlaceEntity>(
        '/arrangement/search_flights/convert_place_to_coordinates',
        { address, place_name: place, item_type: 'transport' }
      );
      return new Place(result);
    } catch (e) {
      const desc =
        type === 'origin'
          ? '出発地'
          : type === 'destination'
          ? '目的地'
          : type === 'stay'
          ? '宿泊地'
          : type === 'start'
          ? '出発場所'
          : type === 'return'
          ? '返却場所'
          : '';
      if (e.status) {
        if (e[type]) {
          if (e.status === 'UNKNOWN_ERROR') {
            e[type] = '外部サービスに不明なエラーが発生しました。時間をおいて再度お試しください。';
          } else {
            e[type] = `${desc}が見つかりませんでした。違う表記をお試しください。`;
          }
        }
      }
      throw e;
    }
  }

  private searchBlock() {
    const state = this.state;
    const repositoryLoading = state.repositoryLoading;
    const searchType = state.searchType;
    const canSearchAmadeus = state.canSearchAmadeus;
    const canSearchTravelport = state.canSearchTravelport;
    const classBase = 'order-histories';

    return (
      <div>
        {repositoryLoading ? (
          <Loading />
        ) : (
          <div className={`${classBase}__type-area`}>
            {canSearchAmadeus && (
              <label className={`${classBase}__label`}>
                <input
                  type="radio"
                  name="search_type"
                  className={`${classBase}__radio`}
                  checked={searchType === SearchType.Amadeus}
                  onChange={e => this.setState({ searchType: SearchType.Amadeus })}
                />
                Amadeus
              </label>
            )}
            {canSearchTravelport && (
              <label className={`${classBase}__label`}>
                <input
                  type="radio"
                  name="search_type"
                  className={`${classBase}__radio`}
                  checked={searchType === SearchType.TravelPort}
                  onChange={e => this.setState({ searchType: SearchType.TravelPort })}
                />
                TravelPort
              </label>
            )}
          </div>
        )}
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          <div>
            <SearchFlightsInputArea>
              <label>
                人数<Required>（※必須）</Required>
              </label>
              <input
                type="text"
                value={state.peoplenum}
                className={`${classBase}__text-input`}
                onChange={e => this.setState({ peoplenum: e.target.value })}
              />
            </SearchFlightsInputArea>
            <SearchFlightsInputArea>
              <label>cabin</label>
              <input
                type="text"
                value={state.cabin}
                className={`${classBase}__text-input`}
                onChange={e => this.setState({ cabin: e.target.value })}
              />
            </SearchFlightsInputArea>
            <SearchFlightsInputArea>
              <label>flight_type</label>
              <input
                type="text"
                value={state.flightType}
                className={`${classBase}__text-input`}
                onChange={e => this.setState({ flightType: e.target.value })}
              />
            </SearchFlightsInputArea>
            <SearchFlightsInputArea>
              <label>carrier_id</label>
              <input
                type="text"
                value={state.carrierId}
                className={`${classBase}__text-input`}
                onChange={e => this.setState({ carrierId: e.target.value })}
              />
            </SearchFlightsInputArea>
          </div>
          <div>
            <SearchFlightsInputArea>
              <label>
                出発地<Required>（※必須）</Required>
              </label>
              <input
                type="text"
                value={state.origin}
                className={`${classBase}__text-input`}
                placeholder="会社名 / ビル名 / 駅名など"
                onChange={e => this.setState({ origin: e.target.value })}
                id="origin"
              />
            </SearchFlightsInputArea>
            <SearchFlightsInputArea>
              <label>
                目的地<Required>（※必須）</Required>
              </label>
              <input
                type="text"
                value={state.destination}
                className={`${classBase}__text-input`}
                placeholder="会社名 / ビル名 / 駅名など"
                onChange={e => this.setState({ destination: e.target.value })}
                id="destination"
              />
            </SearchFlightsInputArea>
            <p>往路</p>
            <SearchFlightsInputArea>
              <label className={`${classBase}__label`}>
                日付<Required>（※必須）</Required>
              </label>
              <DatetimePicker
                value={state.date}
                onChange={e => this.setState({ date: e })}
                showTime={false}
                disabledDays={0}
                showPast
                border
                deletable
                width="140px"
                calenderClass="rc-calendar-trip"
              />
            </SearchFlightsInputArea>
            <SearchFlightsInputArea>
              <label className={`${classBase}__label`}>時間</label>
              <Select name="hour" value={state.hour} onChange={e => this.setState({ hour: e.target.value })}>
                <option value="">-時間</option>
                {_.times(24, i => (
                  <option value={doubleDigits(i)} key={i}>{`${i}時`}</option>
                ))}
              </Select>
              <Select name="time" value={state.min} onChange={e => this.setState({ min: e.target.value })}>
                <option value="">-分</option>
                {_.times(6, i => (
                  <option value={doubleDigits(i * 10)} key={i}>{`${i * 10}分`}</option>
                ))}
              </Select>
              <Select value={state.arrivalType} onChange={e => this.setState({ arrivalType: e.target.value })}>
                <option value="departure">出発</option>
                <option value="arrival">到着</option>
              </Select>
            </SearchFlightsInputArea>

            <p>復路</p>
            <SearchFlightsInputArea>
              <label className={`${classBase}__label`}>日付</label>
              <DatetimePicker
                value={state.dateTo}
                onChange={e => this.setState({ dateTo: e })}
                placeholder="到着日"
                showTime={false}
                disabledDays={1}
                showPast
                border
                deletable
                width="140px"
                calenderClass="rc-calendar-trip"
              />
            </SearchFlightsInputArea>
            <SearchFlightsInputArea>
              <label className={`${classBase}__label`}>時間</label>
              <Select name="hour" value={state.hourTo} onChange={e => this.setState({ hourTo: e.target.value })}>
                <option value="">-時間</option>
                {_.times(24, i => (
                  <option value={doubleDigits(i)} key={i}>{`${i}時`}</option>
                ))}
              </Select>
              <Select name="time" value={state.minTo} onChange={e => this.setState({ minTo: e.target.value })}>
                <option value="">-分</option>
                {_.times(6, i => (
                  <option value={doubleDigits(i * 10)} key={i}>{`${i * 10}分`}</option>
                ))}
              </Select>
              <Select value={state.arrivalTypeTo} onChange={e => this.setState({ arrivalTypeTo: e.target.value })}>
                <option value="">-</option>
                <option value="departure">出発</option>
                <option value="arrival">到着</option>
              </Select>
            </SearchFlightsInputArea>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { flights, loading, validateError, error } = this.state;
    const classBase = 'order-histories';
    return (
      <div>
        <SearchArea>
          <form className={`${classBase}__search-area`} onSubmit={e => this.handleSubmit(e)}>
            {this.searchBlock()}
            <SearchButton>
              <input className={`${classBase}__submit`} type="submit" value="検索" disabled={loading} />
            </SearchButton>
            <div style={{ marginTop: '10px' }}>表示中の海外航空券：{flights.length}件</div>
          </form>
          <Errors>
            {validateError &&
              _.entries(validateError).map(([k, v]) => (
                <div className="error" key={k}>
                  <Text text={v} />
                </div>
              ))}
          </Errors>
        </SearchArea>
        <div>{loading ? <Loading /> : error ? <p>{error}</p> : <FlightListBlock flights={flights} />}</div>
      </div>
    );
  }
}

export default SearchFlights;
