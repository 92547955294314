// 廃止してorder_item_category.tsに移行したい
interface OrderItemCategoryStrMap {
  [key: string]: string;
}

const orderItemCategoryStrMap: OrderItemCategoryStrMap = {
  hotel: 'ホテル',
  domestic_hotel: '国内ホテル',
  foreign_hotel: '海外ホテル',
  domestic_air: '国内航空券',
  foreign_air: '海外航空券',
  shinkansen: '新幹線',
  rental_car: 'レンタカー',
  air_package: '航空券パッケージ',
  railway_package: '新幹線パッケージ',
  shipping: '配送料',
  wifi: 'WiFi',
  room: '会議室',
  bus: 'バス',
  arrangement_request: 'リクエストフォーム',
  cancel: 'キャンセル',
  change_fee: '変更手数料',
  message: 'メッセージ'
};

export default orderItemCategoryStrMap;
