import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { Loading } from '@this/src/components/shared/ui/feedbacks/loading';
import { Button } from '@this/shared/ui/inputs/button';
import { Fetcher } from '@this/src/util';

interface TaskTableProps {}

interface DomesticAirFetchActionResponse {
  data: DomesticAirFetchAction[];
}

interface DomesticAirFetchAction {
  id: number;
  from_airport: string;
  to_airport: string;
  from_date: string;
  to_date: string;
  source: string;
  status: string;
  duration?: number;
  error?: string;
  priority: string;
  fire_reason: string;
  created_at: string;
  updated_at: string;
  assigned_machine_name?: string;
}

interface AirportData {
  from_airports: string[];
  to_airports: string[];
}

const TaskTable: React.FC<TaskTableProps> = () => {
  const [fetchActions, setFetchActions] = useState<DomesticAirFetchAction[]>([]);
  const [fromAirports, setFromAirports] = useState<string[]>([]);
  const [toAirports, setToAirports] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [statusFilter, setStatusFilter] = useState<string>('');
  const [sourceFilter, setSourceFilter] = useState<string>('');
  const [fromAirportFilter, setFromAirportFilter] = useState<string>('');
  const [toAirportFilter, setToAirportFilter] = useState<string>('');

  const handleStatusFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setStatusFilter(e.target.value);
  };

  const handleSourceFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSourceFilter(e.target.value);
  };

  const handleFromAirportChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setFromAirportFilter(e.target.value);
  };

  const handleToAirportChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setToAirportFilter(e.target.value);
  };

  const handleDeleteAllActions = async () => {
    if (confirm('本当にすべてのタスクを削除しますか？')) {
      try {
        await Fetcher.delete('/god/domestic_air_fetch_actions');
        setFetchActions([]);
      } catch (error) {
        setError('タスクデータの削除に失敗しました');
      }
    }
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'queue':
        return 'gray';
      case 'started':
        return 'green';
      case 'succeeded':
        return 'blue';
      case 'faild':
        return 'red';
      default:
        return 'transparent';
    }
  };

  const getJpStatusName = (status: string) => {
    switch (status) {
      case 'queue':
        return '処理待ち';
      case 'started':
        return '開始';
      case 'succeeded':
        return '成功';
      case 'faild':
        return '失敗';
      default:
        return '未定義';
    }
  };

  useEffect(() => {
    const fetchActions = async () => {
      setLoading(true);
      try {
        const params = new URLSearchParams();
        if (statusFilter) params.append('status', statusFilter);
        if (sourceFilter) params.append('source', sourceFilter);
        if (fromAirportFilter) params.append('from_airport', fromAirportFilter);
        if (toAirportFilter) params.append('to_airport', toAirportFilter);
        const response = await Fetcher.get<DomesticAirFetchActionResponse>(
          `/god/domestic_air_fetch_actions?${params.toString()}`
        );
        setFetchActions(response.data);
      } catch (error) {
        setError('データの取得に失敗しました');
      } finally {
        setLoading(false);
      }
    };

    fetchActions();
  }, [statusFilter, sourceFilter, fromAirportFilter, toAirportFilter]);

  useEffect(() => {
    const fetchAirports = async () => {
      try {
        const response = await Fetcher.get<AirportData>('/god/domestic_air/index_airports');
        setFromAirports(response.from_airports);
        setToAirports(response.to_airports);
      } catch (error) {
        setError('データの取得に失敗しました');
      } finally {
        setLoading(false);
      }
    };

    fetchAirports();
  }, []);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <Container style={{ fontSize: '10px' }}>
      <Filters>
        <div>
          <label>ソース:</label>
          <select value={sourceFilter} onChange={handleSourceFilterChange}>
            <option value="">すべて</option>
            <option value="jal_official">JAL</option>
            <option value="ana_official">ANA</option>
          </select>
        </div>
        <div>
          <label>ステータス:</label>
          <select value={statusFilter} onChange={handleStatusFilterChange}>
            <option value="">すべて</option>
            <option value="queue">処理待ち</option>
            <option value="started">開始</option>
            <option value="succeeded">成功</option>
            <option value="failed">失敗</option>
          </select>
        </div>
        <div>
          <label htmlFor="from-airport">出発空港</label>
          <select id="from-airport" value={fromAirportFilter} onChange={handleFromAirportChange}>
            <option value="">すべて</option>
            {fromAirports.map((airport, index) => (
              <option key={index} value={airport}>
                {airport}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="to-airport">到着空港</label>
          <select id="to-airport" value={toAirportFilter} onChange={handleToAirportChange}>
            <option value="">すべて</option>
            {toAirports.map((airport, index) => (
              <option key={index} value={airport}>
                {airport}
              </option>
            ))}
          </select>
        </div>
      </Filters>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button onClick={handleDeleteAllActions}>タスクデータをすべて削除</Button>
      </div>
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>出発空港</th>
            <th>到着空港</th>
            <th>取得開始日(JST)</th>
            <th>取得終了日(JST)</th>
            <th>ソース</th>
            <th>ステータス</th>
            <th>経過時間</th>
            <th>エラー</th>
            <th>優先度</th>
            <th>バッチ・オンデマンド</th>
            <th>処理マシン</th>
            <th>作成日時(UTC)</th>
            <th>更新日時(UTC)</th>
          </tr>
        </thead>
        <tbody>
          {fetchActions.map(action => (
            <tr key={action.id}>
              <td>{action.id}</td>
              <td>{action.from_airport}</td>
              <td>{action.to_airport}</td>
              <td>{action.from_date}</td>
              <td>{action.to_date}</td>
              <td>{action.source}</td>
              <td>
                <StatusContainer>
                  <StatusCircle color={getStatusColor(action.status)} />
                  {getJpStatusName(action.status)}
                </StatusContainer>
              </td>
              <td>{action.duration}</td>
              <td>{action.error}</td>
              <td>{action.priority}</td>
              <td>{action.fire_reason}</td>
              <td>{action.assigned_machine_name}</td>
              <td>{action.created_at}</td>
              <td>{action.updated_at}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px;
`;

const Filters = styled.div`
  display: flex;
`;

const StatusContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StatusCircle = styled.span<{ color: string }>`
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
  margin-right: 5px;
`;

export default observer(TaskTable);
