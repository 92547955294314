import React, { useCallback, useReducer } from 'react';
import { styled } from '@this/constants/themes';
import A from '@this/components/shared/atoms/a';
import Button from '@this/components/shared/atoms/button';
import type Invoice from '@this/domain/invoice/invoice2';

import SimpleLoading from '@this/shared/simple_loading/simple_loading';

interface Props {
  invoice: Invoice;
}

interface FreeeFileboxResponse {
  receipt_id: number;
}

interface State {
  submitting: boolean;
  error: string | null;
}

type Action =
  | { type: 'SUBMITTING_START' }
  | { type: 'SUBMITTING_SUCCESS' }
  | { type: 'SUBMITTING_ERROR'; payload: string };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SUBMITTING_START':
      return { ...state, submitting: true, error: null };
    case 'SUBMITTING_SUCCESS':
      return { ...state, submitting: false };
    case 'SUBMITTING_ERROR':
      return { ...state, submitting: false, error: action.payload };
    default:
      return state;
  }
};

const intialState: State = {
  submitting: false,
  error: null
};

const FreeeFilebox = ({ invoice }: Props) => {
  const [state, dispatch] = useReducer(reducer, intialState);
  const { submitting, error } = state;

  const postFreeeFilebox = useCallback(async () => {
    dispatch({ type: 'SUBMITTING_START' });
    const params = {
      invoice_id: invoice.id
    };
    try {
      const response = await utils.jsonPromise<FreeeFileboxResponse>(
        '/organization/freee/receipt',
        params,
        'POST'
      );
      invoice.freeeReceiptId = response.receipt_id;
      dispatch({ type: 'SUBMITTING_SUCCESS' });
    } catch (e) {
      if (e.status === 400) {
        dispatch({ type: 'SUBMITTING_ERROR', payload: e.responseJSON.message });
      } else {
        dispatch({
          type: 'SUBMITTING_ERROR',
          payload: '通信エラーが発生しました。時間をおいて再度お試しください。'
        });
        utils.sendErrorObject(e);
      }
    }
  }, [invoice, dispatch]);

  return (
    <li>
      <h3>ファイルボックス</h3>
      <p>請求書PDFをfreeeのファイルボックスに送信できます。</p>
      {submitting ? (
        <SimpleLoading />
      ) : (
        <ButtonArea>
          {invoice.freeeReceiptId ? (
            <>
              送信済み（
              <A
                href={`https://secure.freee.co.jp/receipts/${invoice.freeeReceiptId}`}
                target="_blank"
                rel="noreferrer noopener"
              >
                freeeでファイルを表示
              </A>
              ）
            </>
          ) : (
            <>
              <TheButton onClick={() => postFreeeFilebox()}>freeeファイルボックスに送信</TheButton>
              {error && (
                <div className="red" style={{ marginTop: '5px' }}>
                  {error}
                </div>
              )}
            </>
          )}
        </ButtonArea>
      )}
    </li>
  );
};

const TheButton = styled(Button)`
  width: fit-content;
  margin-right: 10px;
`;

const ButtonArea = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

export default FreeeFilebox;
