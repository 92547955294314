import React from 'react';
import _ from 'lodash';

import { styled } from '@this/constants/themes';

import SelectOrganizationsBlockList from './select_organizations_block_list/select_organizations_block_list';

interface Props {
  organization?: string;
  onSelect: (organization: string) => void;
}

interface State {
  showList: boolean;
}

class SelectOrganizationsBlock extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { showList: false };
  }

  handleSelect = (selected: string) => {
    const { onSelect } = this.props;
    this.setState({ showList: false }, () => {
      onSelect(selected);
    });
  };

  handleToggleShowList = () => {
    this.setState({ showList: !this.state.showList });
  };

  render() {
    try {
      const { organization } = this.props;
      const { showList } = this.state;
      return (
        <OrganizationSelectBlock>
          <OrganizationText onClick={this.handleToggleShowList}>{organization}</OrganizationText>
          {showList && <SelectOrganizationsBlockList onSelect={this.handleSelect} />}
        </OrganizationSelectBlock>
      );
    } catch (e) {
      utils.sendErrorObject(e);
      return null;
    }
  }
}

const OrganizationText = styled.p`
  height: 30px;
  background: ${props => props.theme.fieldBgColor};
  line-height: 30px;
  padding: 0 10px;
  cursor: pointer;
  border: 2px solid #eee;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const OrganizationSelectBlock = styled.div`
  position: relative;
  top: 0;
  left: 0;
`;

export default SelectOrganizationsBlock;
