import React, { useState } from 'react';
import styled from 'styled-components';
import NewVersionTable from './new_version_table';
import OldVersionTable from './old_version_table';
import PriorityTable from './priority_table';

const DomesticAirData = () => {
  const [version, setVersion] = useState('new');

  return (
    <Container>
      <div style={{ display: 'flex' }}>
        <label>
          <input
            type="radio"
            name="version"
            value="new"
            checked={version === 'new'}
            onChange={() => setVersion('new')}
          />
          データ取得(新)
        </label>
        <label>
          <input
            type="radio"
            name="version"
            value="old"
            checked={version === 'old'}
            onChange={() => setVersion('old')}
          />
          データ取得(旧)
        </label>
        <label>
          <input
            type="radio"
            name="version"
            value="priority"
            checked={version === 'priority'}
            onChange={() => setVersion('priority')}
          />
          レポジトリ順
        </label>
      </div>
      {version === 'new' && <NewVersionTable />}
      {version === 'old' && <OldVersionTable />}
      {version === 'priority' && <PriorityTable />}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px;
`;

export default DomesticAirData;
