import React from 'react';
import _ from 'lodash';
import Tooltip from '@this/shared/tooltip/tooltip';
import { useSwitchText } from '@this/lib/hooks/useSwitchingText';
import {
  BoxWrap,
  BoxPriceArea,
  BoxPrice,
  BoxSmall,
  BoxTax,
  BoxButtonArea,
  BoxButton,
  LoadingArea,
  AreaText,
  NoVacancyArea,
  NoVacancyText,
  NoVacancyWithinLimitArea,
  SpanBlock
} from '../transport_box/right/right_in_outline.template';
import { BoxButtonText, Yen } from '../shared/box_parts';

import HotelDetail, {
  HotelBox,
  HotelImageArea,
  HotelContentArea,
  Left,
  HotelName,
  HotelBody,
  Breakfast,
  Note,
  Rating,
  AnnounceOverLimit,
  FetchNewPriceText
} from './hotel_box_parts';

import type { SelectHotelBoxProps, SelectHotelBoxState } from './types';

type Props = SelectHotelBoxProps &
  SelectHotelBoxState & {
    handleChange: () => void;

    // FIXME: 渡されないprops
    showCloseButton?: never;
    onClose?: never;
  };

const SelectHotelBoxTemplate: React.SFC<Props> = ({
  key,
  selected,
  inList,
  hotel,
  user,
  isDomestic,
  handleChange,
  hotelPriceLimit,
  isRequestForm,
  disabled
}) => {
  const loadingText = useSwitchText('hotel', hotel.loading);
  const showOverLimitHotel =
    (isDomestic && user.show_hotel_of_over_limit) || (!isDomestic && user.show_foreign_hotel_of_over_limit);
  const limit = hotelPriceLimit || 0;
  const isOverLimit = limit > 0 && hotel.getAveragePrice()! > limit;

  return (
    <HotelBox className="select-hotel-box" selected={selected} key={key}>
      <HotelImageArea hotel={hotel} />
      <HotelContentArea>
        <Left>
          <HotelName hotel={hotel} />
          <HotelBody>
            <HotelDetail hotel={hotel} />
          </HotelBody>
        </Left>
        <BoxWrap isRakutenPackage={hotel.package_type === 'RP'}>
          {!hotel.loading ? (
            !hotel.sold_out ? (
              !showOverLimitHotel && isOverLimit && hotel.operator_class_name === 'RakutenStaticfileOperator' ? (
                <NoVacancyWithinLimitArea>
                  <NoVacancyText>
                    <SpanBlock>規定金額以内の</SpanBlock>
                    <SpanBlock>お部屋がなくなり</SpanBlock>
                    ました
                  </NoVacancyText>
                  <AreaText>他の候補を選択してください</AreaText>
                </NoVacancyWithinLimitArea>
              ) : (
                hotel.package_type !== 'RP' && (
                  <BoxPriceArea>
                    {hotel.breakfast && <Breakfast />}
                    <Note hotel={hotel} hotelPriceLimit={hotelPriceLimit} />
                    <div>
                      {hotel.package_type ? (
                        <>
                          <BoxPrice>
                            {utils.digits(hotel.package_base_price || 0)}
                            <Yen>円</Yen>
                          </BoxPrice>
                          <BoxSmall>/1名</BoxSmall>
                        </>
                      ) : (
                        <>
                          <BoxPrice>
                            {utils.digits(hotel.getAveragePrice() || 0)}
                            <Yen>円</Yen>
                          </BoxPrice>
                          <BoxSmall>/1泊1室</BoxSmall>
                        </>
                      )}
                      <BoxTax>
                        {hotel.includeTax ? '(税込)' : '(税別)'}
                        {!hotel.package_type && (
                          <Tooltip type="info">
                            <div className="select__tooltip-format">
                              {hotel
                                .averagePriceTDetailText()
                                .split('\n')
                                .map((l: string, i: number) => (
                                  <p key={i}>{l}</p>
                                ))}
                            </div>
                          </Tooltip>
                        )}
                      </BoxTax>
                      {hotel.isForeign() &&
                        (hotel.rated() ? <Rating rating={hotel.rating!} /> : <span>評価なし</span>)}
                      <AnnounceOverLimit isOverLimit={isOverLimit} loading={hotel.loading} />
                      <FetchNewPriceText isOverLimit={isOverLimit} loading={hotel.loading} />
                    </div>
                  </BoxPriceArea>
                )
              )
            ) : (
              <NoVacancyArea>
                <NoVacancyText>空室なし</NoVacancyText>
                <AreaText>他の候補を選択してください</AreaText>
              </NoVacancyArea>
            )
          ) : (
            <LoadingArea>
              <img src="/images/loading.gif" width={35} height={35} alt="loading" />
              <AreaText>{loadingText}</AreaText>
            </LoadingArea>
          )}
          {!isRequestForm && (
            <BoxButtonArea>
              <BoxButton
                className="select-hotel-box__right-gray__button"
                selected={selected}
                disabled={disabled}
                onClick={handleChange}
              >
                <BoxButtonText>{selected ? ['変更を', <br key={0} />, 'キャンセル'] : '変更 >'}</BoxButtonText>
              </BoxButton>
            </BoxButtonArea>
          )}
        </BoxWrap>
      </HotelContentArea>
    </HotelBox>
  );
};

export default SelectHotelBoxTemplate;
