import {
  OrganizationTable,
  OrganizationTd,
  OrganizationTh
} from '@this/components/organization/organization.style';
import React from 'react';

interface Props {
  status: any;
}

interface State {}

export default class BulkDeleteActionUsers extends React.Component<Props, State> {
  getStatusClass = (user: any): string => (user.isSuccess() ? 'green' : user.isFail() ? 'red' : 'gray');

  render() {
    const { status } = this.props;
    return (
      <OrganizationTable>
        <thead>
          <tr>
            <OrganizationTh>メールアドレス</OrganizationTh>
            <OrganizationTh>ステータス</OrganizationTh>
          </tr>
        </thead>
        <tbody>
          {status.actionUsers.map((user: any, i: number) => (
            <tr key={i}>
              <OrganizationTd>{user.email}</OrganizationTd>
              <OrganizationTd className={this.getStatusClass(user)}>{user.statusMessage()}</OrganizationTd>
            </tr>
          ))}
        </tbody>
      </OrganizationTable>
    );
  }
}
