import React from 'react';
import _ from 'lodash';
import { styled } from '@this/constants/themes';
import { css, withTheme } from 'styled-components';
import A from '@this/components/shared/atoms/a';
import Link from '@this/components/shared/atoms/link';
import { ButtonColor } from '@this/components/shared/atoms/button';

import SearchIconTemplate from '../../reserve_trip/select/search_icon/search_icon.template';

interface Props {
  trip: any;
  user: any;
  tripReportAvailable: boolean;
  openDraftSearch: (trip: any) => void;
  handleDownloadClick: (id: number, e: React.MouseEvent<HTMLElement>) => void;
  handleCancelClick: (id: number, e: React.MouseEvent<HTMLElement>) => void;
  handleExicClick: (id: number, exicUri: string, e: React.MouseEvent<HTMLElement>) => void;
  showConfirmationModal?: (trip: any) => void;
  handleTicketingClick: (e: React.MouseEvent<HTMLElement>) => void;
  handleTripCancelClick: (e: React.MouseEvent<HTMLElement>) => void;
  handleTripRequestApprovalClick: (e: React.MouseEvent<HTMLElement>) => void;
  handleTicketingDisableClick: (e: React.MouseEvent<HTMLElement>) => void;
  handleShowFlightSeatMapModal: () => void;
  theme: { themeClass: string };
  simpleRequestType: boolean;
}

const TripCardRightTemplate: React.SFC<Props> = ({
  trip,
  user,
  tripReportAvailable,
  openDraftSearch,
  handleCancelClick,
  showConfirmationModal,
  handleTicketingClick,
  handleDownloadClick,
  handleTripCancelClick,
  handleExicClick,
  handleTripRequestApprovalClick,
  handleTicketingDisableClick,
  handleShowFlightSeatMapModal,
  simpleRequestType,
  theme
}) => (
  <CardFootIn>
    {trip.draft ? (
      <RightButtons>
        {trip.user_id === user.id && (
          <RightButtonLink
            to={`/${user && user.organization.use_non_order_item ? '' : 'draft_'}trips/${trip.id}/edit`}
          >
            編集
          </RightButtonLink>
        )}
        {user && !user.organization.use_non_order_item && (
          <RightButtonColored onClick={() => openDraftSearch(trip)}>
            <SearchIcon>
              <SearchIconTemplate color="#fff" />
            </SearchIcon>
            <span>予約</span>
          </RightButtonColored>
        )}
      </RightButtons>
    ) : (
      <RightButtons>
        {simpleRequestType &&
          (trip.approvalStatus(trip.in_advance_approval.length - 1) === 1 ||
            _.isEmpty(trip.in_advance_approval)) && (
            <>
              {trip?.exic_uri && (
                <RightButton onClick={e => handleExicClick(trip.id, trip.exic_uri, e)}>
                  JR東海EX予約(予約画面起動)
                </RightButton>
              )}
            </>
          )}

        {trip.user_id === user.id && (
          <>
            {!simpleRequestType && user.organization.use_non_order_item && (
              <>
                {trip.approvalStatus(trip.in_advance_approval.length - 1) === 1 ||
                _.isEmpty(trip.in_advance_approval) ? (
                  <RightButtonLink to={`/trips/${trip.id}/detail`}>詳細</RightButtonLink>
                ) : trip.status !== 5 && !trip.isApproveCanceled() ? (
                  <RightButtonLink to={`/trips/${trip.id}/edit`}>編集</RightButtonLink>
                ) : null}
              </>
            )}
          </>
        )}
        {trip.in_advance_approval && trip.status !== 8 && trip.status !== 9 && trip.status !== 10 && (
          <>
            {showConfirmationModal &&
              trip.currentOrder &&
              trip.currentOrder.traveler_confirmation_status === 'requested' && (
                <RightButton className="ticket-button" onClick={() => showConfirmationModal(trip)}>
                  見積もり承諾
                </RightButton>
              )}
            {handleTicketingClick && trip.isTicketingRequestable && (
              <>
                {!trip.order.isTicketingExpired() ? (
                  <RightButton className="ticket-button" onClick={e => handleTicketingClick(e)}>
                    発券依頼
                  </RightButton>
                ) : (
                  <DisableRightButton className="ticket-button" onClick={e => handleTicketingDisableClick(e)}>
                    発券依頼
                  </DisableRightButton>
                )}
              </>
            )}
            {handleShowFlightSeatMapModal && trip.isSeatReservationEnable && (
              <RightButton className="ticket-button" onClick={() => handleShowFlightSeatMapModal()}>
                航空便座席指定
              </RightButton>
            )}
            {trip.status !== 5 &&
              trip.status !== 6 &&
              trip.approvalStatus(trip.in_advance_approval.length - 1) === 0 && (
                <RightButton onClick={e => handleCancelClick(trip.id, e)}>申請を取消</RightButton>
              )}
            {(trip.approvalStatus(trip.in_advance_approval.length - 1) === 1 ||
              _.isEmpty(trip.in_advance_approval)) && (
              <>
                {trip.status === 0 &&
                  trip.currentOrder &&
                  trip.currentOrder.traveler_confirmation_status === 'confirmed' &&
                  !user.organization.freee_workflow && (
                    <RightButton onClick={e => handleTripRequestApprovalClick(e)}>承認申請</RightButton>
                  )}
                {!simpleRequestType && trip.isNotOverEndTime() && (
                  <RightButton className="cancel" onClick={e => handleTripCancelClick(e)}>
                    キャンセル依頼
                  </RightButton>
                )}
                {trip.currentOrder && <RightButtonLink to={`/trips/${trip.id}`}>チャット</RightButtonLink>}
                {/* AIトラベルとマイナビのステージ環境のみでの提供 */}
                {(theme.themeClass === 'aitravel' || theme.themeClass === 'mynavi') && (
                  <RightButton onClick={e => handleDownloadClick(trip.id, e)}>CSVダウンロード</RightButton>
                )}
              </>
            )}
          </>
        )}
        {trip.receiptIssuable && !trip.billing && (
          <RightButtonLink to={`/trips/${trip.id}/receipts`}>領収書</RightButtonLink>
        )}
        {/* trip.report_availableは、出張報告βという現状メインで利用されている出張報告と別機能で、新しい出張報告のオプションがオンのときは、このボタンの導線は出張報告βの出張報告機能なため表示しないようにする。 */}
        {/* tripReportAvailableが新しい出張報告の有効可否フラグ */}
        {utils.dig(user, 'organization') &&
          trip.report_available &&
          !tripReportAvailable &&
          theme.themeClass !== 'tabikobo' && (
            <RightButtonLink to={`/trip_reports/${trip.trip_report.id}/application`}>出張報告</RightButtonLink>
          )}
      </RightButtons>
    )}
  </CardFootIn>
);

const CardFootIn = styled.div`
  display: block;
`;

const RightButtons = styled.div`
  margin-left: auto;
`;

const RightButtonBase = css`
  padding: 8px 14px;
  background: #ffffff;
  color: #aa935b;
  border: solid 1px #aa935b;
  border-radius: 4px;
  font-size: 12px;
  font-weight: bold;
  display: block;
  margin: 0 0 10px 0;
  line-height: 150%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const RightButton = styled(A)`
  ${RightButtonBase}
`;

const RightButtonColored = styled(A)`
  ${RightButtonBase}
  ${ButtonColor}
  display: flex;
`;

const DisableRightButton = styled.a`
  padding: 8px 14px;
  background: ${props => props.theme.grayBgColor};
  color: #aaa;
  border-radius: 14px;
  font-size: 12px;
  line-height: 1;
  font-weight: bold;
  display: block;
  margin-left: 5px;
  line-height: 12px;
  cursor: default;
  &:hover {
    color: #aaa;
  }
`;

const RightButtonLink = styled(Link)`
  ${RightButtonBase}
`;

const SearchIcon = styled.div`
  width: 12px;
  height: 12px;
  margin: 1px 3px 0 0;
`;

export default withTheme(TripCardRightTemplate);
