/* eslint-disable max-lines */
import React from 'react';
import { Prompt } from 'react-router';
import _ from 'lodash';
import { withTheme } from 'styled-components';
import type { Moment } from 'moment';
import moment from 'moment';
import { styled } from '@this/constants/themes';
import { ButtonBase } from '@this/components/shared/atoms/button';
import Text from '@this/components/shared/text/text';

import ContentHeader from '@this/components/shared/content_header/content_header';
import SimpleLoading from '@this/components/shared/simple_loading/simple_loading';
import Modal from '@this/shared/modal/modal';
import Confirm from '@this/shared/confirm/confirm';

import User from '@this/domain/user/user';
import NonOrderItem from '@this/domain/non_order_item';
import TransportElement from '@this/domain/transport_element';
import SelectRepository from '@this/domain/select_repository';
import SelectStore from '@this/domain/select_store';
import SearchQuery from '@this/domain/search_query';
import type { AvailableRepository } from '@this/domain/available_repository';
import type { RouteComponentProps } from 'react-router-dom';
import Trip from '@this/src/domain/trip/trip';
import TravelerList from '@this/src/domain/traveler/traveler_list';
import Traveler from '@this/src/domain/traveler/traveler';
import { Fetcher, HTTPError } from '@this/src/util';
import { Wrap, BodyWrap, Body, LoadingWrap } from '../trips/trips';
import TripsFormBasicinfoTemplate from './trips_form_block/trips_form_basicinfo.template';
import type { AirlineName } from './trips_form_block/trips_form_itemform.template';
import TripsFormItemformTemplate from './trips_form_block/trips_form_itemform.template';

interface Props extends RouteComponentProps<Record<string, string | undefined>, Record<string, any>, any> {
  serviceId: number;
  availableRepos: AvailableRepository[];
  theme: { themeClass: string };
}

interface TripsState {
  serviceId: number | undefined;
  trip: any;
  user: any;
  members: any;
  travelers: any;
  title: string;
  loading: boolean;
  shouldBlockNavigation: boolean;
  validationErrors: any;
  outdate: Moment;
  homedate: Moment;
  query: SearchQuery | undefined;
  store: SelectStore | undefined;
  repository: SelectRepository | undefined;
  domesticAirlines: AirlineName[];
  internationalAirlines: AirlineName[];
  isSmartSearching: boolean;
  isConfirmingAddTraveler: boolean;
  removingTravelerNumber: number | null;
  showItemForm: boolean;
  selectedItemType: string | null;
  creatingNonOrderItem: NonOrderItem | null;
  editingNonOrderItem: NonOrderItem | null;
  error: string | null;
  showSearchBox: boolean;
  tripItemFormTemplateErrMsg: string;
  shortdistance: boolean;
  formToggleCheck: boolean;
  useSmartSearch: boolean;
}

interface TripsEditResponse {
  trip: any;
  user: any;
  members: any[];
  query: any;
  shortdistance: boolean;
  use_smart_search: boolean;
}

interface TravelerErrors {
  memberSelect?: string | null;
  travelerFirstNameRomen?: string | null;
  travelerLastNameRomen?: string | null;
  travelerFirstNameKana?: string | null;
  travelerLastNameKana?: string | null;
  travelerBirthday?: string | null;
  travelerGender?: string | null;
}

interface TripFormErrors {
  travelers: TravelerErrors[];
  hotelDeadline: string | null;
  domesticAirDeadline: string | null;
  errors: string[];
}

class TripsForm extends React.Component<Props, TripsState> {
  constructor(props: Props) {
    super(props);
    const lState = props.location.state;

    this.state = {
      serviceId: props.serviceId,
      trip: null,
      user: null,
      members: [],
      travelers: [],
      title: utils.dig(lState, 'title') || '',
      loading: true,
      shouldBlockNavigation: true,
      validationErrors: {},
      outdate: lState && lState.outdate ? moment(lState.outdate) : moment().add(1, 'days'),
      homedate: lState && lState.homedate ? moment(lState.homedate) : moment().add(2, 'days'),
      query: undefined,
      store: undefined,
      repository: undefined,
      domesticAirlines: [],
      internationalAirlines: [],
      isSmartSearching: false,
      isConfirmingAddTraveler: false,
      removingTravelerNumber: null,
      showItemForm: true,
      selectedItemType: null,
      creatingNonOrderItem: null,
      editingNonOrderItem: null,
      error: null,
      showSearchBox: false,
      tripItemFormTemplateErrMsg: '',
      shortdistance: false,
      formToggleCheck: false,
      useSmartSearch: true
    };
  }

  componentDidMount() {
    const lState = this.props.location.state;
    // クエリパラメータの制約対策
    Fetcher.post<TripsEditResponse>(`${location.href}.json`, lState || {}).then(
      result => {
        const trip = new Trip(result.trip);
        const user = new User(result.user);
        const members = _.map(result.members, m => new User(m));
        let travelers: any;
        if (trip) {
          travelers = TravelerList.fromTravelerInformations({
            informations: trip.travelerInformations,
            user,
            members,
            peoplenum: trip.travelerInformations.list.length
          });
        } else {
          travelers = TravelerList.fromCount(1);
          travelers.setTravelerAtIndex(0, new Traveler(result.user));
        }
        const query = result.query
          ? new SearchQuery(
              _.merge(result.query, {
                cabin: user.defaultSeatClasses.join(','),
                defaultSeatClasses: user.defaultSeatClasses,
                travelers,
                time: {
                  startTime: result.query.departure_time || trip.startTime,
                  endTime: result.query.return_time || trip.endTime
                },
                availableRepos: this.props.availableRepos
              })
            )
          : new SearchQuery({
              cabin: user.defaultSeatClasses.join(','),
              defaultSeatClasses: user.defaultSeatClasses,
              peoplenum: travelers.list.length,
              travelers,
              time: {
                startTime: trip.startTime,
                endTime: trip.endTime
              },
              availableRepos: this.props.availableRepos
            });
        const outdate =
          (lState && lState.outdate) || !trip.departure_time ? this.state.outdate : moment(trip.departure_time);
        const homedate =
          (lState && lState.homedate) || !trip.return_time ? this.state.homedate : moment(trip.return_time);
        this.fetchAirlines();

        this.setState(
          {
            trip,
            user,
            members,
            travelers,
            title: trip !== null && trip.id !== null ? trip.title : this.state.title,
            outdate,
            homedate,
            query,
            loading: false,
            // showItemForm: result.query !== null,
            isSmartSearching: result.query !== null,
            shortdistance: result.shortdistance,
            useSmartSearch: result.use_smart_search
          },
          () => {
            if (trip.draft && result.query && query) {
              this.handleSmartSearch(query);
            }
          }
        );
      },
      () => {
        this.setState({
          error: '通信環境が不安定です。\n時間をおいてもう一度お試しください。'
        });
      }
    );
  }

  componentDidUpdate() {
    // 未保存離脱の警告をPromptで実装しているが、再読み込みとタブの終了に対応していないため
    if (this.state.shouldBlockNavigation) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = null;
    }
  }

  async fetchAirlines() {
    const domestic = await Fetcher.get<{ airlines: AirlineName[] }>('/domestic_airlines.json');
    const international = await Fetcher.get<{ airlines: AirlineName[] }>('/international_airlines.json');
    this.setState({
      domesticAirlines: domestic.airlines,
      internationalAirlines: international.airlines
    });
  }

  addedItems = () => {
    let items = utils.dig(this.state.trip, 'nonOrderItems') || [];
    if (utils.dig(this.state.trip, 'draft')) {
      items = items.concat(utils.dig(this.state.store, 'result', 'items') || []);
    } else {
      items = items.concat(utils.dig(this.state.trip, 'order', 'orderItems') || []);
    }

    return _.sortBy(items, item => item.startDateTime());
  };

  handleChangeTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ title: e.target.value });
  };

  handleTravelerTypeChange = (i: number, e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === 'self') {
      this.state.travelers.setTravelerAtIndex(i, new Traveler(this.state.user));
    } else {
      this.state.travelers.setTravelerTypeAtIndex(i, e.target.value);
    }
    this.setTripTravelersToQuery();
  };

  handleTravelerSelect = (i: number, traveler: any) => {
    this.state.travelers.setTravelerAtIndex(i, traveler);
    this.setTripTravelersToQuery();
  };

  handleTravelerInfoChange = (i: number, method: string, e: React.ChangeEvent<HTMLInputElement>) => {
    this.state.travelers.list[i][method](e.target.value);
    this.setTripTravelersToQuery();
  };

  setTripTravelersToQuery = () => {
    if (!this.state.query) {
      return;
    }

    const query = this.state.query;
    query.setTravelers(this.state.travelers);
    this.setState({ query });
  };

  handleCancelAddTraveler = () => {
    this.setState({ isConfirmingAddTraveler: false });
  };

  handleCancelRemoveTraveler = () => {
    this.setState({ removingTravelerNumber: null });
  };

  handleAddTraveler = (e?: React.MouseEvent<HTMLElement>) => {
    if (e) {
      e.preventDefault();
    }

    if (utils.dig(this.state.store, 'result', 'items') && !this.state.isConfirmingAddTraveler) {
      this.setState({
        isConfirmingAddTraveler: true
      });
    } else {
      this.state.travelers.addTraveler(this.state.user);
      const query = this.state.query;
      if (query) {
        query.setPeoplenum(query.peoplenum + 1);
      }
      this.setState(
        {
          query,
          isConfirmingAddTraveler: false
        },
        () => {
          if (this.state.store && this.state.query) {
            this.handleSmartSearch(this.state.query);
          }
        }
      );
    }
  };

  handleRemoveTraveler = (i?: number, e?: React.MouseEvent<HTMLElement>) => {
    if (e) {
      e.preventDefault();
    }

    if (utils.dig(this.state.store, 'result', 'items') && i && !this.state.removingTravelerNumber) {
      this.setState({
        removingTravelerNumber: i
      });
    } else {
      this.state.travelers.removeTravelerAtIndex(i || this.state.removingTravelerNumber);
      const query = this.state.query;
      if (query) {
        query.setPeoplenum(query.peoplenum + -1);
      }
      this.setState(
        {
          query,
          removingTravelerNumber: null
        },
        () => {
          if (this.state.store && this.state.query) {
            this.handleSmartSearch(this.state.query);
          }
        }
      );
    }
  };

  handleChangeDate = (kind: 'outdate' | 'homedate', date: Moment) => {
    let outMoment = this.state.outdate;
    let homeMoment = this.state.homedate;

    switch (kind) {
      case 'outdate': {
        outMoment = date;
        if (date > homeMoment) {
          homeMoment = moment(outMoment);
        }
        break;
      }
      case 'homedate': {
        homeMoment = date;
        if (date < outMoment) {
          outMoment = moment(homeMoment);
        }
        break;
      }
      default: {
        break;
      }
    }
    const query = this.state.query;
    if (!this.state.repository && query) {
      query.resetTime(outMoment, homeMoment);
    }

    this.setState({
      query,
      outdate: outMoment,
      homedate: homeMoment
    });
  };

  handleShowItemForm = () => {
    this.setState({
      showItemForm: true,
      isSmartSearching: false,
      creatingNonOrderItem: null,
      editingNonOrderItem: null
    });
  };

  changeSmartSearch = () => {
    const flag = this.state.isSmartSearching;
    const flagCheck = this.state.formToggleCheck;
    this.setState({ isSmartSearching: !flag, formToggleCheck: !flagCheck });
    app.render();
  };

  handleSmartSearch = (query: SearchQuery) => {
    Fetcher.post('/search_histories', query.searchHistoryParams());
    this.setState(
      {
        shouldBlockNavigation: false
      },
      () => {
        if (this.state.query) {
          this.props.history.push(`${location.pathname}${query.getQueryString()}`);

          const store = new SelectStore({ query: this.state.query }, this.state.user, this.props.availableRepos);

          this.setState(
            {
              store,
              repository: new SelectRepository({ store })
            },
            () => {
              if (this.state.repository) {
                this.state.repository.startSearch(this.props.theme.themeClass);
              }
            }
          );
        }

        this.setState({
          shouldBlockNavigation: true
        });
      }
    );
  };

  selectItemType = (elementType: string) => {
    if (this.state.trip) {
      const item = new NonOrderItem({ elementType, peopleNum: this.state.travelers.list.length, supplier: '' });
      const startTime = this.state.outdate.format('YYYY-MM-DD HH:mm');
      const endTime = this.state.homedate.format('YYYY-MM-DD HH:mm');
      item.handleAddElementByType(elementType, startTime, endTime);
      if (elementType !== 'hotel') {
        item.transports[0].handleTransportTypeChange(elementType);
      }
      this.setState({
        selectedItemType: elementType,
        creatingNonOrderItem: item
      });
    }
  };

  isSelectItemTypeClass = (elementType: string) => {
    if (this.state.selectedItemType === elementType) {
      return 'trip_form_item_selected';
    }
    return '';
  };

  deselectItemType = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    this.setState({
      selectedItemType: null,
      creatingNonOrderItem: null,
      editingNonOrderItem: null
    });
  };

  addNonOrderItemToTrip = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const trip = this.state.trip;

    if (this.state.editingNonOrderItem) {
      trip.nonOrderItems = _.filter(trip.nonOrderItems, item => item !== this.state.editingNonOrderItem);
    }
    trip.nonOrderItems.push(this.state.creatingNonOrderItem);
    this.setState({
      trip,
      showItemForm: false,
      selectedItemType: null,
      creatingNonOrderItem: null,
      tripItemFormTemplateErrMsg: ''
    });
  };

  static handleTimeChange(item: NonOrderItem, type: 'from' | 'to', time: Moment) {
    item.transports[0][type].handleTimeChange(time);
  }

  startEditingNonOrderItem = (item: NonOrderItem) => {
    const element = item.elements[0];
    this.setState({
      showItemForm: true,
      isSmartSearching: false,
      selectedItemType: element instanceof TransportElement ? element.transportType : element.type,
      creatingNonOrderItem: _.cloneDeep(item),
      editingNonOrderItem: item
    });
  };

  toggleNonOrderItemNeed = (item: NonOrderItem) => {
    item.toggleNeed();
    if (this.state.editingNonOrderItem === item) {
      this.setState({
        selectedItemType: null,
        creatingNonOrderItem: null,
        editingNonOrderItem: null
      });
    }
  };

  handleSelectItem = () => {
    this.updateUrl();
  };

  private updateUrl() {
    this.setState(
      {
        shouldBlockNavigation: false
      },
      () => {
        if (this.state.store && this.state.query) {
          let href = location.pathname;
          href += this.state.query.getQueryString();
          if (this.state.store.result) {
            href += this.state.store.result.getQueryString();
          }
          this.props.history.push(href);
          const params = utils.getParams();
          _.map(params.items, (item, i) => {
            if (item.item_type === 'hotel') {
              _.merge(item, {
                prefecture: this.state.repository ? this.state.repository.getPrefecture(Number(i)) : ''
              });
            }
            return item;
          });
        }

        this.setState({
          shouldBlockNavigation: true
        });
      }
    );
  }

  handleSubmit = async (e: React.MouseEvent<HTMLElement>) => {
    e.persist();
    const validationErrors = this.validationErrors();
    await this.validate(validationErrors);
    if (_.isEmpty(validationErrors)) {
      this.submit();
    } else {
      e.preventDefault();
      this.setState({ validationErrors });
    }
  };

  async validate(errors: TripFormErrors) {
    try {
      const params = {
        query: this.state.store && this.state.query ? this.state.query.getSubmitParams() : {},
        search_result: this.state.store ? this.state.store.result.confirmParams() : {}
      };
      await Fetcher.post('/trips/confirm/validate', params);
    } catch (e) {
      if (e instanceof HTTPError && e.response?.status === 400) {
        const deadline = e.response.data.hotel_deadline;
        errors.hotelDeadline = deadline;
        const airDeadline = e.response.data.domestic_air_deadline;
        errors.domesticAirDeadline = airDeadline;

        const res_err = e.response.data.errors;
        errors.errors = res_err || [];
      }
    }
    if (this.state.store && this.state.query) {
      const resultErrors = this.state.store.result.errors(this.state.query);
      const err = (errors.errors || []).concat(resultErrors || []);
      if (!_.isEmpty(err)) errors.errors = err;
    }
  }

  submit() {
    if (this.state.trip.draft) {
      const href = this.state.trip.id ? `/trips/${this.state.trip.id}/confirm` : '/trips/confirm';
      this.setState(
        {
          shouldBlockNavigation: false
        },
        () => {
          this.props.history.push({ pathname: href, state: this.submitParams() });
        }
      );
    } else {
      Fetcher.put(`/trips/${this.state.trip.id}/confirm`, this.submitParams()).then(
        () => {
          this.setState(
            {
              shouldBlockNavigation: false
            },
            () => {
              this.props.history.push('/trips');
            }
          );
        },
        () => {
          this.setState({
            error: '通信環境が不安定です。\n時間をおいてもう一度お試しください。'
          });
        }
      );
    }
  }

  submitParams() {
    return {
      params: {
        title: this.state.title,
        travelers: this.state.travelers.getTravelersParam(),
        departure_time: this.state.outdate.format('YYYY-MM-DD HH:mm ZZ'),
        return_time: this.state.homedate.format('YYYY-MM-DD HH:mm ZZ'),
        non_order_items: _.compact(_.map(this.state.trip.nonOrderItems, item => item.updateParams())),
        search_result: this.state.store ? this.state.store.result.confirmParams() : {}
      },
      query: this.state.store && this.state.query ? this.state.query.getSubmitParams() : {}
    };
  }

  validationErrors() {
    const errors: TripFormErrors = {
      travelers: (this.state.travelers.list || []).map((t: any) => {
        const tError: TravelerErrors = {};
        if (utils.dig(this.state.user, 'organization')) {
          switch (t.type) {
            case 'self':
            case 'member': {
              if (!t.id) {
                tError.memberSelect = '出張者を選択してください';
              }
              break;
            }
            case 'companion': {
              tError.travelerFirstNameRomen = TripsForm.alphabetError('出張者の名前(ローマ字)', t.firstNameRoman);
              tError.travelerLastNameRomen = TripsForm.alphabetError('出張者の名字(ローマ字)', t.lastNameRoman);
              tError.travelerFirstNameKana = TripsForm.katakanaError('出張者の名前(カナ)', t.firstNameKana);
              tError.travelerLastNameKana = TripsForm.katakanaError('出張者の名字(カナ)', t.lastNameKana);
              tError.travelerBirthday = TripsForm.dateError('出張者の誕生日', t.flightBirthday);
              tError.travelerGender = TripsForm.requiredError('出張者の性別', t.flightGender);
              break;
            }
            default: {
              break;
            }
          }
        }
        return tError;
      }),
      hotelDeadline: null,
      domesticAirDeadline: null,
      errors: []
    };

    errors.travelers = _.map(errors.travelers, te => utils.compactObject(te));
    errors.travelers = utils.compactObject(errors.travelers);
    return utils.compactObject(errors);
  }

  toggleShowSearchBox = () => {
    this.setState({ showSearchBox: !this.state.showSearchBox });
  };

  handleOpenShowSearchBox = () => {
    this.setState({ showSearchBox: true });
  };

  static requiredError(name: string, value: string, message = 'を入力してください') {
    if (!value || value.length === 0) {
      return name + message;
    }
    return null;
  }

  static katakanaError(name: string, value: string) {
    const error = TripsForm.requiredError(name, value);
    if (error) {
      return error;
    }
    if (!value.match(/^[ァ-ヶー　]*$/)) {
      return `${name}はカタカナで入力してください`;
    }
    return null;
  }

  static alphabetError(name: string, value: string) {
    const error = TripsForm.requiredError(name, value);
    if (error) {
      return error;
    }
    if (!value.match(/^[a-zA-Z]+$/)) {
      return `${name}はアルファベッドで入力してください`;
    }
    return null;
  }

  static dateError(name: string, value: string) {
    const error = TripsForm.requiredError(name, value);
    if (error) {
      return error;
    }
    if (!moment(value).isValid()) {
      return `${name}を正しく入力してください（例：1986-01-01）`;
    }
    return null;
  }

  render() {
    const {
      serviceId,
      trip,
      user,
      members,
      travelers,
      title,
      loading,
      shouldBlockNavigation,
      validationErrors,
      outdate,
      homedate,
      query,
      store,
      repository,
      domesticAirlines,
      internationalAirlines,
      isSmartSearching,
      isConfirmingAddTraveler,
      removingTravelerNumber,
      showItemForm,
      selectedItemType,
      creatingNonOrderItem,
      editingNonOrderItem,
      error,
      showSearchBox,
      tripItemFormTemplateErrMsg,
      shortdistance,
      formToggleCheck,
      useSmartSearch
    } = this.state;
    try {
      return (
        <Wrap>
          <Prompt
            when={shouldBlockNavigation}
            message="保存されていないデータが失われます。本当によろしいですか？"
          />
          <FormBodyWrap>
            <div>
              <FormBody>
                <ContentHeader
                  title={`旅程情報${location.pathname === '/trips/new' ? '登録' : '編集'}`}
                  backButton
                  backLink="/trips"
                />
                <TripForm>
                  <TripFormLeft>
                    {loading ? (
                      <LoadingWrap>
                        <SimpleLoading />
                      </LoadingWrap>
                    ) : error ? (
                      <LoadingWrap>
                        <Error>{error}</Error>
                      </LoadingWrap>
                    ) : (
                      <TripsFormBasicinfoTemplate
                        editable={!!(trip === null || trip.id === null || trip.draft)}
                        travelers={travelers}
                        user={user}
                        members={members}
                        outdate={outdate}
                        homedate={homedate}
                        title={title}
                        validationErrors={validationErrors}
                        handleChangeTitle={this.handleChangeTitle}
                        handleRemoveTraveler={this.handleRemoveTraveler}
                        handleTravelerTypeChange={this.handleTravelerTypeChange}
                        handleTravelerSelect={this.handleTravelerSelect}
                        handleTravelerInfoChange={this.handleTravelerInfoChange}
                        handleAddTraveler={this.handleAddTraveler}
                        handleChangeDate={this.handleChangeDate}
                      />
                    )}
                  </TripFormLeft>
                  <TripFormRight>
                    <Section>
                      <SectionIn>
                        {(trip === null || trip.draft || trip.tripDetailEditable()) && (
                          <SubmitButton onClick={e => this.handleSubmit(e)}>
                            {trip === null || trip.draft ? '次へ' : '保存'}
                          </SubmitButton>
                        )}
                        {!_.isEmpty(validationErrors.hotelDeadline) && (
                          <Error>
                            <Text text={validationErrors.hotelDeadline} />
                          </Error>
                        )}
                        {!_.isEmpty(validationErrors.domesticAirDeadline) && (
                          <Error>
                            <Text text={validationErrors.domesticAirDeadline} />
                          </Error>
                        )}
                        {!_.isEmpty(validationErrors.errors) && (
                          <Error>
                            <Text text={validationErrors.errors.join('\n')} />
                          </Error>
                        )}
                      </SectionIn>
                    </Section>
                  </TripFormRight>
                </TripForm>
                <TripsFormItemformTemplate
                  serviceId={serviceId}
                  user={user}
                  trip={trip}
                  query={query}
                  store={store}
                  repository={repository}
                  domesticAirlines={domesticAirlines}
                  internationalAirlines={internationalAirlines}
                  addedItems={this.addedItems()}
                  editingNonOrderItem={editingNonOrderItem}
                  creatingNonOrderItem={creatingNonOrderItem}
                  selectedItemType={selectedItemType}
                  selectItemType={this.selectItemType}
                  isSelectItemTypeClass={this.isSelectItemTypeClass}
                  deselectItemType={this.deselectItemType}
                  startEditingNonOrderItem={this.startEditingNonOrderItem}
                  toggleNonOrderItemNeed={this.toggleNonOrderItemNeed}
                  handleSelectItem={this.handleSelectItem}
                  handleOpenShowSearchBox={this.handleOpenShowSearchBox}
                  handleShowItemForm={this.handleShowItemForm}
                  toggleShowSearchBox={this.toggleShowSearchBox}
                  changeSmartSearch={this.changeSmartSearch}
                  handleSmartSearch={this.handleSmartSearch}
                  addNonOrderItemToTrip={this.addNonOrderItemToTrip}
                  handleTimeChange={TripsForm.handleTimeChange}
                  tripItemFormTemplateErrMsg={tripItemFormTemplateErrMsg}
                  isSmartSearching={isSmartSearching}
                  showItemForm={showItemForm}
                  showSearchBox={showSearchBox}
                  shortdistance={shortdistance}
                  formToggleCheck={formToggleCheck}
                  useSmartSearch={
                    !this.state.loading &&
                    (trip === null || trip.id === null || trip.draft ? useSmartSearch : false)
                  }
                />
              </FormBody>
            </div>
            {isConfirmingAddTraveler && (
              <Modal show hideModal={() => this.handleCancelAddTraveler()}>
                <Confirm
                  onConfirmed={() => this.handleAddTraveler()}
                  onAborted={() => this.handleCancelAddTraveler()}
                  message="出張者の人数を追加する場合は、Smart Search の検索結果を追加後の人数で更新します。よろしいですか？"
                />
              </Modal>
            )}
            {removingTravelerNumber && (
              <Modal show hideModal={() => this.handleCancelRemoveTraveler()}>
                <Confirm
                  onConfirmed={() => this.handleRemoveTraveler()}
                  onAborted={() => this.handleCancelRemoveTraveler()}
                  message="出張者の人数を削除する場合は、Smart Search の検索結果を削除後の人数で更新します。よろしいですか？"
                />
              </Modal>
            )}
            <FooterActionGroup>
              <FooterButtonArea>
                {(trip === null || trip.draft || trip.tripDetailEditable()) && (
                  <SubmitButton onClick={e => this.handleSubmit(e)}>
                    {trip === null || trip.draft ? '次へ' : '保存'}
                  </SubmitButton>
                )}
              </FooterButtonArea>
            </FooterActionGroup>
          </FormBodyWrap>
        </Wrap>
      );
    } catch (e) {
      utils.sendErrorObject(e);
      return null;
    }
  }
}

const FormBodyWrap = styled(BodyWrap)`
  padding: 0;

  @media screen and (min-width: 1030px) {
    padding: 20px 0;
  }
`;

const FormBody = styled(Body)`
  padding: 0;
`;

const TripForm = styled.div`
  padding: 20px;

  @media screen and (min-width: 815px) {
    display: flex;
  }
`;

const TripFormLeft = styled.div`
  @media screen and (min-width: 815px) {
    width: 80%;
  }
`;

const TripFormRight = styled.div`
  margin: 0 0 0 auto;

  @media screen and (max-width: 814px) {
    display: none;
  }
`;

const Section = styled.div`
  padding-bottom: 20px;
`;

const SectionIn = styled.div`
  margin-left: 10px;
  font-size: 13px;
`;

const Error = styled.div`
  color: ${props => props.theme.redColor};
`;

const SubmitButton = styled.button`
  ${ButtonBase};
  font-size: 13px;
  width: 200px;
`;

const FooterActionGroup = styled.div`
  background: #fff;
  position: sticky;
  bottom: 0;
  box-shadow: 0 -3px 3px 3px rgba(0, 0, 0, 0.1);
`;

const FooterButtonArea = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 20px;
`;

export default withTheme(TripsForm);
