/* eslint-disable max-lines */
import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';
import type { RouteComponentProps } from 'react-router-dom';
import { styled } from '@this/constants/themes';
import ContentBody from '@this/components/shared/atoms/content_body';

import { Loading } from '@this/components/shared/ui/feedbacks/loading/loading';
import { Modal, ModalHeader, ModalBody } from '@this/shared/ui/feedbacks/modal';
import Confirm from '@this/shared/confirm/confirm';

import type User from '@this/domain/user/user';
import Traveler from '@this/domain/traveler/traveler';

import type { AvailableRepository } from '@this/domain/available_repository';
import { Link } from '@this/shared/ui/navigations/link';
import { Button } from '@this/shared/ui/inputs/button';
import { Box } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import Trip from '@this/domain/trip/trip';
import DraftSearch from '@this/components/trips_management/trips/draft_search/draft_search';
import { media } from '@this/components/shared/atoms/media';
import DatetimePicker from '@this/shared/datetime_picker/datetime_picker';
import type { Moment } from 'moment';
import type Project from '@this/domain/project/project';
import type Department from '@this/domain/department/department';
import Notification from '@this/src/notification';
import { Fetcher, HTTPError } from '@this/src/util';
import TripHeader from './trip_header';
import TripCard from './trip_card';
import TripModalTemplate from './trip_modal.template';
import TripDownloadModalTemplate from './trip_download_modal.template';
import ConfirmationModalTemplate from './confirmation_modal.template';
import ExicModal from './exic_modal';

type SortKey = 'created_at' | 'started_at';
type SortDirection = 'asc' | 'desc';
type Type = 'user';

interface Props extends RouteComponentProps {
  user: User | null;
  serviceId: number;
  availableRepos: AvailableRepository[];
  availableOptions: string[];
}

interface FetchTripsResult {
  user: any;
  trips: any[];
  show_fee: boolean;
  service: { name: string; tel: string };
  departments: Department[];
  projects: Project[];
  current_page: number;
  total_pages: number;
}

interface NotifiedUsersResult {
  users: any[];
}

interface TripParams {
  type: Type;
  page: number;
  trip_id: string | null | undefined;
  trip_from_date: string | undefined;
  trip_to_date: string | undefined;
  sort_key: SortKey;
  sort_direction: SortDirection;
}

const Trips = observer(({ user, serviceId, availableRepos, availableOptions }: Props) => {
  const tripReportAvailable = availableOptions.includes('trip_report');
  const [service, setService] = useState<{ name: string; tel: string }>({ name: '', tel: '' });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [selectedDraft, setSelectedDraft] = useState<any | null>(null);
  const [ongoingTrips, setOngoingTrips] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [tripId, setTripId] = useState<string | null>(null);
  const [tripFromDate, setTripFromDate] = useState<Moment | undefined>(undefined);
  const [tripToDate, setTripToDate] = useState<Moment | undefined>(undefined);
  const [sortKey, setSortKey] = useState<SortKey>('created_at');
  const [sortDirection, setSortDirection] = useState<SortDirection>('desc');
  const [departments, setDepartments] = useState<Department[]>([]);
  const [projects, setProjects] = useState<Project[]>([]);

  const [downloadTripId, setDownloadTripId] = useState<number | null>(null);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [downloadError, setDownloadError] = useState<string | null>(null);
  const [cancelTripId, setCancelTripId] = useState<number | null>(null);
  const [cancelReason, setCancelReason] = useState('');
  const [showCancelApproveModal, setShowCancelApproveModal] = useState(false);
  const [cancelSubmitting, setCancelSubmitting] = useState(false);
  const [cancelError, setCancelError] = useState<string | null>(null);
  const [exicTripId, setExicTripId] = useState<number | null>(null);
  const [showExicModal, setShowExicModal] = useState(false);
  const [exicError, setExicError] = useState<string | null>(null);
  const [confirmingTrip, setConfirmingTrip] = useState<any>(null);
  const [confirmingTripNotifiedUsers, setConfirmingTripNotifiedUsers] = useState<(Traveler | undefined)[] | null>(
    null
  );
  const [excludeOrder, setExcludeOrder] = useState(false);
  const [cancelingHotelId, setCancelingHotelId] = useState<number | null>(null);
  const [tripParams, setTripPrams] = useState<TripParams>({
    type: 'user',
    page: 1,
    trip_id: undefined,
    trip_from_date: undefined,
    trip_to_date: undefined,
    sort_key: 'created_at',
    sort_direction: 'desc'
  });
  const [showWorkflowUnappliedModal, setShowWorkflowUnappliedModal] = useState(false);
  const [workflowUnappliedTrips, setWorkflowUnappliedTrips] = useState<any[]>([]);

  const fetchTrips = useCallback(() => {
    setLoading(true);
    setError(null);
    utils
      .jsonPromise<FetchTripsResult>('/trips.json', tripParams)
      .then(result => {
        setService(result.service);
        setDepartments(result.departments);
        setProjects(result.projects);
        setOngoingTrips(_.map(result.trips, t => new Trip(_.merge(t, { showFee: result.show_fee }))));
        setTotalPages(result.total_pages);
      })
      .finally(() => setLoading(false))
      .catch(e => {
        setError('通信環境が不安定です。\n時間をおいてもう一度お試しください。');
        utils.sendErrorObject(e);
      });
  }, [tripParams]);

  useEffect(() => {
    fetchTrips();
  }, [fetchTrips]);

  useEffect(() => {
    const unappliedTrips = ongoingTrips.filter(trip => trip.status === 8 && trip.freee_approval_request_id);
    setWorkflowUnappliedTrips(unappliedTrips);
    setShowWorkflowUnappliedModal(unappliedTrips.length > 0);
  }, [ongoingTrips]);

  const handleDownloadClick = useCallback((tripId: number, e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setDownloadTripId(tripId);
    setShowDownloadModal(true);
    setDownloadError(null);
  }, []);

  const handleCancelClick = useCallback((tripId: number, e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setCancelTripId(tripId);
    setShowCancelApproveModal(true);
    setCancelError(null);
  }, []);

  const showConfirmationModal = useCallback((trip: any) => {
    setConfirmingTrip(trip);
    utils
      .jsonPromise<NotifiedUsersResult>(`/trips/${trip.id}/notified_users.json`)
      .then(result => {
        setConfirmingTripNotifiedUsers(_.map(result.users, u => new Traveler(u)));
      })
      .catch(e => {
        alert('通信環境が不安定です。\n時間をおいてもう一度お試しください。');
        utils.sendErrorObject(e);
      });
  }, []);

  const handleSearchTrips = useCallback(() => {
    setCurrentPage(1);
    setTripPrams({
      type: 'user',
      page: 1,
      trip_id: tripId || undefined,
      trip_from_date: tripFromDate?.format('YYYY-MM-DD'),
      trip_to_date: tripToDate?.format('YYYY-MM-DD'),
      sort_key: sortKey,
      sort_direction: sortDirection
    });
  }, [tripId, tripFromDate, tripToDate, sortKey, sortDirection]);

  const handlePageChange = useCallback(
    (_: React.ChangeEvent<unknown>, page: number) => {
      setCurrentPage(page);
      setTripPrams({
        type: 'user',
        page,
        trip_id: tripId || undefined,
        trip_from_date: tripFromDate?.format('YYYY-MM-DD'),
        trip_to_date: tripToDate?.format('YYYY-MM-DD'),
        sort_key: sortKey,
        sort_direction: sortDirection
      });
    },
    [tripId, tripFromDate, tripToDate, sortKey, sortDirection]
  );

  const handleCancelDownload = useCallback(() => {
    setDownloadTripId(null);
    setShowDownloadModal(false);
  }, []);

  const handleSubmitConfirmation = useCallback(() => {
    Fetcher.put(`/orders/${confirmingTrip.currentOrder.id}/confirmations.json`, {
      user_ids: (confirmingTripNotifiedUsers || []).map(user => utils.dig(user, 'id'))
    })
      .then(() => {
        fetchTrips();
      })
      .finally(() => {
        setConfirmingTrip(null);
        setConfirmingTripNotifiedUsers(null);
      })
      .catch(e => {
        if (e instanceof HTTPError && e.response?.data.errors) {
          Notification.error(e.response.data.errors);
        } else {
          alert('通信環境が不安定です。\n時間をおいてもう一度お試しください。');
        }
      });
  }, [fetchTrips, confirmingTrip, confirmingTripNotifiedUsers]);

  const handleCancelConfirmation = useCallback(() => {
    setConfirmingTrip(null);
    setConfirmingTripNotifiedUsers(null);
  }, []);

  const handleNotifiedUserAdd = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();
      const users = confirmingTripNotifiedUsers || [];
      users.push(undefined);
      setConfirmingTripNotifiedUsers(users);
    },
    [confirmingTripNotifiedUsers]
  );

  const handleNotifiedUserSelect = useCallback(
    (i: number, traveler: Traveler) => {
      const users = confirmingTripNotifiedUsers || [];
      users[i] = traveler;
      setConfirmingTripNotifiedUsers(users);
    },
    [confirmingTripNotifiedUsers]
  );

  const handleNotifiedUserRemove = useCallback(
    (e: React.MouseEvent<HTMLElement>, i: number) => {
      e.preventDefault();
      const users = confirmingTripNotifiedUsers || [];
      users.splice(i, 1);
      setConfirmingTripNotifiedUsers(users);
    },
    [confirmingTripNotifiedUsers]
  );

  const handleSubmitCancel = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();
      setCancelSubmitting(true);
      setCancelError(null);
      Fetcher.put(`/application/in_advance/${cancelTripId}/cancel`, { cancel_reason: cancelReason })
        .then(() => {
          setShowCancelApproveModal(false);
          setCancelReason('');
          setCancelError(null);
          fetchTrips();
        })
        .finally(() => {
          setCancelSubmitting(false);
        })
        .catch(e => {
          setCancelError(
            e instanceof HTTPError && e.response?.data.error
              ? e.response.data.error
              : '通信環境が不安定です。\n時間をおいてもう一度お試しください。'
          );
        });
    },
    [cancelTripId, cancelReason, fetchTrips]
  );

  const handleCancelCancel = useCallback(() => {
    setShowCancelApproveModal(false);
    setCancelTripId(null);
  }, []);

  const handleExicClick = useCallback((tripId: number, exicUri: string, e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setExicTripId(tripId);
    setShowExicModal(true);
    setExicError(null);
    if (exicUri === 'credentials_missing') {
      setExicError('EXIC会員ID、又はEXICパスワードが未設定です。\nアカウント設定より設定してください。');
    } else if (exicUri === 'traveler_not_found') {
      setExicError('ログインユーザーと出張者が異なるため、EX予約への遷移対象外です。');
    }
  }, []);

  const handleSubmitExic = useCallback((id: number | null, e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setShowExicModal(false);
    window.open(`/exic_synchronize/connect/${id}` || '', '_blank', 'noopener,noreferrer');
  }, []);

  const handleCancelExic = useCallback(() => {
    setShowExicModal(false);
    setExicTripId(null);
  }, []);

  const handleHotelCancelConfirm = useCallback(() => {
    utils
      .jsonPromise(`/hotels/${cancelingHotelId}/cancel`, {}, 'PUT')
      .then(
        _result => {
          setCancelingHotelId(null);
          fetchTrips();
        },
        error => {
          alert(
            utils.dig(error, 'responseJSON', 'error') ||
              '通信環境が不安定です。\n時間をおいてもう一度お試しください。'
          );
          setCancelingHotelId(null);
        }
      )
      .catch(e => {
        utils.sendErrorObject(e);
      });

    setCancelingHotelId(null);
  }, [cancelingHotelId, fetchTrips]);

  return (
    <Wrap>
      <BodyWrap>
        <div>
          <TripHeader selected="trip" tripReportAvailable={tripReportAvailable} />
          <Body>
            <Box display="flex">
              <PageTitle style={{ marginRight: 'auto' }}>予約した旅程一覧</PageTitle>
              <Box display="flex" alignItems="center" style={{ gap: '0 10px' }}>
                {!tripReportAvailable && <Link href="/trip_reports">出張報告一覧(β)</Link>}
                <Button href={`/${user && user.organization.use_non_order_item ? '' : 'draft_'}trips/new`}>
                  新規作成
                </Button>
              </Box>
            </Box>
            <SearchFields className="searchFields">
              {/* <SearchField>
                <SearchTitle>出張者</SearchTitle>
                <SearchInput>
                  <input
                    type="text"
                    placeholder="出張者"
                    value={filterParams.travelerName}
                    onChange={handleTravelerNameChange}
                  />
                </SearchInput>
              </SearchField> */}
              <SearchField>
                <SearchTitle>旅程番号</SearchTitle>
                <SearchInput>
                  <input
                    type="text"
                    placeholder="旅程番号"
                    value={tripId || ''}
                    onChange={e => setTripId(e.target.value)}
                  />
                </SearchInput>
              </SearchField>
              <SearchField>
                <SearchTitle>出張日</SearchTitle>
                <SearchInput>
                  <DatetimePicker
                    dateFieldId="filterParams-fromDate"
                    value={tripFromDate}
                    onChange={date => setTripFromDate(date)}
                    onClear={() => setTripFromDate(undefined)}
                    placeholder="開始日"
                    border
                    showToday
                    showPast
                    width="140px"
                    deletable
                  />
                  <span>〜</span>
                  <DatetimePicker
                    dateFieldId="filterParams-toDate"
                    value={tripToDate}
                    onChange={date => setTripToDate(date)}
                    onClear={() => setTripToDate(undefined)}
                    placeholder="終了日"
                    border
                    showToday
                    showPast
                    width="140px"
                    deletable
                  />
                </SearchInput>
              </SearchField>
              <SearchField>
                <SearchTitle>並び順</SearchTitle>
                <Box display="flex">
                  <SearchInput>
                    <select value={sortKey} onChange={e => setSortKey(e.target.value as SortKey)}>
                      <option value="created_at">旅程作成日順</option>
                      <option value="started_at">出発日順</option>
                    </select>
                  </SearchInput>
                  <SearchInput>
                    <select
                      value={sortDirection}
                      onChange={e => setSortDirection(e.target.value as SortDirection)}
                    >
                      <option value="desc">降順</option>
                      <option value="asc">昇順</option>
                    </select>
                  </SearchInput>
                </Box>
              </SearchField>
              <SearchField>
                <Button className={loading ? 'disabled' : undefined} onClick={handleSearchTrips}>
                  検索
                </Button>
              </SearchField>
            </SearchFields>
            <TripsWrap>
              {loading ? (
                <Loading />
              ) : error ? (
                <Error>{error}</Error>
              ) : selectedDraft ? (
                <DraftSearch
                  trip={selectedDraft}
                  onClose={() => setSelectedDraft(null)}
                  availableRepos={availableRepos}
                />
              ) : ongoingTrips.length > 0 ? (
                <ListWrap>
                  {ongoingTrips.map(trip => (
                    <CardWrap key={trip.id}>
                      <TripCard
                        trip={trip}
                        user={user}
                        service={service}
                        departments={departments}
                        projects={projects}
                        openDraftSearch={() => setSelectedDraft(trip)}
                        handleDownloadClick={handleDownloadClick}
                        handleCancelClick={handleCancelClick}
                        handleExicClick={handleExicClick}
                        // handleHotelCancelClick={this.handleHotelCancelClick}
                        showConfirmationModal={showConfirmationModal}
                        serviceId={serviceId}
                        fetchTrips={fetchTrips}
                        tripReportAvailable={tripReportAvailable}
                      />
                    </CardWrap>
                  ))}
                  <Pagination
                    style={{ margin: '10px 0' }}
                    count={totalPages}
                    page={currentPage}
                    onChange={handlePageChange}
                  />
                </ListWrap>
              ) : (
                <NoItem>条件に合致する旅程はありません</NoItem>
              )}
              <TripDownloadModalTemplate
                showDownloadModal={showDownloadModal}
                downloading={false}
                useNonOrderItem={user?.organization?.use_non_order_item || false}
                excludeOrder={excludeOrder}
                handleSubmitDownload={() => {
                  setShowDownloadModal(false);
                }}
                handleCancelDownload={handleCancelDownload}
                handleToggleOnlyNonOrderItem={() => setExcludeOrder(!excludeOrder)}
                downloadError={downloadError}
                downloadTripId={downloadTripId}
              />
              <ConfirmationModalTemplate
                applicant={user}
                users={confirmingTripNotifiedUsers}
                showConfirmationModal={confirmingTrip !== null}
                handleSubmitConfirmation={handleSubmitConfirmation}
                handleCancelConfirmation={handleCancelConfirmation}
                handleNotifiedUserAdd={handleNotifiedUserAdd}
                handleNotifiedUserSelect={handleNotifiedUserSelect}
                handleNotifiedUserRemove={handleNotifiedUserRemove}
              />
              <TripModalTemplate
                cancelReason={cancelReason}
                cancelSubmitting={cancelSubmitting}
                showCancelApproveModal={showCancelApproveModal}
                handleSubmitCancel={handleSubmitCancel}
                handleCancelCancel={handleCancelCancel}
                handleCancelReasonChange={e => setCancelReason(e.target.value)}
                cancelError={cancelError}
              />
              <ExicModal
                showExicModal={showExicModal}
                tripId={exicTripId}
                handleSubmitExic={handleSubmitExic}
                handleCancelExic={handleCancelExic}
                exicError={exicError}
              />
            </TripsWrap>
          </Body>
        </div>
        {cancelingHotelId && (
          <Modal open onClose={() => setCancelingHotelId(null)}>
            <Confirm
              onConfirmed={() => handleHotelCancelConfirm()}
              onAborted={() => setCancelingHotelId(null)}
              message={`ホテルの予約をキャンセルしてよろしいですか？\n※この操作は取り消せません`}
            />
          </Modal>
        )}
        <Modal open={showWorkflowUnappliedModal} onClose={() => setShowWorkflowUnappliedModal(false)}>
          <ModalHeader>ワークフローを申請してください</ModalHeader>
          <ModalBody>
            <Box marginBottom="20px">
              以下の旅程のワークフローが申請待ちになっています。
              <br />
              詳細ページに遷移してワークフローを申請してください。
            </Box>
            <Box>
              {workflowUnappliedTrips.map(trip =>
                trip.freee_approval_request_id ? (
                  <Box>
                    <a
                      href={`https://secure.freee.co.jp/approval_requests/${trip.freee_approval_request_id}`}
                      // eslint-disable-next-line react/jsx-no-target-blank
                      target="_blank"
                      rel="noreferrer"
                    >
                      freeeワークフロー申請ページ（旅程番号：{trip.id}）
                    </a>
                  </Box>
                ) : null
              )}
            </Box>
          </ModalBody>
        </Modal>
      </BodyWrap>
    </Wrap>
  );
});

export const Wrap = styled.div`
  min-height: 100vh;
`;

export const BodyWrap = styled.div`
  min-height: 100vh;
  display: block;
`;

export const Body = styled(ContentBody)`
  max-width: 1150px;
  padding: 20px;
  background: #fff;
`;

const PageTitle = styled.h2`
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 24px;
`;

const SearchFields = styled.div`
  display: flex;
  column-gap: 16px;
  row-gap: 8px;
  align-items: flex-end;

  ${media.sp`
    flex-wrap: wrap;
  `}
`;

const SearchField = styled.div`
  display: flex;
  flex-flow: column;
`;

const SearchTitle = styled.label`
  font-size: 12px;
  color: ${props => props.theme.grayTextColor};
`;

const SearchInput = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  column-gap: 4px;

  input[type='text'] {
    margin-bottom: 0;
  }

  label {
    font-weight: normal;
    cursor: pointer;
    margin-right: 10px;
  }

  input[type='checkbox'] {
    cursor: pointer;
  }

  & .single-calendar-input {
    width: 120px !important;
  }

  select,
  #filterParams-fromDate,
  #filterParams-toDate {
    background-color: #fff;
    border: 1px solid #eee;
    border-radius: 3px;
    box-shadow: inset 0 1px 3px rgb(0 0 0 / 6%);
    box-sizing: border-box;
    font-family: 'Helvetica Neue', 'Helvetica', 'Roboto', 'Arial', sans-serif;
    font-size: 1em;
    padding: 0.5em;
    margin-bottom: 0;
    transition: border-color;
    width: 100%;
    height: auto;
  }

  select {
    font-size: 14px;
  }
`;

export const LoadingWrap = styled.div`
  padding-top: 20px;
`;

const Error = styled.div`
  padding: 20px;
  color: ${props => props.theme.redColor};
`;

const TripsWrap = styled.div`
  padding: 20px 0;
`;

const ListWrap = styled.div``;

const NoItem = styled.p`
  margin: 0 auto;
  text-align: center;
`;

const CardWrap = styled.div`
  box-shadow: 0 0 3px 1px ${props => props.theme.grayBorderColor};
  border-radius: 5px;
`;

export default Trips;
