import { Fetcher, objectToFormData } from '@this/src/util';
import React from 'react';
import _ from 'lodash';
import { styled } from '@this/constants/themes';
import A from '@this/shared/atoms/a';
import { Red } from '@this/components/admin/shared/atom';
import { Modal, ModalHeader, ModalBody, ModalFooter } from '@this/shared/ui/feedbacks/modal';
import { Button } from '@this/shared/ui/inputs/button';
import SimpleLoading from '@this/shared/simple_loading/simple_loading';
import FlashMessage from '@this/shared/flash_message/flash_message';
import {
  RoleNameInput,
  ResourceWrapper,
  Left,
  Right,
  InputWrapper
} from '@this/components/organization/organization_roles/roles';

import AdminRole from '@this/domain/admin_role/admin_role';
import { AdminResource, ReadPermissionType, WritePermissionType } from '@this/domain/admin_role/admin_resource';
import { Box } from '@material-ui/core';
import { reportError } from '@this/lib/bugsnag';
import { Title } from './admin';

interface Props {}

interface State {
  roles: AdminRole[];
  loading: boolean;
  error: string | null;
  showModal: boolean;
  targetRole: AdminRole | null;
  saving: boolean;
  saveError: string | null;
}

interface FetchAdminRolesResult {
  roles: any[];
}

class AdminRoles extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      roles: [],
      loading: false,
      error: null,
      showModal: false,
      targetRole: null,
      saving: false,
      saveError: null
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    Fetcher.get<FetchAdminRolesResult>('/admin/admin_roles.json').then(
      result => {
        this.setState({
          roles: _.map(result.roles, raw => new AdminRole(raw)),
          loading: false
        });
      },
      _error => {
        this.setState({
          loading: false,
          error: '通信環境が不安定です。\n時間をおいてもう一度お試しください。'
        });
      }
    );
  }

  skeletonRole() {
    const organizationRole = new AdminRole({ id: 0, name: '', admin_role_resources: [] });
    // 便宜上、1つめのロールのリソース一覧から、リソースID, リソース名を得る
    this.state.roles[0].resources.forEach(r => {
      const organization_resource = new AdminResource({
        id: 0,
        resource_id: r.resourceId,
        resource_name: r.resourceName,
        resource_path: r.resourcePath,
        read_permission: false,
        write_permission: false
      });
      organizationRole.resources.push(organization_resource);
    });
    return organizationRole;
  }

  createRole() {
    this.setState({
      targetRole: this.skeletonRole(),
      showModal: true
    });
  }

  editRole(targetRole: AdminRole) {
    this.setState({
      targetRole,
      showModal: true
    });
  }

  setShowModal(val: boolean) {
    this.setState({
      showModal: val
    });
  }

  setTragetRole(targetRole: AdminRole) {
    this.setState({ targetRole });
  }

  handleInputChange = (resource_id: number, type: string) => {
    if (!this.state.targetRole) {
      return;
    }

    // ユーザが選択したチェックボックスに紐づくpermissionの値を反転させる
    // read無かつwrite有という状態を作らないようにしている
    const newResources = this.state.targetRole.resources.map(resource => {
      if (resource.resourceId === resource_id) {
        if (type === ReadPermissionType) {
          resource.readPermission = !resource.readPermission;
          if (!resource.readPermission) {
            resource.writePermission = false;
          }
        } else if (type === WritePermissionType) {
          resource.writePermission = !resource.writePermission;
          if (resource.writePermission) {
            resource.readPermission = true;
          }
        }
        return resource;
      }
      return resource;
    });

    const newTargetRole = { ...this.state.targetRole };
    newTargetRole.resources = newResources;
    this.setTragetRole(newTargetRole);
  };

  handleRoleNameChange(val: string) {
    if (!this.state.targetRole) {
      return;
    }

    const newTargetRole = { ...this.state.targetRole };
    newTargetRole.name = val;
    this.setTragetRole(newTargetRole);
  }

  updateRoleResource() {
    if (this.state.targetRole && !this.state.targetRole.name) {
      this.setState({ saveError: '役割名を入力してください' });
      return;
    }

    this.setState({ saving: true });
    Fetcher.put<any>('/admin/roles.json', objectToFormData(this.state.targetRole))
      .then(_result => {
        this.setState({ saving: false });
        // 権限を変えたタイミングでヘッダに表示される情報も変更したいため、
        // データを読み込みなおす目的でリロードを行う
        location.reload();
      })
      .catch(e => {
        this.setState({ saving: false });
        alert(e.response.data.message);
      });
  }

  setSaveError(val: string) {
    this.setState({
      saveError: val
    });
  }

  render() {
    try {
      const { roles, loading, error, showModal, targetRole, saving, saveError } = this.state;
      return (
        <div className="admin-dashboard">
          <Title>管理者役割設定</Title>
          <div className="admin__content-in">
            <Box m={2}>
              <Button onClick={() => this.createRole()}>役割を追加</Button>
            </Box>
            {loading ? (
              <SimpleLoading />
            ) : error ? (
              <Red>{error}</Red>
            ) : roles.length > 0 ? (
              <Table>
                <thead>
                  <tr>
                    <Th />
                    {roles.map((role, i) => (
                      <Th key={i} colSpan={1}>
                        {role.name}
                        {/*
                        アドホックな対応だが、デフォルトで作成される管理者、一般ユーザーについては、編集のリンクを表示しない。
                        */}
                        {role.name !== 'administrator' && <A onClick={() => this.editRole(role)}>(編集)</A>}
                      </Th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <Th>権限</Th>
                    {roles.map((_, i) => (
                      <React.Fragment key={i}>
                        <Th>表示 / 編集</Th>
                      </React.Fragment>
                    ))}
                  </tr>
                  {roles[0].resources.map((r, i) => (
                    <tr key={i}>
                      <Td>{r.resourceName}</Td>
                      {roles.map((role, j) => (
                        <React.Fragment key={j}>
                          <ResourceTd>
                            {role.resources[i].readPermission ? '◯ / ' : 'ｘ / '}
                            {role.resources[i].writePermission ? '◯' : 'ｘ'}
                          </ResourceTd>
                          {/*
                            <ResourceTd>{role.resources[i].writePermission ? '◯' : 'ｘ'}</ResourceTd>
                          */}
                        </React.Fragment>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : null}
          </div>
          <Modal open={showModal} size="large" onClose={() => this.setShowModal(false)}>
            <ModalHeader>役割を保存する</ModalHeader>
            <ModalBody>
              <FlashMessage
                message={saveError}
                type="error"
                handleFlashMessageClose={() => {
                  this.setSaveError('');
                }}
              />
              <label>役割名</label>
              <RoleNameInput
                type="text"
                value={targetRole ? targetRole.name : ''}
                onChange={e => this.handleRoleNameChange(e.target.value)}
              />
              <ResourceWrapper>
                <Left>
                  {targetRole &&
                    targetRole.resources
                      .slice(0, targetRole.resources.length / 2)
                      .map((resource, i) => (
                        <ResourceFragment key={i} resource={resource} handleInputChange={this.handleInputChange} />
                      ))}
                </Left>
                <Right>
                  {targetRole &&
                    targetRole.resources
                      .slice(targetRole.resources.length / 2)
                      .map((resource, i) => (
                        <ResourceFragment key={i} resource={resource} handleInputChange={this.handleInputChange} />
                      ))}
                </Right>
              </ResourceWrapper>
            </ModalBody>
            <ModalFooter>
              <Button color="sub" onClick={() => this.setShowModal(false)}>
                キャンセル
              </Button>
              <Button onClick={() => this.updateRoleResource()} disabled={saving}>
                保存
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      );
    } catch (e) {
      reportError(e);
      return null;
    }
  }
}

interface FragmentProps {
  resource: AdminResource;
  handleInputChange: (resource_id: number, type: string) => void;
}

const ResourceFragment = (props: FragmentProps) => {
  return (
    <>
      <h3>{props.resource.resourceName}</h3>
      <InputWrapper>
        <label>表示</label>
        <input
          type="checkbox"
          checked={props.resource.readPermission}
          onChange={() => props.handleInputChange(props.resource.resourceId, ReadPermissionType)}
        />
        <label>編集</label>
        <input
          type="checkbox"
          checked={props.resource.writePermission}
          onChange={() => props.handleInputChange(props.resource.resourceId, WritePermissionType)}
        />
      </InputWrapper>
    </>
  );
};

const Table = styled.table`
  margin: 0;
  font-size: 12px;
  width: auto;
`;

const Th = styled.th`
  padding: 8px;
`;

const Td = styled.td`
  padding: 8px;
`;

const ResourceTd = styled.td`
  padding: 8px;
  text-align: center;
`;

export default AdminRoles;
