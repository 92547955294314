import React from 'react';
import _ from 'lodash';

import TextArea from 'react-textarea-autosize';
import SimpleLoading from '../../../../shared/simple_loading/simple_loading';

interface Props {
  traceId: number;
}

interface State {
  memo: string;
  loading: boolean;
  saving: boolean;
  succeed: boolean;
}

interface TraceIdMemoResponse {
  memo: string;
}

class TraceIdMemo extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      memo: '',
      loading: false,
      saving: false,
      succeed: false
    };
  }

  getMemoUrl(): string {
    return `/arrangement/trace_id_memos/${this.props.traceId}`;
  }

  public async componentDidMount() {
    this.fetchMemo();
  }

  async fetchMemo(): Promise<void> {
    this.setState({ loading: true });
    return utils.jsonPromise<TraceIdMemoResponse>(this.getMemoUrl()).then(response =>
      this.setState({
        memo: response.memo || '',
        loading: false
      })
    );
  }

  async updateMemo(): Promise<void> {
    this.setState({ saving: true });
    const args = { memo: this.state.memo };
    return utils.jsonPromise<void>(this.getMemoUrl(), args, 'PUT').then(() =>
      this.setState({
        saving: false,
        succeed: true
      })
    );
  }

  handleChange(memo: string) {
    this.setState({ memo, succeed: false });
  }

  handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    this.updateMemo();
  }

  render() {
    const classBase = 'order-histories__trip-block__trace-id-memo';
    const state = this.state;
    return (
      <div>
        {state.loading ? (
          <SimpleLoading />
        ) : (
          <>
            <form onSubmit={e => this.handleSubmit(e)} className="flex">
              <TextArea value={state.memo} onChange={e => this.handleChange(e.target.value)} />
              <input type="submit" value="保存" className={`${classBase}__save-button`} disabled={state.saving} />
            </form>
            {state.succeed ? <div className="green">保存しました</div> : null}
          </>
        )}
      </div>
    );
  }
}

export default TraceIdMemo;
