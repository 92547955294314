import * as React from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import {
  OrganizationTitle,
  OrganizationTable,
  OrganizationTd,
  OrganizationTh,
  OrganizationBody,
  AddButton,
  OrganizationTdButton,
  OrganizationAddressArea
} from '@this/components/organization/organization.style';
import { ButtonType } from '@this/shared/atoms/button';
import SimpleLoading from '@this/shared/simple_loading/simple_loading';
import type { OrganizationBase } from '@this/components/organization/organization_base/type';
import OrganizationBaseForm from '@this/components/organization/organization_base/organization_base_form';
import { MasterListContainer } from '@this/components/organization/master_list_container';
import { WithConfirmModal } from '@this/shared/ui/feedbacks/modal/confirm_modal';
import Notification from '../../../notification';

type Props = { availableOptions: string[] } & RouteComponentProps;

interface State {
  organizationBases: OrganizationBase[];
  loading: boolean;
  isOpenModal: boolean;
  editingTarget?: OrganizationBase;
  totalPage: number;
}

interface OrganizationBaseListResponse {
  organization_bases: OrganizationBase[];
  total_page: number;
}

class OrganizationBases extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      organizationBases: new Array<OrganizationBase>(),
      loading: true,
      isOpenModal: false,
      totalPage: 1
    };
  }

  async componentDidMount() {
    this.fetchOrganizationBaseList();
  }

  fetchOrganizationBaseList = async (page = 1) => {
    const res = await utils.jsonPromise<OrganizationBaseListResponse>('/organization/organization_bases.json', {
      page
    });
    this.setState({
      organizationBases: res.organization_bases,
      totalPage: res.total_page,
      loading: false
    });
  };

  openCreateModal = () => this.setState({ isOpenModal: true });

  openEditModal = (organizationBase: OrganizationBase) =>
    this.setState({ editingTarget: organizationBase, isOpenModal: true });

  closeModal = () => this.setState({ editingTarget: undefined, isOpenModal: false });

  handleSubmit = (organizationBase: OrganizationBase) => {
    if (organizationBase.id === 0) {
      this.onSubmitCreate(organizationBase);
    } else {
      this.onSubmitEdit(organizationBase);
    }
  };

  onSubmitCreate = (organizationBase: OrganizationBase) => {
    OrganizationBases.catchRequestError(async () => {
      const { organizationBases } = this.state;
      const res = await utils.jsonPromise<OrganizationBase>(
        '/organization/organization_bases.json',
        organizationBase,
        'post'
      );

      organizationBases.push(res);
      this.setState({ organizationBases });

      this.closeModal();
      Notification.success('拠点を作成しました');
    });
  };

  onSubmitEdit = (organizationBase: OrganizationBase) => {
    OrganizationBases.catchRequestError(async () => {
      const { organizationBases, editingTarget } = this.state;
      if (!editingTarget) {
        return;
      }

      const res = await utils.jsonPromise<OrganizationBase>(
        `/organization/organization_bases/${organizationBase.id}.json`,
        organizationBase,
        'put'
      );

      const index = organizationBases.findIndex(o => o.id === organizationBase.id);
      organizationBases.splice(index, 1, res);
      this.setState({ organizationBases });

      this.closeModal();
      Notification.success('拠点を編集しました');
    });
  };

  onSubmitDelete = ({ id }: OrganizationBase) => {
    OrganizationBases.catchRequestError(async () => {
      await utils.jsonPromise<void>(`/organization/organization_bases/${id}.json`, null, 'delete');

      const { organizationBases } = this.state;
      const index = organizationBases.findIndex(o => o.id === id);
      organizationBases.splice(index, 1);
      this.setState({ organizationBases });

      Notification.success('拠点を削除しました');
    });
  };

  private static async catchRequestError(promiseFn: () => void) {
    try {
      await promiseFn();
    } catch (e) {
      const errors = e.responseJSON.errors;
      Notification.error(errors[0]);
    }
  }

  render() {
    const { organizationBases, loading, isOpenModal, editingTarget, totalPage } = this.state;
    const { availableOptions } = this.props;
    const restrictionDistanceAndTimeAvailability = availableOptions.includes('restriction_distance_and_time');

    return (
      <>
        <OrganizationTitle>拠点マスタ</OrganizationTitle>
        <OrganizationBody>
          {loading ? (
            <SimpleLoading />
          ) : (
            <MasterListContainer
              totalPage={totalPage}
              onPaginate={page => this.fetchOrganizationBaseList(page)}
              createButton={<AddButton onClick={this.openCreateModal}>拠点を追加</AddButton>}
              renderContent={() => (
                <OrganizationTable>
                  <thead>
                    <tr>
                      <OrganizationTh>拠点名</OrganizationTh>
                      <OrganizationTh>郵便番号</OrganizationTh>
                      <OrganizationTh>拠点住所</OrganizationTh>
                      <OrganizationTh>宛名</OrganizationTh>
                      <OrganizationTh width="100px">編集</OrganizationTh>
                      <OrganizationTh width="100px">削除</OrganizationTh>
                    </tr>
                  </thead>
                  <tbody>
                    {organizationBases.map((b: OrganizationBase, i: number) => (
                      <tr key={i}>
                        <OrganizationTd>{b.name}</OrganizationTd>
                        <OrganizationTd>{b.postal_code}</OrganizationTd>
                        <OrganizationTd>
                          {b.address}
                          {restrictionDistanceAndTimeAvailability && (
                            <OrganizationAddressArea>
                              <p>{b.google_map_address}</p>
                            </OrganizationAddressArea>
                          )}
                        </OrganizationTd>
                        <OrganizationTd>{b.addressee}</OrganizationTd>
                        <OrganizationTd>
                          <OrganizationTdButton onClick={() => this.openEditModal(b)}>編集</OrganizationTdButton>
                        </OrganizationTd>
                        <OrganizationTd>
                          <WithConfirmModal>
                            {({ open }) => (
                              <OrganizationTdButton
                                buttonType={ButtonType.DANGER}
                                onClick={async () => {
                                  const { result } = await open({
                                    description: `${b.name}を削除してもよろしいですか？`,
                                    color: 'danger',
                                    submitButtonLabel: '削除'
                                  });

                                  if (result) {
                                    this.onSubmitDelete(b);
                                  }
                                }}
                              >
                                削除
                              </OrganizationTdButton>
                            )}
                          </WithConfirmModal>
                        </OrganizationTd>
                      </tr>
                    ))}
                  </tbody>
                </OrganizationTable>
              )}
            />
          )}
        </OrganizationBody>

        <OrganizationBaseForm
          open={isOpenModal}
          organizationBase={editingTarget}
          onAbort={this.closeModal}
          onSubmit={this.handleSubmit}
        />
      </>
    );
  }
}

export default OrganizationBases;
