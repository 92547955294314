import React from 'react';
import _ from 'lodash';
import { styled } from '@this/constants/themes';
import SimpleLoading from '@this/src/components/shared/simple_loading/simple_loading';
import type { SelectTransportBoxProps, SelectTransportBoxState } from './transport_box';
import SelectTransportBoxRightInOutlineShortdistanceTemplate from './right/right_in_outline_shortdistance.template';
import SelectTransportBoxRightInListTemplate from './right/right_in_list.template';
import SelectTransportBoxRightInListPriceTemplate from './right/right_in_list_price.template';
import SelectTransportBoxRightInDomesticAirListTemplate from './right/right_in_domestic_air_list.template';
import SelectTransportBoxShortdistanceModalWindowTemplate from './transport_box_shortdistance_modal.template';
import type SelectStore from '../../../../domain/select_store';
import SegmentClass from '../../../../domain/transit/segment';
import { LegTime, LegTimeSpace, BoxTitle, Summary, Segment, Name } from './transport_box_shortdistance.template';

type Props = SelectTransportBoxProps &
  SelectTransportBoxState & {
    handleDomesticAirPriceChange: (index: number) => void;
    handleChange: () => void;
    store: SelectStore;
    direction?: string;
    segment: SegmentClass;
    i: number;
  };

const SelectTransportBoxShortdistanceExpressTemplate: React.SFC<Props> = ({
  transport,
  inList,
  selected,
  domesticAirPriceIndex,
  loading,
  handleDomesticAirPriceChange,
  handleChange,
  store,
  isRequestForm,
  segment,
  i
}) => (
  <Left>
    {segment.leg_summaries.map((legsummary, j) =>
      !inList && legsummary.price === 0 && legsummary.name !== '徒歩' ? (
        legsummary.name === '' ? (
          <LoadingWrap>
            <SimpleLoading />
            経路を検索中です
          </LoadingWrap>
        ) : (
          <Error>経路の検索に失敗しました</Error>
        )
      ) : !inList || (inList && legsummary.type === 'jr') || (inList && legsummary.type === 'air') ? (
        <Segment
          key={`express_short_distance_${i}_${j}`}
          inList={inList}
          className={
            !inList &&
            !segment.isUseSelectTransportBox() &&
            (legsummary.type === 'jr' || legsummary.type === 'air')
              ? 'select-transport-box__button_exist_leg_shortdistance__separate'
              : ''
          }
        >
          <Summary>
            {legsummary.from.express_station && legsummary.from.express_station !== legsummary.from.name && (
              <BoxContentThin>
                <LegTime />
                <div>乗車駅: {legsummary.from.name}</div>
              </BoxContentThin>
            )}
            <BoxContent>
              <LegTime>{legsummary.from.time}</LegTime>
              <BoxTitle>出発</BoxTitle>
              <Space
                className={
                  !segment.isUseSelectTransportBox() && (legsummary.type === 'jr' || legsummary.type === 'air')
                    ? 'select-transport-box__button_exist_leg_station'
                    : ''
                }
              >
                <Name>
                  {legsummary.from.express_station ? legsummary.from.express_station : legsummary.from.name}
                </Name>
              </Space>
            </BoxContent>
            <BoxContent>
              <LegTimeSpace />
              {legsummary.type === 'jr' && <Image src="/images/train.png" />}
              {legsummary.type === 'air' && <Image src="/images/plane.png" />}
              {legsummary.name && legsummary.name.match(/\//) ? (
                <>
                  {legsummary.name.split('/').map(n => (
                    <>
                      <ImageBox>
                        <Image src={SegmentClass.imagePathByName(n)} />
                      </ImageBox>
                      <Space>{n}</Space>
                    </>
                  ))}
                </>
              ) : (
                <>
                  <ImageBox>
                    <Image src={SegmentClass.imagePathByName(legsummary.name)} />
                  </ImageBox>
                  <Space>{legsummary.name}</Space>
                </>
              )}
              <SelectTransportBoxShortdistanceModalWindowTemplate segment={segment} legsummary={legsummary} />
            </BoxContent>
            <BoxContent>
              <LegTime>{legsummary.to.time}</LegTime>
              <BoxTitle>到着</BoxTitle>
              <Space
                className={
                  !segment.isUseSelectTransportBox() && (legsummary.type === 'jr' || legsummary.type === 'air')
                    ? 'select-transport-box__button_exist_leg_station'
                    : ''
                }
              >
                <Name>{legsummary.to.express_station ? legsummary.to.express_station : legsummary.to.name}</Name>
              </Space>
            </BoxContent>
            {legsummary.to.express_station && legsummary.to.express_station !== legsummary.to.name && (
              <BoxContentThin>
                <LegTime />
                <div>降車駅: {legsummary.to.name}</div>
              </BoxContentThin>
            )}
          </Summary>
          {!segment.isUseSelectTransportBox() && (legsummary.type === 'jr' || legsummary.type === 'air') ? (
            <>
              {segment.useSelectTransportBox()}
              {!inList ? (
                <SelectTransportBoxRightInOutlineShortdistanceTemplate
                  price={((): number | undefined => {
                    // ボタン押下後に呼ばれる。
                    if (transport.package && store.result.packageProvider === 'wbf') {
                      // パッケージ
                      return transport.additionalPrice || 0;
                    }
                    // 個別
                    if (transport.prices && transport.prices[domesticAirPriceIndex]) {
                      // 国内航空券
                      return transport.prices[domesticAirPriceIndex].price;
                    }
                    // 新幹線
                    return transport.price;
                  })()}
                  isAddtional={transport.package === true && store.result.packageProvider === 'wbf'}
                  priceRange={store.result.type === 'airPackage' ? transport.price_range : undefined}
                  includeTax={transport.includeTax}
                  selected={selected}
                  onClick={handleChange}
                  isRequestForm={isRequestForm || false}
                  isRakutenPackage={transport.isRakutenPackage}
                />
              ) : (
                <div className="select-transport-box__right-wrapper-shortdistance">
                  {transport.package ? (
                    // パッケージ
                    <SelectTransportBoxRightInListTemplate
                      loading={loading}
                      selected={selected}
                      hovered={transport.hovered}
                      price={store.result.packageProvider === 'wbf' ? transport.additionalPrice || 0 : undefined}
                      isAddtional={store.result.packageProvider === 'wbf'}
                      priceRange={store.result.type === 'airPackage' ? transport.price_range : undefined}
                      showsPricePerPersonText={store.result.type !== 'airPackage'}
                      onClick={handleChange}
                      onTouchStart={transport.handleTouched}
                      onMouseOver={transport.setHovered(true)}
                      onMouseLeave={transport.setHovered(false)}
                      isPackage={transport.package}
                      isDomestic
                    />
                  ) : !transport.airExists() ? (
                    // 個別/新幹線
                    <SelectTransportBoxRightInListTemplate
                      loading={loading}
                      selected={selected}
                      hovered={transport.hovered}
                      price={transport.price}
                      isDomestic
                      onClick={handleChange}
                      onTouchStart={transport.handleTouched}
                      onMouseOver={transport.setHovered(true)}
                      onMouseLeave={transport.setHovered(false)}
                    />
                  ) : (
                    // 個別/航空券 : 変更可、不可 x 席種のボタンを表示
                    <SelectTransportBoxDomesticAirRightWrapper>
                      {transport.prices.map((p, i) => (
                        <div key={i} onClick={() => handleDomesticAirPriceChange(i)}>
                          <SelectTransportBoxRightInDomesticAirListTemplate
                            key={i}
                            loading={loading}
                            selected={selected && i === domesticAirPriceIndex}
                            price={p}
                          />
                        </div>
                      ))}
                    </SelectTransportBoxDomesticAirRightWrapper>
                  )}
                </div>
              )}
            </>
          ) : (
            <div className="select-transport-box__right-wrapper-shortdistance">
              <SelectTransportBoxRightInListPriceTemplate price={legsummary.price ? legsummary.price : 0} />
            </div>
          )}
        </Segment>
      ) : null
    )}
  </Left>
);

const LoadingWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 8px;
`;

const Error = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  color: ${props => props.theme.redColor};
`;

export const BoxContent = styled.div`
  display: flex;
  padding: 5px;
  align-items: center;
`;

export const BoxContentThin = styled.div`
  display: flex;
  padding: 2px;
`;

export const Left = styled.div`
  flex-shrink: 10;
  padding: 0;
  width: 100%;
`;

export const ImageBox = styled.div`
  display: flex;
  min-width: 20px;
  margin-right: 5px;
`;

export const Image = styled.img`
  height: 20px;
  margin-left: 5px;
`;

export const BoxTitleBlank = styled.div`
  width: 25px;
  margin-right: 10px;
  height: 18px;
`;

export const BoxSpace = styled.div`
  width: 25px;
  margin-right: 10px;
`;

export const Space = styled.div`
  margin-right: 10px;
`;

const SelectTransportBoxDomesticAirRightWrapper = styled.div`
  display: block;
  margin-left: auto;
  min-width: 242px;
  background: #f8f6f1;
  padding: 12px;
`;

export const SpaceLeft = styled.div`
  margin-left: 20px;
`;

export default SelectTransportBoxShortdistanceExpressTemplate;
