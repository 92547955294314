import React, { useCallback, useEffect, useReducer } from 'react';
import Divider from '@material-ui/core/Divider';
import { Fetcher } from '@this/src/util';
import { styled } from '@this/constants/themes';
import Link from '@this/components/shared/atoms/link';
import type Invoice from '@this/domain/invoice/invoice2';

import type { FreeePostDealHistoriesCount } from '@this/src/domain/freee/freee_post_deal_history_list';
import FreeePostDealHistoryList from '@this/src/domain/freee/freee_post_deal_history_list';
import type {
  FreeePostDealHistoryResponseArgs,
  FreeePostDealHistoryUpdateStatusResponseArgs
} from '@this/src/domain/freee/freee_post_deal_history';
import type { AutoRemoveFreeePostDeals } from '@this/src/domain/setting';
import FreeeFilebox from './freee_filebox';
import FreeeDealsSubmit from './freee_deals_submit';
import FreeeHistories from './freee_histories';
// import AutoRemoveChange from './auto_remove_change';

const STATUS_INTERVAL = 3000;

interface Props {
  freeeConnected: boolean;
  invoice: Invoice;
}

export type FreeeExternalProps = Props;

type EnvType = 'production' | 'staging' | 'development';

interface FreeePostDealHistoriesResponse {
  histories: FreeePostDealHistoryResponseArgs[];
  histories_count: FreeePostDealHistoriesCount;
  auto_remove: AutoRemoveFreeePostDeals;
  freee_company_id?: number;
  env?: EnvType;
}

interface FreeeFileboxResponse {
  receipt_id: number;
}

interface State {
  env: EnvType | null;
  loading: boolean;
  loadingError: string | null;
  submitting: boolean;
  freeePostDealHistoryList: FreeePostDealHistoryList;
  postError: string | null;
  method: 'separate' | 'combine';
  autoRemove: AutoRemoveFreeePostDeals;
  freeeCompanyId: number | null;
}

type Action =
  | { type: 'FETCH_POST_DEAL_HISTORIES_START' }
  | { type: 'FETCH_POST_DEAL_HISTORIES_SUCCESS'; payload: FreeePostDealHistoriesResponse }
  | { type: 'FETCH_POST_DEAL_HISTORIES_ERROR'; payload: string | null }
  | { type: 'SET_POST_DEALS_METHOD'; payload: 'separate' | 'combine' }
  | { type: 'SET_AUTO_REMOVE'; payload: AutoRemoveFreeePostDeals }
  | { type: 'DEALS_SUBMITTING_START' }
  | { type: 'DEALS_SUBMITTING_SUCCESS' }
  | { type: 'DEALS_SUBMITTING_ERROR'; payload: string }
  | { type: 'RELOAD_LIST'; payload: FreeePostDealHistoryList };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'FETCH_POST_DEAL_HISTORIES_START':
      return { ...state, loading: true, loadingError: null };
    case 'FETCH_POST_DEAL_HISTORIES_SUCCESS':
      return {
        ...state,
        env: action.payload.env || null,
        loading: false,
        submitting: false,
        freeePostDealHistoryList: new FreeePostDealHistoryList().fromResponse(action.payload.histories),
        autoRemove: action.payload.auto_remove,
        freeeCompanyId: action.payload.freee_company_id || null
      };
    case 'FETCH_POST_DEAL_HISTORIES_ERROR':
      return { ...state, loading: false, submitting: false, loadingError: action.payload };
    case 'SET_POST_DEALS_METHOD':
      return { ...state, method: action.payload };
    case 'SET_AUTO_REMOVE':
      return { ...state, autoRemove: action.payload };
    case 'DEALS_SUBMITTING_START':
      return { ...state, submitting: true, postError: null };
    case 'DEALS_SUBMITTING_SUCCESS':
      return { ...state, submitting: false };
    case 'DEALS_SUBMITTING_ERROR':
      return { ...state, submitting: false, postError: action.payload };
    case 'RELOAD_LIST':
      return { ...state, freeePostDealHistoryList: action.payload };
    default:
      return state;
  }
};

const intialState: State = {
  env: null,
  loading: false,
  loadingError: null,
  submitting: false,
  freeePostDealHistoryList: new FreeePostDealHistoryList(),
  postError: null,
  method: 'separate',
  autoRemove: 'hidden',
  freeeCompanyId: null
};

const FreeeExternal = ({ freeeConnected, invoice }: Props) => {
  const [state, dispatch] = useReducer(reducer, intialState);
  const {
    env,
    loading,
    loadingError,
    submitting,
    freeePostDealHistoryList,
    postError,
    method,
    // autoRemove,
    freeeCompanyId
  } = state;

  const fetchFreeePostDealHistories = useCallback(() => {
    const params = {
      year: invoice.targetYear,
      month: invoice.targetMonth
    };
    dispatch({ type: 'FETCH_POST_DEAL_HISTORIES_START' });
    utils
      .jsonPromise<FreeePostDealHistoriesResponse>('/organization/freee/histories', params)
      .then(response => {
        dispatch({ type: 'FETCH_POST_DEAL_HISTORIES_SUCCESS', payload: response });
      })
      .catch(e => {
        dispatch({
          type: 'FETCH_POST_DEAL_HISTORIES_ERROR',
          payload: '通信エラーが発生しました。時間をおいて再度お試しください。'
        });
        utils.sendErrorObject(e);
      });
  }, [invoice, dispatch]);

  const fetchFreeePostDealStatus: () => NodeJS.Timeout | false = useCallback(() => {
    if (!freeePostDealHistoryList.submitting) return false;

    const history = freeePostDealHistoryList.lastAction;
    if (!history) return false;

    const params = {
      history_id: history.historyId,
      action_type: history.actionType
    };

    return setInterval(() => {
      utils
        .jsonPromise<FreeePostDealHistoryUpdateStatusResponseArgs>('/organization/freee/histories/status', params)
        .then(response => {
          dispatch({ type: 'RELOAD_LIST', payload: freeePostDealHistoryList.updateStatus(response) });
        })
        .catch(e => {
          dispatch({
            type: 'DEALS_SUBMITTING_ERROR',
            payload: '通信エラーが発生しました。時間をおいて再度お試しください。'
          });
          utils.sendErrorObject(e);
        });
    }, STATUS_INTERVAL);
  }, [freeePostDealHistoryList.submitting, dispatch]);

  useEffect(() => {
    fetchFreeePostDealHistories();
  }, [fetchFreeePostDealHistories]);

  useEffect(() => {
    const intervalId = fetchFreeePostDealStatus();

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [fetchFreeePostDealStatus]);

  const postFreeeDeals = useCallback(
    async (targetMethod?: string) => {
      dispatch({ type: 'DEALS_SUBMITTING_START' });
      const params = {
        invoice_id: invoice.id,
        method: targetMethod || method
      };
      try {
        await utils.jsonPromise<FreeeFileboxResponse>('/organization/freee/deals', params, 'POST');
        fetchFreeePostDealHistories();
      } catch (e) {
        if (e.status === 400) {
          dispatch({ type: 'DEALS_SUBMITTING_ERROR', payload: e.responseJSON.message });
        } else {
          dispatch({
            type: 'DEALS_SUBMITTING_ERROR',
            payload: '通信エラーが発生しました。時間をおいて再度お試しください。'
          });
          utils.sendErrorObject(e);
        }
      }
    },
    [invoice, method, dispatch, fetchFreeePostDealHistories]
  );

  const resetFreeeDeals = useCallback(
    async (historyId: number) => {
      dispatch({ type: 'DEALS_SUBMITTING_START' });
      const params = { id: historyId };
      try {
        await utils.jsonPromise('/organization/freee/deals', params, 'DELETE');
        fetchFreeePostDealHistories();
      } catch (e) {
        dispatch({
          type: 'DEALS_SUBMITTING_ERROR',
          payload: '通信エラーが発生しました。時間をおいて再度お試しください。'
        });
        utils.sendErrorObject(e);
      }
    },
    [dispatch, fetchFreeePostDealHistories]
  );

  // const setAutoRemove = useCallback(
  //   async (autoRemove: AutoRemoveFreeePostDeals) => {
  //     const params = { auto_remove: autoRemove };
  //     try {
  //       await utils.jsonPromise('/organization/freee/auto_remove', params, 'PUT');
  //       dispatch({ type: 'SET_AUTO_REMOVE', payload: autoRemove });
  //     } catch (e) {
  //       utils.sendErrorObject(e);
  //     }
  //   },
  //   [dispatch]
  // );

  const regenerate = useCallback(
    (historyId: number) => {
      if (!env || env === 'production') return;

      Fetcher.put(`/organization/freee/regenerates/${historyId}`, {}).then(() => {
        fetchFreeePostDealHistories();
      });
    },
    [env, fetchFreeePostDealHistories]
  );

  const stop = useCallback(
    (historyId: number) => {
      if (!env || env === 'production') return;

      Fetcher.delete(`/organization/freee/regenerates/${historyId}`, {}).then(() => {
        fetchFreeePostDealHistories();
      });
    },
    [env, fetchFreeePostDealHistories]
  );

  if (!freeeConnected) return <FreeeUnconnected />;

  return (
    <Vertical>
      <Horizontal>
        <Ul>
          <FreeeFilebox invoice={invoice} />
        </Ul>
      </Horizontal>
      <Divider />
      <Horizontal>
        <Ul>
          <FreeeDealsSubmit
            freeePostDealHistoryList={freeePostDealHistoryList}
            listLoading={loading}
            submitting={submitting}
            postError={postError}
            method={method}
            freeeCompanyId={freeeCompanyId}
            env={env}
            setMethod={method => dispatch({ type: 'SET_POST_DEALS_METHOD', payload: method })}
            regenerate={regenerate}
            stop={stop}
            postFreeeDeals={postFreeeDeals}
          />
          {/* 取引の削除機能は機能提供範囲外とする */}
          {/* https://aitravel.atlassian.net/browse/AITRAVEL-4889?focusedCommentId=32866 */}
          {/* <AutoRemoveChange autoRemove={autoRemove} setAutoRemove={setAutoRemove} /> */}
        </Ul>
        <FlexItem>
          <FreeeHistories
            freeePostDealHistoryList={freeePostDealHistoryList}
            loading={loading}
            loadingError={loadingError}
            submitting={submitting}
            actionDisabled={!freeeCompanyId}
            postFreeeDeals={postFreeeDeals}
            resetFreeeDeals={resetFreeeDeals}
          />
        </FlexItem>
      </Horizontal>
    </Vertical>
  );
};

const FreeeUnconnected = () => {
  return (
    <p>
      現在はfreeeと連携されていません。
      <br />
      <Link to="/organization/setting">こちら</Link>から連携設定してください。
    </p>
  );
};

const Vertical = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Horizontal = styled.div`
  display: flex;
  gap: 20px;
`;

const FlexItem = styled.div`
  flex: 1;
`;

const Ul = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-left: 10px;
  list-style-type: disc;
  padding-inline-start: 30px;
`;

export default FreeeExternal;
