import _ from 'lodash';
import { observable, computed } from 'mobx';
import type { Moment } from 'moment';
import moment from 'moment';
import type { ImageFile } from 'react-dropzone';
import type { ParticipantCompanyJson } from './participant_company';
import ParticipantCompany from './participant_company';
import type { ReportJson } from './report';
import { Report } from './report';
import type { ExpensesTypeJson } from './expenses_type';
import ExpensesType from './expenses_type';
import type { ProjectArgs } from '../project/project';
import Project from '../project/project';
import type { TaxTypeArgs } from '../tax_type';
import TaxType from '../tax_type';

export type Category =
  | 'general_expenses'
  | 'travel_expenses'
  | 'travel_expenses_daily_allowance'
  | 'reception_expenses';

export type TripType = 'round_trip' | 'one_way' | 'other';

export interface ItemJson {
  id?: number;
  payee: string;
  paid_at: string;
  price: number;
  expense_type: string;
  expenses_type: ExpensesTypeJson;
  project?: ProjectArgs;
  memo: string;
  category: Category;
  from: string;
  to: string;
  trip_type: TripType;
  participant_companies: ParticipantCompanyJson[];
  railway_name?: string;
  price_average?: number;
  expenses_report_id?: number;
  created_at: string;
  updated_at: string;
  category_options?: { [key: string]: string };
  expense_type_options?: { [key: string]: string };
  receipt_file_path?: string;
  expenses_report: ReportJson;
  tax_type_id?: number;
  non_order_item?: {
    id: number;
    trip_report_id: number;
    trip_report: {
      id: number;
      name: string;
    };
  };
  tax_type?: TaxTypeArgs;
}

export class Item {
  readonly id?: number;

  payee: string;

  paidAt: Moment;

  price: number;

  priceDisplay: string;

  expenseType: string;

  expensesType: ExpensesType;

  project: Project | null;

  memo: string;

  category: Category;

  from: string;

  to: string;

  tripType: TripType;

  participantCompanies: ParticipantCompany[];

  railwayName: string;

  priceAverageDisplay: string;

  taxTypeId: number | null;

  taxType?: TaxType;

  @observable
  receiptFilePath?: string;

  @observable
  receiptFile?: ImageFile;

  readonly reportId?: number;

  readonly createdAt: Moment;

  readonly updatedAt: Moment;

  readonly categoryOptions?: { [key: string]: string };

  readonly expenseTypeOptions?: { [key: string]: string };

  selected: boolean;

  report?: Report;

  tripReport?: { id: number; name: string };

  constructor(args: ItemJson) {
    this.id = args.id;
    this.payee = args.payee || '';
    this.paidAt = args.paid_at ? moment(args.paid_at) : moment();
    this.price = args.price;
    this.priceDisplay = new Intl.NumberFormat().format(args.price);
    this.expenseType = args.expense_type || '';
    this.expensesType = args.expenses_type && new ExpensesType(args.expenses_type);
    this.project = args.project ? new Project(args.project) : null;
    this.memo = args.memo || '';
    this.category = args.category;
    this.from = args.from || '';
    this.to = args.to || '';
    this.tripType = args.trip_type;
    this.participantCompanies = args.participant_companies
      ? args.participant_companies.map(c => new ParticipantCompany(c))
      : [];
    this.railwayName = args.railway_name || '';
    this.priceAverageDisplay = '';
    this.setPriceAverageDisplay();
    this.reportId = args.expenses_report_id;
    this.createdAt = moment(args.created_at);
    this.updatedAt = moment(args.updated_at);
    this.categoryOptions = args.category_options;
    this.expenseTypeOptions = args.expense_type_options;
    this.selected = false;
    this.receiptFilePath = args.receipt_file_path;
    this.report = args.expenses_report && new Report(args.expenses_report);
    this.tripReport = args.non_order_item && {
      id: args.non_order_item.trip_report_id,
      name: args.non_order_item.trip_report.name
    };
    this.taxTypeId = args.tax_type_id || null;
    this.taxType = args.tax_type && new TaxType(args.tax_type);
  }

  participantCompaniesToDisplay(): ParticipantCompany[] {
    return this.participantCompanies.filter(c => !c.destroy);
  }

  ownCompany(): ParticipantCompany {
    const own = this.participantCompanies.find(c => c.own);
    if (own) return own;
    const c = new ParticipantCompany({ name: '', people_num: 1, own: true });
    this.participantCompanies.push(c);
    return c;
  }

  otherCompaniesToDisplay(): ParticipantCompany[] {
    return this.participantCompaniesToDisplay().filter(c => !c.own);
  }

  setPayee(value: string) {
    this.payee = value;
    app.render();
  }

  setPaidAt(value: string) {
    this.paidAt = moment(value);
    app.render();
  }

  setPrice(value: number) {
    this.price = value;
    app.render();
  }

  setExpensesType(value: ExpensesType) {
    this.expensesType = value;
    app.render();
  }

  setProject(value: Project | null) {
    this.project = value;
    app.render();
  }

  setMemo(value: string) {
    this.memo = value;
    app.render();
  }

  setCategory(value: Category) {
    this.category = value;
    this.expenseType = '';
    app.render();
  }

  setFrom(value: string) {
    this.from = value;
    app.render();
  }

  setTo(value: string) {
    this.to = value;
    app.render();
  }

  setTripType(value: TripType) {
    this.tripType = value;
    app.render();
  }

  addParticipantCompany() {
    const c = new ParticipantCompany({ name: '', people_num: 1, own: false });
    this.participantCompanies.push(c);
    app.render();
  }

  setRailwayName(value: string) {
    this.railwayName = value;
    app.render();
  }

  setPriceAverageDisplay() {
    const totalPeopleNum =
      this.participantCompanies
        .filter(c => c.destroy !== '1')
        .map(c => c.peopleNum)
        .reduce((a, b) => a + b, 0) || 0;
    if (this.price === null || totalPeopleNum === 0) {
      this.priceAverageDisplay = '--円';
    } else {
      const average = Math.round(this.price / totalPeopleNum);
      this.priceAverageDisplay = `${average.toLocaleString()}円`;
    }
    app.render();
  }

  setTaxTypeId(value: number | null) {
    this.taxTypeId = value;
    app.render();
  }

  toggleSelected() {
    this.selected = !this.selected;
    app.render();
  }

  categoryIsTravelExpenses(): boolean {
    return (
      this.category === 'travel_expenses' &&
      !this.expensesTypeIsTrain() &&
      !this.expensesTypeIsBus() &&
      !this.expensesTypeIsTaxi()
    );
  }

  expensesTypeIsTrain(): boolean {
    return this.expensesType.isTrain();
  }

  expensesTypeIsTaxi(): boolean {
    return this.expensesType.isTaxi();
  }

  expensesTypeIsBus(): boolean {
    return this.expensesType.isBus();
  }

  showDistance(): boolean {
    const category = this.expensesType.category;
    return this.expensesType.isDailyAllowance() || category === 'travel_expenses_daily_allowance';
  }

  needParticipantCompanies(): boolean {
    return this.expensesType.isNeedParticipants();
  }

  submitParams() {
    const params = {
      category: this.category,
      payee: this.payee,
      paid_at: this.paidAt.format('YYYY-MM-DD'),
      price: this.price,
      expenses_type_id: this.expensesType.id,
      project_id: this.project?.id,
      memo: this.memo,
      from: this.from,
      to: this.to,
      trip_type: this.tripType,
      participant_companies_attributes: this.participantCompanies.map(c => c.submitParams()),
      railway_name: this.railwayName,
      receipt_file_path: this.receiptFilePath ? this.receiptFilePath : '',
      tax_type_id: this.taxTypeId
    };
    return params;
  }

  ekispertPriceParams() {
    return {
      from: this.from,
      to: this.to,
      date: this.paidAt.format('YYYY/MM/DD'),
      trip_type: this.tripType
    };
  }

  editable() {
    return !this.report || this.report?.status === 'draft' || this.report?.status === 'rejected';
  }

  @computed
  get formData() {
    const data = new FormData();
    if (this.receiptFile) {
      data.append('file', this.receiptFile);
    }
    const params = this.submitParams();
    data.append('category', params.category);
    data.append('payee', params.payee);
    data.append('paid_at', params.paid_at);
    data.append('price', params.price ? params.price.toString() : '');
    data.append('expenses_type_id', params.expenses_type_id.toString());
    data.append('project_id', params.project_id ? params.project_id.toString() : '');
    data.append('tax_type_id', params.tax_type_id ? params.tax_type_id.toString() : '');
    data.append('memo', params.memo);
    data.append('from', params.from);
    data.append('to', params.to);
    data.append('trip_type', params.trip_type);
    data.append('receipt_file_path', params.receipt_file_path);
    _.each(params.participant_companies_attributes, (p, i) => {
      if (p.id) {
        data.append(`participant_companies_attributes[${i}][id]`, p.id.toString());
      }
      data.append(`participant_companies_attributes[${i}][name]`, p.name);
      data.append(`participant_companies_attributes[${i}][people_num]`, p.people_num.toString());
      data.append(`participant_companies_attributes[${i}][own]`, p.own ? 'true' : 'false');
      data.append(`participant_companies_attributes[${i}][_destroy]`, p.destroy ? 'true' : 'false');
    });
    data.append('railway_name', params.railway_name);
    return data;
  }
}

export default Item;
