import React from 'react';
import _ from 'lodash';
import { observer } from 'mobx-react';
import { styled } from '@this/constants/themes';
import SimpleLoading from '@this/shared/simple_loading/simple_loading';
import type ExpensesType from '@this/domain/expenses/expenses_type';
import type ExpensesAccountType from '@this/src/domain/expenses/expenses_account_type';

type Props = {
  type: ExpensesType;
  accountTypes: ExpensesAccountType[];
  categoryOptions: [string, string][];
  submitting: boolean;
  onSubmit: () => void;
  onClickCancel: () => void;
  errors: string[];
};

const Form: React.FC<Props> = ({
  type,
  accountTypes,
  categoryOptions,
  submitting,
  onSubmit,
  onClickCancel,
  errors
}) => {
  return (
    <>
      <form onSubmit={() => onSubmit()}>
        <div className="type-edit">
          <div className="type-edit__section">
            <div className="type-edit__label">経費科目</div>
            <input id="name" type="text" value={type.name || ''} onChange={e => type.setName(e.target.value)} />
          </div>
          <div className="type-edit__section">
            <div className="type-edit__input_area">
              <div className="type-edit__label">経費科目コード</div>
              <input id="name" type="text" value={type.code || ''} onChange={e => type.setCode(e.target.value)} />
            </div>
          </div>
          <div className="type-edit__section">
            <div className="type-edit__input_area">
              <div className="type-edit__label">対応させる勘定科目</div>
              {type.expensesAccountType && (
                <select
                  value={type.expensesAccountType.id}
                  onChange={e => {
                    const accountType = _.find(
                      accountTypes,
                      account => account.id === parseInt(e.target.value, 10)
                    );
                    if (accountType) type.setExpensesAccountType(accountType);
                  }}
                >
                  {accountTypes &&
                    accountTypes.map((value: ExpensesAccountType) => (
                      <React.Fragment key={value.id}>
                        {(!value.isDeleted ||
                          (value.isDeleted &&
                            type.expensesAccountType &&
                            type.expensesAccountType.id === value.id)) && (
                          <option value={value.id}>{value.isDeletedName}</option>
                        )}
                      </React.Fragment>
                    ))}
                </select>
              )}
            </div>
          </div>
          <div className="type-edit__section">
            <div className="type-edit__input_area">
              <div className="type-edit__label">入力フォーマット</div>
              <select value={type.category} onChange={e => type.setCategry(e.target.value)}>
                {_.map(categoryOptions, (option, i) => (
                  <option key={i} value={i}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div style={{ marginTop: '20px' }}>
          <TheButton type="button" disabled={submitting} onClick={() => onClickCancel()}>
            キャンセル
          </TheButton>
          <TheButton type="submit" disabled={submitting} onClick={() => onSubmit()}>
            保存
          </TheButton>
        </div>
        {errors && errors.map((e, i) => <p key={i}>{e}</p>)}
      </form>
      {submitting && <SimpleLoading />}
    </>
  );
};

const TheButton = styled.button`
  margin-right: 10px;
`;

export default observer(Form);
