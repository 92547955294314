import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';
import { styled } from '@this/constants/themes';
import { Box, Grid } from '@material-ui/core';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@this/components/shared/ui/data_displays/table';
import { Link } from '@this/components/shared/ui/navigations/link';
import { Title } from '@this/components/admin/admin';

import axios from 'axios';
import SimpleLoading from '@this/shared/simple_loading/simple_loading';
import ManualModal from '@this/components/admin/manuals/manual_modal';

interface ManualJson {
  id: number;
  name: string;
  file_url: string;
}

interface Response {
  manuals: ManualJson[];
}

const Manuals = observer(() => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [manuals, setManuals] = useState<ManualJson[]>([]);
  const [manual, setManual] = useState<ManualJson | null>(null);
  const [showManualModal, setShowManualModal] = useState<boolean>(false);
  const [modalType, setModalType] = useState<string>('');

  const fetchManuals = useCallback(async () => {
    setIsLoading(true);
    axios.get<Response>('/admin/manuals.json', {}).then(response => {
      setIsLoading(false);
      setManuals(response.data.manuals);
    });
    setIsLoading(false);
  }, []);

  useEffect(() => {
    fetchManuals();
  }, []);

  return (
    <>
      <Title>マニュアル</Title>
      <Content>
        {isLoading ? (
          <SimpleLoading />
        ) : (
          <Grid item xs={12}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {manuals.map(data => (
                  <TableRow hover key={data.id}>
                    <TableCell>
                      <Text>{data.name}</Text>
                    </TableCell>
                    <TableCell>
                      <Link target="_blank" href={`${data.file_url}`} isExternal>
                        <LinkText>表示</LinkText>
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Link
                        size="small"
                        onClick={() => {
                          setManual(data);
                          setShowManualModal(true);
                          setModalType('mod');
                        }}
                        disabled={isLoading}
                      >
                        <LinkText>上書き</LinkText>
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Link
                        size="small"
                        onClick={() => {
                          setManual(data);
                          setShowManualModal(true);
                          setModalType('delete');
                        }}
                        disabled={isLoading}
                      >
                        <LinkText>削除</LinkText>
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>

            <Box marginTop="10px">
              <Link
                onClick={() => {
                  setManual(null);
                  setShowManualModal(true);
                  setModalType('add');
                }}
                disabled={isLoading}
              >
                <LinkText>＋追加</LinkText>
              </Link>
            </Box>

            {showManualModal && (
              <ManualModal
                open
                onClose={() => setShowManualModal(false)}
                afterSubmit={() => fetchManuals()}
                id={manual?.id}
                fileName={manual?.name}
                modalType={modalType}
              />
            )}
          </Grid>
        )}
      </Content>
    </>
  );
});

const Text = styled.span`
  padding: 3px;
  font-size: 12px;
  font-weight: bold;
  color: ${props => props.theme.textColor};
`;

const LinkText = styled.span`
  padding: 3px;
  font-size: 12px;
  font-weight: bold;
  color: ${props => props.theme.linkColor};
`;

const Content = styled.div`
  padding: 10px 20px;
`;

export default Manuals;
