import React from 'react';
import { styled } from '@this/constants/themes';
import type { InvoiceSplitItem } from '@this/src/domain/invoice/invoice2';
import type Invoice from '@this/src/domain/invoice/invoice2';
import { Button } from '@this/shared/ui/inputs/button';

interface Props {
  invoice: Invoice;
  invoiceSplitItem: InvoiceSplitItem;
  encoding: string;
  last?: boolean;
}

export const InvoiceSplittedTableRow: React.FC<Props> = ({ invoice, invoiceSplitItem, encoding, last }) => {
  return (
    <Tr key={invoiceSplitItem.id} noBorder={!last}>
      <Td colSpan={2}>
        {'　'}
        {invoiceSplitItem.name}
      </Td>
      <Td>{utils.formatPriceWithMark(invoiceSplitItem.price_with_tax)}</Td>
      <Td />
      <RightTd>
        <Button
          size="small"
          href={`/organization/invoices/${invoice.id}/invoice_splitteds/${invoiceSplitItem.id}/invoice_trip_materials.csv?encoding=${encoding}`}
          isExternal
        >
          集計用データ
        </Button>
        <Button
          size="small"
          href={`/organization/invoices/${invoice.id}/invoice_splitteds/${invoiceSplitItem.id}/invoice_journals_qualified.csv?encoding=${encoding}`}
          isExternal
          style={{ marginLeft: '10px' }}
        >
          会計用データ
        </Button>
      </RightTd>
      <Td>
        <Button
          size="small"
          href={`/organization/invoices/${invoice.id}/invoice_splitteds/${invoiceSplitItem.id}/invoice_pdf.pdf`}
          isExternal
        >
          請求書
        </Button>
      </Td>
      <Td />
    </Tr>
  );
};

const Tr = styled.tr<{ noBorder?: boolean }>`
  ${props =>
    props.noBorder
      ? `
    & > td {
      border-bottom: none;
    }
  `
      : ''}
`;

const Td = styled.td`
  padding: 4px;
`;

const RightTd = styled.td`
  padding: 4px;
  text-align: right;
`;
