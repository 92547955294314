import type { Dispatch, FormEvent, SetStateAction } from 'react';
import React from 'react';
import { styled } from '@this/constants/themes';

import type { ModalProps } from '@this/shared/ui/feedbacks/modal';
import { Modal, ModalHeader, ModalBody, ModalFooter } from '@this/shared/ui/feedbacks/modal';
import Project from '@this/domain/project/project';
import type User from '@this/src/domain/user/user';
import type { ApproveStages, WorkflowType } from '@this/domain/project/project';
import { Button } from '@this/shared/ui/inputs/button';
import { FormControl, InputLabel } from '@this/shared/ui/inputs/form_control';
import { Input } from '@this/shared/ui/inputs/input';
import { Radio, RadioGroup } from '@this/shared/ui/inputs/radio';
import { BudgetStore } from '@this/src/domain/organization/budget_store';
import SelectApprovers from '../departments/select_approvers/select_approvers';
import { media } from '../../shared/atoms/media';
import { BudgetsForm } from '../budgets/budgets_form';

type Props = {
  project?: Project;
  members?: User[];
  approveStages: ApproveStages;
  loading?: boolean;
  onClose: () => void;
  onSubmit: (newProject: Project) => void;
  showApproval: boolean;
  useProject: boolean;
  handleApproverChange: (i: number, s: number, member: User, workflowType?: WorkflowType) => void;
  handleApproverReset: (i: number, s: number, workflowType?: WorkflowType) => void;
} & Pick<ModalProps, 'open'>;

const formId = 'projectForm';
export const ProjectFormModal = ({
  project,
  members,
  approveStages,
  onSubmit,
  loading,
  showApproval,
  useProject,
  handleApproverChange,
  handleApproverReset,
  ...props
}: Props) => (
  <Modal {...props} size={showApproval ? 'large' : 'medium'}>
    <ModalHeader>プロジェクト{project ? '編集' : '作成'}</ModalHeader>
    <ProjectForm
      project={project}
      members={members}
      approveStages={approveStages}
      onClose={props.onClose}
      onSubmit={onSubmit}
      showApproval={showApproval}
      useProject={useProject}
      handleApproverChange={handleApproverChange}
      handleApproverReset={handleApproverReset}
    />
  </Modal>
);

const ProjectForm = ({
  project,
  members,
  approveStages,
  loading,
  onClose,
  onSubmit,
  showApproval,
  useProject,
  handleApproverChange,
  handleApproverReset
}: Pick<
  Props,
  | 'project'
  | 'members'
  | 'approveStages'
  | 'onClose'
  | 'onSubmit'
  | 'loading'
  | 'showApproval'
  | 'useProject'
  | 'handleApproverChange'
  | 'handleApproverReset'
>) => {
  const [code, setCode] = React.useState(project?.code ?? '');
  const [name, setName] = React.useState(project?.name ?? '');
  const [disabled, setDisabled] = React.useState(project?.disabled ?? false);
  const budgets = React.useMemo(() => new BudgetStore(), []);

  const handleSubmit = (e: FormEvent) => {
    e.stopPropagation();
    e.preventDefault();

    onSubmit(
      new Project({ id: project?.id ?? 0, code, name, disabled, approveStages, budgets: budgets.submitParams() })
    );
  };

  React.useEffect(() => {
    budgets.fetchBudgets(project?.id, 'project');
  }, [project?.id]);

  return (
    <>
      <ModalBody>
        <form id={formId} onSubmit={handleSubmit}>
          <EditWrap>
            <EditSection showApproval={showApproval}>
              <ProjectFormLeftSection
                code={code}
                name={name}
                disabled={disabled}
                project={project}
                members={members}
                approveStages={approveStages}
                showApproval={showApproval}
                useProject={useProject}
                handleApproverChange={handleApproverChange}
                handleApproverReset={handleApproverReset}
                setCode={setCode}
                setName={setName}
                setDisabled={setDisabled}
              />
            </EditSection>
            {showApproval && (
              <EditSection showApproval={showApproval}>
                <BudgetsForm store={budgets} />
              </EditSection>
            )}
          </EditWrap>
        </form>
      </ModalBody>
      <ModalFooter>
        <Button color="sub" onClick={onClose} data-testid="project_form_cancel">
          キャンセル
        </Button>
        <Button type="submit" form={formId} disabled={loading} data-testid="project_form_submit">
          {project ? '登録' : '作成'}
        </Button>
      </ModalFooter>
    </>
  );
};

type LeftSectionProps = {
  code: string;
  name: string;
  disabled: boolean;
  setCode: Dispatch<SetStateAction<string>>;
  setName: Dispatch<SetStateAction<string>>;
  setDisabled: Dispatch<SetStateAction<boolean>>;
} & Pick<
  Props,
  | 'project'
  | 'members'
  | 'approveStages'
  | 'showApproval'
  | 'useProject'
  | 'handleApproverChange'
  | 'handleApproverReset'
>;

const ProjectFormLeftSection = ({
  code,
  name,
  disabled,
  project,
  members,
  approveStages,
  showApproval,
  useProject,
  handleApproverChange,
  handleApproverReset,
  setCode,
  setName,
  setDisabled
}: LeftSectionProps) => (
  <>
    <FormControl required fullWidth>
      <InputLabel>プロジェクトコード</InputLabel>
      <Input name="code" value={code} onChange={e => setCode(e.target.value)} disabled={useProject} />
    </FormControl>

    <FormControl margin="dense" required fullWidth>
      <InputLabel>プロジェクト名</InputLabel>
      <Input name="name" value={name} onChange={e => setName(e.target.value)} disabled={useProject} />
    </FormControl>

    {showApproval && (
      <>
        {Object.entries(approveStages).map(([workflowType, stage]) => (
          <WorkflowForm key={workflowType} id={`${workflowType}-workflow`}>
            {Object.entries(stage).map(([stageId, approvers], s) => (
              <FormControl key={stageId} margin="dense" fullWidth>
                <InputLabel>承認者</InputLabel>
                {approvers.map((approverId, i) => (
                  <SelectApprovers
                    key={approverId}
                    rawMembers={utils.dig(project, 'members') ?? members ?? []}
                    members={
                      project?.membersNotApprover(workflowType as WorkflowType, parseInt(stageId, 10)) ??
                      members ??
                      []
                    }
                    selectedId={approverId !== '' ? approverId : 0}
                    i={i}
                    s={s + 1}
                    workflowType={workflowType as WorkflowType}
                    onSelect={handleApproverChange}
                    onReset={handleApproverReset}
                  />
                ))}
              </FormControl>
            ))}
          </WorkflowForm>
        ))}
      </>
    )}

    <FormControl margin="dense" required fullWidth>
      <InputLabel>ステータス</InputLabel>
      <RadioGroup
        layout="horizontal"
        name="disabled"
        value={disabled}
        onChange={(_, value) => setDisabled(value === 'true')}
      >
        <Radio value={false} data-testid="project_form_disabled_false">
          有効
        </Radio>
        <Radio value data-testid="project_form_true">
          無効
        </Radio>
      </RadioGroup>
    </FormControl>
  </>
);

const WorkflowForm = styled.div`
  .select-approvers {
    &__wrapper {
      margin: 0;
    }
  }
`;

const EditWrap = styled.div`
  display: flex;
  justify-content: space-between;

  ${media.sp`
    flex-direction: column;
  `}
`;

const EditSection = styled.div<{ showApproval: boolean }>`
  width: ${props => (props.showApproval ? '50%' : '100%')};
  padding: 8px;
  box-sizing: border-box;

  ${media.sp`
    width: 100%;
  `}
`;
