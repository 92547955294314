import React from 'react';
import _ from 'lodash';
import moment from '@this/lib/moment';
import { withTheme } from 'styled-components';

import type ArrangementRequestFlightInfo from '@this/domain/arrangement_request_info/arrangement_request_flight_info';
import DateSelector from '@this/shared/date_selector/date_selector';
import { toDomesticTel } from '@this/src/util';

export type Props = {
  flightInfo: ArrangementRequestFlightInfo;
  foreignFlight: boolean;
  packageFlight: boolean;
  theme: { themeClass: string };
};

const ArrangementRequestFlightInfoForm: React.SFC<Props> = ({
  flightInfo,
  foreignFlight,
  packageFlight,
  theme
}) => (
  <div className="reserve-confirm__section">
    <div className="reserve-confirm__subtitle">航空券</div>
    <div className="reserve-confirm__child-content">
      {flightInfo.travelers.list.map((traveler, i) => (
        <div key={i}>
          <p className="reserve-confirm__peoplenum">{i + 1}人目</p>
          <div className="reserve-confirm__traveler">
            <div className="reserve-confirm__traveler__input-area">
              {foreignFlight ? (
                <>
                  <div className="reserve-confirm__label">氏名（ローマ字）</div>
                  <div style={{ width: '300px' }}>
                    <input
                      className="reserve-confirm__input-name"
                      type="text"
                      placeholder="【必須】姓（LastName) 例) Yamada"
                      value={traveler.flightLastNameRoman}
                      onChange={flightInfo.travelers.handleTravelerInfoChange(i, 'setFlightLastNameRoman')}
                    />
                    <input
                      className="reserve-confirm__input-name"
                      type="text"
                      placeholder="【任意】ミドルネーム 例) William"
                      value={traveler.flightMiddleNameRoman}
                      onChange={flightInfo.travelers.handleTravelerInfoChange(i, 'setFlightMiddleNameRoman')}
                    />
                    <input
                      className="reserve-confirm__input-name"
                      type="text"
                      placeholder="【必須】名（FirstName）例) Ichiro"
                      value={traveler.flightFirstNameRoman}
                      onChange={flightInfo.travelers.handleTravelerInfoChange(i, 'setFlightFirstNameRoman')}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="reserve-confirm__label">氏名（カナ）</div>
                  <div className="reserve-confirm__traveler__input-area-right">
                    <input
                      className="reserve-confirm__input-name"
                      type="text"
                      placeholder="例) ヤマダ"
                      value={traveler.flightLastNameKana}
                      onChange={flightInfo.travelers.handleTravelerInfoChange(i, 'setFlightLastNameKana')}
                    />
                    <input
                      className="reserve-confirm__input-name"
                      type="text"
                      placeholder="例) イチロウ"
                      value={traveler.flightFirstNameKana}
                      onChange={flightInfo.travelers.handleTravelerInfoChange(i, 'setFlightFirstNameKana')}
                    />
                  </div>
                </>
              )}
            </div>
            <div className="reserve-confirm__traveler__input-area">
              <div className="reserve-confirm__label">性別</div>
              <div className="reserve-confirm__traveler__input-area-right">
                <label className="reserve-confirm__radio-label">
                  <input
                    className="reserve-confirm__radio-input"
                    type="radio"
                    value="male"
                    checked={traveler.flightGender === 'male'}
                    onChange={flightInfo.travelers.handleTravelerInfoChange(i, 'setFlightGender')}
                  />
                  <span>男性</span>
                </label>
                <label className="reserve-confirm__radio-label">
                  <input
                    className="reserve-confirm__radio-input"
                    type="radio"
                    value="female"
                    checked={traveler.flightGender === 'female'}
                    onChange={flightInfo.travelers.handleTravelerInfoChange(i, 'setFlightGender')}
                  />
                  <span>女性</span>
                </label>
              </div>
            </div>
            <div className="reserve-confirm__traveler__input-area">
              <div className="reserve-confirm__label">誕生日</div>
              <div className="reserve-confirm__traveler__input-area-right">
                {traveler.type === 'companion' || traveler.id === flightInfo.user.id ? (
                  <DateSelector
                    date={moment(traveler.flightBirthday).isValid() ? moment(traveler.flightBirthday) : undefined}
                    onDateChange={flightInfo.travelers.handleTravelerBirthDayChange(i, 'setFlightBirthday')}
                  />
                ) : (
                  '****-**-**(プライバシー保護のため非表示)'
                )}
              </div>
            </div>
            <div className="reserve-confirm__traveler__input-area">
              <div className="reserve-confirm__label">緊急連絡先電話番号</div>
              <div className="reserve-confirm__traveler__input-area-right">
                <input
                  className="reserve-confirm__input"
                  type="text"
                  placeholder="例) 090-1111-2222"
                  value={toDomesticTel(traveler.flightTel)}
                  onChange={flightInfo.travelers.handleTravelerInfoChange(i, 'setFlightTel')}
                />
              </div>
            </div>
            {foreignFlight && (
              <>
                <div className="reserve-confirm__traveler__input-area">
                  <div className="reserve-confirm__label">パスポート番号</div>
                  <div className="reserve-confirm__traveler__input-area-right">
                    <input
                      className="reserve-confirm__input passport-number"
                      type="text"
                      placeholder="例) AA1234567"
                      value={traveler.passportNumber}
                      onChange={flightInfo.travelers.handleTravelerInfoChange(i, 'setPassportNumber')}
                    />
                  </div>
                </div>
                <div className="reserve-confirm__traveler__input-area">
                  <div className="reserve-confirm__label">パスポート有効期限</div>
                  <div className="reserve-confirm__traveler__input-area-right">
                    <input
                      className="reserve-confirm__input passport-expire"
                      type="text"
                      placeholder="例) 2025-01-01"
                      value={traveler.passportExpire}
                      onChange={flightInfo.travelers.handleTravelerInfoChange(i, 'setPassportExpire')}
                    />
                  </div>
                </div>
                <div className="reserve-confirm__traveler__input-area">
                  <div className="reserve-confirm__label">パスポート発行国</div>
                  <div className="reserve-confirm__traveler__input-area-right">
                    <input
                      className="reserve-confirm__input passport-issued-coutry"
                      type="text"
                      placeholder="例) 日本"
                      value={traveler.passportIssuedCoutry}
                      onChange={flightInfo.travelers.handleTravelerInfoChange(i, 'setPassportIssuedCountry')}
                    />
                  </div>
                </div>
              </>
            )}
            {!packageFlight && (
              <div className="reserve-confirm__traveler__input-area">
                <div className="reserve-confirm__label">座席の希望</div>
                <div className="reserve-confirm__traveler__input-area-right">
                  <input
                    className="reserve-confirm__input air-seat"
                    type="text"
                    placeholder="(任意)「前方通路側」など"
                    value={traveler.airSeat}
                    onChange={flightInfo.travelers.handleTravelerInfoChange(i, 'setAirSeat')}
                  />
                </div>
              </div>
            )}
            <div className="reserve-confirm__traveler__input-area">
              <div className="reserve-confirm__label">マイル番号</div>
              <div className="reserve-confirm__traveler__input-area-right">
                <input
                  className="reserve-confirm__input mileage-number"
                  type="text"
                  placeholder="(任意)マイル番号を入力してください"
                  value={traveler.mileageNumber}
                  onChange={flightInfo.travelers.handleTravelerInfoChange(i, 'setMileageNumber')}
                />
              </div>
            </div>
            {traveler.mileageNumbers && traveler.mileageNumbers.length > 0 && (
              <div>
                <div className="reserve-confirm__label">登録済みマイル番号</div>
                <div className="reserve-confirm__label">
                  <div>
                    {traveler.mileageNumbers.map((mileage, i) => (
                      <div key={i}>
                        {mileage.carrier}: {mileage.number}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      ))}
      <div className="reserve-confirm__small red">
        {foreignFlight
          ? '※海外への渡航をご予定の場合、氏名等がパスポートの記載と異なると、当日ご利用いただけない場合がございます。'
          : '※氏名、誕生日が間違っていると搭乗できない場合がありますのでご注意ください。'}
      </div>
      <div className="reserve-confirm__small gray">
        ※各航空会社の定めにより、発券後の搭乗者名の変更には変更手数料が発生いたします。
        {theme.themeClass !== 'mynavi' && (
          <>
            詳細については
            <a href="/charges" target="_blank">
              コチラ
            </a>
          </>
        )}
      </div>
    </div>
  </div>
);

export default withTheme(ArrangementRequestFlightInfoForm);
