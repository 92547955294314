import type { Moment } from '@this/src/lib/moment';
import moment from 'moment';

export type FreeePostDealHistoryActionType = 'post' | 'remove';
export type FreeePostDealHistoryMethod = 'separate' | 'combine';
export type FreeePostDealHistoryStatus =
  | 'unstarted'
  | 'started'
  | 'succeeded'
  | 'failed'
  | 'canceled'
  | 'invoice_disabled';
export type FreeePostDealHistoryCount = Record<FreeePostDealHistoryStatus | 'total', number>;
export type FreeePostDealHistoryAction = 'remove';

export interface FreeePostDealHistoryResponseArgs {
  index: number;
  history_id: number;
  invoice_id: number;
  freee_transaction_id: string;
  action_type: FreeePostDealHistoryActionType;
  method: FreeePostDealHistoryMethod;
  status: FreeePostDealHistoryStatus;
  action_error?: string;
  started_at: string;
  finished_at: string;
  success_count: number;
  failure_count: number;
  total_count: number;
  actions: FreeePostDealHistoryAction[];
}

export interface FreeePostDealHistoryUpdateStatusResponseArgs {
  index: number;
  history_id: number;
  action_type: FreeePostDealHistoryActionType;
  action_status: FreeePostDealHistoryStatus;
  action_error?: string;
  finished_at?: string;
  history_count: FreeePostDealHistoryCount;
}

export interface FreeePostDealHistoryArgs {
  index: number;
  historyId: number;
  invoiceId: number;
  freeeTransactionId: string;
  actionType: FreeePostDealHistoryActionType;
  method: FreeePostDealHistoryMethod;
  status: FreeePostDealHistoryStatus;
  actionError: string | null;
  startedAt: Moment | null;
  finishedAt: Moment | null;
  successCount: number;
  failureCount: number;
  totalCount: number;
  actions: FreeePostDealHistoryAction[];
}

export const convertFreeePostDealHistoryResponseToArgs = (
  response: FreeePostDealHistoryResponseArgs
): FreeePostDealHistoryArgs => {
  const args: FreeePostDealHistoryArgs = {
    index: response.index,
    historyId: response.history_id,
    invoiceId: response.invoice_id,
    freeeTransactionId: response.freee_transaction_id,
    actionType: response.action_type,
    method: response.method,
    status: response.status,
    actionError: response.action_error || null,
    startedAt: response.started_at ? moment(response.started_at) : null,
    finishedAt: response.finished_at ? moment(response.finished_at) : null,
    successCount: response.success_count,
    failureCount: response.failure_count,
    totalCount: response.total_count,
    actions: response.actions || []
  };
  return args;
};

class FreeePostDealHistory {
  index: number;

  historyId: number;

  invoiceId: number;

  freeeTransactionId: string | null;

  actionType: FreeePostDealHistoryActionType;

  method: FreeePostDealHistoryMethod;

  status: FreeePostDealHistoryStatus;

  startedAt: Moment | null;

  finishedAt: Moment | null;

  successCount: number;

  failureCount: number;

  totalCount: number;

  actions: FreeePostDealHistoryAction[];

  errorMessage: string | null = null;

  constructor(args: FreeePostDealHistoryArgs) {
    this.index = args.index;
    this.historyId = args.historyId;
    this.invoiceId = args.invoiceId;
    this.freeeTransactionId = args.freeeTransactionId;
    this.actionType = args.actionType;
    this.method = args.method;
    this.status = args.status;
    this.startedAt = args.startedAt;
    this.finishedAt = args.finishedAt;
    this.successCount = args.successCount;
    this.failureCount = args.failureCount;
    this.totalCount = args.totalCount;
    this.actions = args.actions;
    this.errorMessage = args.actionError;
  }

  get actionTypeText(): string {
    switch (this.actionType) {
      case 'post':
        return '登録';
      case 'remove':
        return '削除';
      default:
        return '不明';
    }
  }

  get methodText(): string {
    switch (this.method) {
      case 'separate':
        return '明細ごとに取引を作成';
      case 'combine':
        return '1取引に明細を紐付け';
      default:
        return '不明';
    }
  }

  get statusText(): string {
    switch (this.status) {
      case 'unstarted':
      case 'started':
        return '登録中';
      case 'succeeded':
        if (this.actionType === 'post') {
          return '登録完了';
        }

        return '削除完了';
      case 'failed':
        return '登録失敗';
      case 'canceled':
        return '削除済み';
      case 'invoice_disabled':
        return '請求書無効';
      default:
        return '不明';
    }
  }

  get startedAtText(): string {
    return this.startedAt ? this.startedAt.format('YYYY/MM/DD HH:mm') : '';
  }

  get finishedAtText(): string {
    return this.finishedAt ? this.finishedAt.format('YYYY/MM/DD HH:mm') : '';
  }

  get countText(): string {
    return `${this.successCount} / ${this.totalCount}${
      this.failureCount > 0 ? ` (${this.failureCount}件失敗)` : ''
    }`;
  }

  get progress(): number {
    return this.totalCount > 0 ? parseInt(String((this.runnedCount / this.totalCount) * 100), 10) : 0;
  }

  get runnedCount(): number {
    return this.successCount + this.failureCount;
  }

  get progressText(): string {
    return `${this.runnedCount} / ${this.totalCount}`;
  }

  updateCount(counts: FreeePostDealHistoryCount): FreeePostDealHistory {
    if (this.actionType === 'post') {
      this.successCount = counts.succeeded || 0;
    } else {
      this.successCount = counts.canceled || 0;
    }

    this.failureCount = counts.failed || 0;
    this.totalCount = counts.total || 0;

    return this;
  }

  updateStatus(response: FreeePostDealHistoryUpdateStatusResponseArgs): FreeePostDealHistory {
    this.status = response.action_status;
    this.finishedAt = response.finished_at ? moment(response.finished_at) : null;
    this.successCount = response.history_count.succeeded || 0;
    this.failureCount = response.history_count.failed || 0;
    this.totalCount = response.history_count.total || 0;

    if (this.status === 'failed' && response.action_error) {
      this.errorMessage = response.action_error;
    }

    return this;
  }
}

export default FreeePostDealHistory;
