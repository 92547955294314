import React, { useCallback, useEffect, useMemo, useReducer } from 'react';
import type { Dispatch, Reducer, ReducerAction } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';

import MuiPaper from '@material-ui/core/Paper';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import SearchTracking from '@this/src/domain/search_tracking';
import type { SearchTrackingArgs } from '@this/src/domain/search_tracking';
import SearchTrackingLog from '@this/src/domain/search_tracking_log';
import type { SearchTrackingLogArgs } from '@this/src/domain/search_tracking_log';
import SearchTrackingSummary from '@this/src/domain/search_tracking_summary';
import type { SearchTrackingSummaryArgs } from '@this/src/domain/search_tracking_summary';
import SearchTrackingOptions from '@this/src/domain/search_tracking_options';
import type { SearchTrackingOptionsArgs } from '@this/src/domain/search_tracking_options';

import { styled } from '@this/constants/themes';
import { Link } from '@this/src/components/shared/ui/navigations/link';
import { Loading } from '@this/shared/ui/feedbacks/loading';

import SearchTrackingDetailInformation from './search_tracking_detail_information';
import SearchTrackingDetailSummary from './search_tracking_detail_summary';
import SearchTrackingDetailSettings from './search_tracking_detail_settings';
import SearchTrackingDetailSearchArea, {
  initialSearchQuery,
  searchQueryReducer
} from './search_tracking_detail_search_area';
import type { SearchQuery, SearchQueryAction } from './search_tracking_detail_search_area';
import SearchTrackingDetailLog from './search_tracking_detail_log';
import SearchTrackingLogDetailDialog from './search_tracking_log/search_tracking_log_detail_dialog';

interface State {
  searchTracking: SearchTracking | null;
  searchTrackingLogs: SearchTrackingLog[];
  searchTrackingSummary: SearchTrackingSummary;
  searchTrackingOptions: SearchTrackingOptions;
  loading: boolean;
  showSearchTrackingLog: number | null;
  searchQuery: SearchQuery;
}

interface Response {
  search_tracking: SearchTrackingArgs;
  search_tracking_logs: SearchTrackingLogArgs[];
  search_tracking_summary: SearchTrackingSummaryArgs;
  search_tracking_options: SearchTrackingOptionsArgs;
}

type Action =
  | SearchQueryAction
  | { type: 'SET_LOADING'; payload: boolean }
  | { type: 'SET_RESPONSE'; payload: Response }
  | { type: 'SET_SHOW_SEARCH_TRACKING_LOG'; payload: number | null };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_STATUS':
    case 'SET_PATH':
    case 'SET_SEARCH_TYPE':
    case 'SET_SEARCH_QUERY':
      return { ...state, searchQuery: searchQueryReducer(state.searchQuery, action) };
    case 'SET_LOADING':
      return { ...state, loading: action.payload };
    case 'SET_RESPONSE':
      return {
        ...state,
        searchTracking: new SearchTracking(action.payload.search_tracking),
        searchTrackingLogs: action.payload.search_tracking_logs.map(raw => new SearchTrackingLog(raw)),
        searchTrackingSummary: new SearchTrackingSummary(action.payload.search_tracking_summary),
        searchTrackingOptions: new SearchTrackingOptions(action.payload.search_tracking_options),
        loading: false
      };
    case 'SET_SHOW_SEARCH_TRACKING_LOG':
      return { ...state, showSearchTrackingLog: action.payload };
    default:
      return state;
  }
};

interface Request {
  searchTrackingId: number;
  dispatch: Dispatch<ReducerAction<Reducer<State, Action>>>;
}

const fetchSearchTrackingLogs = ({ searchTrackingId, dispatch }: Request) => {
  dispatch({ type: 'SET_LOADING', payload: true });

  utils
    .jsonPromise<Response>(`/arrangement/search_trackings/${searchTrackingId}.json`)
    .then(result => {
      dispatch({ type: 'SET_RESPONSE', payload: result });
    })
    .catch(() => {
      dispatch({ type: 'SET_LOADING', payload: false });
    });
};

const initialState: State = {
  searchTracking: null,
  searchTrackingLogs: [],
  searchTrackingSummary: SearchTrackingSummary.default(),
  searchTrackingOptions: SearchTrackingOptions.default(),
  loading: false,
  showSearchTrackingLog: null,
  searchQuery: initialSearchQuery
};

const SearchTrackingDetails: React.FC = observer(() => {
  const { id } = useParams<{ id: string }>();
  const [state, dispatch] = useReducer(reducer, initialState);

  const {
    searchTracking,
    searchTrackingLogs,
    searchTrackingSummary,
    searchTrackingOptions,
    loading,
    searchQuery
  } = state;

  const trackingLogs = useMemo(() => {
    return searchTrackingLogs.filter(log => {
      if (searchQuery.status && log.status !== searchQuery.status) {
        return false;
      }
      if (searchQuery.path.length > 0 && !searchQuery.path.includes(log.path)) {
        return false;
      }
      if (searchQuery.searchType && log.searchType !== searchQuery.searchType) {
        return false;
      }
      return true;
    });
  }, [searchTrackingLogs, searchQuery]);

  const handleClose = useCallback(() => {
    dispatch({ type: 'SET_SHOW_SEARCH_TRACKING_LOG', payload: null });
  }, [dispatch]);

  const fetch = useCallback(() => {
    fetchSearchTrackingLogs({ searchTrackingId: Number(id), dispatch });
  }, [id, dispatch]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return (
    <Wrapper>
      <Left>
        <BackLinkSection>
          <Link href="/arrangement/search_trackings">
            <ArrowBackIcon />
            ログの一覧へ戻る
          </Link>
        </BackLinkSection>
        <Paper>
          {loading ? (
            <Loading />
          ) : (
            <Content>
              {searchTracking && <SearchTrackingDetailInformation searchTracking={searchTracking} />}
              {searchTrackingSummary && (
                <SearchTrackingDetailSummary searchTrackingSummary={searchTrackingSummary} />
              )}
              {searchTracking && <SearchTrackingDetailSettings searchTracking={searchTracking} />}
            </Content>
          )}
        </Paper>
      </Left>
      <Right>
        <SearchTrackingDetailSearchArea
          searchQuery={searchQuery}
          searchTrackingOptions={searchTrackingOptions}
          dispatch={dispatch}
        />
        {loading ? (
          <Loading />
        ) : (
          <LogArea>
            {trackingLogs.map(log => (
              <SearchTrackingDetailLog
                key={log.id}
                searchTrackingLog={log}
                onClick={() => dispatch({ type: 'SET_SHOW_SEARCH_TRACKING_LOG', payload: log.id })}
              />
            ))}
          </LogArea>
        )}
      </Right>
      <SearchTrackingLogDetailDialog searchTrackingLogId={state.showSearchTrackingLog} onClose={handleClose} />
    </Wrapper>
  );
});

const Wrapper = styled.div`
  display: flex;
  gap: 16px;
  padding: 16px;
  min-height: 100%;
  background-color: ${props => props.theme.grayBgColorLight};
`;

const Left = styled.div`
  flex: 1;
`;

const Right = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
`;

const BackLinkSection = styled.div`
  margin-bottom: 16px;

  a {
    display: flex;
    align-items: center;
    gap: 8px;
  }
`;

const Paper = styled(MuiPaper)`
  width: 100%;
  height: 100%;
  padding: 16px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const LogArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex: 1;
`;

export default SearchTrackingDetails;
