import React, { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import type { Moment } from 'moment';
import { useLocation } from 'react-router-dom';
import { styled } from '@this/constants/themes';
import { ButtonBase } from '@this/components/shared/atoms/button';
import { OrganizationTitle, OrganizationHeader } from '@this/components/organization/organization.style';
import { Select } from '@this/shared/ui/inputs/select';
import type {
  DateRangePickerPreset,
  DateRangePickerValue
} from '@this/shared/ui/inputs/datepicker/date_range_picker';
import { DateRangePicker } from '@this/shared/ui/inputs/datepicker/date_range_picker';
import type { AggregateType, UrlQuery } from './share';
import DashboardSummarySection from './sections/summary_patterns/dashboard_summary_category';
import DashboardDepartmentSection from './sections/department_patterns/dashboard_department_cache';
import DashboardTravelerSection from './sections/traveler_patterns/dashboard_traveler_cache';
import DashboardTravelerDepartmentSection from './sections/traveler_department_patterns/dashboard_traveler_department_cache';
import DashboardProjectSection from './sections/project_patterns/dashboard_project_cache';
import DashboardCostRankingSection from './sections/cost_ranking_patterns/dashboard_cost_ranking_cache';
import DashboardPurchaseLeadtimeSection from './sections/purchase_leadtime_patterns/dashboard_purchase_leadtime_cache';
import DashboardPurchaseLeadtimeByDepartmentSection from './sections/purchase_leadtime_by_department_patterns/dashboard_purchase_leadtime_by_department_cache';
import DashboardPurchaseLeadtimeByTravelerDepartmentSection from './sections/purchase_leadtime_by_traveler_department_patterns/dashboard_purchase_leadtime_by_department_cache';
import DashboardPurchaseLeadtimeByEmployeeSection from './sections/purchase_leadtime_by_employee_patterns/dashboard_purchase_leadtime_by_employee_cache';
import DashboardPurchaseLeadtimeByProjectSection from './sections/purchase_leadtime_by_project_patterns/dashboard_purchase_leadtime_by_project_cache';
import DashboardApprovalsLeadtimeSection from './sections/approvals_leadtime_patterns/dashboard_approvals_leadtime_cache';
import DashboardApprovalsLeadtimeByDepartmentSection from './sections/approvals_leadtime_by_department_patterns/dashboard_approvals_leadtime_by_department_cache';
import DashboardApprovalsLeadtimeByTravelerDepartmentSection from './sections/approvals_leadtime_by_traveler_department_patterns/dashboard_approvals_leadtime_by_traveler_department_cache';
import DashboardApprovalsLeadtimeByEmployeeSection from './sections/approvals_leadtime_by_employee_patterns/dashboard_approvals_leadtime_by_employee_cache';
import DashboardApprovalsLeadtimeByProjectSection from './sections/approvals_leadtime_by_project_patterns/dashboard_approvals_leadtime_by_project_cache';
import DashboardApprovalRejectionCountSection from './sections/approval_rejection_count/dashboard_approval_count_cache';
import DashboardApprovalRejectionCountByDepartmentSection from './sections/approval_rejection_count_by_department/dashboard_approval_count_department_cache';
import DashboardApprovalRejectionCountByTravelerDepartmentSection from './sections/approval_rejection_count_by_traveler_department/dashboard_approval_count_traveler_department_cache';
import {
  DepartmentSelectContext,
  useDepartments
} from '../../organization/dashboard/share/department_select/context';
import { RefreshCacheButton } from './share/refresh_cache_button';

const ENDPOINT = '/organization' as const;

interface State {
  aggregateType: AggregateType;
  from: Moment;
  to: Moment;
  urlQuery: UrlQuery;
  refreshAt: Moment | null;
  purchaseDepartmentId: number | null;
  approvalsDepartmentId: number | null;
  waitApprovalStatusCount: boolean;
  waitPurchaseLeadtime: boolean;
  waitApprovedLeadtime: boolean;
}

const dateRangePickerPresets: DateRangePickerPreset[] = [
  { label: '当月', value: [moment().startOf('month').toDate(), moment().endOf('month').toDate()] },
  { label: '過去3ヶ月', value: [moment().add(-3, 'months').toDate(), moment().toDate()] },
  { label: '過去6ヶ月', value: [moment().add(-6, 'months').toDate(), moment().toDate()] },
  { label: '過去1年', value: [moment().add(-1, 'years').toDate(), moment().toDate()] }
];

const setUrlQueryParams = (queryParams: { name: string; value: string }[]) => {
  const searchParams = new URLSearchParams(window.location.search);
  queryParams.forEach(q => searchParams.set(q.name, q.value));
  window.history.pushState({}, '', `${ENDPOINT}?${searchParams.toString()}`);
};

const Dashboard: React.FC = () => {
  const location = useLocation();
  const search = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const urlQuery = useMemo<UrlQuery>(
    () => ({
      type: 'billing_amount',
      from: search.get('from') ? moment(search.get('from')) : moment().subtract(8, 'days'),
      to: search.get('to') ? moment(search.get('to')) : moment().subtract(1, 'days'),
      purchaseDepartmentId: search.get('purchase_department_id')
        ? parseInt(search.get('purchase_department_id') ?? '', 10)
        : null,
      approvalsDepartmentId: search.get('approvals_department_id')
        ? parseInt(search.get('approvals_department_id') ?? '', 10)
        : null
    }),
    [search]
  );

  const [state, setState] = useState<State>({
    aggregateType: urlQuery.type,
    from: urlQuery.from,
    to: urlQuery.to,
    purchaseDepartmentId: urlQuery.purchaseDepartmentId,
    approvalsDepartmentId: urlQuery.approvalsDepartmentId,
    urlQuery,
    refreshAt: null,
    waitApprovalStatusCount: true,
    waitPurchaseLeadtime: true,
    waitApprovedLeadtime: true
  });

  const {
    aggregateType,
    from,
    to,
    purchaseDepartmentId,
    approvalsDepartmentId,
    urlQuery: query,
    refreshAt,
    waitApprovalStatusCount,
    waitPurchaseLeadtime,
    waitApprovedLeadtime
  } = state;

  const departments = useDepartments({ serviceType: 'organization' });

  useEffect(() => {
    setUrlQueryParams([
      { name: 'type', value: query.type },
      { name: 'from', value: query.from.format('YYYY-MM-DD') },
      { name: 'to', value: query.to.format('YYYY-MM-DD') },
      { name: 'purchase_department_id', value: query.purchaseDepartmentId?.toString() ?? '' },
      { name: 'approvals_department_id', value: query.approvalsDepartmentId?.toString() ?? '' }
    ]);
  }, [query]);

  const handleSearch = useCallback(() => {
    setState(state => ({
      ...state,
      urlQuery: {
        type: state.aggregateType,
        from: state.from,
        to: state.to,
        purchaseDepartmentId: state.purchaseDepartmentId,
        approvalsDepartmentId: state.approvalsDepartmentId
      }
    }));
  }, [setState]);

  const handleDateRangeChange = useCallback(
    (value: DateRangePickerValue | null) => {
      const [newFrom, newTo] = value ?? [];
      setState(state => ({
        ...state,
        from: moment(newFrom),
        to: moment(newTo)
      }));
    },
    [setState]
  );

  const handleTypeChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const { value } = event.target;
      setState(state => ({ ...state, aggregateType: value as AggregateType }));
    },
    [setState]
  );

  const handlePurchaseDepartmentSelect = useCallback(
    (purchaseDepartmentId: number | null) => {
      setState(state => ({
        ...state,
        purchaseDepartmentId,
        urlQuery: { ...state.urlQuery, purchaseDepartmentId }
      }));
    },
    [setState]
  );

  const handleApprovalsDepartmentSelect = useCallback(
    (approvalsDepartmentId: number | null) => {
      setState(state => ({
        ...state,
        approvalsDepartmentId,
        urlQuery: { ...state.urlQuery, approvalsDepartmentId }
      }));
    },
    [setState]
  );

  const handleRefreshCache = useCallback(() => {
    setState(state => ({ ...state, refreshAt: moment() }));
  }, [setState]);

  const proceedApprovalStatusCount = useCallback(() => {
    setState(state => ({ ...state, waitApprovalStatusCount: false }));
  }, [setState]);

  const proceedPurchaseLeadtime = useCallback(() => {
    setState(state => ({ ...state, waitPurchaseLeadtime: false }));
  }, [setState]);

  const proceedApprovedLeadtime = useCallback(() => {
    setState(state => ({ ...state, waitApprovedLeadtime: false }));
  }, [setState]);

  return (
    <DepartmentSelectContext.Provider value={departments}>
      <OrganizationTitle>ダッシュボード</OrganizationTitle>
      <OrganizationHeader>
        <div style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
          <div style={{ marginRight: '20px' }}>
            <RefreshCacheButton dateType={query.type} from={from} to={to} onRefreshCache={handleRefreshCache} />
          </div>
          <div style={{ marginRight: '10px' }}>
            <Select value={aggregateType} onChange={handleTypeChange}>
              <option value="billing_amount">請求対象日</option>
              <option value="arrangement_request">手配依頼日</option>
              <option value="start_date">出発日</option>
              <option value="end_date">帰着日</option>
            </Select>
          </div>
          <DateRangePicker
            value={[from.toDate(), to.toDate()]}
            presets={dateRangePickerPresets}
            disabledClear
            onChange={handleDateRangeChange}
          />
          <div className="organization-trips__reload-button-area(" style={{ marginLeft: '5px' }}>
            <Button value="更新" onClick={handleSearch} />
          </div>
        </div>
      </OrganizationHeader>
      <OrganizationBodyWrapper>
        <Row>
          <FullBlock>
            <DashboardSummarySection serviceType="organization" urlQuery={query} refreshAt={refreshAt} />
          </FullBlock>
        </Row>
        <Row>
          <HalfBlock>
            <DashboardDepartmentSection serviceType="organization" urlQuery={query} refreshAt={refreshAt} />
          </HalfBlock>
          <HalfBlock>
            <DashboardTravelerDepartmentSection
              serviceType="organization"
              urlQuery={query}
              refreshAt={refreshAt}
            />
          </HalfBlock>
        </Row>
        <Row>
          <HalfBlock>
            <DashboardTravelerSection serviceType="organization" urlQuery={query} refreshAt={refreshAt} />
          </HalfBlock>
          <HalfBlock>
            <DashboardProjectSection serviceType="organization" urlQuery={query} refreshAt={refreshAt} />
          </HalfBlock>
        </Row>
        <Row>
          <HalfBlock>
            <DashboardCostRankingSection serviceType="organization" urlQuery={query} refreshAt={refreshAt} />
          </HalfBlock>
          <HalfBlock>
            <DashboardApprovalRejectionCountSection
              serviceType="organization"
              urlQuery={query}
              refreshAt={refreshAt}
              wait={waitApprovalStatusCount}
            />
          </HalfBlock>
        </Row>
        <Row>
          <HalfBlock>
            <DashboardApprovalRejectionCountByDepartmentSection
              serviceType="organization"
              urlQuery={query}
              refreshAt={refreshAt}
              proceed={proceedApprovalStatusCount}
            />
          </HalfBlock>
          <HalfBlock>
            <DashboardApprovalRejectionCountByTravelerDepartmentSection
              serviceType="organization"
              urlQuery={query}
              refreshAt={refreshAt}
            />
          </HalfBlock>
        </Row>
        <Row>
          <HalfBlock>
            <DashboardPurchaseLeadtimeSection
              serviceType="organization"
              urlQuery={query}
              departmentId={purchaseDepartmentId}
              onSelect={handlePurchaseDepartmentSelect}
              refreshAt={refreshAt}
              wait={waitPurchaseLeadtime}
            />
          </HalfBlock>
          <HalfBlock>
            <DashboardApprovalsLeadtimeSection
              serviceType="organization"
              urlQuery={query}
              departmentId={approvalsDepartmentId}
              onSelect={handleApprovalsDepartmentSelect}
              refreshAt={refreshAt}
              wait={waitApprovedLeadtime}
            />
          </HalfBlock>
        </Row>
        <Row>
          <HalfBlock>
            <DashboardPurchaseLeadtimeByDepartmentSection
              serviceType="organization"
              urlQuery={query}
              refreshAt={refreshAt}
              proceed={proceedPurchaseLeadtime}
            />
          </HalfBlock>
          <HalfBlock>
            <DashboardApprovalsLeadtimeByDepartmentSection
              serviceType="organization"
              urlQuery={query}
              refreshAt={refreshAt}
              proceed={proceedApprovedLeadtime}
            />
          </HalfBlock>
        </Row>
        <Row>
          <HalfBlock>
            <DashboardPurchaseLeadtimeByTravelerDepartmentSection
              serviceType="organization"
              urlQuery={query}
              refreshAt={refreshAt}
            />
          </HalfBlock>
          <HalfBlock>
            <DashboardApprovalsLeadtimeByTravelerDepartmentSection
              serviceType="organization"
              urlQuery={query}
              refreshAt={refreshAt}
            />
          </HalfBlock>
        </Row>
        <Row>
          <HalfBlock>
            <DashboardPurchaseLeadtimeByProjectSection
              serviceType="organization"
              urlQuery={query}
              refreshAt={refreshAt}
            />
          </HalfBlock>
          <HalfBlock>
            <DashboardApprovalsLeadtimeByProjectSection
              serviceType="organization"
              urlQuery={query}
              refreshAt={refreshAt}
            />
          </HalfBlock>
        </Row>
        <LastRow>
          <HalfBlock>
            <DashboardPurchaseLeadtimeByEmployeeSection
              serviceType="organization"
              urlQuery={query}
              refreshAt={refreshAt}
            />
          </HalfBlock>
          <HalfBlock>
            <DashboardApprovalsLeadtimeByEmployeeSection
              serviceType="organization"
              urlQuery={query}
              refreshAt={refreshAt}
            />
          </HalfBlock>
        </LastRow>
      </OrganizationBodyWrapper>
    </DepartmentSelectContext.Provider>
  );
};

export const Button = styled.input.attrs({
  type: 'submit'
})`
  &&& {
    ${ButtonBase};
    padding: 5px 10px;
  }
`;

const OrganizationBodyWrapper = styled.div`
  background: #eee;
`;

const Row = styled.div`
  display: flex;
  column-gap: 20px;
  margin-bottom: 20px;
`;

const LastRow = styled.div`
  display: flex;
  column-gap: 20px;
`;

const FullBlock = styled.div`
  width: 100%;
  background: #fff;
  padding: 20px;
`;

const HalfBlock = styled.div`
  width: 50%;
  background: #fff;
  padding: 20px;
  max-height: 700px;
  overflow-y: auto;
`;

export default Dashboard;
