/* eslint-disable max-lines */
import React from 'react';
import _ from 'lodash';
import { styled } from '@this/constants/themes';
import type { Moment } from 'moment';
import { observer } from 'mobx-react';
import type { ImageFile } from 'react-dropzone';
import { Link } from 'react-router-dom';
import { Input } from '@this/shared/ui/inputs/input';
import EkispertInput from '@this/shared/autocompletable_input/ekispert_input';
import { Link as NavLink } from '@this/shared/ui/navigations/link';
import type Project from '@this/src/domain/project/project';
import type TaxType from '@this/src/domain/tax_type';
import { Textarea } from '@this/shared/ui/inputs/textarea';
import StatusLabel from './status_label';
import type { ExpensesType } from '../../../domain/expenses/expenses_type';
import { RadioField, CheckboxField } from '../../shared/form_elements/form_elements';
import DatePickerForSearch from '../search_form/date_picker_for_search';
import type { FilterableSelectorOption } from '../form/filterable_selector';
import FilterableSelector from '../form/filterable_selector';
import type { Item, Category, TripType } from '../../../domain/expenses/item';
import ReceiptDropzone from './receipt_dropzone';

interface Props {
  item: Item;
  expensesTypes?: ExpensesType[];
  projects?: Project[];
  taxTypes?: TaxType[];
  onSubmit: () => void;
  categoryOptions: { [key: string]: string };
  tripTypeOptions: { [key: string]: string };
  submitting: boolean;
  errors?: string | string[];
  edit?: boolean;
  onDelete?: () => void;
  backPath: string;
  receiptAvailable: boolean;
  isAdmin: boolean;
  hideStatus?: boolean;
  useProject?: boolean;
}

interface EkispertCourse {
  price: number;
  route: string;
  distance: number;
}

interface EkispertPriceResponse {
  courses: EkispertCourse[];
}

type CalcPriceOrder = 'price' | 'time' | 'transfer';

interface State {
  calcPriceOrder: CalcPriceOrder;
  useIc: boolean;
  priceError: boolean;
}

const baseClass = 'expenses-report-item-form';

@observer
class Form extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      calcPriceOrder: 'price',
      useIc: true,
      priceError: false
    };

    if (!props.edit) {
      this.handleExpenseSelect(this.props.item.expensesType.id);
    }
  }

  private handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    this.props.onSubmit();
  }

  private handleDelete(e: React.FormEvent) {
    e.preventDefault();
    const ret = confirm('本当に削除しますか？');
    if (ret) {
      this.props.onDelete!();
    }
  }

  private handleAddParticipantCompany = () => {
    this.props.item.addParticipantCompany();
  };

  private handleChangeRoute = (name: string, value: string, _address: string) => {
    if (name === 'origin') {
      this.props.item.setFrom(value);
    } else {
      this.props.item.setTo(value);
    }
  };

  private handleChangeTravelExpensesPaidAt = (value: Moment) => {
    this.props.item.setPaidAt(value.format('YYYY-MM-DD'));
    this.handleFetchPrice();
  };

  private handleChangeTripType = (item: Item, value: TripType) => {
    item.setTripType(value);
    this.handleFetchPrice();
  };

  private handleChangeCalcPriceOrder = (value: CalcPriceOrder) => {
    this.setState({ calcPriceOrder: value }, () => {
      this.handleFetchPrice();
    });
  };

  private handleChangeUseIc = () => {
    this.setState({ useIc: !this.state.useIc }, () => {
      this.handleFetchPrice();
    });
  };

  private handleChangePrice = (value: string, item: Item) => {
    const price = parseInt(value, 10);
    if (value === '' || (price >= 0 && price <= 1000000)) {
      item.setPrice(price);
      this.setState({ priceError: false });
    } else if (!isNaN(price)) {
      this.setState({ priceError: true });
    }
    item.setPriceAverageDisplay();
  };

  private handleFetchPrice = async () => {
    const item = this.props.item;
    const order = this.state.calcPriceOrder;
    const useIc = this.state.useIc;
    if (item.from && item.to && item.paidAt && item.tripType) {
      const params = _.merge(item.ekispertPriceParams(), { sort: order, use_ic: useIc });
      const q = utils.toParams(params);
      const res = await utils.jsonPromise<EkispertPriceResponse>(`/biztra/ekispert/price?${q}`);
      const course = res.courses[0];
      if (course) {
        if (item.category !== 'travel_expenses_daily_allowance') {
          item.setPrice(course.price);
        }
        if (item.showDistance()) {
          item.setMemo(`${course.route}\n直線距離: ${new Intl.NumberFormat().format(course.distance)}m`);
        } else {
          item.setMemo(course.route);
        }
      }
    }
  };

  static deleteFilteredExpensesType(expensesTypes: ExpensesType[] | undefined, item: Item): ExpensesType[] {
    if (!expensesTypes) {
      return [];
    }
    return _.filter(expensesTypes, type => {
      return type.filterDeletedpulldownList(item);
    });
  }

  handleExpenseSelect = (id: number | null) => {
    const expenses = this.props.expensesTypes;
    if (!expenses) {
      return;
    }
    const expense = expenses.find(e => e.id === id);
    if (!expense) {
      return;
    }
    this.props.item.setCategory(expense.category as Category);
    this.props.item.setExpensesType(expense);

    if (this.props.item.expensesType.isRequiredUpdateMemo()) {
      this.handleFetchPrice();
    } else {
      this.props.item.setMemo('');
    }
  };

  handleProjectSelect = (id: number | null) => {
    if (id === null) {
      this.props.item.setProject(null);
      return;
    }

    const projects = this.props.projects;
    if (!projects) return;

    const project = projects.find(project => project.id === id);
    if (!project) return;

    this.props.item.setProject(project);
  };

  selectedExpenseOption(): FilterableSelectorOption | null {
    const expense = this.props.item.expensesType;
    if (!expense) {
      return null;
    }
    return {
      label: expense.nameText(),
      value: expense.id.toString(),
      matcher: expense.nameText()
    };
  }

  selectedProjectOption(): FilterableSelectorOption | null {
    const project = this.props.item.project;
    if (!project) return null;

    return {
      label: project.codeAndName(),
      value: project.id.toString(),
      matcher: project.codeAndName()
    };
  }

  private handleFileDrop(file?: ImageFile) {
    this.props.item.receiptFile = file;
  }

  private renderReceiptDropzone() {
    const item = this.props.item;
    return (
      <>
        <Title>領収書アップロード</Title>
        {this.props.receiptAvailable ? (
          <ReceiptDropzone
            selectedPath={
              item.receiptFile
                ? item.receiptFile.preview
                : item.receiptFilePath
                ? `/biztra/images/${item.receiptFilePath}`
                : undefined
            }
            onSelect={f => this.handleFileDrop(f)}
            onRemove={() => {
              item.receiptFile = undefined;
              item.receiptFilePath = undefined;
            }}
          />
        ) : (
          <ProDescription>
            {this.props.isAdmin ? (
              <>
                ※<TheLink to="/biztra/organization/plans">ビズトラProにアップグレード</TheLink>
                すると、スマホ等で撮影した領収書やPDFファイルを添付することができます
              </>
            ) : (
              <>
                ※ビズトラProにアップグレードすると、スマホ等で撮影した領収書やPDFファイルを添付することができます
              </>
            )}
          </ProDescription>
        )}
      </>
    );
  }

  private generalExpensesInputs() {
    const { item } = this.props;
    const ownCompany = item.ownCompany();
    return (
      <div>
        {item.needParticipantCompanies() ? (
          <div className={`${baseClass}__company-form-wrapper`}>
            <Title>参加企業</Title>
            <div className={`${baseClass}__company-form`}>
              <Section className={`${baseClass}__company-form__item`}>
                <Title>合計人数</Title>
                <div>
                  {item.participantCompanies
                    .filter(c => c.destroy !== '1')
                    .map(c => c.peopleNum)
                    .reduce((a, b) => a + b, 0) || 0}
                </div>
              </Section>
              <Section className={`${baseClass}__company-form__item`}>
                <Title>平均単価</Title>
                <div>{item.priceAverageDisplay}</div>
              </Section>
            </div>
            <div className={`${baseClass}__company-form`}>
              <ChildInputWrapper className={`${baseClass}__company-form__item`}>
                <InputSmall>自社の参加人数</InputSmall>
                <TheInput
                  value={`${ownCompany.peopleNum}`}
                  onChange={e => {
                    ownCompany.setPeopleNum(e.target.value);
                    item.setPriceAverageDisplay();
                  }}
                  size="small"
                  type="number"
                  inputProps={{
                    placeholder: '1',
                    style: { fontSize: 14 }
                  }}
                />
              </ChildInputWrapper>
            </div>
            {item.otherCompaniesToDisplay().map((c, i) => (
              <CompanyForm key={i} className={`${baseClass}__company-form`}>
                <ChildInputWrapper className={`${baseClass}__company-form__item`}>
                  <InputSmall>会社名</InputSmall>
                  <TheInput
                    value={c.name}
                    onChange={e => c.setName(e.target.value)}
                    size="small"
                    inputProps={{
                      placeholder: '株式会社トランスファーデータ',
                      style: { fontSize: 14 }
                    }}
                  />
                </ChildInputWrapper>
                <ChildInputWrapper className={`${baseClass}__company-form__item`}>
                  <InputSmall>参加人数</InputSmall>
                  <TheInput
                    value={`${c.peopleNum}`}
                    onChange={e => {
                      c.setPeopleNum(e.target.value);
                      item.setPriceAverageDisplay();
                    }}
                    size="small"
                    type="number"
                    inputProps={{
                      placeholder: '1',
                      style: { fontSize: 14 }
                    }}
                  />
                </ChildInputWrapper>
                <button
                  className="expenses-report-item-form__company-remove-button"
                  type="button"
                  onClick={() => {
                    c.setDestroy();
                    item.setPriceAverageDisplay();
                  }}
                >
                  削除
                </button>
              </CompanyForm>
            ))}
            <div>
              <CompanyButton
                onClick={() => {
                  this.handleAddParticipantCompany();
                  item.setPriceAverageDisplay();
                }}
              >
                追加
              </CompanyButton>
            </div>
          </div>
        ) : null}
      </div>
    );
  }

  private travelExpensesInputs() {
    const { item, tripTypeOptions } = this.props;
    const { calcPriceOrder, useIc } = this.state;
    const orderOptions: { [key: string]: string } = {
      price: '価格優先',
      time: '速さ優先',
      transfer: '乗換回数優先'
    };

    const otherTripTypeOption = Object.keys(tripTypeOptions!).filter(v => v === 'other');
    const sortedTripTypeOptions = [
      ...Object.keys(tripTypeOptions!)
        .reverse()
        .filter(v => v !== 'other'),
      ...otherTripTypeOption
    ];

    return (
      <div>
        {item.categoryIsTravelExpenses() && (
          <EkispertSection className={`${baseClass}__group`}>
            <EkispertInputWrapper className={`${baseClass}__group__half first use_ic`}>
              <div className={`${baseClass}__group`}>
                <TripTypeOptions className="trip-type-options">
                  {sortedTripTypeOptions.map(v => (
                    <RadioField
                      key={v}
                      label={tripTypeOptions[v]}
                      value={v}
                      checked={item.tripType === v}
                      onChange={value => this.handleChangeTripType(item, value as TripType)}
                      id={v}
                    />
                  ))}
                </TripTypeOptions>
              </div>
            </EkispertInputWrapper>
          </EkispertSection>
        )}
        <EkispertSection className={`${baseClass}__group`}>
          <EkispertInputWrapper className={`${baseClass}__group__half first`}>
            <InputSmall>出発</InputSmall>
            <EkispertInput
              example="駅名・ビル名など"
              onChange={this.handleChangeRoute}
              onDisappearSuggest={this.handleFetchPrice}
              value={item.from}
              freeInput={!item.expensesTypeIsTrain()}
              serviceType="biztra"
              id="origin"
            />
          </EkispertInputWrapper>
          <EkispertInputWrapper className={`${baseClass}__group__half last`}>
            <InputSmall>到着</InputSmall>
            <EkispertInput
              example="駅名・ビル名など"
              onChange={this.handleChangeRoute}
              onDisappearSuggest={this.handleFetchPrice}
              value={item.to}
              freeInput={!item.expensesTypeIsTrain()}
              serviceType="biztra"
              id="destination"
            />
          </EkispertInputWrapper>
        </EkispertSection>
        {item.expensesTypeIsTrain() ? (
          <EkispertSection className={`${baseClass}__group`}>
            <EkispertInputWrapper className={`${baseClass}__group__half first use_ic`}>
              <div className={`${baseClass}__group`}>
                <UesIc className="use-ic">
                  <CheckboxField
                    label="ICカード優先"
                    checked={useIc}
                    onChange={this.handleChangeUseIc}
                    id="use-ic"
                  />
                </UesIc>
                <TripTypeOptions className="trip-type-options">
                  {Object.keys(tripTypeOptions!)
                    .reverse()
                    .filter(v => v !== 'other')
                    .map(v => (
                      <RadioField
                        key={v}
                        label={tripTypeOptions[v]}
                        value={v}
                        checked={item.tripType === v}
                        onChange={value => this.handleChangeTripType(item, value as TripType)}
                        id={v}
                      />
                    ))}
                </TripTypeOptions>
              </div>
              <div className={`${baseClass}__group`}>
                <div className="order-options">
                  {Object.keys(orderOptions).map(v => (
                    <RadioField
                      key={`order-${v}`}
                      label={orderOptions[v]}
                      value={v}
                      checked={calcPriceOrder === v}
                      onChange={value => this.handleChangeCalcPriceOrder(value as CalcPriceOrder)}
                      id={`order-${v}`}
                    />
                  ))}
                </div>
              </div>
            </EkispertInputWrapper>
            <ChildInputWrapper className={`${baseClass}__group__half last`}>
              <InputSmall>利用鉄道名</InputSmall>
              <TheInput
                value={item.railwayName}
                onChange={e => item.setRailwayName(e.target.value)}
                size="small"
                type="text"
                style={{ width: '100%' }}
                inputProps={{
                  placeholder: '利用鉄道名',
                  style: { fontSize: 14 }
                }}
              />
            </ChildInputWrapper>
          </EkispertSection>
        ) : null}
        {item.expensesTypeIsTaxi() && (
          <div className={`${baseClass}__group`}>
            <div className="trip-type-options first">
              {Object.keys(tripTypeOptions!)
                .reverse()
                .filter(v => v !== 'other')
                .map(v => (
                  <RadioField
                    key={v}
                    label={tripTypeOptions[v]}
                    value={v}
                    checked={item.tripType === v}
                    onChange={value => this.handleChangeTripType(item, value as TripType)}
                    id={v}
                  />
                ))}
            </div>
          </div>
        )}
      </div>
    );
  }

  private errors() {
    const errors = this.props.errors;
    if (!errors) {
      return null;
    }

    const toElm = (line: string) => {
      let error = line;
      if (this.props.item.category === 'travel_expenses') error = line.replace('支払先', '訪問先');
      return <p key={error}>{error}</p>;
    };
    const elm = typeof errors === 'string' ? errors.split('\n').map(toElm) : errors.map(toElm);

    return <div className="expenses-report-item-form__errors">{elm}</div>;
  }

  render() {
    const { item, projects, submitting, isAdmin, hideStatus, useProject } = this.props;
    const deleteFilteredExpensesType = Form.deleteFilteredExpensesType(this.props.expensesTypes, item);
    const defaultTaxType = this.props.taxTypes?.find(t => t.id === item.expensesType.taxTypeId);
    const className = this.props.edit ? 'editPage' : '';
    return (
      <TheForm className="expenses-report-item-form">
        <div className={`${baseClass}__group`}>
          {!hideStatus && (
            <Section className={`${baseClass}__group__half first`}>
              <StatusLabelWrapper>
                <StatusLabel report={item.report} />
              </StatusLabelWrapper>
              {item.report?.isRejected() && <RejectedReason>差戻理由：{item.report?.rejectReason}</RejectedReason>}
            </Section>
          )}
        </div>
        <div className={`${baseClass}__group`}>
          <Section className={`${baseClass}__group__half first`}>
            <Title>日付</Title>
            <DatePickerForSearch
              value={item.paidAt.format('YYYY/MM/DD')}
              onChange={this.handleChangeTravelExpensesPaidAt}
            />
          </Section>
        </div>
        <div className={`${baseClass}__group`}>
          <Section className={`${baseClass}__group__half first`}>
            <Section className="form-elements-select-field">
              <Title>経費科目</Title>
              <SelectorWrapper className="form-elements-select-field__selector">
                <FilterableSelector
                  placeholder="検索"
                  options={deleteFilteredExpensesType.map(type => {
                    return { label: type.nameText(), value: type.id.toString(), matcher: type.nameText() };
                  })}
                  selected={this.selectedExpenseOption()}
                  onSelect={selected => this.handleExpenseSelect(selected ? parseInt(selected.value, 10) : null)}
                />
              </SelectorWrapper>
            </Section>
          </Section>
        </div>
        {item.category === 'general_expenses' || item.expensesType.isReception()
          ? this.generalExpensesInputs()
          : this.travelExpensesInputs()}
        <div className={`${baseClass}__group`}>
          <Section className={`${baseClass}__group__half first`}>
            <Title>金額</Title>
            <TheInput
              value={item.price || item.price === 0 ? `${item.price}` : ''}
              onChange={e => this.handleChangePrice(e.target.value, item)}
              size="small"
              type="number"
              inputProps={{
                placeholder: '980',
                style: { fontSize: 14 }
              }}
            />
            <InputSmall>円</InputSmall>
          </Section>
          {this.state.priceError ? (
            <div className={`${baseClass}__errors`}> 金額は0～1,000,000まで入力可能です。 </div>
          ) : null}
        </div>
        <div className={`${baseClass}__group`}>
          <Section className={`${baseClass}__group__half first`}>
            <Title>税区分</Title>
            <SelectWrapper className="form-elements-select-field__selector">
              <Select
                value={item.taxTypeId || ''}
                onChange={e => item.setTaxTypeId(parseInt(e.target.value, 10) || null)}
              >
                {defaultTaxType && (
                  <option value="">
                    {defaultTaxType.name} [{item.expensesType.name}の設定]
                  </option>
                )}
                {this.props.taxTypes?.map(taxType => (
                  <option key={taxType.id} value={taxType.id}>
                    {taxType.name}
                  </option>
                ))}
              </Select>
            </SelectWrapper>
          </Section>
        </div>
        <div className={`${baseClass}__group`}>
          <Section className={`${baseClass}__group__half first`}>
            <Title>{item.category === 'general_expenses' ? '支払先' : '訪問先'}</Title>
            <TheInput
              value={item.payee}
              onChange={e => item.setPayee(e.target.value)}
              size="small"
              type="text"
              style={{ width: '100%' }}
              inputProps={{
                placeholder:
                  item.category === 'general_expenses' ? 'ビズトラレストラン' : '株式会社トランスファーデータ',
                style: { fontSize: 14 }
              }}
            />
          </Section>
        </div>
        {useProject && projects && projects.length > 0 && (
          <div className={`${baseClass}__group`}>
            <div className={`${baseClass}__group__half first`}>
              <Section className="form-elements-select-field">
                <Title>プロジェクト</Title>
                <div className="form-elements-select-field__selector">
                  <FilterableSelector
                    placeholder="検索"
                    options={projects.map(project => ({
                      label: project.codeAndName(),
                      value: project.id.toString(),
                      matcher: project.codeAndName()
                    }))}
                    selected={this.selectedProjectOption()}
                    onSelect={selected => this.handleProjectSelect(selected ? parseInt(selected.value, 10) : null)}
                  />
                </div>
              </Section>
            </div>
          </div>
        )}
        {item.tripReport && (
          <div className={`${baseClass}__group`}>
            <div className={`${baseClass}__group__half first`}>
              <Section className="form-elements-select-field">
                <Title>出張報告</Title>
                <LinkLabel href={`/trip_report/${item.tripReport.id}`} isExternal>
                  {item.tripReport.name}
                </LinkLabel>
              </Section>
            </div>
          </div>
        )}
        <div className={`${baseClass}__group`}>
          <Section className={`${baseClass}__group__half first`}>
            <Title>備考</Title>
            <StyledTextarea
              value={item.memo}
              onChange={e => item.setMemo(e.target.value)}
              size="small"
              rows="6"
              fullWidth
              placeholder="経路や使途などの詳細を入力"
            />
          </Section>
        </div>
        <div>{this.renderReceiptDropzone()}</div>
        {this.errors()}
        <ButtonArea>
          {this.props.edit ? (
            <>
              {this.props.onDelete && (
                <DeleteButton
                  onClick={e => this.handleDelete(e)}
                  disabled={
                    submitting ||
                    (item.report &&
                      !isAdmin &&
                      (item.report.status === 'approved' || item.report.status === 'completed'))
                  }
                >
                  削除
                </DeleteButton>
              )}
              <PrimarySubmitButton
                className={`${className}`}
                onClick={e => this.handleSubmit(e)}
                disabled={
                  submitting ||
                  (item.report &&
                    !isAdmin &&
                    (item.report.status === 'approved' || item.report.status === 'completed'))
                }
              >
                保存
              </PrimarySubmitButton>
            </>
          ) : (
            <PrimarySubmitButton
              className={`${className}`}
              onClick={e => this.handleSubmit(e)}
              disabled={submitting}
            >
              保存
            </PrimarySubmitButton>
          )}
        </ButtonArea>
        {item.report && !isAdmin && (item.report.status === 'approved' || item.report.status === 'completed') && (
          <Error className={`${baseClass}__errors`}>承認済み、完了ステータスの経費は変更できません</Error>
        )}
      </TheForm>
    );
  }
}

const blackTxtColor = '#323232';

const primaryBtnColor = '#1d7c2d';

const primaryTxtColor = '#FFFFFF';

const outlineBtnColor = '#FFFFFF';

const baseColor = '#927230';

const deleteBtnColor = '#faf9f8';

const deleteTxtColor = '#c72f62';

const TheForm = styled.form`
  width: 100%;
`;

const Section = styled.div`
  margin-bottom: 20px;
  &.form-elements-select-field {
    margin-bottom: unset;
  }
  @media screen and (max-width: 767px) {
    width: 100%;
    padding-right: unset !important;
    margin-bottom: 12px;
  }
`;

const Title = styled.div`
  margin: 5px 0;
  color: ${blackTxtColor};
  font-size: 16px;
  font-weight: bold;
`;

const LinkLabel = styled(NavLink)`
  font-size: 16px;
`;

const ChildInputWrapper = styled.div`
  display: flex;
  align-items: center;
  @media screen and (max-width: 767px) {
    &.last {
      width: 100%;
      padding-left: unset;
      margin-bottom: 12px;
    }
  }
`;

const CompanyForm = styled.div`
  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: stretch;
    gap: 8px;
    margin-top: 12px;
  }
`;

const EkispertSection = styled.div`
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
`;

const EkispertInputWrapper = styled(ChildInputWrapper)`
  &.use_ic {
    display: block;
  }
  &&& {
    .auto-completable-input {
      flex-grow: 1;
      margin-right: 50px;
    }

    input,
    input:hover,
    input:focus {
      background: #faf9f8;
      border-radius: 4px;
      border: 1px solid #f1ede5;
      font-size: 14px;
      padding: 13px 10px;
      margin-bottom: 0;
      width: 100%;
    }
  }
  @media screen and (max-width: 767px) {
    &&& .auto-completable-input {
      margin-right: unset !important;
    }
    &.first {
      width: 100%;
      padding-right: unset;
      margin-bottom: 12px;
    }
    &.last {
      width: 100%;
      padding-left: unset;
      margin-bottom: 12px;
    }
  }
`;

const UesIc = styled.div`
  @media screen and (max-width: 767px) {
    & > label {
      margin-left: unset !important;
    }
  }
`;

const TripTypeOptions = styled.div`
  @media screen and (max-width: 767px) {
    & label {
      margin-right: 10px;
    }
  }
`;

const DeleteButton = styled.button.attrs({ type: 'button' })`
  background: ${deleteBtnColor};
  color: ${deleteTxtColor};
  border: 1px solid ${deleteTxtColor};
  border-radius: 6px;
  font-size: 1rem;
  padding: 0.875rem 2.25rem;
  &&:focus,
  &&:hover {
    background: ${deleteBtnColor};
    color: ${deleteTxtColor};
  }
`;
const PrimarySubmitButton = styled.button.attrs({ type: 'submit' })`
  width: unset !important;
  background: ${primaryBtnColor};
  color: ${primaryTxtColor};
  border-radius: 6px;
  font-size: 1rem;
  padding: 0.875rem 4.625rem;
  &&:focus,
  &&:hover {
    background: ${primaryBtnColor};
  }
  &.editPage {
    padding: 0.875rem 3.375rem;
  }
`;

const OutlineButton = styled.button.attrs({ type: 'button' })`
  background: ${outlineBtnColor};
  color: ${baseColor};
  border: 1px solid ${baseColor};
  border-radius: 6px;
  font-size: 1rem;
  padding: 0.875rem 2.25rem;
  &&:focus,
  &&:hover {
    background: ${outlineBtnColor};
    color: ${baseColor};
  }
`;

const OutlineSmallButton = styled(OutlineButton)`
  font-size: 0.875rem;
  padding: 0.5rem 1.25rem;
`;

const CompanyButton = styled(OutlineSmallButton)`
  position: relative;
  padding-left: 3em;
  display: inline-block;
  &:before {
    content: '+';
    display: inline-block;
    position: absolute;
    font-size: 1.25em;
    top: 16%;
    right: 37%;
    margin-right: 1.5em;
  }
  @media screen and (max-width: 767px) {
    margin-top: 12px;
  }
`;

const SelectorWrapper = styled.div`
  word-wrap: break-word;
`;

const TheInput = styled(Input)`
  flex-grow: 1;
  padding: 10px;
  background: #faf9f8;

  .MuiOutlinedInput-notchedOutline {
    border-color: #f1ede5;
  }
`;

const StyledTextarea = styled(Textarea)`
  flex-grow: 1;
  padding: 10px;
  background-color: #faf9f8;
  border-color: #f1ede5;
  font-size: 14px;
`;

const InputSmall = styled.small`
  margin: 0 12px;
  font-size: 16px;
  line-height: 45px;
  white-space: nowrap;
`;

const ProDescription = styled.div`
  font-size: 12px;
`;

const TheLink = styled(Link)`
  color: ${baseColor};
`;

const ButtonArea = styled.div`
  display: flex;
  gap: calc(10 / 1290 * 100%);
  justify-content: flex-start;
  margin-top: calc(24 / 1290 * 100%);
`;

const Error = styled.div`
  text-align: right;
`;

const StatusLabelWrapper = styled.div``;

const RejectedReason = styled.div`
  color: #767676;
  font-size: 18px;
  word-wrap: break-word;
`;

export const SelectWrapper = styled.div`
  position: relative;
  width: 100%;

  &:before {
    content: '';
    position: absolute;
    top: 17px;
    right: 16px;
    width: 8px;
    height: 8px;
    border-right: 1px solid;
    border-bottom: 1px solid;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

export const Select = styled.select`
  appearance: none;
  background: #faf9f8;
  border-radius: 4px;
  border: 1px solid #f1ede5;
  font-size: 14px;
  padding: 13px 10px;
  margin-bottom: 0;
  width: 100%;
`;

export default Form;
