/* eslint-disable max-lines */
import _ from 'lodash';
import { observable } from 'mobx';
import type {
  PaymentGatewayType,
  PaymentMethodType,
  PaymentMethodOptions
} from '@this/domain/organization/organization2';
import moment from 'moment-timezone';
import type { ImageFile } from 'react-dropzone';

import type { ExpensesAccountTypeJson } from '@this/domain/expenses/expenses_account_type';
import { ExpensesAccountType } from '@this/domain/expenses/expenses_account_type';
import type Hotel from '@this/domain/hotel/hotel';
import type { WorkflowStyle } from '@this/domain/workflow_style';
import { toDomesticTel } from '@this/src/util';
import type ProjectList from './project/project_list';
import type ProjectShareList from './project/project_share_list';
import type Department from './department/department';
import type ChargingDepartmentShareList from './department/charging_department_share_list';
import type OrganizationAddress from './department/organization_address';
import type OrganizationBase from './organization_base/organization_base';
import TravelerList from './traveler/traveler_list';
import Traveler from './traveler/traveler';
import type User from './user/user';
import type ReservingTrip from './trip/reserving_trip';
import Setting from './setting';
import type { PackageProvider } from './select_store';
import type { OrderItemMappingArgs, OrderItemMappingMode } from './order_item_mapping';
import OrderItemMappingList from './order_item_mapping_list';
import { isOverDate, isOverLimitDateHour } from '../components/shared/checker/date_checker';
import type RentalCarLimitType from './organization/rental_car_limit_type';
import ApproveItemList from './approve_item/approve_item_list';

export interface CardJson {
  card_no: string;
  card_seq: string;
  delete_flag: string;
  expire: string;
  holder_name: string;
}

export interface RentalCarJson {
  id: number;
  search_query_id: number | undefined;
  origin: string;
  origin_address?: string;
  destination: string;
  destination_address?: string;
  departure_time: string;
  return_time: string;
  car_type: string;
  car_type_other: string;
  car_number: number | null;
}

export interface ConsignmentSale {
  name: string;
  company_name: string;
  travel_agency_number: string;
  address: string;
  postcode: string;
}

type OrderItemMappingListTarget = 'department' | 'project' | 'expenses_account_type';
export interface ApproveItemsResponse {
  approve_items: any[];
  total_page: number;
}

interface ExpensesAccountTypeResponse {
  expenses_account_types: ExpensesAccountTypeJson[];
}

interface Stage {
  stage: number;
  approvers: User[];
}

export interface PaymentTransaction {
  token?: string;
  uid: string;
}

export interface PaymentGatewayMember {
  uid: string;
  name: string;
  masked_credit_card_number: string;
}

interface Args {
  reservingTrip: ReservingTrip;
  travelers: TravelerList;
  user: User;
  searchQueryId: never;
  foreignExist: never;
  domesticExist: never;
  shinkansenAddress: never;
  shinkansenAddressee: never;
  shinkansenAddressType: 'select' | 'text' | 'organization_base_select';
  postcode: never;
  // seat?
  allTravelersNotification: never;
  notifiedUsers: Traveler[];
  internalNumber?: string;
  finalDestination?: string;
  purpose: string;
  departments: Department[];
  projects: ProjectList;
  projectId?: string;
  expensesAccountTypeId?: string;
  // rentalCarRequired?
  // departureDate?
  // returnDate?
  // departurePlace?
  // returnPlace?
  // returnPlaceName?
  chargingDepartmentShares: ChargingDepartmentShareList;
  chargingDepartment?: Department;
  projectShares: ProjectShareList;
  expensesAccountTypes: ExpensesAccountType[];
  orderItemMappingArgsList: OrderItemMappingArgs[];
  carType: [string, string][];
  rentalCarAvailable: boolean;
  rentalCars: RentalCarJson[];
  // remarks?
  // hotelFirstName?
  // hotelLastName?
  // hotelTel?

  paymentGatewayType: PaymentGatewayType | undefined;
  paymentGatewayMembers: PaymentGatewayMember[] | undefined;
  paymentTransaction: PaymentTransaction | undefined;
  paymentMethodOptions: PaymentMethodOptions | undefined;
  paymentMethodType: PaymentMethodType | undefined;

  // cardNum01?
  // cardNum02?
  // cardNum03?
  // cardNum04?
  // cardExpireMonth?
  // cardExpireYear?
  // cardName?
  // cardCode?
  roomNum: number | undefined;
  type: never;
  packageType: never;
  packageProvider?: PackageProvider | null;
  packageSearchCondition: never;
  hasMembers: boolean;
  consignmentSale: ConsignmentSale;
  loginUserId: number;
  rentalCarLimitType: RentalCarLimitType;
  nationalities: { id: number; name: string }[];
  expensesAccountTypeAvailable: boolean;
  expensesAccountTypeRequired?: boolean;
  useDefaultWorkflow: boolean;
  workflowStyle?: WorkflowStyle;
  approveItemValues?: Map<number, string>;
  approveItemValueCodes?: Map<number, string>;
}

class ReserveInfo {
  reservingTrip: ReservingTrip;

  travelers: TravelerList;

  user: User;

  searchQueryId: never;

  foreignExist: never;

  domesticExist: never;

  organizationAddress: OrganizationAddress | null;

  organizationBase: OrganizationBase | null;

  shinkansenAddress: string;

  shinkansenAddressee: string;

  shinkansenAddressType: 'select' | 'text' | 'organization_base_select';

  isSeparateShinkansenType: boolean;

  postcode: string;

  seat: 'reserve' | 'free' | 'green' | undefined;

  ticket: 'normal' | 'kyuusyuu' | 'bulk_ticket' | 'ex' | undefined;

  bedTypeId: number | undefined;

  bedTypeDescription: string | undefined;

  allTravelersNotification: boolean;

  notifiedUsers: (Traveler | undefined)[];

  internalNumber: string;

  finalDestination: string;

  purpose: string;

  chargingDepartment: Department | null;

  departments: Department[];

  chargingDepartmentShares: ChargingDepartmentShareList;

  projects: ProjectList;

  projectId: string;

  expensesAccountTypeId: string;

  projectShares: ProjectShareList;

  expensesAccountTypes: ExpensesAccountType[];

  orderItemMappingsProjectPart: OrderItemMappingList;

  orderItemMappingsDepartmentPart: OrderItemMappingList;

  orderItemMappingsExpensesPart: OrderItemMappingList;

  rentalCarAvailable: boolean;

  rentalCarRequired: 'true' | 'false';

  carTypeOptions: [string, string][];

  rentalCars: {
    departureDate: string | undefined;

    returnDate: string | undefined;

    departurePlace: string;

    returnPlace: 'current' | 'other';

    returnPlaceName: string;

    carType: string;

    carTypeOptions: [string, string][];

    carTypeOther: string;

    carNumber: number;

    remarks: string;

    firstNameKana: string;

    lastNameKana: string;

    driverTel: string;
  }[];

  rentalCarLimitType: RentalCarLimitType;

  hotels: {
    id: string | null | undefined;
    name: string | null | undefined;
    type: Hotel['type'];
    rooms:
      | {
          hotelFirstName: string;
          hotelLastName: string;
          hotelFirstNameKana?: string;
          hotelLastNameKana?: string;
          hotelTel: string;
          bedTypes: { id: number; description: string }[] | null | undefined;
          bedTypeId: number | null | undefined;
          bedTypeDescription: string | null | undefined;
        }[]
      | null
      | undefined;
    plan_name: string | null | undefined;
  }[];

  roomNum: number | undefined;

  paymentGatewayType: PaymentGatewayType | undefined;

  paymentGatewayMembers: PaymentGatewayMember[] | undefined;

  paymentTransaction: PaymentTransaction | undefined;

  paymentMethodOptions: PaymentMethodOptions | undefined;

  paymentMethodType: PaymentMethodType | undefined;

  cardNum01: '';

  cardNum02: '';

  cardNum03: '';

  cardNum04: '';

  cardExpireMonth: '';

  cardExpireYear: '';

  cardName: '';

  cardCode: '';

  setting: Setting | null;

  type: never;

  packageType: never;

  packageProvider: PackageProvider | null;

  packageSearchCondition: never;

  hasMembers: boolean;

  consignmentSale: ConsignmentSale;

  loginUserId: number;

  showProjectDetail: boolean;

  showDepartmentDetail: boolean;

  showExpensesAccountTypeDetail: boolean;

  nationalities: { id: number; name: string }[];

  approveItems: ApproveItemList;

  private approveItemsPath: string;

  @observable
  privateUse: boolean;

  expensesAccountTypeAvailable: boolean;

  expensesAccountTypeRequired: boolean;

  approveItemValues: Map<number, string>;

  approveItemValueCodes: Map<number, string>;

  approveItemFiles: Map<number, ImageFile>;

  approveItemFilesBase64: Map<number, string>;

  approveStages: Stage[];

  useDefaultWorkflow: boolean;

  workflowStyle?: WorkflowStyle;

  constructor(args: Args) {
    this.reservingTrip = args.reservingTrip;
    this.travelers = args.travelers || new TravelerList();
    this.user = args.user;
    this.searchQueryId = args.searchQueryId;
    this.foreignExist = args.foreignExist;
    this.domesticExist = args.domesticExist;
    this.organizationAddress = null;
    this.organizationBase = null;
    this.shinkansenAddress = args.shinkansenAddress || '';
    this.shinkansenAddressee = args.shinkansenAddressee || '';
    this.shinkansenAddressType = args.shinkansenAddressType || 'select';

    // 出張者が1人の時は出張者ごとの情報を入力させる
    // 複数人なら一括設定をデフォルトにする
    this.isSeparateShinkansenType = this.travelers.list.length === 1;

    this.postcode = args.postcode || '';
    if (this.reservingTrip.isShinkansenInfoRequired()) {
      this.seat = this.setInitialTargetSeat();
      this.ticket = 'normal';

      // デフォルトthis.seatと個別の出張者のshinkansenSeatTypeがズレるのを防ぐ
      // https://aitravel.slack.com/archives/C6RBR4S5P/p1687512530635179
      this.travelers.list.forEach(traveler => {
        traveler.shinkansenSeatType = this.seat;
        traveler.shinkansenTicketType = this.ticket;
      });
    }
    this.allTravelersNotification = args.allTravelersNotification != null ? args.allTravelersNotification : true;
    this.notifiedUsers = args.notifiedUsers;
    this.internalNumber = args.internalNumber || '';
    this.finalDestination = args.finalDestination || '';
    this.purpose = args.purpose || '';
    this.departments = args.departments || '';
    this.chargingDepartmentShares = args.chargingDepartmentShares || '';
    this.projects = args.projects || '';
    this.projectShares = args.projectShares || '';
    this.chargingDepartment = args.chargingDepartment || null;
    this.projectId = args.projectId || '';
    this.expensesAccountTypeId = args.expensesAccountTypeId || '';
    this.expensesAccountTypes = args.expensesAccountTypes;
    this.orderItemMappingsProjectPart = OrderItemMappingList.defaultList();
    this.orderItemMappingsDepartmentPart = OrderItemMappingList.defaultList();
    this.orderItemMappingsExpensesPart = OrderItemMappingList.defaultList();
    this.rentalCarAvailable = args.rentalCarAvailable;
    this.rentalCarRequired = args.rentalCars.length > 0 ? 'true' : 'false';
    this.rentalCarLimitType = args.rentalCarLimitType;
    this.carTypeOptions = args.carType || '';
    this.rentalCars =
      args.rentalCars && args.rentalCars.length > 0
        ? args.rentalCars.map((car: RentalCarJson) => ({
            departureDate: car.departure_time,
            returnDate: car.return_time,
            departurePlace: car.origin_address ? `${car.origin}(${car.origin_address})` : car.origin,
            returnPlace: car.origin === car.destination ? 'current' : 'other',
            returnPlaceName:
              car.origin === car.destination
                ? ''
                : car.destination_address
                ? `${car.destination}(${car.destination_address})`
                : car.destination,
            carType: car.car_type || 'compact',
            carTypeOptions: args.carType || '',
            carTypeOther: car.car_type_other || '',
            carNumber: car.car_number || 1,
            remarks: '',
            firstNameKana: args.travelers.list[0].firstNameKana || '',
            lastNameKana: args.travelers.list[0].lastNameKana || '',
            driverTel: args.travelers.list[0].tel || ''
          }))
        : [
            {
              departureDate: this.reservingTrip.rentalCarStartDate(),
              returnDate: this.reservingTrip.rentalCarEndDate(),
              departurePlace: '',
              returnPlace: 'current',
              returnPlaceName: '',
              carType: 'compact',
              carTypeOptions: args.carType || '',
              carTypeOther: '',
              carNumber: 1,
              remarks: '',
              firstNameKana: args.travelers.list[0].firstNameKana || '',
              lastNameKana: args.travelers.list[0].lastNameKana || '',
              driverTel: args.travelers.list[0].tel || ''
            }
          ];
    if (args.roomNum) {
      this.hotels = args.reservingTrip.hotelRooms(this.travelers, args.roomNum);
    } else {
      this.hotels = [];
    }
    this.roomNum = args.roomNum;
    this.paymentGatewayType = args.paymentGatewayType || undefined;
    this.paymentGatewayMembers = args.paymentGatewayMembers || undefined;
    this.paymentTransaction = args.paymentTransaction || undefined;
    this.paymentMethodOptions = args.paymentMethodOptions || undefined;
    this.paymentMethodType = args.paymentMethodType || undefined;
    this.cardNum01 = ''; // args.cardNum01 || '';
    this.cardNum02 = ''; // args.cardNum02 || '';
    this.cardNum03 = ''; // args.cardNum03 || '';
    this.cardNum04 = ''; // args.cardNum04 || '';
    this.cardExpireMonth = ''; // args.cardExpireMonth || '';
    this.cardExpireYear = ''; // args.cardExpireYear || '';
    this.cardName = ''; // args.cardName || '';
    this.cardCode = ''; // args.cardCode || '';

    this.setting = this.getSetting();
    this.type = args.type;
    this.packageType = args.packageType;
    this.packageProvider = args.packageProvider || null;
    this.packageSearchCondition = args.packageSearchCondition;
    this.hasMembers = args.hasMembers;
    this.consignmentSale = args.consignmentSale;
    this.loginUserId = args.loginUserId;
    this.privateUse = false;
    this.showProjectDetail = false;
    this.showDepartmentDetail = false;
    this.showExpensesAccountTypeDetail = false;
    this.nationalities = args.nationalities;
    this.expensesAccountTypeAvailable = args.expensesAccountTypeAvailable;
    this.expensesAccountTypeRequired = args.expensesAccountTypeRequired ? args.expensesAccountTypeRequired : false;
    this.approveItems = new ApproveItemList([]);
    this.approveItemsPath = '/organization/approve_items/display';

    this.approveItemValues = args.approveItemValues ? args.approveItemValues : new Map<number, string>();
    this.approveItemValueCodes = args.approveItemValueCodes
      ? args.approveItemValueCodes
      : new Map<number, string>();
    this.approveStages = [];
    this.approveItemFiles = new Map<number, ImageFile>();
    this.approveItemFilesBase64 = new Map<number, string>();
    this.useDefaultWorkflow = args.useDefaultWorkflow;
    this.workflowStyle = args.workflowStyle;
  }

  getApplicant() {
    const applicants = this.travelers.list.filter(t => t.department_approvers);
    let applicant = _.first(applicants);
    if (!applicant) {
      applicant = new Traveler(this.user);
    }
    return applicant;
  }

  getSetting() {
    const raw = this.user.organization.setting;
    if (raw) {
      return new Setting(raw);
    }

    return null;
  }

  getProject() {
    return this.projects.find(this.projectId);
  }

  getProjectOption() {
    const p = this.getProject();
    if (!p) {
      return null;
    }
    return { label: p.codeAndName(), value: p.id.toString(), matcher: p.codeAndName() };
  }

  getNationalityName(nationalityId: number) {
    const nationality = this.nationalities.find(nationality => nationality.id === nationalityId);
    return nationality ? nationality.name : '';
  }

  getFirstExistAccountTraveler() {
    const accountTraveler = this.travelers.getFirstExistAccountTraveler();
    if (accountTraveler) {
      return accountTraveler;
    }
    return this.user;
  }

  prepareDepartmentDetailOption() {
    const departmentsOption: any[] = this.departments.map(d => ({
      label: d.descriptionWithCode(),
      value: `department-${d.id.toString()}`,
      matcher: d.descriptionWithCode()
    }));
    const sharesOption: any[] = this.chargingDepartmentShares.list.map(s => ({
      labelLines: s.codeAndNameLines(),
      value: `share-${s.id.toString()}`,
      matcher: s.codeAndNameOneLine()
    }));
    return departmentsOption.concat(sharesOption);
  }

  prepareProjectDetailOption() {
    const projectsOption: any[] = this.projects.list.map(p => ({
      label: p.codeAndName(),
      value: `project-${p.id.toString()}`,
      matcher: p.codeAndName()
    }));
    const sharesOption: any[] = this.projectShares.list.map(s => ({
      labelLines: s.codeAndNameLines(),
      value: `share-${s.id.toString()}`,
      matcher: s.codeAndNameOneLine()
    }));
    return projectsOption.concat(sharesOption);
  }

  expensesAccountTypeOptions() {
    const options: any[] = this.expensesAccountTypes.map(e => {
      let label = '';
      if (e.code !== null) {
        label += `${e.code}：`;
      }
      label += e.name;
      return {
        label,
        value: `expenses-${e.id!.toString()}`,
        matcher: label
      };
    });
    return options;
  }

  isApprovalRequired() {
    const applicant = this.getApplicant();
    if (applicant) {
      return applicant.approvalRequired(this.foreignExist);
    }

    return undefined;
  }

  isRentalCarRequired() {
    return this.rentalCarRequired === 'true' ? true : undefined;
  }

  isInternalNumberShown() {
    const { setting } = this;
    if (!setting) return true;

    return setting.internalNumber !== 'hidden';
  }

  isProjectNameShown() {
    if (!(this.projects && this.projects.list.length > 0)) return false;

    const { setting } = this;
    if (!setting) return true;

    return setting.projectName !== 'hidden';
  }

  isChargingDepartmentShown() {
    if (!(this.departments && this.departments.length > 0)) return false;

    const { setting } = this;
    if (!setting) return true;

    return setting.chargingDepartment !== 'hidden';
  }

  isExpensesAcountTypeShown() {
    const { setting } = this;
    if (!setting) return true;

    return setting.expensesAccountType !== 'hidden';
  }

  isPrivateUseShown() {
    const { setting } = this;
    if (!setting) return false;

    return setting.allowPrivateUse;
  }

  organizationError() {
    const applicant = this.getApplicant();
    if (applicant) {
      return applicant.inAdvanceOrganizationError(this.foreignExist);
    }

    return undefined;
  }

  joinCardNumber() {
    return [this.cardNum01, this.cardNum02, this.cardNum03, this.cardNum04].join('');
  }

  selectedCardName() {
    const member = _.find(this.paymentGatewayMembers, c => c.uid === this.paymentTransaction?.uid);
    if (member) {
      return member.name;
    }

    return undefined;
  }

  isTargetSeatType(seatType: 'free' | 'reserve' | 'green') {
    if (!this.reservingTrip.isUnreservedSeatAvailable) {
      if (seatType === 'free') return false;
      if (seatType === 'reserve') return true;
    }

    const travelerGrades: any[] = this.travelers.list.map(t => Number(t.grade_id));
    /* ユーザーの役職がない場合はtrueを返す */
    if (!travelerGrades.filter(Boolean).length) {
      return true;
    }
    const grades = this.user.organization.grades?.filter(g => travelerGrades.includes(g.id));
    const targetGrades = grades?.filter(g => g.shinkansen_seat_type?.split(',').includes(seatType));
    if (targetGrades?.length) {
      return true;
    }
    return false;
  }

  setInitialTargetSeat() {
    const SeatTypes = ['free', 'reserve', 'green'];
    const travelerGrades: any[] = this.travelers.list.map(t => Number(t.grade_id));
    const grades = this.user.organization.grades?.filter(g => travelerGrades.includes(g.id));
    let targetSeatTypes: any[] | undefined = grades?.map(g => g.shinkansen_seat_type?.split(',')).flat();
    targetSeatTypes = targetSeatTypes?.filter(seatType => SeatTypes.includes(seatType));

    if (targetSeatTypes && targetSeatTypes.length) {
      if (targetSeatTypes.includes('reserve')) {
        return 'reserve';
      }
      if (targetSeatTypes.includes('free')) {
        return this.reservingTrip.isUnreservedSeatAvailable ? 'free' : 'reserve';
      }
      return 'green';
    }
    if (this.reservingTrip.isUnreservedSeatAvailable) return 'free';
    return undefined;
  }

  rentalCarsParam() {
    return _.map(this.rentalCars, rentalCar => ({
      departure_date: rentalCar.departureDate,
      departure_place: rentalCar.departurePlace,
      return_date: rentalCar.returnDate,
      return_place: ReserveInfo.returnPlaceParam(rentalCar),
      car_type: rentalCar.carType,
      car_type_other: ReserveInfo.carTypeOtherParam(rentalCar),
      car_number: rentalCar.carNumber,
      remarks: rentalCar.remarks,
      first_name_kana: rentalCar.firstNameKana,
      last_name_kana: rentalCar.lastNameKana,
      driver_tel: rentalCar.driverTel
    }));
  }

  departureDateParam() {
    return _.map(this.rentalCars, rentalCar => rentalCar.departureDate);
  }

  departurePlaceParam() {
    return _.map(this.rentalCars, rentalCar => rentalCar.departurePlace);
  }

  returnDateParam() {
    return _.map(this.rentalCars, rentalCar => rentalCar.returnDate);
  }

  getChargingDepartmentIDs() {
    const ids: number[] = [];
    this.orderItemMappingsDepartmentPart.list.forEach(mapping => {
      if (mapping.chargingDepartmentId !== null) {
        ids.push(mapping.chargingDepartmentId);
      }
    });
    return ids;
  }

  static returnPlaceParam(rentalCar: {
    departureDate: string | undefined;
    returnDate: string | undefined;
    departurePlace: string;
    returnPlace: 'current' | 'other';
    returnPlaceName: string;
    carType: string;
    carTypeOptions: [string, string][];
    carTypeOther: string;
    remarks: string;
    firstNameKana: string;
    lastNameKana: string;
  }) {
    if (rentalCar.returnPlace === 'current') {
      return rentalCar.departurePlace;
    }
    return rentalCar.returnPlaceName;
  }

  static carTypeOtherParam(rentalCar: {
    departureDate: string | undefined;
    returnDate: string | undefined;
    departurePlace: string;
    returnPlace: 'current' | 'other';
    returnPlaceName: string;
    carType: string;
    carTypeOptions: [string, string][];
    carTypeOther: string;
    remarks: string;
    firstNameKana: string;
    lastNameKana: string;
  }) {
    if (rentalCar.carType === 'other') {
      return rentalCar.carTypeOther;
    }
    return '';
  }

  seatText() {
    switch (this.seat) {
      case 'free':
        return '自由席';
      case 'reserve':
        return '指定席';
      case 'green':
        return 'グリーン車';
      default:
        return undefined;
    }
  }

  ticketText() {
    switch (this.ticket) {
      case 'normal':
        return '通常きっぷ';
      case 'kyuusyuu':
        return 'JR九州';
      case 'bulk_ticket':
        return '回数券';
      case 'ex':
        return 'EX-IC';
      default:
        return undefined;
    }
  }

  setApproveItemValue(id: number, value: string) {
    this.approveItemValues.set(id, value);
    app.render();
  }

  getApproveItemValue(id: number) {
    return this.approveItemValues.get(id);
  }

  setApproveItemValueCode(id: number, value: string) {
    this.approveItemValueCodes.set(id, value);
    app.render();
  }

  getApproveItemValueCode(id: number) {
    return this.approveItemValueCodes.get(id);
  }

  async setApproveItemFile(id: number, file: ImageFile) {
    this.approveItemFiles.set(id, file);
    this.approveItemValues.set(id, file.name);
    this.approveItemFilesBase64.set(id, await this.convertFileToBase64(file));
    app.render();
  }

  async convertFileToBase64(file: ImageFile) {
    if (file) {
      const result = await new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
          resolve(fileReader.result as string);
        };
        fileReader.onerror = error => {
          reject(error);
        };
      });
      return result as string;
    }
    return '';
  }

  getApproveItemFile(id: number) {
    return this.approveItemFiles.get(id);
  }

  removeApproveItemFile(id: number) {
    this.approveItemFiles.delete(id);
    this.approveItemValues.delete(id);
    this.approveItemFilesBase64.delete(id);
    app.render();
  }

  setApproveStages(stages: Stage[]) {
    this.approveStages = stages;
  }

  getApproveItemFileBase64(id: number) {
    return this.approveItemFilesBase64.get(id);
  }

  getApproveItemFileType(id: number) {
    const file = this.getApproveItemFile(id);
    if (file) {
      return file.type;
    }
    return '';
  }

  private generateApproveItemParams() {
    const data: any[] = [];
    this.approveItems.list.forEach(r => {
      data.push({
        id: r.id,
        userDisplayName: r.userDisplayName,
        dataType: r.dataType,
        display: r.display,
        requiredType: r.requiredType,
        placeholder: r.placeholder,
        value: this.approveItemValues.get(r.id),
        valueCode: this.approveItemValueCodes.get(r.id),
        file: this.getApproveItemFileBase64(r.id),
        fileType: this.getApproveItemFileType(r.id)
      });
    });
    return JSON.stringify(data);
  }

  private static requiredError(name: string, value: string | undefined | null, message = 'を入力してください。') {
    // if (message == null) { message = 'を入力してください'; }
    if (!value || value.length === 0) {
      return name + message;
    }

    return undefined;
  }

  private static flightBirthdayRequiredError(name: string, value: string | undefined | null) {
    if (!value || value.length === 0) {
      return '搭乗者の誕生日が設定されておりません。 該当ユーザーでログイン後、アカウント設定画面より誕生日を設定していただくか、管理者が社員マスタより設定してください。';
    }

    return undefined;
  }

  private static surrogatePairError(name: string, value: string) {
    const surrogatePairs = value.match(/[\uD800-\uDBFF][\uDC00-\uDFFF]/g) || [];
    if (surrogatePairs.length > 0) {
      return `${name}に環境依存文字「${surrogatePairs.join('')}」が含まれています`;
    }

    return undefined;
  }

  private static postcodeError(name: string, value: string) {
    if (!value.match(/^\d{3}-?\d{4}$/)) {
      return `${name}を正しく入力してください。`;
    }

    return undefined;
  }

  private static phoneError(name: string, value: string) {
    const phoneNumber = value.replace(/-/g, '');
    if (phoneNumber.length > 11) {
      return `${name}は番号11桁以下で入力して下さい。`;
    }

    return undefined;
  }

  private static katakanaError(name: string, value: string) {
    // eslint-disable-next-line no-irregular-whitespace
    if (!value.match(/^[ァ-ヶー　]*$/)) {
      return `${name}はカタカナで入力してください。`;
    }

    return undefined;
  }

  private static alphabetError(name: string, value: string) {
    const error = ReserveInfo.requiredError(name, value);
    if (error) {
      return error;
    }

    if (!value.match(/^[a-zA-Z]+$/)) {
      return `${name}はアルファベッドで入力してください。`;
    }

    return undefined;
  }

  private static alphabetErrorAllowEmpty(name: string, value: string) {
    if (!value || value.length === 0) {
      return undefined;
    }

    if (!value.match(/^[a-zA-Z]+$/)) {
      return `${name}はアルファベッドで入力してください。`;
    }

    return undefined;
  }

  private static kanaError(name: string, value = '') {
    const error = ReserveInfo.requiredError(name, value);
    if (error) {
      return error;
    }

    if (!value.match(/^[ァ-ヶｦ-ﾟー]+$/)) {
      return `${name}はカタカナで入力してください。`;
    }

    return undefined;
  }

  private static flightBirthdayError(name: string, value: string) {
    const error = ReserveInfo.flightBirthdayRequiredError(name, value);
    if (error) {
      return error;
    }

    if (!moment(value).isValid()) {
      return `${name}を正しく入力してください。（例：1986-01-01）`;
    }

    return undefined;
  }

  private organizationAddressError(organizationAddress: OrganizationAddress | null) {
    const value = this.organizationAddress && this.organizationAddress.location;
    if (!value || value.length === 0) {
      return '選択された部署に住所が登録されていません。別の部署を選択するか、手動で入力してください。';
    }

    return undefined;
  }

  private static expiredError(name: string, value: string) {
    const error = ReserveInfo.requiredError(name, value);
    if (error) {
      return error;
    }

    if (isOverDate(value)) {
      return '有効期限が切れています。パスポートをご確認ください。内容が変更されている場合、アカウント設定から変更してください。';
    }

    return undefined;
  }

  private static requireProjectMappingError(name: string, values: any[]) {
    const result = values.find(v => _.isNil(v.project_id) && _.isNil(v.project_share_id));
    if (result) {
      return `${name}を選択して下さい`;
    }
    return undefined;
  }

  private static rquireDepartmentMappingError(name: string, values: any[]) {
    const result = values.find(v => _.isNil(v.charging_department_id) && _.isNil(v.charging_department_share_id));
    if (result) {
      return `${name}を選択して下さい`;
    }
    return undefined;
  }

  private static requireExpensesMappingError(name: string, values: any[]) {
    const result = values.find(v => _.isNil(v.expenses_account_type_id));
    if (result) {
      return `${name}を選択して下さい`;
    }
    return undefined;
  }

  limitError(name: string, value: string | undefined) {
    let actualDays = 0;
    if (!value) {
      return ReserveInfo.requiredError(name, value);
    }

    actualDays = this.rentalCarLimitType.actualDays;

    if (
      isOverLimitDateHour(
        actualDays,
        this.rentalCarLimitType.hour,
        value,
        this.rentalCarLimitType.bookingTooLate,
        this.rentalCarLimitType.todayHoliday
      )
    ) {
      return `レンタカーの手配は${this.rentalCarLimitType.description()}が依頼期限です`;
    }

    return undefined;
  }

  isPurposeBusinessTripRequired() {
    return !_.isNil(this.setting) && this.setting.purposeBusinessTrip === 'mandatory';
  }

  isDestinationBusinessTripRequired() {
    return !_.isNil(this.setting) && this.setting.destinationBusinessTrip === 'mandatory';
  }

  isInternalNumberRequired() {
    return !_.isNil(this.setting) && this.setting.internalNumber === 'mandatory';
  }

  isProjectNameRequired() {
    return (
      this.projects &&
      this.projects.list.length > 0 &&
      !_.isNil(this.setting) &&
      this.setting.projectName === 'mandatory'
    );
  }

  isChargingDepartmentRequired() {
    return (
      this.departments &&
      this.departments.length > 0 &&
      !_.isNil(this.setting) &&
      this.setting.chargingDepartment === 'mandatory'
    );
  }

  isExpensesAccountTypeRequired() {
    return !_.isNil(this.setting) && this.setting.expensesAccountType === 'mandatory';
  }

  validationErrors(serviceId: number, isSimpleRequest: boolean | null = false) {
    const errors: { [key: string]: string | undefined } = {};
    const projectMapping: any[] = this.separateOrderItemMappings(this.orderItemMappingsProjectPart);
    const departmentMapping: any[] = this.separateOrderItemMappings(this.orderItemMappingsDepartmentPart);
    const expensesMapping: any[] = this.separateOrderItemMappings(this.orderItemMappingsExpensesPart);

    if (this.reservingTrip.isHotelInfoRequired()) {
      _.map(this.hotels, hotel => {
        _.map(hotel.rooms, (room, i) => {
          errors[`lastName_${i}`] = ReserveInfo.alphabetError('宿泊者名(氏)', room.hotelLastName);
          errors[`firstName_${i}`] = ReserveInfo.alphabetError('宿泊者名(名)', room.hotelFirstName);
          errors[`lastNameKana_${i}`] = ReserveInfo.kanaError('宿泊者名(氏カナ)', room.hotelLastNameKana);
          errors[`firstNameKana_${i}`] = ReserveInfo.kanaError('宿泊者名(名カナ)', room.hotelFirstNameKana);
          errors[`tel_${i}`] = ReserveInfo.requiredError('宿泊者電話番号', toDomesticTel(room.hotelTel));
          if (!errors[`tel_${i}`]) {
            errors[`tel_${i}`] = ReserveInfo.phoneError('宿泊者電話番号', toDomesticTel(room.hotelTel));
          }
        });
      });
    }

    if (this.reservingTrip.isShinkansenInfoRequired() && this.ticket !== 'ex' && !this.user.organization.use_qr) {
      if (this.shinkansenAddressType === 'text') {
        errors.postcode = ReserveInfo.postcodeError('郵便番号', this.postcode);
        if (_.isEmpty(errors.postcode)) {
          errors.postcode = ReserveInfo.requiredError('郵便番号', this.postcode);
        }
        errors.shinkansenAddress = ReserveInfo.requiredError('切符送り先住所', this.shinkansenAddress);
        errors.shinkansenAddressee = ReserveInfo.requiredError('切符送り先宛名', this.shinkansenAddressee);
      } else if (this.shinkansenAddressType === 'select') {
        errors.organizationAddress = this.organizationAddressError(this.organizationAddress);
      }
    }

    if (!isSimpleRequest && this.isInternalNumberRequired())
      errors.internalNumber = ReserveInfo.requiredError('社内管理番号', this.internalNumber);
    if (!isSimpleRequest && this.isProjectNameRequired()) {
      if (this.showProjectDetail) {
        errors.projectId = ReserveInfo.requireProjectMappingError('プロジェクト名', projectMapping);
      } else {
        errors.projectId = ReserveInfo.requiredError('プロジェクト名', this.projectId);
      }
    }
    if (!isSimpleRequest && this.isChargingDepartmentRequired()) {
      if (this.showDepartmentDetail) {
        errors.chargingDepartment = ReserveInfo.rquireDepartmentMappingError('費用負担部署', departmentMapping);
      } else {
        errors.chargingDepartment = ReserveInfo.requiredError(
          '費用負担部署',
          this.chargingDepartment && this.chargingDepartment.id.toString()
        );
      }
    }
    Array.from(this.travelers.list).forEach((t, i) => {
      if (this.user.organization) {
        switch (t.type) {
          case 'self':
            if (!t.id) {
              errors.memberSelect = '出張者を選択してください';
            }
            break;
          case 'member':
            if (!t.id) {
              errors.memberSelect = '出張者を選択してください';
            }
            break;
          case 'companion':
            if (this.reservingTrip.isForeignFlightCustomerInfoRequired()) {
              errors.travelerFirstNameRomen = ReserveInfo.requiredError(
                '出張者の名前(ローマ字)',
                t.firstNameRoman
              );
              errors.travelerLastNameRomen = ReserveInfo.requiredError('出張者の名字(ローマ字)', t.lastNameRoman);
              errors.travelerFirstNameKana = ReserveInfo.requiredError('出張者の名前(カナ)', t.firstNameKana);
              errors.travelerLastNameKana = ReserveInfo.requiredError('出張者の名字(カナ)', t.lastNameKana);
              if (_.isEmpty(errors.travelerFirstNameRomen) && _.isEmpty(errors.travelerLastNameRomen)) {
                errors.travelerFirstNameRomen = ReserveInfo.alphabetError(
                  '出張者の名前(ローマ字)',
                  t.firstNameRoman
                );
                errors.travelerLastNameRomen = ReserveInfo.alphabetError(
                  '出張者の名字(ローマ字)',
                  t.lastNameRoman
                );
              }
              if (_.isEmpty(errors.travelerFirstNameKana) && _.isEmpty(errors.travelerLastNameKana)) {
                errors.travelerFirstNameKana = ReserveInfo.katakanaError('出張者の名前(カナ)', t.firstNameKana);
                errors.travelerLastNameKana = ReserveInfo.katakanaError('出張者の名字(カナ)', t.lastNameKana);
              }
            } else {
              errors.travelerFirstNameRomen = ReserveInfo.requiredError(
                '出張者の名前(ローマ字)',
                t.firstNameRoman
              );
              errors.travelerLastNameRomen = ReserveInfo.requiredError('出張者の名字(ローマ字)', t.lastNameRoman);
              errors.travelerFirstNameKana = ReserveInfo.requiredError('出張者の名前(カナ)', t.firstNameKana);
              errors.travelerLastNameKana = ReserveInfo.requiredError('出張者の名字(カナ)', t.lastNameKana);
              if (_.isEmpty(errors.travelerFirstNameRomen) && _.isEmpty(errors.travelerLastNameRomen)) {
                errors.travelerFirstNameRomen = ReserveInfo.alphabetError(
                  '出張者の名前(ローマ字)',
                  t.firstNameRoman
                );
                errors.travelerLastNameRomen = ReserveInfo.alphabetError(
                  '出張者の名字(ローマ字)',
                  t.lastNameRoman
                );
              }
              if (_.isEmpty(errors.travelerFirstNameKana) && _.isEmpty(errors.travelerLastNameKana)) {
                errors.travelerFirstNameKana = ReserveInfo.katakanaError('出張者の名前(カナ)', t.firstNameKana);
                errors.travelerLastNameKana = ReserveInfo.katakanaError('出張者の名字(カナ)', t.lastNameKana);
              }
              if (_.isEmpty(errors.travelerFirstNameKana) && _.isEmpty(errors.travelerLastNameKana)) {
                errors.travelerFirstName =
                  ReserveInfo.katakanaError('搭乗者氏名', t.flightLastNameKana) ||
                  ReserveInfo.katakanaError('搭乗者氏名', t.flightFirstNameKana);
              }
            }
            break;
          default:
        }
      }
      if (this.reservingTrip.isFlightCustomerInfoRequired()) {
        if (this.reservingTrip.isForeignFlightCustomerInfoRequired()) {
          errors[`flightCustomerName_${i}`] =
            ReserveInfo.alphabetError('搭乗者氏名', t.flightLastNameRoman) ||
            ReserveInfo.alphabetError('搭乗者氏名', t.flightFirstNameRoman) ||
            ReserveInfo.alphabetErrorAllowEmpty('搭乗者氏名', t.flightMiddleNameRoman);
        } else {
          errors[`flightCustomerName_${i}`] =
            ReserveInfo.requiredError('搭乗者氏名', t.flightLastNameKana) ||
            ReserveInfo.requiredError('搭乗者氏名', t.flightFirstNameKana);
          if (_.isEmpty(errors[`flightCustomerName_${i}`])) {
            errors[`flightCustomerName_${i}`] =
              ReserveInfo.katakanaError('搭乗者氏名', t.flightLastNameKana) ||
              ReserveInfo.katakanaError('搭乗者氏名', t.flightFirstNameKana);
          }
          errors[`flightCustomerNameRoman_${i}`] =
            ReserveInfo.requiredError('搭乗者氏名(アルファベット)', t.flightLastNameRoman) ||
            ReserveInfo.requiredError('搭乗者氏名(アルファベット)', t.flightFirstNameRoman);
          if (_.isEmpty(errors[`flightCustomerName_${i}`])) {
            errors[`flightCustomerNameRoman_${i}`] =
              ReserveInfo.alphabetError('搭乗者氏名(アルファベット)', t.flightLastNameRoman) ||
              ReserveInfo.alphabetError('搭乗者氏名(アルファベット)', t.flightFirstNameRoman) ||
              ReserveInfo.alphabetErrorAllowEmpty('搭乗者氏名(アルファベット)', t.flightMiddleNameRoman);
          }
        }

        if (t.type === 'companion') {
          errors[`travelerBirthday_${i}`] = ReserveInfo.flightBirthdayError('出張者の誕生日', t.flightBirthday);
        }
        errors[`flightCustomerBirthday_${i}`] = ReserveInfo.flightBirthdayError(
          '搭乗者の誕生日',
          t.flightBirthday
        );
        errors[`flightCustomerTel_${i}`] = ReserveInfo.requiredError(
          '搭乗者の緊急連絡先',
          toDomesticTel(t.flightTel)
        );
        if (this.reservingTrip.isForeignFlightCustomerInfoRequired()) {
          errors[`passportNumber_${i}`] = ReserveInfo.requiredError('パスポート番号', t.passportNumber);
          errors[`passportExpire_${i}`] = ReserveInfo.requiredError('パスポート有効期限', t.passportExpire);
          if (_.isEmpty(errors[`passportExpire_${i}`])) {
            errors[`passportExpire_${i}`] = ReserveInfo.expiredError('パスポート有効期限', t.passportExpire);
          }
          errors[`passportIssuedCoutry_${i}`] = ReserveInfo.requiredError(
            'パスポート発行国',
            t.passportIssuedCoutry
          );
        }
        if (
          !this.reservingTrip.isPackage() &&
          this.travelers.list.filter(traveler => traveler.type === 'self').length > 1
        ) {
          errors.selfSelection = '航空券が含まれる場合、「自分の手配をする」は1人のみ選択可能です。';
        }
      }
      if (this.reservingTrip.isFlightCustomerInfoRequired()) {
        errors[`flightCustomerGender_${i}`] = ReserveInfo.requiredError(
          '性別',
          t.flightGender,
          'を選択してください'
        );
      }
    });

    if (this.isRentalCarRequired()) {
      _.map(this.rentalCars, (rentalCar, j) => {
        errors[`departureDate_${j}`] = this.limitError('出発時間', rentalCar!.departureDate);
        if (_.isEmpty(rentalCar.departurePlace)) {
          errors[`departurePlace_${j}`] = ReserveInfo.requiredError('出発場所', rentalCar.departurePlace);
        }
        if (rentalCar.returnPlace !== 'current' && _.isEmpty(rentalCar.returnPlaceName)) {
          errors[`returnPlace_${j}`] = ReserveInfo.requiredError('返却場所', rentalCar.returnPlaceName);
        }
        if (rentalCar.carType === 'other' && _.isEmpty(rentalCar.carTypeOther)) {
          errors[`carType_${j}`] = ReserveInfo.requiredError('車種・クラス', rentalCar.carTypeOther);
        }
        errors[`rcLastNameKana_${j}`] = ReserveInfo.requiredError('利用者氏名（カナ）', rentalCar.lastNameKana);
        errors[`rcFirstNameKana_${j}`] = ReserveInfo.requiredError('利用者氏名（カナ）', rentalCar.firstNameKana);
        if (_.isEmpty(errors[`rcLastNameKana_${j}`]) && _.isEmpty(errors[`rcFirstNameKana_${j}`])) {
          errors[`rcLastNameKana_${j}`] = ReserveInfo.katakanaError('利用者氏名（カナ）', rentalCar.lastNameKana);
          errors[`rcFirstNameKana_${j}`] = ReserveInfo.katakanaError(
            '利用者氏名（カナ）',
            rentalCar.firstNameKana
          );
        }
        errors[`rcTel_${j}`] = ReserveInfo.requiredError('運転者電話番号', toDomesticTel(rentalCar.driverTel));
      });
    }

    if (this.isApprovalRequired() || this.isDestinationBusinessTripRequired()) {
      errors.area = ReserveInfo.requiredError('出張先', this.finalDestination);
      if (!errors.area) {
        errors.area = ReserveInfo.surrogatePairError('出張先', this.finalDestination);
      }
    }
    if (this.isApprovalRequired() || this.isPurposeBusinessTripRequired()) {
      errors.purpose = ReserveInfo.requiredError('出張の目的', this.purpose);
      if (!errors.purpose) {
        errors.purpose = ReserveInfo.surrogatePairError('出張の目的', this.purpose);
      }
    }
    if (!this.user.organization.freee_workflow && this.isApprovalRequired() && this.workflowStyle === 'trip') {
      if (this.approveStages.length === 0) {
        errors.approveStages = 'ワークフロールートに空欄があります。承認者を登録してください。';
      }
      _.each(this.approveStages, stage => {
        _.each(stage.approvers, approver => {
          if (_.isNil(approver)) {
            errors.approveStages = 'ワークフロールートに空欄があります。承認者を登録してください。';
          }
        });
      });
    }

    if (
      this.user.organization.reserve_request_mail_notification_type === 'selectable_user' &&
      (_.isNil(this.notifiedUsers) || this.notifiedUsers?.length === 0)
    ) {
      errors.notifiedUsers = '出張内容を確認するユーザを1名以上設定してください。';
    }

    if (this.expensesAccountTypeAvailable && this.isExpensesAccountTypeRequired()) {
      errors.expensesAccountType = ReserveInfo.requireExpensesMappingError('勘定科目', expensesMapping);
    }

    this.approveItems.list.forEach(r => {
      if (
        (r.requiredType !== 'optional' && r.requiredType !== 'required_with_workflow' && r.requiredType !== '') ||
        this.isApproveItemRequiredWithWorkflow(r.requiredType) === true
      ) {
        errors[`appleveItem_${r.id}`] = ReserveInfo.requiredError(
          r.userDisplayName,
          this.approveItemValues.get(r.id)
        );
      }
    });
    errors.card = this.cardError();
    errors.organization = this.organizationErrors();

    return utils.compactObject(errors);
  }

  isApproveItemRequiredWithWorkflow(requiredType: string) {
    if (requiredType !== 'required_with_workflow') {
      return false;
    }
    if (this.isApprovalRequired()) {
      return true;
    }
    if (this.user.departmentId() === null || this.user.departmentId() === '') {
      return true;
    }
    return false;
  }

  private cardError() {
    const errors = [];
    if (this.paymentMethodType === 'card' && this.paymentTransaction?.uid === 'new') {
      if (this.cardNum01 === '' || this.cardNum02 === '' || this.cardNum03 === '' || this.cardNum04 === '') {
        errors.push('カード番号を正しく入力してください。');
      }
      if (this.cardExpireMonth === '' || this.cardExpireYear === '') {
        errors.push('有効期限を正しく入力してください。');
      }
      if (this.cardName === '') {
        errors.push('名義人の名前を入力してください。');
      }
      if (this.cardCode === '') {
        errors.push('セキュリティコードを入力してください。');
      }
    }
    if (errors.length > 0) {
      return errors.join('\n');
    }

    return undefined;
  }

  private organizationErrors() {
    if (
      this.isApprovalRequired() &&
      this.useDefaultWorkflow &&
      this.organizationError() &&
      this.workflowStyle !== 'trip'
    ) {
      const errors: string[] = [];
      errors.push('出張者の所属部署またはその承認者が設定されていません。管理者に連絡してください。');
      errors.push(`■${this.user.organization.name}の管理者`);
      _.each(this.user.organization.admins, (u, i) => errors.push(`${u.name}: '${u.email}`));
      return errors.join('\n');
    }

    return undefined;
  }

  getNewCardParams() {
    return {
      cardno: this.joinCardNumber(),
      expire: `${this.cardExpireYear}${this.cardExpireMonth}`,
      expireYear: this.cardExpireYear,
      expireMonth: this.cardExpireMonth,
      securitycode: this.cardCode,
      holdername: this.cardName
    };
  }

  getHotelsParam() {
    return _.map(this.hotels, hotel => ({
      id: hotel.id,
      rooms: _.map(hotel.rooms, room => ({
        guest_first_name: room.hotelFirstName,
        guest_last_name: room.hotelLastName,
        guest_first_name_kana: room.hotelFirstNameKana,
        guest_last_name_kana: room.hotelLastNameKana,
        guest_tel: room.hotelTel,
        bed_type: room.bedTypeId,
        bed_type_description: room.bedTypeDescription,
        plan_name: hotel.plan_name
      }))
    }));
  }

  submitParams() {
    const params: { [key: string]: any } = {
      shinkansen_address:
        this.shinkansenAddressType === 'text'
          ? this.shinkansenAddress
          : this.shinkansenAddressType === 'organization_base_select'
          ? this.organizationBase && this.organizationBase.address
          : this.organizationAddress && this.organizationAddress.location,
      shinkansen_addressee:
        this.shinkansenAddressType === 'text'
          ? this.shinkansenAddressee
          : this.shinkansenAddressType === 'select'
          ? this.organizationAddress && this.organizationAddress.addressee
          : this.shinkansenAddressType === 'organization_base_select'
          ? this.organizationBase && this.organizationBase.addressee
          : '',
      shinkansen_address_type: this.shinkansenAddressType,
      postcode:
        this.shinkansenAddressType === 'text'
          ? this.handlePostcodeFormat()
          : this.shinkansenAddressType === 'select'
          ? this.organizationAddress && this.organizationAddress.postalCode
          : this.shinkansenAddressType === 'organization_base_select'
          ? this.organizationBase && this.organizationBase.postalCode
          : '',
      organization_address_id: this.organizationAddress && this.organizationAddress.id,
      travelers: this.travelers.getTravelersParam(this.reservingTrip),
      all_travelers_notification: this.allTravelersNotification,
      notified_user_ids: this.notifiedUsers.map(user => utils.dig(user, 'id')),
      internal_number: this.internalNumber,
      approval_required: this.isApprovalRequired(),
      final_destination: this.finalDestination,
      purpose: this.purpose,
      hotels: this.getHotelsParam(),
      applicant_id: this.getApplicant().id,
      search_query_id: this.searchQueryId,
      charging_department_id: this.chargingDepartment && this.chargingDepartment.id,
      project_id: this.projectId,
      expenses_account_type_id: this.projectId,
      order_item_mappings: this.fixOrderItemMappings(
        this.orderItemMappingsProjectPart,
        this.orderItemMappingsDepartmentPart,
        this.orderItemMappingsExpensesPart
      ),
      non_order_item_mappings: this.fixNonOrderItemMappings(
        this.orderItemMappingsProjectPart,
        this.orderItemMappingsDepartmentPart,
        this.orderItemMappingsExpensesPart
      ),
      show_department_detail: this.showDepartmentDetail,
      show_project_detail: this.showProjectDetail,
      show_expenses_account_type_detail: this.showExpensesAccountTypeDetail,
      type: this.type,
      package_type: this.packageType,
      package_search_condition: this.packageSearchCondition,
      rental_car_required: this.isRentalCarRequired(),
      rental_cars: [],
      private_use: this.privateUse,
      approve_stages: JSON.stringify(this.approveStages)
    };
    if (this.hotels && this.hotels.length > 0) {
      const hotel = this.hotels[0];
      if (hotel.rooms && hotel.rooms.length > 0) {
        const room = hotel.rooms[0];
        params.last_name = room.hotelLastName;
        params.first_name = room.hotelFirstName;
        params.tel = room.hotelTel;
      }
    }
    if (this.seat && this.ticket !== 'bulk_ticket' && !this.isSeparateShinkansenType) {
      params.sheet = this.seat;
    }
    if (this.ticket && !this.isSeparateShinkansenType) {
      params.ticket = this.ticket;
    }
    if (this.reservingTrip.items) {
      params.items = _.map(this.reservingTrip.items, item => item && item.submitParams());
    }
    if (this.rentalCarRequired === 'true') {
      params.rental_cars = this.rentalCarsParam();
    }
    params.approve_items = this.generateApproveItemParams();
    return params;
  }

  setShinkansenAddress(value: string) {
    this.shinkansenAddress = value;
    app.render();
  }

  setShinkansenAddressee(value: string) {
    this.shinkansenAddressee = value;
    app.render();
  }

  setShinkansenAddressType(value: 'select' | 'text' | 'organization_base_select') {
    this.shinkansenAddressType = value;
    app.render();
  }

  setIsSeparateShinkansenType(value: boolean) {
    this.isSeparateShinkansenType = value;
    app.render();
  }

  setPostcode(value: string) {
    this.postcode = value;
    app.render();
  }

  setOrganizationAddress(value: OrganizationAddress) {
    this.organizationAddress = value;
    app.render();
  }

  setOrganizationBase(value: OrganizationBase) {
    this.organizationBase = value;
    app.render();
  }

  setChargingDepartment(value: Department) {
    this.chargingDepartment = value;
    app.render();
  }

  setSeat(value: 'reserve' | 'free' | 'green') {
    this.seat = value;
    app.render();
  }

  setTicket(value: 'normal' | 'kyuusyuu' | 'bulk_ticket' | 'ex') {
    this.ticket = value;
    app.render();
  }

  setHotelLastName(value: string, i: number, j: number) {
    this.hotels[i].rooms![j].hotelLastName = value;
    app.render();
  }

  setHotelFirstName(value: string, i: number, j: number) {
    this.hotels[i].rooms![j].hotelFirstName = value;
    app.render();
  }

  setHotelLastNameKana(value: string, i: number, j: number) {
    this.hotels[i].rooms![j].hotelLastNameKana = value;
    app.render();
  }

  setHotelFirstNameKana(value: string, i: number, j: number) {
    this.hotels[i].rooms![j].hotelFirstNameKana = value;
    app.render();
  }

  setHotelTel(value: string, i: number, j: number) {
    this.hotels[i].rooms![j].hotelTel = value || '';
    app.render();
  }

  setBedType(value: never, i: number, j: number) {
    this.hotels[i].rooms![j].bedTypeId = value;
    this.hotels[i].rooms![j].bedTypeDescription = _.find(
      this.hotels[i].rooms![j].bedTypes,
      bedType => bedType.id === value
    )!.description;
    app.render();
  }

  toggleAllTravelersNotification(_value: never) {
    this.allTravelersNotification = !this.allTravelersNotification;
    app.render();
  }

  setNotifiedUsers(users: (Traveler | undefined)[]) {
    this.notifiedUsers = users;
    app.render();
  }

  setInternalNumber(value: never) {
    this.internalNumber = value;
    app.render();
  }

  setFinalDestination(value: never) {
    this.finalDestination = value;
    app.render();
  }

  setPurpose(value: never) {
    this.purpose = value;
    app.render();
  }

  setProjectId(value: string) {
    this.projectId = value;
    app.render();
  }

  setShowProjectDetail(value: boolean) {
    this.showProjectDetail = value;
    app.render();
  }

  setShowDepartmentDetail(value: boolean) {
    this.showDepartmentDetail = value;
    app.render();
  }

  setShowExpensesAccountTypeDetail(value: boolean) {
    this.showExpensesAccountTypeDetail = value;
    app.render();
  }

  setOrderItemMappingsDepartmentPart(orderItemMappingArgsList: OrderItemMappingArgs[]) {
    this.orderItemMappingsDepartmentPart = new OrderItemMappingList(orderItemMappingArgsList);
  }

  setOrderItemMappingsProjectPart(orderItemMappingArgsList: OrderItemMappingArgs[]) {
    this.orderItemMappingsProjectPart = new OrderItemMappingList(orderItemMappingArgsList);
  }

  setOrderItemMappingsExpensesPart(orderItemMappingArgsList: OrderItemMappingArgs[]) {
    this.orderItemMappingsExpensesPart = new OrderItemMappingList(orderItemMappingArgsList);
    app.render();
  }

  resetOrderItemMappings(target: OrderItemMappingListTarget) {
    if (target === 'department') {
      this.orderItemMappingsDepartmentPart = OrderItemMappingList.defaultList();
    } else if (target === 'project') {
      this.orderItemMappingsProjectPart = OrderItemMappingList.defaultList();
    } else if (target === 'expenses_account_type') {
      this.orderItemMappingsExpensesPart = OrderItemMappingList.defaultList();
    }
    app.render();
  }

  initOrderItemMappings(target: OrderItemMappingListTarget, mode: OrderItemMappingMode) {
    let list = new OrderItemMappingList([]);
    if (mode === 'item') {
      const rentalCarsCount = this.rentalCarRequired === 'true' ? this.rentalCars.length : 0;
      list = OrderItemMappingList.initialListForItem(this.reservingTrip, rentalCarsCount);
    } else if (mode === 'traveler') {
      list = OrderItemMappingList.initialListForTraveler(this.travelers.list.length);
    } else if (mode === 'both') {
      const rentalCarsCount = this.rentalCarRequired === 'true' ? this.rentalCars.length : 0;
      list = OrderItemMappingList.initialListForBoth(
        this.reservingTrip,
        this.travelers.list.length,
        rentalCarsCount
      );
    }

    if (target === 'department') {
      this.orderItemMappingsDepartmentPart = list;
    } else if (target === 'project') {
      this.orderItemMappingsProjectPart = list;
    } else if (target === 'expenses_account_type') {
      this.orderItemMappingsExpensesPart = list;
    }
    app.render();
  }

  private fixOrderItemMappings(
    projectMappings: OrderItemMappingList,
    departmentMappings: OrderItemMappingList,
    expensesMappings: OrderItemMappingList
  ): any[] {
    const projectPart = this.separateOrderItemMappings(projectMappings);
    const departmentPart = this.separateOrderItemMappings(departmentMappings);
    const expensesPart = this.separateOrderItemMappings(expensesMappings);
    return ReserveInfo.mergeOrderItemMappings(projectPart, departmentPart, expensesPart);
  }

  private fixNonOrderItemMappings(
    projectMappings: OrderItemMappingList,
    departmentMappings: OrderItemMappingList,
    expensesMappings: OrderItemMappingList
  ): any[] {
    const projectPart = this.separateNonOrderItemMappings(projectMappings);
    const departmentPart = this.separateNonOrderItemMappings(departmentMappings);
    const expensesPart = this.separateNonOrderItemMappings(expensesMappings);
    return ReserveInfo.mergeOrderItemMappings(projectPart, departmentPart, expensesPart);
  }

  private separateNonOrderItemMappings(originalMappings: OrderItemMappingList): any[] {
    const travelerSeparated: any[] = [];
    originalMappings.list.forEach(mapping => {
      if (mapping.travelerId === -1) {
        this.travelers.list.forEach((traveler, traveler_index) => {
          travelerSeparated.push({
            traveler_id: traveler_index,
            order_item_type: mapping.orderItemType,
            order_item_index: mapping.orderItemIndex,
            project_id: mapping.projectId || undefined,
            project_share_id: mapping.projectShareId || undefined,
            charging_department_id: mapping.chargingDepartmentId || undefined,
            charging_department_share_id: mapping.chargingDepartmentShareId || undefined,
            expenses_account_type_id: mapping.expensesAccountTypeId || undefined
          });
        });
      } else {
        travelerSeparated.push(mapping.submitParams());
      }
    });

    const results: any[] = [];
    travelerSeparated.forEach(mapping => {
      if (mapping.order_item_type === 'all') {
        // non_order_itemの場合を追加
        this.reservingTrip?.nonOrderItems.forEach((item, i) => {
          results.push({
            traveler_id: mapping.traveler_id,
            detail_type: mapping.order_item_type,
            order_item_type: 'item',
            order_item_index: i,
            project_id: mapping.project_id || undefined,
            project_share_id: mapping.project_share_id || undefined,
            charging_department_id: mapping.charging_department_id || undefined,
            charging_department_share_id: mapping.charging_department_share_id || undefined,
            expenses_account_type_id: mapping.expenses_account_type_id || undefined
          });
        });
      } else {
        results.push({
          ...mapping,
          detail_type: 'detail'
        });
      }
    });
    return results;
  }

  static mergeOrderItemMappings(
    projectMappings: any[],
    departmentMappings: any[],
    expensesMappings: any[]
  ): any[] {
    const merged: any[] = projectMappings.map(projectMapping => {
      const departmentMapping = departmentMappings.find(
        department =>
          department.traveler_id === projectMapping.traveler_id &&
          department.order_item_type === projectMapping.order_item_type &&
          department.order_item_index === projectMapping.order_item_index
      );
      const expensesMapping = expensesMappings.find(
        expenses =>
          expenses.traveler_id === projectMapping.traveler_id &&
          expenses.order_item_type === projectMapping.order_item_type &&
          expenses.order_item_index === projectMapping.order_item_index
      );
      projectMapping.charging_department_id = departmentMapping?.charging_department_id;
      projectMapping.charging_department_share_id = departmentMapping?.charging_department_share_id;
      projectMapping.expenses_account_type_id = expensesMapping?.expenses_account_type_id;
      return projectMapping;
    });
    return merged;
  }

  private separateOrderItemMappings(originalMappings: OrderItemMappingList): any[] {
    const travelerSeparated: any[] = [];
    originalMappings.list.forEach(mapping => {
      if (mapping.travelerId === -1) {
        this.travelers.list.forEach((traveler, traveler_index) => {
          travelerSeparated.push({
            traveler_id: traveler_index,
            order_item_type: mapping.orderItemType,
            order_item_index: mapping.orderItemIndex,
            project_id: mapping.projectId || undefined,
            project_share_id: mapping.projectShareId || undefined,
            charging_department_id: mapping.chargingDepartmentId || undefined,
            charging_department_share_id: mapping.chargingDepartmentShareId || undefined,
            expenses_account_type_id: mapping.expensesAccountTypeId || undefined
          });
        });
      } else {
        travelerSeparated.push(mapping.submitParams());
      }
    });

    const results: any[] = [];
    travelerSeparated.forEach(mapping => {
      if (mapping.order_item_type === 'all') {
        if (this.reservingTrip.isPackage()) {
          results.push({
            traveler_id: mapping.traveler_id,
            order_item_type: 'package',
            order_item_index: 1,
            project_id: mapping.project_id || undefined,
            project_share_id: mapping.project_share_id || undefined,
            charging_department_id: mapping.charging_department_id || undefined,
            charging_department_share_id: mapping.charging_department_share_id || undefined,
            expenses_account_type_id: mapping.expenses_account_type_id || undefined
          });
        } else {
          this.reservingTrip.items.forEach((item, i) => {
            results.push({
              traveler_id: mapping.traveler_id,
              order_item_type: 'item',
              order_item_index: i,
              project_id: mapping.project_id || undefined,
              project_share_id: mapping.project_share_id || undefined,
              charging_department_id: mapping.charging_department_id || undefined,
              charging_department_share_id: mapping.charging_department_share_id || undefined,
              expenses_account_type_id: mapping.expenses_account_type_id || undefined
            });
          });
        }

        if (this.isRentalCarRequired()) {
          this.rentalCars.forEach((car, i) => {
            results.push({
              traveler_id: mapping.traveler_id,
              order_item_type: 'rental_car',
              order_item_index: i,
              project_id: mapping.project_id || undefined,
              project_share_id: mapping.project_share_id || undefined,
              charging_department_id: mapping.charging_department_id || undefined,
              charging_department_share_id: mapping.charging_department_share_id || undefined,
              expenses_account_type_id: mapping.expenses_account_type_id || undefined
            });
          });
        }
      } else {
        results.push(mapping);
      }
    });
    return results;
  }

  setPaymentGatewayMembers(value: PaymentGatewayMember[]) {
    this.paymentGatewayMembers = value;
    app.render();
  }

  setPaymentTransaction(value: PaymentTransaction) {
    this.paymentTransaction = value;
    app.render();
  }

  setPaymentMethodType(value: PaymentMethodType) {
    this.paymentMethodType = value;
    app.render();
  }

  setCardNum01(value: never) {
    this.cardNum01 = value;
    app.render();
  }

  setCardNum02(value: never) {
    this.cardNum02 = value;
    app.render();
  }

  setCardNum03(value: never) {
    this.cardNum03 = value;
    app.render();
  }

  setCardNum04(value: never) {
    this.cardNum04 = value;
    app.render();
  }

  setCardExpireMonth(value: never) {
    this.cardExpireMonth = value;
    app.render();
  }

  setCardExpireYear(value: never) {
    this.cardExpireYear = value;
    app.render();
  }

  setCardName(value: never) {
    this.cardName = value;
    app.render();
  }

  setCardCode(value: never) {
    this.cardCode = value;
    app.render();
  }

  setTravelerAtIndex(i: number, traveler: Traveler) {
    this.travelers.setTravelerAtIndex(i, traveler);
    this.hotels!.map((hotel, j) => {
      if (hotel.rooms!.length > i) {
        return hotel.rooms!.map(() => {
          this.setHotelFirstName(traveler.firstNameRoman, j, i);
          this.setHotelLastName(traveler.lastNameRoman, j, i);
          this.setHotelFirstNameKana(traveler.firstNameKana, j, i);
          this.setHotelLastNameKana(traveler.lastNameKana, j, i);
          this.setHotelTel(traveler.tel, j, i);
          return true;
        });
      }
      return hotel;
    });
  }

  resetTravelerAtIndex(i: number) {
    this.hotels!.map((hotel, j) => {
      if (hotel.rooms!.length > i) {
        this.setHotelFirstName('', j, i);
        this.setHotelLastName('', j, i);
        this.setHotelFirstNameKana('', j, i);
        this.setHotelLastNameKana('', j, i);
        this.setHotelTel('', j, i);
      }
      return hotel;
    });
  }

  setManualHotelFirstName(value: string, i: number) {
    this.hotels!.map((hotel, j) => {
      if (hotel.rooms!.length > i) {
        this.setHotelFirstName(value, j, i);
      }
      return hotel;
    });
  }

  setManualHotelLastName(value: string, i: number) {
    this.hotels!.map((hotel, j) => {
      if (hotel.rooms!.length > i) {
        this.setHotelLastName(value, j, i);
      }
      return hotel;
    });
  }

  setManualHotelFirstNameKana(value: string, i: number) {
    this.hotels!.map((hotel, j) => {
      if (hotel.rooms!.length > i) {
        this.setHotelFirstNameKana(value, j, i);
      }
      return hotel;
    });
  }

  setManualHotelLastNameKana(value: string, i: number) {
    this.hotels!.map((hotel, j) => {
      if (hotel.rooms!.length > i) {
        this.setHotelLastNameKana(value, j, i);
      }
      return hotel;
    });
  }

  setRentalCarRequired(value: never) {
    this.rentalCarRequired = value;
    app.render();
  }

  setDeparturePlace(value: never, i: number) {
    this.rentalCars[i].departurePlace = value;
    app.render();
  }

  setDepartureDate(value: never, i: number) {
    this.rentalCars[i].departureDate = value;
    app.render();
  }

  setReturnPlace(value: never, i: number) {
    this.rentalCars[i].returnPlace = value;
    app.render();
  }

  setReturnDate(value: never, i: number) {
    this.rentalCars[i].returnDate = value;
    app.render();
  }

  setReturnPlaceName(value: never, i: number) {
    this.rentalCars[i].returnPlaceName = value;
    app.render();
  }

  setCarType(value: never, i: number) {
    this.rentalCars[i].carType = value;
    app.render();
  }

  setCarTypeOther(value: never, i: number) {
    this.rentalCars[i].carTypeOther = value;
    app.render();
  }

  setCarNumber(value: never, i: number) {
    this.rentalCars[i].carNumber = value;
    app.render();
  }

  setRemarks(value: never, i: number) {
    this.rentalCars[i].remarks = value;
    app.render();
  }

  setRCFirstNameKana(value: string, i: number) {
    this.rentalCars[i].firstNameKana = value;
    app.render();
  }

  setRCLastNameKana(value: string, i: number) {
    this.rentalCars[i].lastNameKana = value;
    app.render();
  }

  setDriverTel(value: string, i: number) {
    this.rentalCars[i].driverTel = value;
    app.render();
  }

  addRentalCar() {
    this.rentalCars.push({
      departureDate: this.reservingTrip.startDate(),
      returnDate: this.reservingTrip.endDate(),
      departurePlace: '',
      returnPlace: 'current',
      returnPlaceName: '',
      carType: 'compact',
      carTypeOptions: this.carTypeOptions,
      carTypeOther: '',
      carNumber: 1,
      remarks: '',
      firstNameKana: this.travelers.list[0].firstNameKana || '',
      lastNameKana: this.travelers.list[0].lastNameKana || '',
      driverTel: this.travelers.list[0].tel || ''
    });
    app.render();
  }

  removeRentalCar(i: number) {
    this.rentalCars = this.rentalCars.filter((r, ri) => ri !== i);
    app.render();
  }

  handleBedTypeChange(e: any, i: number, j: number) {
    e.preventDefault();
    const bedType = _.find(this.hotels[i].rooms![j].bedTypes, b => b.id === e.target.value);
    this.hotels[i].rooms![j].bedTypeId = bedType && bedType.id;
    this.hotels[i].rooms![j].bedTypeDescription = bedType && bedType.description;
    app.render();
  }

  handlePostcodeFormat() {
    if (!this.postcode.includes('-') && this.postcode !== '') {
      return this.insertHyphen(this.postcode, 3, '-');
    }

    return this.postcode;
  }

  private insertHyphen(postcode: string, index: 3, hyphen: '-') {
    this.postcode = postcode.slice(0, index) + hyphen + postcode.slice(index, postcode.length);
    return this.postcode;
  }

  async fetchApproveItems(target: string) {
    await utils
      .jsonPromise<ApproveItemsResponse>(`${this.approveItemsPath}.json`, {
        foreign_exist: this.foreignExist,
        target
      })
      .then(
        result => {
          this.approveItems = new ApproveItemList(result.approve_items);
          if (this.approveItems.list && this.approveItems.list.length > 0) {
            this.approveItems.list.forEach(approveItem => {
              if (
                approveItem.defaultItem != null &&
                approveItem.defaultItemCode != null &&
                approveItem.defaultItemName != null
              ) {
                this.setApproveItemValueCode(approveItem.id, approveItem.defaultItemCode);
                this.setApproveItemValue(approveItem.id, approveItem.defaultItemName);
              }
            });
          }
        },
        _error => {
          throw _error;
        }
      )
      .catch(e => {
        throw e;
      });
  }

  async resetExpenseAccountType() {
    const chargingDepartmentIds = this.getChargingDepartmentIDs();
    await utils
      .jsonPromise<ExpensesAccountTypeResponse>(
        `/reserve_confirm/expenses_account_types.json?${Date.now()}`,
        { 'department_ids[]': chargingDepartmentIds },
        'GET'
      )
      .then(
        result => {
          this.expensesAccountTypes = result.expenses_account_types.map(e => new ExpensesAccountType(e));
        },
        _error => {
          throw _error;
        }
      )
      .catch(e => {
        throw e;
      });
  }
}

export default ReserveInfo;
