import React from 'react';
import _ from 'lodash';

import { styled } from '@this/constants/themes';
import { css, withTheme } from 'styled-components';

import { Box } from '@material-ui/core';
import { Button } from '@this/shared/ui/inputs/button';
import { Text } from '@this/components/shared/ui/data_displays/typography';
import { getSpacing } from '@this/components/shared/ui/theme';
import { assertNever, trackClick } from '@this/src/util';

import localStorage from '@this/lib/local_storage';
import moment from '../../../lib/moment';

import SearchRoundTrip from './search_round_trip';
import SearchOneWayTemplate from './search_one_way.template';
import SearchMultipleLocationsTemplate from './search_multiple_locations.template';
import SearchHotelTripTemplate from './search_hotel.template';
import RentalCarTripTemplate from './search_rental_car_trip.template';
import type { SearchProps, SearchState } from './types';
import SearchQuery from '../../../domain/search_query';
import SimpleSelectorCustom from '../../simple_selector_custom';

interface CarTypeResponse {
  car_type: [string, string][];
}

class SearchTripForm extends React.Component<SearchProps, SearchState> {
  constructor(props: SearchProps) {
    super(props);

    this.state = {
      query: this.props.query
        ? this.props.query
        : new SearchQuery(
            Object.assign(utils.getParams(), {
              cabin: (this.props.defaultSeatClasses || []).join(',')
            })
          ),
      errors: [],
      isLoadingCurrentPlace: false,
      addItemEnable: true
    };
  }

  handleTabChange = (i: number) => {
    this.state.query.setCurrentTabIndex(i);
  };

  handleChange =
    (name: 'peoplenum' | 'roomnum' | 'smoke' | 'breakfast') => (e: React.ChangeEvent<HTMLSelectElement>) => {
      e.preventDefault();
      const value = e.target.value;
      switch (name) {
        case 'breakfast':
          this.state.query.setBreakfast(value);
          break;
        case 'peoplenum':
          this.state.query.setPeoplenum(Number(value));
          break;
        case 'roomnum':
          this.state.query.setRoomnum(Number(value));
          break;
        case 'smoke':
          this.state.query.setSmoke(value);
          break;
        default: {
          // nameが増えてcaseがないとtscでエラーになるようにするため
          assertNever(name);
          break;
        }
      }
    };

  handleAddItem = (itemType: 'transport' | 'hotel' | 'rentalCar') => (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!this.state.query.isItemLimit()) {
      this.state.query.addItem(itemType);
    }
    this.updateAddItemEnable();
  };

  handleRemoveItem = (index: number) => {
    this.state.query.removeItem(index);
    this.updateAddItemEnable();
  };

  private updateAddItemEnable() {
    if (this.state.query.isItemLimit()) {
      this.setState({ addItemEnable: false });
    }
    this.setState({ addItemEnable: true });
  }

  handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    trackClick('/dashboard search button');
    this.setState({
      loading: true,
      submitting: true,
      errors: []
    });

    try {
      this.state.query.validationPromise();
      this.storeQueries();
      this.storePulldownQueries();
      // 航空会社で絞り込んだあとに別の条件で検索すると航空会社の条件まで加味してしまうため
      this.state.query.clearCarrier();
      this.setState(
        {
          submitting: false,
          loading: false
        },
        () => {
          this.props.handleSearch(this.state.query);
        }
      );
    } catch (errors) {
      this.setState({
        errors,
        submitting: false,
        loading: false
      });
    }
  };

  componentDidMount() {
    SearchTripForm.rebuildTextInputEvent();
    this.fetchCarType();
    this.updateAddItemEnable();
  }

  UNSAFE_componentWillUpdate() {
    // bownown内でlocaleをenに変更されてしまうので
    // bownowは利用をやめたが、他に影響が出ると怖いので下記の処理はおいてある
    if (moment.locale() === 'en') {
      moment.locale('ja');
    }
  }

  static rebuildTextInputEvent() {
    if (!utils.iOS()) {
      return;
    }

    // 以下の問題に対する処置として、textInput後のkeydown, keyupを再定義する
    // - iOSでサジェストが効かない問題
    // - キーボードの確定ボタンで検索すると変換前の文字に戻ってしまう
    // eslint-disable-next-line func-names
    $('#origin, #destination').on('textInput', function () {
      ['keydown', 'keyup'].forEach(keyEvent => {
        const e = document.createEvent('KeyboardEvent');
        (e as any).initKeyboardEvent(keyEvent, true, true, window, 'Enter', 0, '');
        this.dispatchEvent(e);
      });
    });
  }

  private fetchCarType() {
    utils.jsonPromise<CarTypeResponse>('/car_types.json').then(result => {
      this.state.query.setCarType(result.car_type);
    });
  }

  getCurrentPlace = (evt: React.MouseEvent<HTMLAnchorElement>) => {
    evt.preventDefault();
    if (navigator.geolocation) {
      this.setState({ isLoadingCurrentPlace: true });
      return navigator.geolocation.getCurrentPosition(
        position =>
          utils
            .fetchCityName(position.coords.latitude, position.coords.longitude)
            .then(
              result => {
                if (result.status === 'OK') {
                  const items = this.state.query.items;
                  items[0].setOrigin(result.results[0].formatted_address!.replace(/^日本、〒\d{3}-\d{4} /g, ''));
                  items[items.length - 1].setDestination(
                    result.results[0].formatted_address!.replace(/^日本、〒\d{3}-\d{4} /g, '')
                  );
                  return this.setState({
                    isLoadingCurrentPlace: false
                  });
                }
                return this.setState({
                  errors: ['位置情報の取得に失敗しました。'],
                  isLoadingCurrentPlace: false
                });
              },
              err =>
                this.setState({
                  errors: ['位置情報の取得に失敗しました。'],
                  isLoadingCurrentPlace: false
                })
            )
            .catch(err => utils.sendErrorObject(err)),
        err => {
          switch (err.code) {
            case 1:
              this.setState({
                errors: ['位置情報取得権限がありません。'],
                isLoadingCurrentPlace: false
              });
              break;
            case 2:
              this.setState({
                errors: ['位置情報が特定できませんでした。'],
                isLoadingCurrentPlace: false
              });
              break;
            case 3:
              this.setState({
                errors: ['位置情報取得がタイムアウトしました。'],
                isLoadingCurrentPlace: false
              });
              break;
            default:
              this.setState({
                errors: ['位置情報の取得に失敗しました。'],
                isLoadingCurrentPlace: false
              });
          }
          return utils.sendErrorObject(err);
        },
        {
          enableHighAccuracy: true,
          timeout: 60000,
          maximumAge: 3000
        }
      );
    }
    return this.setState({
      errors: ['位置情報取得に対応していません'],
      isLoadingCurrentPlace: false
    });
  };

  private storeQueries() {
    this.state.query.items.forEach((item: any) => {
      ['origin', 'destination'].forEach(key => {
        if (_.isEmpty(item[key])) return;

        SearchTripForm.storeData(key, {
          text: item[key],
          address: item[`${key}Address`],
          addDate: _.now()
        });
      });
    });
  }

  private storePulldownQueries() {
    const q = this.state.query;
    localStorage.setItem('breakfast', q.breakfast);
    localStorage.setItem('smoke', q.smoke);
  }

  private static storeData(key: string, data: { text: string; address: string; addDate: number }) {
    const rawdata = localStorage.getItem(key);
    let preData = rawdata ? JSON.parse(rawdata) : rawdata;
    if (!preData || !_.isArray(preData)) {
      preData = [];
    }
    _.remove(preData, (o: any) => o.text === data.text);
    preData.push(data);
    // 一旦履歴は50件に制限する
    if (preData.length >= 50) {
      preData = preData.slice(preData.length - 50, preData.length);
    }
    localStorage.setItem(key, JSON.stringify(preData));
  }

  handleRentalCarInfoAdd = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    this.state.query.addItem('rentalCar');
  };

  render() {
    try {
      const { query, loading, submitting, errors } = this.state;
      const items = ['往復', '片道', '複数拠点', 'ホテル', 'レンタカー'];
      if (query.disabledHotelSearch) {
        items[3] = '';
      }
      if (query.disabledTransitSearch) {
        items[0] = '';
        items[1] = '';
      }
      if (query.disabledHotelSearch && query.disabledTransitSearch) {
        items[2] = '';
      }
      if (query.rentalcarAvailable !== true) {
        items[4] = '';
      }

      // TODO queryから指定するようにしたい(当コンポーネントの外側の仕様をここに持ち込まない)
      if (location.pathname.match(/trips\/new|trips\/\d+\/edit/)) {
        items[2] = '';
        items[4] = '';
      }

      return (
        <form className="search" action="/select" method="GET" onSubmit={this.handleSubmit}>
          <SelectorArea>
            <SelectorAreaExplain>
              AI Travel Smart Searchで目的地・ホテルなどから、
              <br />
              ルートをご提案します。
            </SelectorAreaExplain>
            <SimpleSelectorCustom index={query.currentTabIndex} items={items} onChange={this.handleTabChange} />
          </SelectorArea>
          <SearchArea>
            {query.currentTabIndex === 0 ? (
              <SearchRoundTrip
                {...this.props}
                {...this.state}
                query={query}
                handleChange={this.handleChange}
                handleRemoveItem={this.handleRemoveItem}
                handleAddItem={this.handleAddItem}
                getCurrentPlace={this.getCurrentPlace}
              />
            ) : query.currentTabIndex === 1 ? (
              <SearchOneWayTemplate
                {...this.props}
                {...this.state}
                query={query}
                handleChange={this.handleChange}
                handleRemoveItem={this.handleRemoveItem}
                handleAddItem={this.handleAddItem}
              />
            ) : query.currentTabIndex === 2 ? (
              <SearchMultipleLocationsTemplate
                {...this.props}
                {...this.state}
                query={query}
                handleChange={this.handleChange}
                handleRemoveItem={this.handleRemoveItem}
                handleAddItem={this.handleAddItem}
              />
            ) : query.currentTabIndex === 3 ? (
              <SearchHotelTripTemplate
                {...this.props}
                {...this.state}
                query={query}
                handleChange={this.handleChange}
                handleRemoveItem={this.handleRemoveItem}
                handleAddItem={this.handleAddItem}
              />
            ) : query.currentTabIndex === 4 ? (
              <RentalCarTripTemplate
                {...this.props}
                {...this.state}
                query={query}
                handleChange={this.handleChange}
                handleRentalCarInfoAdd={this.handleRentalCarInfoAdd}
                handleRemoveItem={this.handleRemoveItem}
              />
            ) : null}
            {loading && <img className="select__loading" src="/images/loading.gif" width="50" height="50" />}
          </SearchArea>
          <div className="search__button-wrapper">
            <SearchError>
              {_.entries(errors).map(([key, error]) => (
                <Text key={key} color="danger">
                  {error}
                </Text>
              ))}
            </SearchError>
            <SearchButton>
              <Box m="0 auto" maxWidth="200px">
                <Button id="search-button" type="submit" size="large" fullWidth disabled={submitting}>
                  {query.currentTabIndex === 4 ? '依頼する' : '検索'}
                </Button>
              </Box>
            </SearchButton>
          </div>
        </form>
      );
    } catch (e) {
      utils.sendErrorObject(e);
      return null;
    }
  }
}

export const SelectorArea = styled.div`
  background-color: #f8f6f1;
  padding: 20px;
`;

export const SearchButton = styled.div`
  background-color: #f8f6f1;
  padding: 20px;
`;

export const SelectorAreaExplain = styled.div`
  color: #8f7b4a;
  margin-bottom: 10px;
`;

export const SearchArea = styled.div`
  padding: 20px;
`;

export const OutLabelWrap = styled.div`
  margin: 3px auto 0 7px;
`;

export const OutLabel = styled.label`
  margin-bottom: 0.75em;
  font-weight: bold;
  font-size: 13px;
`;

export const InLabel = styled.label`
  color: ${props => props.theme.linkColor};
  font-weight: bold;
  font-size: 12px;
`;

export const FieldsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: 0;
`;

export const Field = styled.div`
  flex-grow: 99999;
  flex-basis: 230px;
  background: ${props => props.theme.fieldBgColor};
  border: 2px solid ${props => props.theme.fieldBorderColor};
  padding: 3px 5px 3px 10px;
  margin-top: 5px;
  width: 100%;
`;

export const ToggleField = styled(Field)<{ disabled: boolean }>`
  background: ${props => (props.disabled ? props.theme.grayBgColor : props.theme.fieldBgColor)};
`;

export const InputArea = styled.div<{ wrapped: boolean }>`
  display: flex;
  align-items: flex-end;
  flex-wrap: ${props => (props.wrapped ? 'wrap' : 'nowrap')};
`;

export const AddressArea = styled.div`
  color: ${props => props.theme.grayTextColor};
  font-weight: bold;
  font-size: 11px;
`;

const InputBase = css`
  border: 0;
  padding: 0 15px 0 0;
  margin-bottom: 0;
  box-shadow: none;
  line-height: 1;
  background: ${props => props.theme.fieldBgColor};
  box-shadow: none;
  cursor: pointer;
`;

export const TextField = styled.input`
  &&& {
    ${InputBase}
    padding-top: 2px;
    &:focus {
      box-shadow: none;
    }
    &:disabled {
      display: none;
    }
  }
`;

export const SelectWrap = styled.div`
  flex-basis: 105px;
`;

export const Select = styled.select`
  ${InputBase}
  padding: 0;
  appearance: none;
  background: ${props => props.theme.fieldBgColor};

  &:focus {
    outline: 0;
  }

  option {
    appearance: none;
  }
`;

const SearchError = styled.div`
  padding: ${getSpacing(2)}px 0;
`;

export default withTheme(SearchTripForm);
