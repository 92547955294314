import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import { observer } from 'mobx-react';
import type { Moment } from 'moment';
import moment from 'moment';
import { Box } from '@material-ui/core';
import { styled } from '@this/constants/themes';
import SimpleLoading from '@this/shared/simple_loading/simple_loading';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@this/components/shared/ui/data_displays/table';
import Trip from '@this/domain/trip/trip';
import type { SnoozeTodoCreationParams } from '@this/components/arrangement/todo_list/types';
import TodoCard from './todo_card/todo_card';

interface Response {
  trips: object[];
  next_business_day: string;
}

enum TabType {
  TODO,
  SNOOZED
}

const TripTodoList = observer(() => {
  const history = useHistory();
  const [currentTab, setCurrentTab] = useState<TabType>(TabType.TODO);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [todoTrips, setTodoTrips] = useState<Trip[]>([]);
  const [snoozedTrips, setSnoozedTrips] = useState<Trip[]>([]);
  const [nextBusinessDay, setNextBusinessDay] = useState<Moment>(moment().add(30, 'days'));
  const [editingTripIds, setEditingTripIds] = useState<Set<number>>(new Set<number>());
  const [canUpdateTrips, setCanUpdateTrips] = useState<boolean>(true);
  const [canUpdateTripsTimeoutId, setCanUpdateTripsTimeoutId] = useState<any>(undefined);

  const fetchTrips = () => {
    setIsLoading(true);

    utils
      .jsonPromise<Response>('/arrangement/todo_list.json')
      .then(result => {
        const trips = result.trips.map(raw => new Trip(raw));
        const todoTrips: Trip[] = [];
        const snoozedTrips: Trip[] = [];
        trips.forEach(t => {
          if (t.isSnoozing()) {
            snoozedTrips.push(t);
          } else {
            todoTrips.push(t);
          }
        });
        setTodoTrips(todoTrips);
        setSnoozedTrips(snoozedTrips);
        setNextBusinessDay(moment(result.next_business_day).endOf('day'));
      })
      .finally(() => setIsLoading(false))
      .catch(() => {});
  };

  const initPusher = (key: string, channelPrefix: string) => {
    if (typeof Pusher === 'undefined') {
      window.setTimeout(() => initPusher(key, channelPrefix), 100);
      return;
    }

    const pusher = new Pusher(key, { encrypted: true });
    const eventChannel = pusher.subscribe(`${channelPrefix}partner`);

    eventChannel.bind('reload', () => {
      // snooze編集中はupdateしない
      if (editingTripIds.size > 0) {
        return;
      }

      // 更新後1分間は自動でupdateしないように
      if (!canUpdateTrips) {
        if (!canUpdateTripsTimeoutId) {
          const id = window.setTimeout(() => {
            setCanUpdateTrips(true);
            setCanUpdateTripsTimeoutId(null);
          }, 1000 * 60);
          setCanUpdateTripsTimeoutId(id);
        }

        return;
      }

      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      fetchTrips();
      setCanUpdateTrips(false);
    });
  };

  const setupPusher = async () => {
    const res = await utils.jsonPromise<{ pusherChannelPrefix: string; pusherKey: string }>(
      '/arrangement/virtual_counter.json'
    );

    initPusher(res.pusherKey, res.pusherChannelPrefix);
  };

  useEffect(() => {
    fetchTrips();
    setupPusher();
    history.push('/arrangement/todo_list?tab=TRIP');
  }, []);

  const addEditingTripId = (id: number) => {
    const ids = _.clone(editingTripIds);
    ids.add(id);
    setEditingTripIds(ids);
  };

  const removeEditingTripId = (id: number) => {
    const ids = _.clone(editingTripIds);
    ids.delete(id);
    setEditingTripIds(ids);
  };

  const createSnooze = async (params: SnoozeTodoCreationParams): Promise<void> => {
    try {
      await utils.jsonPromise('/arrangement/snoozed_todos', params, 'POST');
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      fetchTrips();
      removeEditingTripId(params.trip_id);
    } catch (e) {
      utils.sendErrorObject(e);
      throw e;
    }
  };

  const cancelSnooze = async (tripId: number): Promise<void> => {
    try {
      await utils.jsonPromise(`/arrangement/snoozed_todos/${tripId}`, { canceled: true }, 'PUT');
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      fetchTrips();
      removeEditingTripId(tripId);
    } catch (e) {
      utils.sendErrorObject(e);
      throw e;
    }
  };

  let trips: Trip[] = [];
  if (currentTab === TabType.TODO) {
    trips = todoTrips;
  } else {
    trips = snoozedTrips;
  }

  return (
    <>
      <Box display="flex" marginBottom="10px">
        <Tab>
          <input type="radio" checked={currentTab === TabType.TODO} onChange={() => setCurrentTab(TabType.TODO)} />
          {`TODO(${todoTrips.length})`}
        </Tab>
        <Tab>
          <input
            type="radio"
            checked={currentTab === TabType.SNOOZED}
            onChange={() => setCurrentTab(TabType.SNOOZED)}
          />
          {`スヌーズ中(${snoozedTrips.length})`}
        </Tab>
      </Box>
      {isLoading ? (
        <SimpleLoading />
      ) : (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell nowrap>Trip ID</TableCell>
              <TableCell nowrap>ステータス</TableCell>
              <TableCell nowrap>カテゴリ</TableCell>
              <TableCell nowrap>日程</TableCell>
              <TableCell>社名</TableCell>
              <TableCell>メッセージ</TableCell>
              <TableCell nowrap>スヌーズ</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {trips.map(t => (
              <TodoCard
                key={t.id}
                trip={t}
                nextBusinessDay={nextBusinessDay}
                editing={editingTripIds.has(t.id)}
                onCreateSnooze={createSnooze}
                onCancelSnooze={cancelSnooze}
                onOpenForm={() => addEditingTripId(t.id)}
                onCloseForm={() => removeEditingTripId(t.id)}
              />
            ))}
          </TableBody>
        </Table>
      )}
    </>
  );
});

const Tab = styled.label`
  margin-right: 20px;
`;

export default TripTodoList;
