import CsvDropzone from '@this/components/organization/csv_bulk_action/csv_dropzone';
import Link from '@this/shared/atoms/link';
import type { CSSProperties } from 'react';
import React from 'react';
import { styled } from '@this/constants/themes';
import { ButtonBase } from '@this/shared/atoms/button';
import type { FileValidatorResult } from '@this/components/organization/csv_bulk_action/types';
import { useConfirmModal } from '@this/shared/ui/feedbacks/modal/confirm_modal';

interface Props {
  loading: boolean;
  backPath: string;
  historyPath: string;
  validateResult?: FileValidatorResult;
  uploadResult?: any;
  dropZoneStyle?: CSSProperties;
  select: (file?: File) => void;
  upload: (file: File) => void;
}

const CsvBulkDeleteMemberForm: React.FC<Props> = ({
  loading,
  backPath,
  historyPath,
  validateResult,
  uploadResult,
  dropZoneStyle,
  select,
  upload
}) => {
  const canSubmit = validateResult && validateResult.file && !uploadResult && !loading;
  const { open } = useConfirmModal();

  const handleClick = async () => {
    const { result } = await open({
      description: ['csvに含まれている社員をマスタから削除します。', '削除したデータは元に戻せません。'],
      submitButtonLabel: '削除',
      color: 'danger'
    });

    if (!canSubmit) {
      return;
    }

    if (result) {
      upload(validateResult!.file!);
    }
  };

  return (
    <form>
      <CsvDropzone
        loading={loading}
        validateResult={validateResult}
        uploadResult={uploadResult}
        dropZoneStyle={dropZoneStyle}
        select={select}
        historyPath={historyPath}
      />
      <Actions>
        <Link to={backPath}>戻る</Link>
        <Button onClick={handleClick} disabled={!canSubmit}>
          アップロード
        </Button>
      </Actions>
    </form>
  );
};

const Actions = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  *:not(:first-child) {
    margin-left: 10px;
  }
`;

const Button = styled.button.attrs({
  type: 'button'
})`
  ${ButtonBase};
  display: inline-block;
  margin: 0 10px;
  padding: 0.75em 1em;
`;

export default CsvBulkDeleteMemberForm;
