import React, { useCallback, useContext } from 'react';
import type { ModalProps } from '../modal';
import { Modal, ModalBody, ModalFooter } from '../modal';
import { Button } from '../../../inputs/button';

export type ConfirmModalParams = Omit<ConfirmModalProps, 'open' | 'onCancel' | 'onSubmit'>;
export type ConfirmModalResult = { event: object; result: boolean };
export type ConfirmModalContextProps = { open: (params: ConfirmModalParams) => Promise<ConfirmModalResult> };
export const ConfirmModalContext = React.createContext<ConfirmModalContextProps>({ open: () => Promise.reject() });

export const useConfirmModal = () => useContext(ConfirmModalContext);

// class component用。hookが使えないため
export const WithConfirmModal = ({
  children
}: {
  children: (props: ReturnType<typeof useConfirmModal>) => any;
}) => {
  const { open } = useConfirmModal();
  return children({ open });
};

export const ConfirmModalContainer = ({ children }: { children: React.ReactNode }) => {
  const [modalParams, setModalParams] = React.useState<ConfirmModalParams | null>(null);
  const [modalResultFn, setModalResultFn] = React.useState<(e: object, result: boolean) => void>(() => () => {});

  const open = useCallback(
    (params: ConfirmModalParams) => {
      return new Promise<ConfirmModalResult>(resolve => {
        setModalParams(params);

        setModalResultFn(() => (e: object, result: boolean) => {
          if (!e) {
            return;
          }

          resolve({ event: e, result });
          setModalParams(null);
        });
      });
    },
    [setModalParams, setModalResultFn]
  );
  const contextValue = React.useMemo(() => ({ open }), [open]);

  return (
    <ConfirmModalContext.Provider value={contextValue}>
      {children}

      {modalParams && (
        <ConfirmModal
          {...modalParams}
          open={!!modalParams}
          onSubmit={e => modalResultFn(e, true)}
          onCancel={e => modalResultFn(e, false)}
        />
      )}
    </ConfirmModalContext.Provider>
  );
};
export type ConfirmModalProps = {
  open: boolean;
  description: string[] | string;
  color?: 'primary' | 'danger';
  submitButtonLabel?: string;
  options?: { size: ModalProps['size'] };
  onSubmit: (e: object) => void;
  onCancel: (e: object) => void;
};
export const ConfirmModal = ({
  open,
  description,
  color = 'primary',
  submitButtonLabel = '確認',
  options,
  onSubmit,
  onCancel
}: ConfirmModalProps) => {
  return (
    <Modal size={options?.size ?? 'small'} open={open} onClose={e => onCancel(e)}>
      <ModalBody>
        {Array.isArray(description) ? description.map(d => <p key={d}>{d}</p>) : <p>{description}</p>}
      </ModalBody>
      <ModalFooter>
        <Button color="sub" onClick={onCancel}>
          キャンセル
        </Button>
        <Button onClick={onSubmit} color={color}>
          {submitButtonLabel}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
