import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import type { Moment } from 'moment';
import moment from 'moment';
import { styled } from '@this/constants/themes';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@this/components/shared/ui/data_displays/table';
import SimpleLoading from '@this/shared/simple_loading/simple_loading';
import Trip from '@this/domain/trip/trip';
import type OrderItem from '@this/domain/order_item';
import type { CheckDailyDeliveryParams } from '../types';
import LogModal from '../log_modal';

interface Response {
  trips: object[];
  next_business_day: string;
}

const DeliveryAndBookingCardList: React.FC = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [orderItems, setOrderItems] = useState<OrderItem[]>([]);
  const [filteredOrderItems, setFilteredOrderItems] = useState<OrderItem[]>([]);
  const [startDateFrom, setStartDateFrom] = useState<Moment>(moment());
  const [startDateTo, setStartDateTo] = useState<Moment>(moment().add(180, 'days'));
  const [supplierSuppliedItemNames, setSupplierSuppliedItemNames] = useState<string[]>([]);
  const [suppliedItemName, setSuppliedItemName] = useState<string>('');
  const [isDeliveryChecked, setIsDeliveryChecked] = useState<'false' | 'true' | 'both'>('false');
  const [logModalTraceId, setLogModalTraceId] = useState<number | null>(null);

  // ブッキング・デリバリーチェックのデータを取得する
  const fetchTripsForDeliveryAndBooking = () => {
    setIsLoading(true);

    utils
      .jsonPromise<Response>('/arrangement/todo_list/daily_delivery_and_booking.json', {
        start_date_from: startDateFrom.format('YYYY-MM-DD'),
        start_date_to: startDateTo.format('YYYY-MM-DD')
      })
      .then(result => {
        const trips = result.trips.map(raw => new Trip(raw));
        const deliveryAndBookingOrderItems: OrderItem[] = [];
        trips.forEach(t => {
          t.order.orderItems.forEach(orderItem => {
            deliveryAndBookingOrderItems.push(orderItem);
          });
        });
        setOrderItems(deliveryAndBookingOrderItems);
      })
      .finally(() => setIsLoading(false))
      .catch(() => {});
  };

  // ブッキング・デリバリーチェックのチェックを変更した時にデータを更新し、再取得する
  const updateDeliveryAndBookingIsChecked = async (params: CheckDailyDeliveryParams): Promise<void> => {
    try {
      await utils.jsonPromise('/arrangement/todo_list/check_daily_delivery', params, 'PUT');
      fetchTripsForDeliveryAndBooking();
    } catch (e) {
      utils.sendErrorObject(e);
      throw e;
    }
  };

  useEffect(() => {
    history.push('/arrangement/todo_list?tab=DELIVERY_AND_BOOKING');
  }, []);

  useEffect(() => {
    fetchTripsForDeliveryAndBooking();
  }, [startDateFrom, startDateTo]);

  // orderItems をトリガーとした各状態の更新処理
  useEffect(() => {
    const newFilteredOrderItems: OrderItem[] = [];
    const newSupplierSuppliedItemNames: string[] = [];
    let newSuppliedItem: string = suppliedItemName;
    orderItems.forEach(orderItem => {
      orderItem.supplierSuppliedItemNames.forEach(suppliedItemName => {
        if (!newSupplierSuppliedItemNames.includes(suppliedItemName)) {
          newSupplierSuppliedItemNames.push(suppliedItemName);
        }
      });
    });

    // 新しい OrderItems に該当文字列がなければ空文字にリセットする
    if (!newSupplierSuppliedItemNames.includes(suppliedItemName)) {
      newSuppliedItem = '';
    }

    orderItems.forEach(orderItem => {
      let matched = true;
      if (newSuppliedItem !== '') {
        matched = matched && orderItem.supplierSuppliedItemNames.includes(newSuppliedItem);
      }
      if (isDeliveryChecked !== 'both') {
        matched = matched && orderItem.isDeliveryChecked.toString() === isDeliveryChecked;
      }
      if (matched) {
        newFilteredOrderItems.push(orderItem);
      }
    });

    // 最後に一括更新
    setFilteredOrderItems(newFilteredOrderItems);
    setSupplierSuppliedItemNames(newSupplierSuppliedItemNames);
    setSuppliedItemName(newSuppliedItem);
  }, [orderItems]);

  // 仕入れ商品・チェック済み絞り込み
  React.useEffect(() => {
    const newFilteredOrderItems: OrderItem[] = [];
    orderItems.forEach(orderItem => {
      let matched = true;
      if (suppliedItemName !== '') {
        matched = matched && orderItem.supplierSuppliedItemNames.includes(suppliedItemName);
      }
      if (isDeliveryChecked !== 'both') {
        matched = matched && orderItem.isDeliveryChecked.toString() === isDeliveryChecked;
      }
      if (matched) {
        newFilteredOrderItems.push(orderItem);
      }
    });
    setFilteredOrderItems(newFilteredOrderItems);
  }, [suppliedItemName, isDeliveryChecked]);

  const handleUpdateSuppliedItemName = React.useCallback(
    e => {
      setSuppliedItemName(e.target.value);
    },
    [suppliedItemName, filteredOrderItems]
  );

  const handleUpdateIsDeliveryChecked = React.useCallback(
    e => {
      setIsDeliveryChecked(e.target.value);
    },
    [isDeliveryChecked, filteredOrderItems]
  );

  return (
    <>
      <OrderItemFilterList>
        <OrderItemFilter>
          <OrderItemFilterLabel minWidth="50px">出発日</OrderItemFilterLabel>
          <OrderItemFilterInput
            type="date"
            value={startDateFrom.format('YYYY-MM-DD')}
            onChange={e => setStartDateFrom(moment(e.target.value))}
          />
          <div>〜</div>
          <OrderItemFilterInput
            type="date"
            value={startDateTo.format('YYYY-MM-DD')}
            onChange={e => setStartDateTo(moment(e.target.value))}
          />
        </OrderItemFilter>
        <OrderItemFilterSpacer />
        <OrderItemFilter>
          <OrderItemFilterLabel minWidth="80px">仕入れ商品</OrderItemFilterLabel>
          <OrderItemFilterSelect onChange={handleUpdateSuppliedItemName} defaultValue={suppliedItemName}>
            <option key="" value="">
              選択なし
            </option>
            {supplierSuppliedItemNames.map(supplierSuppliedItemName => {
              return (
                <option key={supplierSuppliedItemName} value={supplierSuppliedItemName}>
                  {supplierSuppliedItemName}
                </option>
              );
            })}
          </OrderItemFilterSelect>
        </OrderItemFilter>
        <OrderItemFilterSpacer />
        <OrderItemFilter>
          <OrderItemFilterLabel minWidth="80px">チェック状態</OrderItemFilterLabel>
          <OrderItemFilterSelect onChange={handleUpdateIsDeliveryChecked} defaultValue={isDeliveryChecked}>
            <option key="false" value="false">
              未チェックのみ
            </option>
            <option key="true" value="true">
              チェック済のみ
            </option>
            <option key="both" value="both">
              すべて
            </option>
          </OrderItemFilterSelect>
        </OrderItemFilter>
      </OrderItemFilterList>
      {isLoading ? (
        <SimpleLoading />
      ) : (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Trip ID</TableCell>
              <TableCell>OrderItem カテゴリ</TableCell>
              <TableCell>仕入れ商品</TableCell>
              <TableCell>確認項目</TableCell>
              <TableCell>日程</TableCell>
              <TableCell>社名</TableCell>
              <TableCell>チェック日</TableCell>
              <TableCell>チェック担当者</TableCell>
              <TableCell>チェック</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredOrderItems.map(orderItem => {
              const trip = orderItem.order.trip;
              return (
                <TableRow key={orderItem.id}>
                  {/* Trip ID */}
                  <TableCell>
                    <a
                      href={`/arrangement/virtual_counter?trip_id=${trip.id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {trip.id}
                    </a>
                    <p>
                      (<a onClick={() => setLogModalTraceId(orderItem.traceId)}>ログ</a>)
                    </p>
                  </TableCell>
                  {/* カテゴリ */}
                  <TableCell>{orderItem.categoryStr()}</TableCell>
                  {/* 仕入れ商品 */}
                  <TableCell>
                    {orderItem.supplierSuppliedItemNames.map(supplierSuppliedItemNames => {
                      return (
                        <div key={`${orderItem.id}${supplierSuppliedItemNames}`}>{supplierSuppliedItemNames}</div>
                      );
                    })}
                  </TableCell>
                  {/* 確認項目 */}
                  <TableCell>{orderItem.deliveryAndBookingConfirmation}</TableCell>
                  {/* 日程 */}
                  <TableCell>
                    {orderItem.startDateStr()}〜{orderItem.endDateStr()}
                  </TableCell>
                  {/* 社名 */}
                  <TableCell>{trip.user.organization && trip.user.organization.name}</TableCell>
                  {/* チェック日 */}
                  <TableCell>
                    {orderItem.deliveryCheckedAt && moment(orderItem.deliveryCheckedAt).format('YYYY-MM-DD')}
                  </TableCell>
                  {/* チェック担当者 */}
                  <TableCell>{orderItem.deliveryCheckedByName}</TableCell>
                  {/* チェック */}
                  <TableCell>
                    <input
                      type="checkbox"
                      defaultChecked={orderItem.isDeliveryChecked}
                      onChange={e =>
                        updateDeliveryAndBookingIsChecked({
                          id: orderItem.id,
                          is_delivery_checked: e.target.checked
                        })
                      }
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      )}
      {logModalTraceId && <LogModal traceId={logModalTraceId} onClose={() => setLogModalTraceId(null)} />}
    </>
  );
};

const OrderItemFilterList = styled.div`
  display: flex;
  width: 100%;
`;

const OrderItemFilter = styled.div`
  display: flex;
  align-items: center;
`;

const OrderItemFilterLabel = styled.div<{ minWidth: string }>`
  min-width: ${props => props.minWidth};
`;

const OrderItemFilterInput = styled.input`
  margin-bottom: 0 !important;
`;

const OrderItemFilterSelect = styled.select`
  margin-bottom: 0 !important;
`;

const OrderItemFilterSpacer = styled.div`
  margin-right: 20px;
`;

export default DeliveryAndBookingCardList;
