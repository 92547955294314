import _ from 'lodash';
import moment from 'moment';
import type MarginType from '@this/domain/organization/margin_type2';
import type { LegJson, TransitJson, legsummaryesTypeprice, TransitPriceJson } from '../select_repository';
import Segment from './segment';

export type Args = TransitJson & {
  package_type?: never;
  packageType?: 'IIT' | 'DP' | undefined;
  showFee: boolean;
  marginType?: MarginType;
};

class Transit {
  // TransitJson
  public id: string;

  public price: number; // 楽天パッケージの初回リクエストでは取得できない。

  public price_range: string; // 楽天パッケージの初回リクエストで取得できる。他パッケージでは空。

  // private price_text: string | null;

  public prices: TransitPriceJson[];

  private pricesOriginal: TransitPriceJson[];

  public changeable_price: number | null;

  public changeable_price_text: string | null;

  public changeable_pre_sale: boolean;

  public unchangeable_price: number | null;

  // private unchangeable_pre_sale: boolean;

  private routes: string[] | null;

  public text: string;

  public selected_text: string;

  public legsummaries_fromto_jrair: string;

  public legsummaries_fromto_before: string;

  public legsummaries_fromto_after: string;

  public legsummaries_typeprice_before: legsummaryesTypeprice;

  public legsummaries_typeprice_after: legsummaryesTypeprice;

  public legs_fromto: string;

  // private time: string | null;

  private departure_time: string | null;

  private arrival_time: string | null;

  // private transits: never[];

  // public segments: SegmentJson[]  // コンストラクタで上書かれる

  // private url: string | null;

  public shinkansen: boolean;

  private shinkansen_too_late: boolean | null;

  private shinkansen_deadline: null;

  private air_too_late: boolean;

  public air: boolean;

  public airport: string | null;

  private changeable_air: null;

  private domesticAirPriceIndex: number | null;

  // private selected_price: number;

  private fee: number;

  public peoplenum: number;

  // private search_query_id: number;

  // private search_query_item_id: number | null;

  public optimal: boolean | null;

  public package: true | null;

  // private additional_price: null;

  public unchangeable_price_text: string | undefined;

  //
  private raw: Args;

  public segments: Segment[];

  // private package_type: never | undefined;

  public packageType: 'IIT' | 'DP' | undefined;

  public additionalPrice: number | null | undefined;

  public isAirChangeable: boolean | null | undefined;

  private showFee: boolean;

  private marginType: MarginType | undefined;

  private isSP: boolean;

  public hovered: boolean;

  public changeableHovered: boolean;

  public unchangeableHovered: boolean;

  private firstLegCache: LegJson | undefined;

  private lastLegCache: LegJson | undefined;

  private firstLegNameCache: string | undefined;

  private lastLegNameCache: string | undefined;

  private firstLegFromNameCache: string | undefined;

  private lastLegToNameCache: string | undefined;

  private firstLegFromTimeCache: string | undefined;

  private lastLegToTimeCache: string | undefined;

  private firstRouteCache: string | undefined;

  private lastRouteCache: string | undefined;

  public includeTax: boolean;

  public isRakutenPackage: boolean | null | undefined;

  public railway_distance: number | null;

  public isUnreservedSeatAvailable: boolean | null;

  public flightNames?: string[];

  constructor(args: Args) {
    this.id = args.id;
    this.price = args.price;
    this.price_range = args.price_range;
    // this.price_text = args.price_text;
    this.prices = args.prices;
    this.pricesOriginal = args.prices;
    this.changeable_price = args.changeable_price;
    this.changeable_price_text = args.changeable_price_text;
    this.changeable_pre_sale = args.changeable_pre_sale;
    this.unchangeable_price = args.unchangeable_price;
    // this.unchangeable_pre_sale = args.unchangeable_pre_sale;
    this.routes = args.routes;
    this.text = args.text;
    this.selected_text = args.selected_text;
    this.legsummaries_fromto_jrair = args.legsummaries_fromto_jrair;
    this.legsummaries_fromto_before = args.legsummaries_fromto_before;
    this.legsummaries_fromto_after = args.legsummaries_fromto_after;
    this.legsummaries_typeprice_before = args.legsummaries_typeprice_before;
    this.legsummaries_typeprice_after = args.legsummaries_typeprice_after;
    this.legs_fromto = args.legs_fromto;
    // this.time = args.time;
    this.departure_time = args.departure_time;
    this.arrival_time = args.arrival_time;
    // this.transits = args.transits;
    // this.url = args.url;
    this.shinkansen = args.shinkansen;
    this.shinkansen_too_late = args.shinkansen_too_late;
    this.shinkansen_deadline = args.shinkansen_deadline;
    this.air_too_late = args.air_too_late;
    this.air = args.air;
    this.airport = args.airport;
    this.changeable_air = args.changeable_air;
    this.domesticAirPriceIndex = args.domestic_air_price_index;
    // this.selected_price = args.selected_price;
    this.fee = args.fee;
    this.peoplenum = args.peoplenum;
    // this.search_query_id = args.search_query_id;
    // this.search_query_item_id = args.search_query_item_id;
    this.optimal = args.optimal;
    this.package = args.package;
    // this.additional_price = args.additional_price;
    this.unchangeable_price_text = args.unchangeable_price_text;

    this.raw = args;

    // this.package_type = args.package_type;
    this.packageType = args.packageType;
    this.additionalPrice = args.additional_price;

    if (args.segments) {
      this.segments = _.map(args.segments, s => new Segment(s));
    } else {
      this.segments = [];
    }
    this.peoplenum = args.peoplenum || 1;
    this.isAirChangeable = this.changeable_air;
    this.showFee = typeof args.showFee === 'undefined' ? true : args.showFee;
    this.marginType = args.marginType;
    this.isSP = false;
    this.hovered = false;
    this.changeableHovered = false;
    this.unchangeableHovered = false;

    this.includeTax = true;
    this.isRakutenPackage = args.is_rakuten_package;
    this.railway_distance = args.railway_distance;
    this.isUnreservedSeatAvailable = args.is_unreserved_seat_available;
    this.flightNames = args.flight_names;
  }

  shouldShowOrigin(): boolean {
    return !!this.firstLegName() && !!this.firstRoute() && this.firstLegName() !== this.firstRoute();
  }

  shouldShowDest(): boolean {
    return !!this.lastLegName() && !!this.lastRoute() && this.lastLegName() !== this.lastRoute();
  }

  firstLeg(): LegJson | undefined {
    if (!this.firstLegCache) this.firstLegCache = utils.dig(_.first(this.segments), 'firstLeg');
    return this.firstLegCache;
  }

  lastLeg(): LegJson | undefined {
    if (!this.lastLegCache) this.lastLegCache = utils.dig(_.last(this.segments), 'lastLeg');
    return this.lastLegCache;
  }

  firstLegName(): string | undefined {
    if (!this.firstLegNameCache) this.firstLegNameCache = utils.dig(this.firstLeg(), 'name');
    return this.firstLegNameCache;
  }

  lastLegName(): string | undefined {
    if (!this.lastLegNameCache) this.lastLegNameCache = utils.dig(this.lastLeg(), 'name');
    return this.lastLegNameCache;
  }

  firstLegFromName(): string | undefined {
    if (!this.firstLegFromNameCache) this.firstLegFromNameCache = utils.dig(this.firstLeg(), 'from', 'name');
    return this.firstLegFromNameCache;
  }

  lastLegToName(): string | undefined {
    if (!this.lastLegToNameCache) this.lastLegToNameCache = utils.dig(this.lastLeg(), 'to', 'name');
    return this.lastLegToNameCache;
  }

  firstLegFromTime() {
    if (!this.firstLegFromTimeCache) this.firstLegFromTimeCache = utils.dig(this.firstLeg(), 'from', 'time');
    return this.firstLegFromTimeCache;
  }

  lastLegToTime() {
    if (!this.lastLegToTimeCache) this.lastLegToTimeCache = utils.dig(this.lastLeg(), 'to', 'time');
    return this.lastLegToTimeCache;
  }

  firstLegFromDate() {
    const d = utils.dig(this.firstLeg(), 'from', 'time_full');
    if (d) {
      return d.split('T')[0];
    }
    return null;
  }

  lastLegToDate() {
    const d = utils.dig(this.lastLeg(), 'to', 'time_full');
    if (d) {
      return d.split('T')[0];
    }
    return null;
  }

  firstRoute() {
    if (!this.firstRouteCache) this.firstRouteCache = _.first(this.routes);
    return this.firstRouteCache;
  }

  lastRoute() {
    if (!this.lastRouteCache) this.lastRouteCache = _.last(this.routes);
    return this.lastRouteCache;
  }

  airExists() {
    return this.segments.some(s => s.isAir());
  }

  shinkansenExists() {
    return this.segments.some(s => s.type === 'shin');
  }

  expressExists() {
    return this.segments.some(s => s.type === 'express');
  }

  connectingAirExists() {
    const airSegments = this.segments.filter(s => s.isAir());
    const airLineNames = this.getAirLineName();
    return airSegments.length > 1 || this.segments.some(s => s.hasConnectingAir) || airLineNames.length > 1;
  }

  getSegments() {
    return this.segments;
  }

  ticketPrice(): number {
    if (this.airExists()) {
      if (this.domesticAirPriceIndex === null) {
        return 0;
      }
      // select画面の右側の選択肢を選択した瞬間、this.pricesが選択前の要素を持っているのに、
      // this.domesticAirPriceIndexのみ選択後の要素を指す。そのときはエラーではなく、0を返す
      return this.prices == null ||
        this.prices.length < this.domesticAirPriceIndex ||
        this.prices[this.domesticAirPriceIndex] === undefined ||
        this.prices[this.domesticAirPriceIndex] === null
        ? 0
        : this.prices[this.domesticAirPriceIndex].price;
    }
    if (this.shinkansenExists() || this.expressExists()) {
      return this.price;
    }
    return 0;
  }

  ticketPriceText() {
    return `${utils.formatPrice(this.ticketPrice())} × ${this.peoplenum}名`;
  }

  totalTicketPrice(): number {
    return this.ticketPrice() * this.peoplenum;
  }

  marginAmount(): number {
    if (this.marginType) {
      return this.marginType.calcMarginAmount(this.totalTicketPrice(), this.peoplenum);
    }
    return this.fee * this.peoplenum;
  }

  feeText() {
    if (this.marginType) {
      return this.marginType.describe(this.totalTicketPrice(), this.peoplenum);
    }
    // feeはTransit::SHINKANSEN_FEE or Transit::DOMESTIC_AIR_FEE
    return `${utils.formatPrice(this.fee)} × ${this.peoplenum}名`;
  }

  pricePerPerson() {
    return this.totalPrice() / this.peoplenum;
  }

  totalPrice(): number {
    if (this.package) {
      return (this.price + 1080) * this.peoplenum;
    }
    if (this.showFee) {
      return this.totalTicketPrice() + this.marginAmount();
    }
    return this.totalTicketPrice();
  }

  priceText() {
    return `：${utils.formatPrice(this.totalPrice())}\n${this.priceDetailText()}`;
  }

  priceDetailText() {
    let text = `└ チケット代金: ${this.ticketPriceText()}`;
    if (this.showFee && this.marginAmount() > 0) text += `\n└ 発券手数料: ${this.feeText()}`;
    return text;
  }

  priceDetailArray() {
    const arr = [];
    arr.push({ title: 'チケット代金:', price: this.ticketPriceText() });
    if (this.showFee && this.marginAmount() > 0) {
      arr.push({ title: '発券手数料:', price: this.feeText() });
    }
    return arr;
  }

  outwordPriceText() {
    return `往路${this.priceText()}`;
  }

  homewordPriceText() {
    return `復路${this.priceText()}`;
  }

  transitPriceText() {
    return `経路${this.priceText()}`;
  }

  shinkansenTooLate(): boolean {
    return !!this.shinkansen_too_late;
  }

  shinkansenDeadline() {
    return this.shinkansen_deadline;
  }

  airTooLate(): boolean {
    return !!this.air_too_late;
  }

  startDate() {
    return this.firstLegFromDate();
  }

  endDate() {
    return this.lastLegToDate();
  }

  startTime() {
    return this.departure_time || this.firstLegFromTime();
  }

  endTime() {
    return this.arrival_time || this.lastLegToTime();
  }

  startDateTime() {
    return moment(`${this.startDate()} ${this.startTime()}`);
  }

  handleTouched = () => {
    this.isSP = true;
  };

  setHovered = (value: boolean) => () => {
    if (!this.isSP) {
      this.hovered = value;
      app.render();
    }
  };

  setChangeableHovered = (value: boolean) => () => {
    if (!this.isSP) {
      this.changeableHovered = value;
      app.render();
    }
  };

  setUnchangeableHovered = (value: boolean) => () => {
    if (!this.isSP) {
      this.unchangeableHovered = value;
      app.render();
    }
  };

  toRaw() {
    return _.merge(this.raw, { changeable_air: this.isAirChangeable }) as Args;
  }

  reserveConfirmElementLabel() {
    if (this.air) {
      return '飛行機';
    }
    if (this.shinkansen) {
      return '新幹線';
    }
    return '在来線';
  }

  reserveConfirmElementLabelByType(type: string) {
    switch (type) {
      case 'train':
        return '在来線';
      case 'bus':
        return 'バス';
      default:
        return 'その他';
    }
  }

  reserveConfirmElementPrice(): number {
    if (this.air) {
      if (this.prices == null) {
        return 0;
      }
      return this.prices[this.domesticAirPriceIndex || 0].price;
    }
    return this.price;
  }

  isIncludeAirline(airlines: string[]): boolean {
    let flg = false;
    this.segments.forEach(segment => {
      segment.legs.forEach(leg => {
        if (airlines.includes(leg.airline_name)) {
          flg = true;
        }
      });
    });
    return flg;
  }

  getAirLineName(): string[] {
    const names: string[] = [];
    this.segments.forEach(segment => {
      segment.legs.forEach(leg => {
        if (leg.airline_name !== null && leg.airline_name !== '') {
          names.push(leg.airline_name);
        }
      });
    });
    return names;
  }

  resetPricesOriginal() {
    this.prices = this.pricesOriginal;
  }

  includeChangeablePrice(changeables: string[]) {
    if (this.prices == null) {
      return false;
    }
    this.prices = this.prices.filter(price => {
      if (changeables.includes(price.ticket_category)) {
        return true;
      }
      return false;
    });
    return false;
  }

  notIncludePremiumSeat() {
    this.prices = this.prices?.filter(price => {
      if (price.seat_type === '普通席') {
        return true;
      }
      return false;
    });
  }
}

export default Transit;
