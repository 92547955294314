import React from 'react';

import { styled } from '@this/constants/themes';
import type Trip from '@this/domain/trip/trip';
import { getSpacing } from '@this/shared/ui/theme';
import { PriceChangeForm } from '@this/components/arrangement/shared/price_change_form';
import { useBoolean } from '@this/components/shared/hooks/use_boolean';

import { ReservationCompleteButton } from './reservation_complete_button';
import { ConfirmRequestButton } from './confirm_request_button';
import { ArrangementCompleteButton } from './arrangement_complete_button';

type Props = {
  className?: string;
  trip: Trip;
  editingOrder: React.ComponentProps<typeof PriceChangeForm>['order'];
  onReloadTrip: React.ComponentProps<typeof PriceChangeForm>['reloadSelectedTrip'];
} & Pick<
  React.ComponentProps<typeof PriceChangeForm>,
  | 'serviceId'
  | 'shareholderTickets'
  | 'bulkTickets'
  | 'taxTypes'
  | 'suppliedItems'
  | 'paymentMethods'
  | 'shareholderInfos'
  | 'cabinOptions'
  | 'individualTargetSuppliedItems'
  | 'hotelElementProviderOptions'
>;

type StepStatusType = 'TODO' | 'CURRENT' | 'DONE';
const getStepStatus = (step: number, status: number): StepStatusType => {
  const todo = 'TODO';
  const current = 'CURRENT';
  const done = 'DONE';

  switch (step) {
    case 1:
    case 2:
      if ([0, 1].includes(status)) {
        return current;
      }
      if ([5, 6].includes(status)) {
        return todo;
      }
      return done;
    case 3:
      if ([0, 1, 2, 5, 6].includes(status)) {
        return todo;
      }
      if ([3].includes(status)) {
        return current;
      }
      return done;
    case 4:
      if ([0, 1, 2, 3, 5, 6].includes(status)) {
        return todo;
      }
      if ([4].includes(status)) {
        return current;
      }
      return done;
    case 5:
      if ([0, 1, 2, 3, 4, 5, 6].includes(status)) {
        return todo;
      }
      return done;
    default:
      return todo;
  }
};

export const ArrangementFlow = ({
  className,
  trip,
  serviceId,
  editingOrder,
  shareholderTickets,
  bulkTickets,
  onReloadTrip,
  hotelElementProviderOptions,
  taxTypes,
  suppliedItems,
  paymentMethods,
  shareholderInfos,
  cabinOptions,
  individualTargetSuppliedItems
}: Props) => {
  const [isEditingOrder, { toggle }] = useBoolean(false);

  return (
    <Root className={className}>
      <h3>手配の流れ</h3>
      <Section>
        <SectionTitle
          stepStatus={getStepStatus(1, trip.status)}
          data-testid={`arrangement-step-status__${getStepStatus(1, trip.status)}`}
        >
          1. 空席確認と値段変更（必要があれば）
        </SectionTitle>
        <p>
          お客様の要望を確認
          <br />
          ↓
          <br />
          ホテルはExpediaで自動予約されます。
          <br />
          飛行機・新幹線はYahoo路線案内で表示される
          <br />
          料金で仮決済されているので、
          <br />
          実際の空席・料金を確認の上、
          <br />
          変更する必要があれば下記をクリックしてフォームから変更
        </p>

        <PriceChangeForm
          serviceId={serviceId}
          selectedTrip={trip}
          order={editingOrder}
          isEditing={isEditingOrder}
          onToggle={toggle}
          shareholderTickets={shareholderTickets}
          bulkTickets={bulkTickets}
          onSubmitted={onReloadTrip}
          taxTypes={taxTypes}
          suppliedItems={suppliedItems}
          paymentMethods={paymentMethods}
          shareholderInfos={shareholderInfos}
          cabinOptions={cabinOptions}
          individualTargetSuppliedItems={individualTargetSuppliedItems}
          reloadSelectedTrip={onReloadTrip}
          hotelElementProviderOptions={hotelElementProviderOptions}
        />

        {trip.isConfirmationRequestable() && (
          <ButtonContainer>
            <ConfirmRequestButton trip={trip} onReloadTrip={onReloadTrip} />
          </ButtonContainer>
        )}
      </Section>
      <Section>
        <SectionTitle
          stepStatus={getStepStatus(3, trip.status)}
          data-testid={`arrangement-step-status__${getStepStatus(3, trip.status)}`}
        >
          2. 実際の手配と売上確定処理
        </SectionTitle>
        <p>
          ホテルの自動予約完了メッセージを待つ
          <br />
          （目安：申し込みから5分以内）
          <br />
          飛行機・新幹線の手配を実施
          <br />
          ↓
          <br />
          手配内容の詳細をお客様にご連絡し、
          <br />
          下記ボタンをクリック
          <br />
          (決済が確定されます)
        </p>
        <ButtonContainer>
          <ReservationCompleteButton serviceId={serviceId} trip={trip} onReloadTrip={onReloadTrip} />
        </ButtonContainer>
      </Section>
      <Section>
        <SectionTitle
          stepStatus={getStepStatus(4, trip.status)}
          data-testid={`arrangement-step-status__${getStepStatus(4, trip.status)}`}
        >
          3. チケット送付と請求書発行
        </SectionTitle>
        <p>飛行機の場合はEチケット送信、新幹線は郵送</p>
      </Section>
      <Section>
        <SectionTitle
          stepStatus={getStepStatus(5, trip.status)}
          data-testid={`arrangement-step-status__${getStepStatus(5, trip.status)}`}
        >
          4. 完了処理
        </SectionTitle>
        <p>3まで完了したら下記のボタンをクリック</p>
        <ButtonContainer>
          <ArrangementCompleteButton trip={trip} onReloadTrip={onReloadTrip} />
        </ButtonContainer>
      </Section>
    </Root>
  );
};

const Root = styled.div``;

const Section = styled.section`
  padding-left: ${getSpacing(5)}px;
  & + & {
    margin-top: ${getSpacing(4)}px;
  }
`;

const SectionTitle = styled.h3<{ stepStatus: StepStatusType }>`
  position: relative;
  &::before {
    position: absolute;
    left: -20px;
    content: '';
    width: 16px;
    height: 16px;
    background-image: url(${({ stepStatus }) => {
      const todo = '';
      const current = '/images/current.png';
      const done = '/images/check.png';

      switch (stepStatus) {
        case 'CURRENT':
          return current;
        case 'DONE':
          return done;
        default:
          return todo;
      }
    }});
    background-size: contain;
    background-repeat: no-repeat;
  }
`;

const ButtonContainer = styled.div`
  margin-top: ${getSpacing(2)}px;
`;
