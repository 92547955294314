import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { Loading } from '@this/src/components/shared/ui/feedbacks/loading';
import { Button } from '@this/shared/ui/inputs/button';
import { Fetcher } from '@this/src/util';

interface ResultTableProps {}

interface DomesticAirFlightCacheResponse {
  data: DomesticAirFlightCache[];
}

interface DomesticAirFlightCache {
  id: number;
  from_airport: string;
  to_airport: string;
  target_date: string;
  departure_time: string;
  arrival_time: string;
  source: string;
  carrer: string;
  flight_number: string;
  changeable: boolean;
  ticket_type: string;
  seat_type: string;
  price: number;
  created_at: string;
  updated_at: string;
  expire_at: string;
}

interface AirportData {
  from_airports: string[];
  to_airports: string[];
}

const ResultTable: React.FC<ResultTableProps> = () => {
  const [flightCaches, setFlightCaches] = useState<DomesticAirFlightCache[]>([]);
  const [fromAirports, setFromAirports] = useState<string[]>([]);
  const [toAirports, setToAirports] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [sourceFilter, setSourceFilter] = useState<string>('jal_official');
  const [fromAirportFilter, setFromAirportFilter] = useState<string>('');
  const [toAirportFilter, setToAirportFilter] = useState<string>('');
  const [targetDateFilter, setTargetDateFilter] = useState<string>(() => {
    const today = new Date();
    const nextWeek = new Date(today.getTime() + 7 * 24 * 60 * 60 * 1000);
    return nextWeek.toISOString().split('T')[0];
  });

  const handleSourceFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSourceFilter(e.target.value);
  };

  const handleFromAirportChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setFromAirportFilter(e.target.value);
  };

  const handleToAirportChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setToAirportFilter(e.target.value);
  };

  const handleTargetDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTargetDateFilter(e.target.value);
  };

  const handleDeleteAllCaches = async () => {
    if (confirm('本当にすべてのキャッシュを削除しますか？')) {
      try {
        await Fetcher.delete('/god/domestic_air_flight_caches');
        setFlightCaches([]);
      } catch (error) {
        setError('キャッシュデータの削除に失敗しました');
      }
    }
  };

  useEffect(() => {
    const fetchFlightCaches = async () => {
      try {
        setLoading(true);
        const params = new URLSearchParams();
        if (sourceFilter) params.append('source', sourceFilter);
        if (fromAirportFilter) params.append('from_airport', fromAirportFilter);
        if (toAirportFilter) params.append('to_airport', toAirportFilter);
        if (targetDateFilter) params.append('target_date', targetDateFilter);
        const response = await Fetcher.get<DomesticAirFlightCacheResponse>(
          `/god/domestic_air_flight_caches?${params.toString()}`
        );
        setFlightCaches(response.data);
      } catch (error) {
        setError('データの取得に失敗しました');
      } finally {
        setLoading(false);
      }
    };

    fetchFlightCaches();
  }, [sourceFilter, fromAirportFilter, toAirportFilter, targetDateFilter]);

  useEffect(() => {
    const fetchAirports = async () => {
      try {
        setLoading(true);
        const response = await Fetcher.get<AirportData>('/god/domestic_air/index_airports');
        setFromAirports(response.from_airports);
        setToAirports(response.to_airports);
      } catch (error) {
        setError('データの取得に失敗しました');
      } finally {
        setLoading(false);
      }
    };

    fetchAirports();
  }, []);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <Container style={{ fontSize: '10px' }}>
      <Filters>
        <div>
          <label>ソース:</label>
          <select value={sourceFilter} onChange={handleSourceFilterChange}>
            <option value="jal_official">JAL</option>
            <option value="ana_official">ANA</option>
          </select>
        </div>
        <div>
          <label htmlFor="from-airport">出発空港</label>
          <select id="from-airport" value={fromAirportFilter} onChange={handleFromAirportChange}>
            <option value="">すべて</option>
            {fromAirports.map((airport, index) => (
              <option key={index} value={airport}>
                {airport}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="to-airport">到着空港</label>
          <select id="to-airport" value={toAirportFilter} onChange={handleToAirportChange}>
            <option value="">すべて</option>
            {toAirports.map((airport, index) => (
              <option key={index} value={airport}>
                {airport}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="target-date">対象日</label>
          <input type="date" id="target-date" value={targetDateFilter} onChange={handleTargetDateChange} />
        </div>
      </Filters>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button onClick={handleDeleteAllCaches}>キャッシュデータをすべて削除</Button>
      </div>
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>出発空港</th>
            <th>到着空港</th>
            <th>対象日(JTC)</th>
            <th>出発時刻(JST)</th>
            <th>到着時刻(JST)</th>
            <th>ソース</th>
            <th>キャリア</th>
            <th>便名</th>
            <th>変更可否</th>
            <th>券種</th>
            <th>席種</th>
            <th>価格</th>
            <th>作成日時(UTC)</th>
            <th>更新日時(UTC)</th>
            <th>有効期限(UTC)</th>
          </tr>
        </thead>
        <tbody>
          {flightCaches.map(cache => (
            <tr key={cache.id}>
              <td>{cache.id}</td>
              <td>{cache.from_airport}</td>
              <td>{cache.to_airport}</td>
              <td>{cache.target_date}</td>
              <td>{cache.departure_time}</td>
              <td>{cache.arrival_time}</td>
              <td>{cache.source}</td>
              <td>{cache.carrer}</td>
              <td>{cache.flight_number}</td>
              <td>{cache.changeable ? '可' : '不可'}</td>
              <td>{cache.ticket_type}</td>
              <td>{cache.seat_type}</td>
              <td>{cache.price}</td>
              <td>{cache.created_at}</td>
              <td>{cache.updated_at}</td>
              <td>{cache.expire_at}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px;
`;

const Filters = styled.div`
  display: flex;
`;

export default observer(ResultTable);
