import React from 'react';
import { styled } from '@this/constants/themes';
import { rgba } from 'polished';
import type { Moment } from 'moment';
import moment from 'moment';
import A from '@this/shared/atoms/a';
import Button from '@this/shared/atoms/button';
import DatetimePicker from '@this/shared/datetime_picker/datetime_picker';
import SimpleLoading from '@this/shared/simple_loading/simple_loading';
import type { SnoozeTodoCreationParams } from '@this/components/arrangement/todo_list/types';
import type { TripStatusKey } from '@this/src/domain/trip/trip_status';
import type Trip from '../../../../domain/trip/trip';
import TripStatusIndicator from '../../shared/trip_status_indicator';

interface Props {
  trip: Trip;
  nextBusinessDay: Moment;
  editing: boolean;
  onCreateSnooze: (params: SnoozeTodoCreationParams) => Promise<void>;
  onCancelSnooze: (id: number) => Promise<void>;
  onOpenForm: () => void;
  onCloseForm: () => void;
}

interface State {
  snoozeTo: Moment;
  memo: string;
  saving: boolean;
  canceling: boolean;
}

class TodoCard extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      snoozeTo: moment(),
      memo: '',
      saving: false,
      canceling: false
    };
  }

  saveSnooze = async () => {
    this.setState({ saving: true });
    try {
      const params: SnoozeTodoCreationParams = {
        trip_id: this.props.trip.id,
        snooze_to: this.state.snoozeTo.format('YYYY-MM-DD hh:mm'),
        memo: this.state.memo
      };
      await this.props.onCreateSnooze(params);
    } catch (e) {
      this.setState({ saving: false });
    }
  };

  async cancelSnooze(id: number) {
    this.setState({ canceling: true });
    try {
      await this.props.onCancelSnooze(id);
    } catch (e) {
      this.setState({ canceling: false });
    }
  }

  render() {
    try {
      const { trip, nextBusinessDay, editing, onCloseForm, onOpenForm } = this.props;
      const { snoozeTo, memo, saving, canceling } = this.state;
      const t = trip;
      return (
        <TheTrip urgent={t.startTime && t.startTime.startOf('day').isBefore(nextBusinessDay)} key={t.id}>
          <Cell>
            <A href={`/arrangement/virtual_counter?trip_id=${t.id}`} target="_blank" rel="noopener noreferrer">
              {t.id}
            </A>
          </Cell>
          <Cell>
            <TripStatusIndicator status={t.status as TripStatusKey} />
          </Cell>
          <Cell>
            {t.order.orderItems.map(item => (
              <Item key={item.id}>
                {item.categoryStr()}（{item.statusStr}）
              </Item>
            ))}
          </Cell>
          <Cell>
            {t.order.orderItems.map(item => (
              <Item key={item.id}>
                <Date>
                  {item.startDateStr()}〜{item.endDateStr()}
                </Date>
              </Item>
            ))}
          </Cell>
          <Cell>{t.user.organization && t.user.organization.name}</Cell>
          <Cell>{t.latestUserMessage}</Cell>
          <Cell>
            {(() => {
              const snooze = t.currentSnooze();
              if (snooze) {
                return (
                  <>
                    <div>{snooze.snoozeTo.format('YYYY-MM-DD hh:mm')}</div>
                    <div>{snooze.memo}</div>
                    {canceling ? (
                      <SimpleLoading />
                    ) : (
                      <TheButton onClick={() => this.cancelSnooze(snooze.id)}>TODOに戻す</TheButton>
                    )}
                  </>
                );
              }
              return (
                <>
                  {saving ? (
                    <SimpleLoading />
                  ) : editing ? (
                    <>
                      <DatetimePicker
                        value={snoozeTo}
                        onChange={d => this.setState({ snoozeTo: d })}
                        showTime
                        showPast={false}
                        disabledDays={0}
                      />
                      <input
                        type="text"
                        placeholder="メモ"
                        value={memo}
                        onChange={e => this.setState({ memo: e.target.value })}
                      />
                      <ButtonArea>
                        <TheButton onClick={() => onCloseForm()}>キャンセル</TheButton>
                        <SaveButton onClick={this.saveSnooze}>保存</SaveButton>
                      </ButtonArea>
                    </>
                  ) : (
                    <Button onClick={() => onOpenForm()}>設定</Button>
                  )}
                </>
              );
            })()}
          </Cell>
        </TheTrip>
      );
    } catch (e) {
      utils.sendErrorObject(e);
      return null;
    }
  }
}

const TheTrip = styled.tr<{ urgent: boolean }>`
  border: 1px solid $gray-color-border;
  padding: 2px;
  margin-bottom: 10px;
  background: ${props => (props.urgent ? rgba('red', 0.3) : 'white')};
`;

const Cell = styled.td`
  padding: 5px 10px;
`;

const Item = styled.div``;

const Date = styled.span``;

const ButtonArea = styled.div`
  display: flex;
`;

const TheButton = styled(Button)`
  min-width: 80px;
`;

const SaveButton = styled(TheButton)`
  margin-left: 10px;
`;

export default TodoCard;
