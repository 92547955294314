import type { Moment } from '@this/src/lib/moment';
import moment from '@this/src/lib/moment';
import { observable } from 'mobx';
import type { OrderItemJobTypeKey } from '@this/domain/order_item/order_item_job_type';
import type { OrderItemStatusKey } from '@this/domain/order_item/order_item_status';

export interface JalReservationTodoArgs {
  id: number;
  trip_id: number;
  trace_id: number;
  order_item_id: number;
  reservation_number: number;
  target_date: string;
  reservation_date: string;
  flight_number: string;
  price: number;
  flight_name: string;
  created_at: string;
  job_type: OrderItemJobTypeKey;
  status: OrderItemStatusKey;
  is_inventoried: boolean;
  inventoried_by: string;
  inventoried_at: string;
  last_log_message: string;
  last_log_message_arranger: string;
}

export interface JalReservationTodoProps {
  id: number;
  tripId: number;
  traceId: number;
  orderItemId: number;
  reservationNumber: number;
  targetDate: Moment | null;
  reservationDate: Moment | null;
  flightNumber: string;
  price: number;
  flightName: string;
  createdAt: Moment;

  jobType: OrderItemJobTypeKey;
  status: OrderItemStatusKey;
  isInventoried: boolean;
  inventoriedBy: string;
  inventoriedAt: string;

  lastLogMessage: string;
  lastLogMessageArranger: string;
}

export const convertJalReservationTodo = (args: JalReservationTodoArgs): JalReservationTodoProps => ({
  id: args.id,
  tripId: args.trip_id,
  traceId: args.trace_id,
  orderItemId: args.order_item_id,
  reservationNumber: args.reservation_number,
  targetDate: args.target_date ? moment(args.target_date) : null,
  reservationDate: args.reservation_date ? moment(args.reservation_date) : null,
  flightNumber: args.flight_number,
  price: args.price,
  flightName: args.flight_name,
  createdAt: moment(args.created_at),
  jobType: args.job_type,
  status: args.status,
  isInventoried: args.is_inventoried,
  inventoriedBy: args.inventoried_by,
  inventoriedAt: args.inventoried_at,
  lastLogMessage: args.last_log_message,
  lastLogMessageArranger: args.last_log_message_arranger
});

export default class JalReservationTodo {
  @observable readonly id: number;

  @observable readonly tripId: number;

  @observable readonly traceId: number;

  @observable readonly orderItemId: number;

  @observable readonly reservationNumber: number;

  @observable readonly targetDate: Moment | null;

  @observable readonly reservationDate: Moment | null;

  @observable readonly flightNumber: string;

  @observable readonly price: number;

  @observable readonly flightName: string;

  @observable readonly createdAt: Moment;

  @observable jobType: OrderItemJobTypeKey;

  @observable status: OrderItemStatusKey;

  @observable readonly isInventoried: boolean;

  @observable readonly inventoriedBy: string;

  @observable readonly inventoriedAt: string;

  @observable readonly lastLogMessage: string;

  @observable readonly lastLogMessageArranger: string;

  constructor(args: JalReservationTodoProps) {
    this.id = args.id;
    this.tripId = args.tripId;
    this.traceId = args.traceId;
    this.orderItemId = args.orderItemId;
    this.reservationNumber = args.reservationNumber;
    this.targetDate = args.targetDate;
    this.reservationDate = args.reservationDate;
    this.flightNumber = args.flightNumber;
    this.price = args.price;
    this.flightName = args.flightName;
    this.createdAt = args.createdAt;

    this.jobType = args.jobType;
    this.status = args.status;
    this.isInventoried = args.isInventoried;
    this.inventoriedBy = args.inventoriedBy;
    this.inventoriedAt = args.inventoriedAt;
    this.lastLogMessage = args.lastLogMessage;
    this.lastLogMessageArranger = args.lastLogMessageArranger;
  }
}
