/* eslint-disable max-lines */
import _ from 'lodash';
import type { FlightType, PackageSearchQueryItems } from '@this/domain/search_query';
import type { TransitSearchApi } from '@this/domain/organization/organization2';
import { ALL_CABIN } from '@this/domain/search_query';
import type SearchQueryItem from './search_query_item';
import type { Airline } from './select_store';
import type SelectStore from './select_store';
import type { AirportSet } from './flight/flight_list';
import FlightList from './flight/flight_list';
import HotelList from './hotel/hotel_list';
import type User from './user/user';
import TransitList from './transit/transit_list';

export interface MarginTypeJson {
  // MarginType.rb
  id: number;
  base_type: 'fixed' | 'percentage';
  base_amount: number;
  organization_id: number;
}

export interface SelectResponse {
  id: number;
  signed_in: boolean;
  show_fee: boolean;
  include_tax: boolean;
  margin_types: MarginTypeJson[];
  search_query_items: never[];
  transport_available: boolean;
  hotel_available: boolean;
  traveler_representative: User;
  default_seat_classes?: string[];
}

export interface LegJson {
  name: string;
  from: {
    code: string | null;
    date: string;
    name: string;
    time: string;
    time_full: string;
  };
  to: {
    code: string | null;
    date: string;
    name: string;
    time: string;
    time_full: string;
  };
  text: string;
  type: null;
  airline_name: string;
}

export interface LegSummaryJson {
  type: string;
  indexes: string[];
  from: {
    name: string;
    express_station: string;
    time: string;
  };
  to: {
    name: string;
    express_station: string;
    time: string;
  };
  name: string | null;
  price: number | null;
  prices: TransitPriceJson[];
  stations: number | null;
  totaltime: string | null;
  transitcount: number | null;
}

export interface SegmentJson {
  // Transport::Segment.rb
  legs: LegJson[];
  leg_summaries: LegSummaryJson[];
  old_price: number;
  price: number;
  changeable_price: number;
  unchangeable_price: number;
  changeable_seat_type: string | null;
  unchangeable_seat_type: string | null;
  changeable_available_seat_count: string | null;
  unchangeable_available_seat_count: string | null;
  type: 'air' | 'shin' | 'express';
  boarding: null;
  arrival: null;
  distance: null;
  route_time: null;
}

export interface TransitPriceJson {
  price: number;
  seat_type: string;
  ticket_category: string;
  ticket_type: string;
  seat_count: string;
}

export interface TransitJson {
  // Transit.rb
  id: string;
  price: number;
  price_range: string;
  price_text: string | null;
  prices: TransitPriceJson[];
  changeable_price: number | null;
  changeable_price_text: string | null;
  changeable_pre_sale: boolean;
  unchangeable_price: number | null;
  unchangeable_pre_sale: boolean;
  routes: string[] | null;
  text: string;
  selected_text: string;
  legsummaries_fromto_jrair: string;
  legsummaries_fromto_before: string;
  legsummaries_fromto_after: string;
  legsummaries_typeprice_before: legsummaryesTypeprice;
  legsummaries_typeprice_after: legsummaryesTypeprice;
  legs_fromto: string;
  time: string | null;
  departure_time: string | null;
  arrival_time: string | null;
  transits: never[];
  segments: SegmentJson[];
  url: string | null;
  shinkansen: boolean;
  shinkansen_too_late: boolean | null;
  shinkansen_deadline: null;
  air_too_late: boolean;
  air: boolean;
  airport: string | null;
  changeable_air: null;
  domestic_air_price_index: number | null;
  selected_price: number;
  fee: number;
  peoplenum: number;
  search_query_id: number;
  search_query_item_id: number | null;
  optimal: boolean | null;
  package: true | null;
  additional_price: null;
  unchangeable_price_text: string | undefined;
  is_rakuten_package: boolean | null;
  railway_distance: number | null;
  is_unreserved_seat_available: boolean | null;
  flight_names: string[];
}

export interface legsummaryesTypeprice {
  type: string;
  price: number;
}

// 行き・帰り、出発・到着の航空便コード。
// 楽天パッケージのみで使う。複数拠点は考慮していない。
export interface RakutenPackageAirCode {
  outwardDeparture: string;
  outwardArrival: string;
  homewardDeparture: string;
  homewardArrival: string;
}

export interface TransportSearchResultJson {
  // Transport::TransportSearchResult.rb
  outword: TransitJson[];
  homeword: TransitJson[];
  changeable_air?: boolean;
}

export interface TransitResponse extends TransportSearchResultJson {
  sameStation: string;
}

export type SupplierDomain = 'domestic' | 'foreign';

export interface HotelJson {
  version: string | null;
  score_index: number;
  id: string | null;
  hotel_id: number | null;
  search_query_id: number | null;
  search_query_item_id: number | null;
  name: string | null;
  address: string | null;
  price: number | null;
  price_detail: {
    nightly_rate: string;
    tax_and_service_fee: number;
  } | null;
  price_detail_text: string | null;
  average_price: number | null;
  average_base_price: number | null;
  average_price_with_tax: number | null;
  average_base_price_with_tax: number | null;
  mandatory_tax: number | null;
  room_rate: number | null;
  room_tax: number | null;
  image: string | null;
  images: string[] | null;
  checkin: string | null;
  checkout: string | null;
  note?: string | null;
  checkin_date: string | null;
  checkout_date: string | null;
  walkminute: string | null;
  latitude: number | null;
  longitude: number | null;
  distance: number | null;
  station_distance: number | null;
  bed_types: { description: string; id: number }[] | null;
  breakfast: string | null;
  cancel_message: null;
  roomnum: string | null;
  stay_days: number | null;
  raw_hotel: never;
  raw_detail: never;
  raw_room: never;
  refundability_score: number | null;
  refund: string | null;
  refundable: null;
  query: never;
  room_type: string | null;
  checkin_instructions: string | null;
  special_checkin_instrcutions: null;
  cancel_policy: null;
  property_amenities: null;
  room_amenities: null;
  sold_out: boolean | null;
  type: 'r' | 'e';
  operator_class_name: string | null;
  detail_path: string | null;
  reserve_url: string | null;
  fee_ratio: number | null;
  user_id: number | null;
  package_type: 'FP' | null;
  package_base_price: number | null;
  too_late?: boolean;
  tel?: string;
  optimal?: boolean;
  rating: number | null;
  supplier_domain?: SupplierDomain;
  parking_information?: string;
  plan_name?: string;
  plan_detail?: string;
}

export interface HotelsResponse {
  search_result: {
    hotels: HotelJson[];
    prefecture_id?: number;
    municipality_code?: string;
  };
  price: number;
  price_limit_over_count: number;
  filtered_by_organization_breakfast_flag: boolean;
  rakuten_static_file_updated_at: string | null;
}

export interface FlightSegmentJson {
  // Flight::FlightSegment.rb
  from: {
    code: string;
    date: string;
    datetime: string;
    name: string; // 発着地の名前。例: 東京
    time: string;
    time_zone: string;
  };
  to: {
    code: string;
    date: string;
    datetime: string;
    name: string;
    time: string;
    time_zone: string;
  };
  duration: null;
  marketing_carrier: string;
  operating_carrier: string;
  flight_number: string;
  booking_class: string;
  cabin: string;
  name: string;
  carrier_name: string;
  fare_basis_code?: string;
  air_route_detail?: AirRouteDetail;
  api_related_data: any;
}

export interface AirRouteDetail {
  cancel_policies: string[];
  change_reservation_policies: string[];
}

export interface FlightSliceJson {
  // Flight::FlightSlice.rb
  id: string;
  duration: string;
  duration_raw: number;
  segments: FlightSegmentJson[];
  deadline: null;
  time_to_live: string | null;
  ticketing_enable: boolean;
  routes: never[];
  description: string;
}

export interface FlightJson {
  // Flight::Flight.rb
  id: number;
  itineraries: FlightSliceJson[];
  outword: FlightSliceJson;
  homeword: FlightSliceJson | null;
  price: number;
  price_text: string;
  description: string;
  score: number;
  fee: number;
  peoplenum: number;
  search_query_id: number;
  search_query_item_id: number;
  ticketing_enable: boolean;
  seat_reservation_enable: boolean;
  minirule?: string;
}

export type FlightsResponse = {
  carrier_ids: string[];
  airports: AirportSet[];
  flights: FlightJson[];
  flight_type: 'N' | 'C' | '';
  applied_query_setting: { carrier: boolean; flight_type: boolean };
};

interface PackagesResponse {
  show: true;
  errors?: string[] | undefined;
  results?: (TransitJson[] | HotelJson[])[];
}

class SelectRepository {
  private store: SelectStore;

  constructor(args: { store: SelectStore }) {
    this.store = args.store;
  }

  async initPromise(query: any): Promise<void> {
    try {
      const result = await utils.jsonPromise<SelectResponse>(`/select.json?${Date.now()}`, query, 'post');
      this.store.query.setId(result.id, result.search_query_items);
      if (!query.partial && this.store.query.getInputItems().length !== result.search_query_items.length) {
        this.store.query.replaceItems(result.id, result.search_query_items);
        this.store.result.replaceItems(this.store.query.items);
      }
      this.store.result.setSearchQueryId(result.id);
      this.store.result.setShowFee(result.show_fee);
      this.store.result.setIncludeTax(result.include_tax);
      this.store.result.setMarginTypes(result.margin_types);
      this.store.result.setTransportAvailable(result.transport_available);
      this.store.result.setHotelAvailable(result.hotel_available);
      this.store.result.setTravelerRepresentative(result.traveler_representative);
      this.store.signedIn = result.signed_in;
    } catch (e) {
      utils.sendErrorObject(e);
    }
  }

  async startSearch(themeClass?: string) {
    try {
      await this.initPromise(utils.getParams());
      const transitSearchApi = this.store.user?.organization?.transit_search_api || 'legacy';
      this.fetchElements(this.store.query.items, transitSearchApi);
      const packageItems = this.store.query.identifyPackageQueryItems();

      if (location.pathname === '/select' && themeClass && packageItems.length > 0) {
        await Promise.all([this.fetchPackages(packageItems[0]), this.fetchJrPackages(themeClass)]);
      } else {
        const message = [
          'ご指定の検索条件では出張パッケージの検索はできません',
          '出発地・目的地が一致する往復の航空券とホテルを指定して検索してください'
        ];
        this.store.packageErrors.ANA = message;
        this.store.packageErrors.JAL = message;
        this.store.jrPackageErrors = message;
        this.store.packageLoading.ANA = false;
        this.store.packageLoading.JAL = false;
        this.store.jrPackageLoading = false;
      }
    } catch (err) {
      utils.sendErrorObject(err);
    }
  }

  async reSearchHotel(lati: number, lag: number) {
    try {
      this.reFetchElements(this.store.query.items, lati, lag);
    } catch (err) {
      utils.sendErrorObject(err);
    }
  }

  fetchPackages(packageItem: PackageSearchQueryItems) {
    const outFlightName = packageItem.origin.flightNum;
    const homeFlightName = packageItem.destination.flightNum;
    const { hotelName } = packageItem.hotel;

    this.fetchRakutenPackages({
      packageItem,
      outFlightName,
      homeFlightName,
      hotelName,
      firstSearch: true
    });
  }

  // 楽天パッケージから行き・帰りの航空便、ホテルの情報を取得する。
  //
  // @param {Object} opts オプション
  // @param {string} opts.outFlightName 絞り込みたい行きの航空便の名前。
  // @param {string} opts.homeFlightName 絞り込みたい帰りの航空便の名前。
  // @param {string} opts.airline 絞り込みたい航空種別。
  // @param {string} opts.hotelName 絞り込みたいホテル名。
  async fetchRakutenPackages(
    opts: {
      packageItem?: PackageSearchQueryItems;
      outFlightName?: string;
      homeFlightName?: string;
      airlines?: Airline[];
      hotelName?: string | null | undefined;
      airCode?: RakutenPackageAirCode | null;
      firstSearch?: boolean;
    } = {}
  ) {
    const airlines: Airline[] = utils.dig(opts, 'airlines') || ['JAL', 'ANA'];
    airlines.forEach(airline => {
      this.store.packageLoading[airline] = true;
    });

    const items = opts.packageItem || this.store.query.identifyPackageQueryItems()[0];
    const outwardTransitQueryItem: SearchQueryItem = items.origin;
    const homewardTransitQueryItem: SearchQueryItem = items.destination;
    if (opts) {
      if (opts.outFlightName) {
        outwardTransitQueryItem.flightNum = opts.outFlightName;
      }
      if (opts.homeFlightName) {
        homewardTransitQueryItem.flightNum = opts.homeFlightName;
      }
    }

    if (opts.hotelName) {
      const hotelQueryItem: SearchQueryItem = items.hotel || {};

      // ホテル名の一意性をある程度担保できそう、かつ、
      // パラメーターとしては長過ぎない文字数。
      const maxHotelNameLength = 20;

      const hotelName: string = _.truncate(opts.hotelName, { length: maxHotelNameLength, omission: '' });

      hotelQueryItem.hotelName = hotelName;
    }

    await Promise.all([
      SelectRepository.hotelGeocodesPromise(this.store.query.items),
      SelectRepository.transportGeocodesPromise(this.store.query.items)
    ]);

    if (this.store.query.items.some(item => !item.domestic)) {
      this.store.showPackage = false;
      airlines.forEach(airline => {
        this.store.packageLoading[airline] = false;
      });
      this.store.result.setType('separate');
      return;
    }

    let airCodeParams: Record<string, unknown> | null;
    if (opts && opts.airCode) {
      airCodeParams = {
        air_code: {
          // パラメーターの名前は短くする。
          out_dep: opts.airCode.outwardDeparture,
          out_arr: opts.airCode.outwardArrival,
          home_dep: opts.airCode.homewardDeparture,
          home_arr: opts.airCode.homewardArrival
        }
      };
    }

    const firstSearch = utils.dig(opts, 'firstSearch');

    await Promise.all(
      airlines.map(async airline => {
        const response = await utils.jsonPromise<PackagesResponse>(
          `/rakuten_packages?${Date.now()}`,
          Object.assign(
            this.store.query.rakutenPackageParams(),
            { airline, first_search: firstSearch },
            airCodeParams
          )
        );

        if (response) {
          if (response.results) {
            this.store.result.setPackageResult(response.results, 'rakuten', airline);
            this.fetchIndirectFlights(airline);
            const hotelResult = this.store.result.packageItems[airline].find(
              item => item.elementType() === 'hotel'
            );
            if (hotelResult && hotelResult.elementList instanceof HotelList && hotelResult.queryItem.destGeocode) {
              const { location } = hotelResult.queryItem.destGeocode;
              hotelResult.setDestLocation(location);
              hotelResult.elementList.fetchDistances(location);
            }
          } else if (response.errors) {
            this.store.packageErrors[airline] = response.errors;
          }
        }

        this.store.packageLoading[airline] = false;
        app.render();
      })
    );
  }

  fetchIndirectFlights(airline: Airline) {
    this.store.result.packageItems[airline].forEach(item => {
      const elementList = item.elementList;
      if (!(elementList instanceof TransitList)) return;

      const transitWithVia = elementList.list.filter(transit => {
        const segment = transit.segments[0];
        if (!segment) return false;

        const hasVia = transit.flightNames && transit.flightNames.length > 1; // 便名が二つ以上ある場合
        const via = segment.legs.filter(l => l.from.name === '' || l.to.name === '');
        return hasVia && via.length;
      });
      // 選択済みの経由便情報を最優先で取得したい
      const selectedIndex = transitWithVia.findIndex(t => t.id === elementList.currentId);
      if (selectedIndex >= 0) {
        [transitWithVia[0], transitWithVia[selectedIndex]] = [transitWithVia[selectedIndex], transitWithVia[0]];
      }
      transitWithVia.forEach(transit => {
        utils
          .jsonPromise<LegJson[]>('/rakuten_packages/via_flights', { transit_id: transit.id, airline })
          .then(res => {
            transit.segments[0].legs = res;
            app.render();
          });
      });
    });
  }

  async fetchJrPackages(themeClass: string) {
    if (themeClass !== 'mynavi') {
      await Promise.all([
        SelectRepository.hotelGeocodesPromise(this.store.query.items),
        SelectRepository.transportGeocodesPromise(this.store.query.items)
      ]);

      const response = await utils.jsonPromise<PackagesResponse>(
        `/jr_packages?${Date.now()}`,
        this.store.query.jrPackageParams()
      );

      if (response) {
        if (response.results) {
          this.store.result.setJrPackageResult(response.results);

          const hotelResult = this.store.result.jrPackageItems.find(item => item.elementType() === 'hotel');
          if (hotelResult && hotelResult.elementList instanceof HotelList && hotelResult.queryItem.destGeocode) {
            hotelResult.elementList.fetchDistances(hotelResult.queryItem.destGeocode.location);
          }
        }
      }
    }

    this.store.jrPackageLoading = false;
    app.render();
  }

  fetchElements(items: SearchQueryItem[], transitSearchApi: TransitSearchApi) {
    SelectRepository.fetchElementHotels(items);
    SelectRepository.fetchElementDomesticTransports(items, transitSearchApi);
    this.fetchElementForeignTransports(items);
  }

  static async fetchElementHotels(items: SearchQueryItem[]) {
    const hotelItems = _.filter(items, item => item.itemType === 'hotel');
    _.each(hotelItems, item => item.fetchHotels());
  }

  reFetchElements(items: SearchQueryItem[], lat: number, lag: number) {
    SelectRepository.reFetchElementHotels(items, lat, lag);
  }

  static async reFetchElementHotels(items: SearchQueryItem[], lat: number, lng: number) {
    const hotelItems = _.filter(items, item => item.itemType === 'hotel');
    _.each(hotelItems, item => item.reFetchHotels(lat, lng));
  }

  static fetchElementDomesticTransports(items: SearchQueryItem[], transitSearchApi: TransitSearchApi) {
    SelectRepository.transportGeocodesPromise(items).then(items => {
      items
        .filter(v => v.domestic)
        .forEach(item => {
          item.fetchDomesticTransits(transitSearchApi);
        });
    });
  }

  fetchElementForeignTransports(items: SearchQueryItem[]) {
    const maxSegments = this.store.maxSegments();

    SelectRepository.transportGeocodesPromise(items).then(items => {
      const foreignItems = items.filter(item => !item.domestic);

      SelectRepository.groupItems(foreignItems, maxSegments).forEach(items => {
        this.fetchForeignFlights(items);
      });
    });
  }

  /*
   * Amadeusの場合は3区間毎、Travelportの場合は6区間毎にPNRをまとめる
   * https://aitravel.atlassian.net/browse/AITRAVEL-3846
   */
  static groupItems(items: SearchQueryItem[], maxSegments: number): SearchQueryItem[][] {
    return _.reduce(
      items,
      (res: SearchQueryItem[][], item) => {
        const currentLength = res.length;
        if (currentLength === 0 || res[currentLength - 1].length === maxSegments) {
          res[currentLength] = [];
          res[currentLength].push(item);
        } else {
          res[currentLength - 1].push(item);
        }
        return res;
      },
      []
    );
  }

  async fetchForeignFlights(items: SearchQueryItem[]) {
    let params_cabin: any;
    const isDefaultSeatClassesIncludeAllCabin = ALL_CABIN.every(cabin =>
      this.store.result.query.defaultSeatClasses.includes(cabin)
    );
    if (this.store.query.cabin[0] === 'all') {
      if (isDefaultSeatClassesIncludeAllCabin) {
        params_cabin = '';
      } else {
        params_cabin = _.cloneDeep(this.store.result.query.defaultSeatClasses).join(',');
      }
    } else if (this.store.query.cabin.length === 0) {
      if (isDefaultSeatClassesIncludeAllCabin) {
        params_cabin = '';
      } else {
        params_cabin = _.cloneDeep(this.store.result.query.defaultSeatClasses).join(',');
      }
    } else if (ALL_CABIN.every(cabin => this.store.query.cabin.includes(cabin))) {
      params_cabin = '';
    } else {
      params_cabin = this.store.query.cabin.join(',');
    }
    const params = {
      type: 'circuit',
      queries: _.map(items, item => item.flightParams()),
      peoplenum: this.store.query.peoplenum,
      cabin: params_cabin,
      flight_type: this.store.query.flightType
    };
    const res = await utils.jsonPromise<FlightsResponse>(`/flights?${Date.now()}`, params);
    const list = new FlightList(res.flights, {
      showFee: this.store.result.showFee,
      marginType: this.store.result.marginTypes.foreignMarginType(),
      current: items[0].selectedElement,
      cabins:
        this.store.query.cabin[0] === 'all'
          ? _.cloneDeep(this.store.result.query.defaultSeatClasses)
          : this.store.query.cabin,
      handleChange: SelectRepository.foreignQueryItems(items)[0].resultItem.handleChange || undefined
    });
    SelectRepository.foreignQueryItems(items).forEach((item, i) => {
      if (item.resultItem) {
        item.resultItem.queryItem.airports = res.airports[i];
        item.resultItem.setFlightIndex(i);
        item.resultItem.setFlightList(list);
        item.resultItem.setLoading(false);
        item.setCarrierIds(res.carrier_ids.length ? res.carrier_ids : ['all']);
      }
    });
    list.firstHandleChange();
    this.store.query.flightType = res.flight_type;
    this.store.appliedFlightQuerySetting = res.applied_query_setting;
  }

  getPrefecture(i: number) {
    if (this.store.query.items[i].domestic) {
      return (
        (this.store.query.items[i].destGeocode && this.store.query.items[i].destGeocode?.prefectureName) || ''
      );
    }
    return '';
  }

  static foreignQueryItems(items: SearchQueryItem[]): SearchQueryItem[] {
    return _.filter(items, item => item.itemType === 'transport' && !item.domestic);
  }

  static transportGeocodesPromise(items: SearchQueryItem[]) {
    const transportItems = _.filter(items, item => item.itemType === 'transport');
    const transportPromises = _.map(transportItems, item => item.transportGeocodesPromise());
    return Promise.all(transportPromises);
  }

  static hotelGeocodesPromise(items: SearchQueryItem[]) {
    const transportItems = _.filter(items, item => item.itemType === 'hotel');
    const transportPromises = _.map(transportItems, item => item.hotelGeocodesPromise());
    return Promise.all(transportPromises);
  }

  updateCabin = (cabins: string[]) => {
    this.store.query.setCabin(cabins);
  };

  updateCarrierIds = (carrierIds: string[]) => {
    this.store.query.setCarrierList(this.store.query.carrierList || this.store.result.flightList!.carrierList);
  };

  updateFlightType = (flightType: FlightType) => {
    this.store.query.setFlightType(flightType);
  };

  reloadFlights() {
    this.store.setTab('none');
    const foreignQueryItems = SelectRepository.foreignQueryItems(this.store.query.items);
    _.each(foreignQueryItems, item => item.resultItem.setLoading(true));
    this.store.pushHistory();
    this.fetchElementForeignTransports(foreignQueryItems);
  }

  startPartialSearch(item: SearchQueryItem) {
    const query = { ...utils.getParams(), partial: true };
    this.initPromise(query)
      .then(
        () => {
          const transitSearchApi = this.store.user?.organization?.transit_search_api || 'legacy';
          this.fetchElements([item], transitSearchApi);
        },
        (error: any) => {
          this.store.setBasicError();
        }
      )
      .catch(e => {
        utils.sendErrorObject(e);
      });
  }
}

export default SelectRepository;
