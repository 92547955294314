import React from 'react';
import _ from 'lodash';

import type OrderItem from '../../../../../domain/order_item';

interface Props {
  orderItem: OrderItem;
}

interface State {
  traceId: string;
  editing: boolean;
  saving: boolean;
  succeed: boolean;
}

class TraceIdEditor extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const traceId = props.orderItem.traceId;
    this.state = {
      traceId: traceId ? traceId.toString() : '',
      editing: false,
      saving: false,
      succeed: false
    };
  }

  getUpdateUrl() {
    return `/arrangement/order_items/${this.props.orderItem.id}`;
  }

  async updateTraceId(): Promise<void> {
    this.setState({ saving: true });
    const args = { trace_id: this.state.traceId };
    return utils.jsonPromise<void>(this.getUpdateUrl(), args, 'PUT').then(() =>
      this.setState({
        saving: false,
        succeed: true,
        editing: false
      })
    );
  }

  handleEdit(e: React.FormEvent<HTMLInputElement>) {
    e.preventDefault();
    this.setState({ editing: true });
  }

  handleChange(traceId: string) {
    this.setState({ traceId, succeed: false });
  }

  handleSubmit(e: React.FormEvent<HTMLInputElement>) {
    e.preventDefault();
    this.updateTraceId();
  }

  handleCancel(e: React.FormEvent<HTMLInputElement>) {
    e.preventDefault();
    this.setState({ editing: false });
  }

  render() {
    const classBase = 'trace-id-editor';
    const state = this.state;
    return (
      <div className={classBase}>
        {state.editing ? (
          <>
            trace_id:
            <input type="text" value={state.traceId} onChange={e => this.handleChange(e.target.value)} />
            <input type="submit" value="保存" onClick={e => this.handleSubmit(e)} disabled={state.saving} />
            <input type="submit" value="戻す" onClick={e => this.handleCancel(e)} />
          </>
        ) : (
          <>
            trace_id: {state.traceId}
            <input type="submit" value="変更" onClick={e => this.handleEdit(e)} />
            {state.succeed ? <div className="green">保存しました</div> : null}
          </>
        )}
      </div>
    );
  }
}

export default TraceIdEditor;
