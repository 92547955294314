###########################################################################################
# 注意！
#
# 現在このファイルはts化中です。このファイルへ関数の追加は行わないでください。
# また関数を変更する場合は、util/**/*.util.tsに同じ関数があるので、そちらにも反映をおねがいします。
###########################################################################################

_ = require('lodash')
Notification = require('./notification').default

module.exports =
  # 金額の文字列へ変換。
  # たとえば num が 3000 の時、"3,000" を返す。
  digits: (num) ->
    type = typeof num
    return null unless type == 'number' || type == 'string'
    num.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")

  # UT済
  formatPrice: (int) ->
    if int != null
      digits(int) + '円'

  # UT済
  formatPriceText: (string) ->
    if string != null
      string.split(',').join('').trim()

  # UT済
  formatPriceWithMark: (int) ->
    if int != null
      '¥' + digits(int)

  # 金額のレンジに合わせて「￥」マークを返す。
  # TODO: 使われてない。
  formatPriceWithRange: (range) ->
    if range == null
      return '○○○○○'

    range = parseInt(range, 10)
    if range == 0
      '○○○○○'
    else if range == 1
      '○○○○○'
    else if range == 2
      '○○○￥￥'
    else if range == 3
      '○○￥￥￥'
    else if range == 4
      '○￥￥￥￥'
    else if range == 5
      '￥￥￥￥￥'
    else
      '○○○○○'

  # UT済
  fetchCityName: (lat, lng) ->
    params =
      latlng: lat + ',' + lng
      language: 'ja'
      key: 'AIzaSyD5SUOf50yWsZrP-EAxGrEgpQd2ZcsMkYA'
    jsonPromise("https://maps.googleapis.com/maps/api/geocode/json", params)

  # TODO: 使われてない
  fetchDistance: (origin, dest) ->
    fetchDistanceMatrix([origin], [dest]).then (result) ->
      res = result.response
      seconds = dig(res, 'rows', 0, 'elements', 0, 'duration', 'value')
      if seconds
        Math.floor(seconds / 60) + '分'

  # fetchDistanceからのみ使用される
  fetchDistanceMatrix: (origins, destinations) ->
    service = new google.maps.DistanceMatrixService()
    params =
      origins: origins
      destinations: destinations
      travelMode: google.maps.TravelMode.WALKING

    new Promise (resolve, reject) ->
      service.getDistanceMatrix params, (response, status) ->
        resolve { 'response': response, 'status': status }

  # UT済
  getParam: (key) ->
    @getParams()[key]

  # UT済
  # 表示中の画面のパラメーターをオブジェクトとして取得。
  getParams: ->
    if window.location
      return {} if _.isEmpty(window.location.search)
      query = window.location.search.substring(1)
      params = query.split('&')
      results = {}
      for param in params
        kv = param.split('=')
        key = decodeURIComponent(kv[0])
        val = decodeURIComponent((kv[1] || '').replace(/\+/g,' '))
        from = key.indexOf('[')
        if from == -1
          results[key] = val
        else
          to = key.indexOf(']')
          index = decodeURIComponent(key.substring(from+1, to))
          subKey = decodeURIComponent(key.substring(to+1))
          subIndex = if subKey.length > 0
            /\[(.*?)\]/.exec(subKey)[1]
          key = decodeURIComponent(key.substring(0, from))
          results[key] ||= []
          unless index
            results[key].push val
          else
            if subIndex
              results[key][index] ||= {}
              results[key][index][subIndex] = val
            else
              results[key][index] = val
      results

  # UT済
  jsonPromise: (url, args = {}, method = 'get', timeout = 300000) ->
    new Promise (resolve, reject) ->
      $.ajax
        url: url
        data: args
        method: method
        dataType: 'json'
        timeout: timeout
      .done (data) ->
        resolve data
      .fail (error) ->
        reject error unless _handleAjaxError(error)

  # UT済
  calcDistance: (lat1, lon1, lat2, lon2) ->
    # ラジアンに変換
    a_lat = lat1 * Math.PI / 180
    a_lon = lon1 * Math.PI / 180
    b_lat = lat2 * Math.PI / 180
    b_lon = lon2 * Math.PI / 180

    # 緯度の平均、緯度間の差、経度間の差
    latave = (a_lat + b_lat) / 2
    latidiff = a_lat - b_lat
    longdiff = a_lon - b_lon

    # 子午線曲率半径
    # 半径を6335439m、離心率を0.006694で設定してます
    meridian = 6335439 / Math.sqrt(Math.pow(1 - 0.006694 * Math.sin(latave) * Math.sin(latave), 3))

    # 卯酉線曲率半径
    # 半径を6378137m、離心率を0.006694で設定してます
    primevertical = 6378137 / Math.sqrt(1 - 0.006694 * Math.sin(latave) * Math.sin(latave))

    # Hubenyの簡易式
    x = meridian * latidiff
    y = primevertical * Math.cos(latave) * longdiff

    Math.sqrt(Math.pow(x,2) + Math.pow(y,2))

  # UT済
  walkMinutesText: (distanceMeter) ->
    minutes = Math.floor(distanceMeter / 70)
    "#{minutes}分"

  # TODO: つかってない
  selectPath: ->
    queryValue = @cookie.get 'query'
    query =
      if queryValue && queryValue.length > 0
        queryArray = _.map JSON.parse(queryValue), (value, key) ->
          "#{key}=#{value}"
        '?' + _(queryArray).join('&')
      else
        ''
    "/select#{query}"

  # TODO: つかってない
  LinkedStateRadioGroupMixin:
    radioGroup: (key) ->
      valueLink: ((value) ->
        value: this.state[key] == value
        requestChange: (->
          s = {}
          s[key] = value
          this.setState(s)
        ).bind(this)
      ).bind(this)

  # UT済
  snakeToCamel: (str) ->
    str.replace /_./g, (s) ->
      s.charAt(1).toUpperCase()

  # UT済
  camelToSnake: (str) ->
    str.replace /([A-Z])/g, (s) ->
      '_' + s.charAt(0).toLowerCase()

  # UT済
  snakeToCamelObject: (object) ->
    res = {}
    for k, v of object
      res[snakeToCamel(k)] =
        if _.isNull(v)
          null
        else if typeof v == 'object'
          snakeToCamelObject(v)
        else
          v
    res

  # UT済
  camelToSnakeObject: (object) ->
    res = {}
    for k, v of object
      res[camelToSnake(k)] =
        if _.isNull(v)
          null
        else if typeof v == 'object'
          camelToSnakeObject(v)
        else
          v
    res

  # UT済
  appendScript: (url) ->
    e = document.createElement("script")
    e.charset = "utf8"
    e.src = url
    document.body.appendChild(e)

  # TODO 使ってない
  sendDebug: (message, params = {}) ->
    $.ajax
      url: '/debug.json'
      data: _.merge params, { message: message }
      method: 'POST'
      dataType: 'json'
      timeout: 20000

  # sendErrorObjectで使用されている
  sendError: (params) ->
    $.ajax
      url: '/errors.json'
      data: params
      method: 'POST'
      dataType: 'json'
      timeout: 20000

  # UT済
  sendErrorObject: (e) ->
    sendError
      message: e.message
      stack: e.stack
    bugsnagClient.notify(e) if typeof bugsnagClient != 'undefined'
    throw e

  # util内でのみ使われる
  zenToHan: (str) ->
    hankaku = str.replace(/[！-～]/g,
      (s) ->
        String.fromCharCode( s.charCodeAt(0) - 0xFEE0 ))
    hankaku

  # UT済
  iOS: ->
    ua = navigator.userAgent
    if /iPhone/.test(ua) || /iPad/.test(ua) || /iPod/.test(ua)
      true
    else
      false

  # UT済
  dig: (obj, keys...) ->
    for key in keys
      if obj
        if typeof obj[key] == 'function'
          obj = obj[key]()
        else
          obj = obj[key]
      else
        return null
    obj

  # TODO: つかってない
  stayDays: (checkin, checkout) ->
    moment(checkout).diff(moment(checkin), 'days')

  # TODO: つかってない
  daysInMonth: (year, month) ->
    days = moment([year, (month - 1), 1]).daysInMonth()
    [1..days]

  # TODO: つかってない
  weekdaysInMonth: (day, weekday) ->
    target = day.clone().startOf('month').day(weekday)
    if target.date() > 7
      target.add(7,'d')
    month = target.month()
    days = []
    while month == target.month()
      days.push(target.clone())
      target.add(7,'d')
    days

  # UT済
  validateEmail: (email) ->
    # coffeelint: disable=max_line_length
    r = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    # coffeelint: enable=max_line_length
    r.test(email)

  # UT済
  isAiTravel: (id) ->
    parseInt(id) == 1
  # UT済
  isTabikobo: (id) ->
    parseInt(id) == 2
  # UT済
  isMynavi: (id) ->
    parseInt(id) == 3
  # UT済
  transitIconPath: (name) ->
    return unless name

    icons = ['ANA', 'JAL', 'SKY', 'ADO', 'SFJ', 'JR', 'SNA', 'JJP', 'GK', 'AMX',
             'FDA', 'HAC', 'IBX', 'ORC', 'MM', 'APJ', 'JW', 'IJ', 'JTA', 'RAC', 'JAC', 'DJ'
            ]
    # replace 内の Max はレシーバーが "JR新幹線Maxとき" の場合に対応している。
    # see: https://github.com/BEST10developers/travel.ai/pull/3275/files#r426154951
    code = zenToHan(name.split('(').shift()).replace(/([^a-zA-Z]|Max)/g, '').toUpperCase()
    if icons.indexOf(code) == -1 && typeof bugsnagClient != 'undefined'
      bugsnagClient.notify(new Error("NO_ICON_ERROR: Icon of #{name} is not prepared"))
    else
      "/images/transit_icons/#{code}.png"
  # UT済
  flightIconPath: (name) ->
    return unless name

    icons = [
      'AA', 'AC', 'AF', 'AI', 'AM', 'AS', 'AY', 'B7', 'BA', 'BI', 'BR',
      'CA', 'CI', 'CX', 'CZ', 'DL', 'EK', 'ET', 'EY', 'FM',
      'GA', 'GS', 'GW', 'HA', 'HO', 'HU', 'HX', 'JL', 'JQ',
      'KA', 'KE', 'KL', 'LA', 'LH', 'LJ', 'LO', 'LX',
      'MF', 'MH', 'MI', 'MU', 'NH', 'NX', 'NZ',
      'OD', 'OM', 'OS', 'OZ', 'PG', 'PK', 'PR', 'QF', 'QR',
      'SA', 'SC', 'SK', 'SL', 'SQ', 'SU', 'TG', 'TK', 'TN', 'TR', 'TW',
      'UA', 'UL', 'UO', 'VJ', 'VN', 'ZH', 'ZU',
      '3U'
    ]
    # https://aitravel.atlassian.net/browse/AITRAVEL-1929
    exceptionCodes = [
      'H1', 'B1'
    ]
    code = zenToHan(name).replace(/[^a-zA-Z0-9]/g, '').toUpperCase().substr(0, 2)
    if exceptionCodes.indexOf(code) != 0
      if icons.indexOf(code) == -1 && typeof bugsnagClient != 'undefined'
        bugsnagClient.notify(new Error("NO_ICON_ERROR: Icon of #{name} is not prepared"))
      else if exceptionCodes.indexOf(code) != 0
        "/images/flight_icons/#{code}.png"
  # UT済
  flightClassIconPath: (cabin) ->
    return unless cabin
    "/images/flight_class_icons/#{cabin}.png"
  # UT済
  flightClassName: (cabin) ->
    return unless cabin
    switch cabin
      when 'F' then 'ファーストクラス'
      when 'C' then 'ビジネスクラス'
      when 'W' then 'プレミアムエコノミークラス'
      when 'M' then 'エコノミークラス'

  # UT済
  sanitizePrice: (int) ->
    if int == ''
      null
    else
      p = parseInt(zenToHan(int.toString()).replace(/[^-?\d]/g, ''))
      if typeof p == 'number'
        p
      else
        null

  # UT済
  toParams: (obj) ->
    _.map obj, (v, k) -> "#{k}=#{v}"
    .join('&')

  renderRouterComponent: (component, props) -> (routeProps) ->
    React.createElement(component, _.merge(routeProps, props))

  # UT済
  scrollToTop: ->
    if window
      window.scrollTo 0, 0

  # UT済
  compactObject: (o) ->
    result = {}
    for k, v of o
      result[k] = v unless _.isEmpty(v)
    result

  # UT済
  # copyElementToClipBoardで呼ばれる
  copyToClipboard: (showAlert = true) ->
    document.execCommand('copy')
    alert('クリップボードにコピーしました') if showAlert

  # UT不可
  selectRange: (el) ->
    if document.createRange && window.getSelection
      range = document.createRange()
      sel = window.getSelection()
      sel.removeAllRanges()
      try
        range.selectNodeContents(el)
        sel.addRange(range)
      catch
        range.selectNode(el)
        sel.addRange(range)
    sel

  # UT不可
  copyElementToClipBoard: (el, showAlert = true) ->
    if document.createRange && window.getSelection
      selectRange(el)
      copyToClipboard(showAlert)
    else if body.createTextRange
      range = body.createTextRange()
      range.moveToElementText(el)
      range.select()
      copyToClipboard(showAlert)

  # TODO: 使ってない
  # HTML貼り付け対応の是非でコピーの内容を変えられる
  copyHTMLAndTextToClipBoard: (html, text, showAlert = true) ->
    if document.createRange && window.getSelection
      document.addEventListener "copy", listener = (event) ->
        event.preventDefault()
        event.clipboardData.setData("text/plain", selectRange(text).focusNode.innerText)
        event.clipboardData.setData("text/html", selectRange(html).focusNode.innerHTML)
        document.removeEventListener("copy", listener)
      copyToClipboard(showAlert)
    else if body.createTextRange
      range = body.createTextRange()
      range.moveToElementText(html)
      range.select()
      copyToClipboard(showAlert)

  _handleAjaxError: (e) ->
    msg = e.responseJSON?.unallowed_ip_address
    if msg
      Notification.error(msg)
      location.reload()
      return true

    false

  noop: ->

  cookie: require('cookie-cutter')
  classnames: require('classnames')
  moment: require('./lib/moment').default
  _: _
