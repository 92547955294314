import * as React from 'react';
import moment from '@this/lib/moment';
import {
  OrganizationBody,
  OrganizationTable,
  OrganizationTh,
  OrganizationTd,
  AddButton,
  OrganizationBodyFooterActions,
  OrganizationTdButton,
  OrganizationTitle
} from '@this/components/organization/organization.style';
import type { RouteComponentProps } from 'react-router';

import SimpleLoading from '@this/shared/simple_loading/simple_loading';
import type { OrganizationInformation } from '@this/components/organization/organization_information/type';
import { ButtonType } from '@this/shared/atoms/button';
import Modal from '@this/shared/modal/modal';
import OrganizationInformationForm from '@this/components/organization/organization_information/organization_information_form';
import Confirm from '@this/shared/confirm/confirm';
import Markdown from '@this/shared/markdown/markdown';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { styled } from '@this/constants/themes';
import { Button } from '@this/shared/ui/inputs/button';
import { Fetcher, HTTPError } from '@this/src/util';
import FlashMessage from '@this/shared/flash_message/flash_message';
import Notification from '../../../notification';

const fetchConnectionErrorMessage = '通信環境が不安定です。\n時間をおいてもう一度お試しください。';

type Props = RouteComponentProps;
interface State {
  loading: boolean;
  saveError: string | null;
  informations: OrganizationInformation[];
  shownCreateModal: boolean;
  editingTarget: OrganizationInformation | null;
  deletionTarget: OrganizationInformation | null;
  selectedRowIds: string[];
}

class OrganizationInformations extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
      saveError: null,
      informations: [],
      shownCreateModal: false,
      editingTarget: null,
      deletionTarget: null,
      selectedRowIds: []
    };
  }

  async componentDidMount() {
    await this.reloadInformations();
  }

  fetchResultSaveError(error: unknown) {
    const saveError =
      error instanceof HTTPError && error.response?.status === 400
        ? error.response.data.errors
        : fetchConnectionErrorMessage;
    this.setState({ loading: false, saveError });
  }

  async reloadInformations() {
    const informations = await utils.jsonPromise<OrganizationInformation[]>('/organization/informations.json');
    this.setState({ loading: false, informations });
  }

  openCreateModal = () => this.setState({ shownCreateModal: true });

  openEditModal = (information: OrganizationInformation) => this.setState({ editingTarget: information });

  openDeleteModal = (information: OrganizationInformation) => this.setState({ deletionTarget: information });

  closeCreateModal = () => this.setState({ shownCreateModal: false });

  closeEditModal = () => this.setState({ editingTarget: null });

  closeDeleteModal = () => this.setState({ deletionTarget: null });

  onSubmitCreate = async (content: OrganizationInformation['content']) => {
    const { informations } = this.state;

    const res = await utils.jsonPromise<OrganizationInformation>(
      '/organization/informations.json',
      { content },
      'post'
    );

    informations.push(res);
    this.setState({ informations });

    this.closeCreateModal();
    Notification.success('お知らせを作成しました');
  };

  onSubmitEdit = async (content: OrganizationInformation['content']) => {
    const { informations, editingTarget } = this.state;
    if (!editingTarget) {
      return;
    }
    const editedTarget = { ...editingTarget, content };

    const res = await utils.jsonPromise<OrganizationInformation>(
      `/organization/informations/${editedTarget.id}.json`,
      editedTarget,
      'put'
    );

    const index = informations.findIndex(o => o.id === editedTarget.id);
    informations.splice(index, 1, res);
    this.setState({ informations });

    this.closeEditModal();
    Notification.success('お知らせを編集しました');
  };

  onSubmitDelete = async () => {
    const { deletionTarget } = this.state;
    if (!deletionTarget) {
      return;
    }

    const id = (deletionTarget as unknown as OrganizationInformation).id;

    await utils.jsonPromise<void>(`/organization/informations/${id}.json`, null, 'delete');

    const { informations } = this.state;
    const index = informations.findIndex(information => information.id === id);
    informations.splice(index, 1);
    this.setState({ informations });

    this.closeDeleteModal();
    Notification.success('お知らせを削除しました');
  };

  handleSaveDisplayOrderClick(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    this.setState({ saveError: null, loading: true });
    return Fetcher.put('/organization/informations/display_order', this.displayOrderSubmitParams() ?? {}).then(
      () => {
        this.setState({ loading: false });
        this.reloadInformations();
      },
      error => {
        this.fetchResultSaveError(error);
      }
    );
  }

  displayOrderSubmitParams() {
    if (this.state.informations) {
      const InformationsListParams = this.state.informations.map(
        (info: OrganizationInformation, index: number) => {
          return { id: info.id, display_order: index + 1 };
        }
      );
      return { informations: InformationsListParams };
    }
    return null;
  }

  onDragStart = (start: any) => {
    const id = start.draggableId;
    const selected = this.state.selectedRowIds.find(selectedId => selectedId === id);
    if (!selected) {
      this.unselectAll();
    }
  };

  onDragEnd = (result: any) => {
    const { destination, source } = result;
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }
    if (this.state.informations) {
      const informations = this.state.informations;

      const quote = informations[source.index];
      informations.splice(source.index, 1);
      informations.splice(destination.index, 0, quote);

      this.setState({ informations });
    }
  };

  unselectAll = () => {
    this.setState({ selectedRowIds: [] });
  };

  getTrStyle = (isDragging: any, draggableStyle: any) => ({
    background: isDragging && '#fff9ed',
    ...draggableStyle
  });

  getTdStyle = (isDragging: any) => ({
    width: isDragging && '15%'
  });

  render() {
    const { loading, informations, shownCreateModal, editingTarget, deletionTarget, saveError } = this.state;

    return (
      <>
        <FlashMessage message={saveError} type="error" />
        <OrganizationTitle>お知らせ登録</OrganizationTitle>
        <OrganizationBody>
          {loading ? (
            <SimpleLoading />
          ) : (
            <>
              {informations.length > 0 && (
                <DragDropContext onDragStart={this.onDragStart} onDragEnd={this.onDragEnd}>
                  <OrganizationTable>
                    <thead>
                      <tr>
                        <OrganizationTh>本文</OrganizationTh>
                        <OrganizationTh width="200px">作成日時</OrganizationTh>
                        <OrganizationTh width="200px">更新日時</OrganizationTh>
                        <OrganizationTh width="100px" />
                        <OrganizationTh width="100px" />
                      </tr>
                    </thead>
                    <Droppable droppableId="tableBody">
                      {(provided: any, snapshot: any) => (
                        <tbody {...provided.droppableProps} ref={provided.innerRef}>
                          {informations.map((information: OrganizationInformation, i: number) => (
                            <Draggable draggableId={String(information?.id)} index={i} key={information?.id}>
                              {(provided: any, snapshot: any) => (
                                <Tr
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  key={information.id}
                                  ref={provided.innerRef}
                                  style={this.getTrStyle(snapshot.isDragging, provided.draggableProps.style)}
                                >
                                  <OrganizationTd style={this.getTdStyle(snapshot.isDragging)}>
                                    <Markdown markdownText={information.content} />
                                  </OrganizationTd>
                                  <OrganizationTd style={this.getTdStyle(snapshot.isDragging)}>
                                    {moment(information.created_at).format('YYYY-MM-DD HH:mm')}
                                  </OrganizationTd>
                                  <OrganizationTd style={this.getTdStyle(snapshot.isDragging)}>
                                    {moment(information.updated_at).format('YYYY-MM-DD HH:mm')}
                                  </OrganizationTd>
                                  <OrganizationTd style={this.getTdStyle(snapshot.isDragging)}>
                                    <OrganizationTdButton onClick={() => this.openEditModal(information)}>
                                      編集
                                    </OrganizationTdButton>
                                  </OrganizationTd>
                                  <OrganizationTd style={this.getTdStyle(snapshot.isDragging)}>
                                    <OrganizationTdButton
                                      buttonType={ButtonType.DANGER}
                                      onClick={() => this.openDeleteModal(information)}
                                    >
                                      削除
                                    </OrganizationTdButton>
                                  </OrganizationTd>
                                </Tr>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </tbody>
                      )}
                    </Droppable>
                  </OrganizationTable>
                </DragDropContext>
              )}
            </>
          )}
          <OrganizationBodyFooterActions>
            <ButtonContents>
              <AddButton onClick={this.openCreateModal}>お知らせを追加</AddButton>
              <Button color="sub" onClick={e => this.handleSaveDisplayOrderClick(e)}>
                順番を保存
              </Button>
            </ButtonContents>
          </OrganizationBodyFooterActions>
        </OrganizationBody>

        <Modal show={shownCreateModal} hideModal={this.closeCreateModal} title="作成">
          <OrganizationInformationForm onAbort={this.closeCreateModal} onSubmit={this.onSubmitCreate} />
        </Modal>

        {editingTarget && (
          <Modal show={!!editingTarget} hideModal={this.closeEditModal} title="編集">
            <OrganizationInformationForm
              informationContent={editingTarget.content}
              onAbort={this.closeEditModal}
              onSubmit={this.onSubmitEdit}
            />
          </Modal>
        )}

        {deletionTarget && (
          <Modal show={!!deletionTarget} hideModal={this.closeDeleteModal} title="削除確認">
            <Confirm
              message="このお知らせを削除してもよろしいですか？"
              onConfirmed={this.onSubmitDelete}
              onAborted={this.closeDeleteModal}
            />
          </Modal>
        )}
      </>
    );
  }
}

const Tr = styled.tr`
  &:hover {
    background: #fff9ed;
  }
`;

const ButtonContents = styled.div`
  display: flex;
  column-gap: 10px;
`;

export default OrganizationInformations;
