import React, { useState } from 'react';
import SettingTable from './setting_table';
import ResultTable from './result_table';
import TaskTable from './task_table';

interface NewVersionTableProps {}

const NewVersionTable: React.FC<NewVersionTableProps> = () => {
  const [mode, setMode] = useState('setting');

  return (
    <>
      <div style={{ display: 'flex' }}>
        <label>
          <input
            type="radio"
            name="mode"
            value="setting"
            checked={mode === 'setting'}
            onChange={() => setMode('setting')}
          />
          クロール設定
        </label>
        <label>
          <input
            type="radio"
            name="mode"
            value="task"
            checked={mode === 'task'}
            onChange={() => setMode('task')}
          />
          タスク管理
        </label>
        <label>
          <input
            type="radio"
            name="mode"
            value="result"
            checked={mode === 'result'}
            onChange={() => setMode('result')}
          />
          データ管理
        </label>
      </div>
      {mode === 'setting' ? <SettingTable /> : <>{mode === 'task' ? <TaskTable /> : <ResultTable />}</>}
    </>
  );
};

export default NewVersionTable;
