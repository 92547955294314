import React from 'react';
import _ from 'lodash';
import type { Moment } from 'moment';
import moment from 'moment';

import type User from '@this/domain/user/user';
import { assertNever } from '@this/src/util';
import type SearchQueryItem from '../../../../domain/search_query_item';

import SearchBlockTemplate from './search_block.template';

export interface SearchBlockProps {
  index?: number;
  item: SearchQueryItem;
  icon?: number;
  user?: User | null;
  onRemoveItem?: (index: number) => void;
  availableOptions?: string[];
}

interface SearchRentalCarLimitResponse {
  business_days: number;
  actual_days: number;
  hour: number;
}

interface State {
  rentalCarLimit: { business_days: number; actual_days: number; hour: number | null };
}

class SearchBlock extends React.Component<SearchBlockProps, State> {
  constructor(props: SearchBlockProps) {
    super(props);

    this.state = {
      rentalCarLimit: { business_days: 2, actual_days: 2, hour: null }
    };
  }

  componentDidMount() {
    utils.jsonPromise<SearchRentalCarLimitResponse>('/rental_car_limit_types.json').then(result => {
      this.setState({
        rentalCarLimit: {
          business_days: result.business_days,
          actual_days: result.actual_days,
          hour: result.hour
        }
      });
      if (this.props.item.itemType === 'rentalCar') {
        const limitedDate = moment().add(result.actual_days, 'days');
        if (
          moment(this.props.item.outdate).isBefore(limitedDate) &&
          moment(this.props.item.homedate).isBefore(limitedDate)
        ) {
          this.props.item.setOutdate(limitedDate);
          this.props.item.setOuthour(`${result.hour}`);
          this.props.item.setHomedate(limitedDate);
        }
      }
    });
  }

  handlePlaceChange = (name: 'origin' | 'destination', value: string, address: string) => {
    if (name === 'origin') {
      this.props.item.setOrigin(value, address);
      if (this.props.item.itemType === 'rentalCar' && this.props.item.returnPlace === 'current') {
        this.props.item.setDestination(value, address);
      }
    } else if (name === 'destination') {
      this.props.item.setDestination(value, address);
    }
  };

  handleChange =
    (name: 'outhour' | 'outmin' | 'outtype' | 'homehour' | 'homemin') =>
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const value = e.target.value;

      switch (name) {
        case 'outhour':
          this.props.item.setOuthour(value);
          break;
        case 'outmin':
          this.props.item.setOutmin(value);
          break;
        case 'outtype':
          if (value === 'arrival' || value === 'departure') {
            this.props.item.setOuttype(value);
          }
          break;
        case 'homehour':
          this.props.item.setHomehour(value);
          break;
        case 'homemin':
          this.props.item.setHomemin(value);
          break;
        default:
          assertNever(name);
          break;
      }
    };

  handleChangeDate = (name: 'outdate' | 'homedate', date: Moment) => {
    switch (name) {
      case 'homedate':
        this.props.item.setHomedate(date);
        break;
      case 'outdate':
        this.props.item.setOutdate(date);
        break;
      default:
        assertNever(name);
        break;
    }
  };

  handleRemoveItem = () => {
    if (this.props.index !== undefined && this.props.onRemoveItem) this.props.onRemoveItem(this.props.index);
  };

  handleRentalCarInfoChange = (method: string) => (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    (this.props.item as any)[method](e.target.value);
  };

  canRemoveItem = () => !!this.props.onRemoveItem;

  render() {
    try {
      return (
        <SearchBlockTemplate
          ref="SearchBlockTemplate"
          {...this.props}
          handlePlaceChange={this.handlePlaceChange}
          handleChange={this.handleChange}
          handleChangeDate={this.handleChangeDate}
          handleRemoveItem={this.handleRemoveItem}
          canRemoveItem={this.canRemoveItem}
          handleRentalCarInfoChange={this.handleRentalCarInfoChange}
          rentalCarLimit={this.state.rentalCarLimit}
        />
      );
    } catch (e) {
      utils.sendErrorObject(e);
      return null;
    }
  }
}

export default SearchBlock;
