import React from 'react';

import type { HotelWeightArgs } from '@this/domain/hotel_weight';
import HotelWeight from '@this/domain/hotel_weight';
import type { HotelBaseScoreArgs } from '@this/domain/hotel_base_score';
import HotelBaseScore from '@this/domain/hotel_base_score';
import SimpleLoading from '@this/shared/simple_loading/simple_loading';
import { styled } from '@this/constants/themes';
import { AdminContentIn } from '@this/components/admin/shared/atom';
import { Title } from '@this/components/admin/admin';
import { Fetcher, HTTPError } from '@this/src/util';
import { reportError } from '@this/lib/bugsnag';
import type { InternationalAirline } from './types';
import ExclusionAirlinesForm from './exclusion_airlines_form';
import HotelBanListForm from './hotel_ban_list_form';
import HotelWeightsForm from './hotel_weights_form';
import HotelBaseScoresForm from './hotel_base_scores_form';
import Notification from '../../../notification';

interface Props {}

interface State {
  airlines: InternationalAirline[];
  hotelBanList: string;
  exclusionAirlineCodes: string[];
  hotelWeights: HotelWeight[];
  hotelBaseScores: HotelBaseScore[];
  loading: boolean;
}

interface InternationalAirlinesResponse {
  airlines: InternationalAirline[];
}

interface Response {
  hotel_ban_list: string;
}

interface ExclusionAirlineResponse {
  airline_codes: string[];
}

interface HotelWeightResponse {
  hotel_weights: HotelWeightArgs[];
}

interface HotelBaseScoreResponse {
  hotel_base_scores: HotelBaseScoreArgs[];
}

class RepositoryDisplayAdjustment extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      airlines: [],
      hotelBanList: '',
      exclusionAirlineCodes: [],
      hotelWeights: [],
      hotelBaseScores: [],
      loading: false
    };
  }

  componentDidMount() {
    this.fetch();
  }

  private async fetch() {
    this.setState({ loading: true });

    try {
      const [{ hotel_ban_list }, { airline_codes }, { airlines }, { hotel_weights }, { hotel_base_scores }] =
        await Promise.all([
          Fetcher.get<Response>('/admin/repository_display_adjustment/hotel_filters'),
          Fetcher.get<ExclusionAirlineResponse>('/admin/repository_display_adjustment/exclusion_airlines'),
          Fetcher.get<InternationalAirlinesResponse>('/admin/international_airlines'),
          Fetcher.get<HotelWeightResponse>('/admin/repository_display_adjustment/hotel_weights.json'),
          Fetcher.get<HotelBaseScoreResponse>('/admin/repository_display_adjustment/hotel_base_scores.json')
        ]);
      this.setState({
        hotelBanList: hotel_ban_list,
        exclusionAirlineCodes: airline_codes,
        airlines,
        hotelWeights: hotel_weights.map(w => new HotelWeight(w)),
        hotelBaseScores: hotel_base_scores.map(s => new HotelBaseScore(s))
      });
    } catch (e) {
      Notification.error(e.toString());
    } finally {
      this.setState({ loading: false });
    }
  }

  updateHotelFilter = async (banList: string) => {
    try {
      await Fetcher.put(`/admin/repository_display_adjustment/hotel_filters`, {
        hotel_ban_list: banList
      });
      this.setState({
        hotelBanList: banList
      });
      Notification.success('ホテル除外ワードを更新しました');
    } catch (e) {
      const message =
        e instanceof HTTPError && e.response?.data.message
          ? e.response.data.message
          : '通信環境が不安定です。\n時間をおいてもう一度お試しください。';
      Notification.error(message);
    }
  };

  updateExlusionAirlines = async (airlineCodes: string[]) => {
    const params = { airline_codes: airlineCodes };

    try {
      const { airline_codes } = await Fetcher.put<ExclusionAirlineResponse>(
        '/admin/repository_display_adjustment/exclusion_airlines/bulk_update',
        params
      );
      this.setState({ exclusionAirlineCodes: airline_codes });
      Notification.success('除外海外航空券を更新しました');
    } catch (e) {
      const message = e instanceof HTTPError && e.response?.data.message ? e.response.data.message : e.message;
      Notification.error(message);
    }
  };

  render() {
    try {
      const { airlines, hotelBanList, exclusionAirlineCodes, hotelWeights, hotelBaseScores, loading } = this.state;
      return (
        <>
          <Title>在庫表示調整</Title>
          <AdminContentIn>
            {loading ? (
              <SimpleLoading />
            ) : (
              <>
                <Section>
                  <SubTitle>ホテル除外ワード</SubTitle>
                  <P>
                    文字列を1行ずつ設定してください。
                    <br />
                    設定された文字列が含まれるホテルやプランは表示されなくなります。
                  </P>
                  <HotelBanListForm banList={hotelBanList} update={this.updateHotelFilter} />
                </Section>

                <Section>
                  <SubTitle>除外海外航空券</SubTitle>
                  <P>
                    リストから対象の航空会社を選択してください。
                    <br />
                    設定された航空会社が検索時に表示されなくなります。
                  </P>
                  <ExclusionAirlinesForm
                    airlines={airlines}
                    exclusionAirlineCodes={exclusionAirlineCodes}
                    onUpdate={this.updateExlusionAirlines}
                  />
                </Section>
                <Section>
                  <SubTitle>ホテル表示重み付け</SubTitle>
                  <P>各評価値の重みを0~100の値で指定してください。</P>
                  <HotelWeightsForm weights={hotelWeights} />
                </Section>
                <Section>
                  <SubTitle>ホテル基本スコア</SubTitle>
                  <P>各プロバイダの基本スコアを0~5の値で指定してください。</P>
                  <HotelBaseScoresForm scores={hotelBaseScores} />
                </Section>
              </>
            )}
          </AdminContentIn>
        </>
      );
    } catch (e) {
      reportError(e);
      return null;
    }
  }
}

const Section = styled.section`
  & + & {
    margin-top: 30px;
  }
`;

const SubTitle = styled.h3`
  margin-bottom: 5px;
`;

const P = styled.p`
  margin-bottom: 3px;
`;

export default RepositoryDisplayAdjustment;
