import React from 'react';
import _ from 'lodash';
import { styled } from '@this/constants/themes';

import { Header, Icon, Date, Body } from '@this/components/reserve_trip/select/outline_item/outline_item.template';
import {
  BoxContent,
  Left,
  Segment,
  Name,
  Space
} from '@this/src/components/reserve_trip/select/transport_box/transport_box_foreign.template';
import {
  BoxPriceArea,
  BoxPrice
} from '@this/components/reserve_trip/select/transport_box/right/right_in_outline.template';
import { HotelNameDiv } from '@this/components/reserve_trip/select/hotel_box/hotel_box_parts';

import type OrderItem from '@this/domain/order_item';

interface Props {
  item: OrderItem;
  icon: number;
}

interface State {
  item: OrderItem;
  icon: number;
}

class OrderItemBlock extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      item: props.item,
      icon: props.icon
    };
  }

  render() {
    const { item, icon } = this.state;
    try {
      return (
        <div className="select-outline__item-block">
          {item.elementType() === 'hotel' ? (
            <>
              <Header key={0}>
                <Icon>{icon}</Icon>
                <Date>{`宿泊：${item.startDateStr()}〜${item.endDateStr()}`}</Date>
              </Header>
              <Body key={1}>
                <Box>
                  <Left>
                    <HotelBody>
                      <HotelNameDiv>{item.hotels[0].name}</HotelNameDiv>
                      <HotelBodyText>{item.hotels[0].planNameDescription()}</HotelBodyText>
                      <HotelBodyText>{item.hotels[0].roomTypeDescription()}</HotelBodyText>
                      <HotelBodyText>{item.hotels[0].bedTypeDescription()}</HotelBodyText>
                      <HotelBodyText>{item.hotels[0].breakfastDescription()}</HotelBodyText>
                      <HotelBodyText>{item.hotels[0].refundableDescription()}</HotelBodyText>
                    </HotelBody>
                  </Left>
                  <Right>
                    <BoxPriceArea>
                      <div>
                        <BoxPrice>{utils.formatPriceWithMark(item.totalPriceWithAll())}</BoxPrice>
                      </div>
                    </BoxPriceArea>
                  </Right>
                </Box>
              </Body>
            </>
          ) : item.elementType() === 'transport' ? (
            <>
              <Header key={0}>
                <Icon>{icon}</Icon>
                <Date>{`交通機関：${item.startDateStr()}`}</Date>
              </Header>
              <Body key={1} className="select-outline__item-block__body">
                <Box>
                  <Left>
                    {item.transports.map((t, i) => (
                      <Segment key={i}>
                        <div className="flex">
                          <Name>{t.name}</Name>
                        </div>
                        <BoxContent>
                          <Space>発</Space>
                          <Space>{t.from.dateTimeStr()}</Space>
                          <Space>{t.from.name}</Space>
                        </BoxContent>
                        <BoxContent>
                          <Space>着</Space>
                          <Space>{t.to.dateTimeStr()}</Space>
                          <Space>{t.to.name}</Space>
                        </BoxContent>
                        <div className="select-transport-box__left__boarding-arrival">{t.detail()}</div>
                      </Segment>
                    ))}
                  </Left>
                  <Right>
                    <BoxPriceArea>
                      <div>
                        <BoxPrice>{utils.formatPriceWithMark(item.totalPriceWithAll())}</BoxPrice>
                      </div>
                    </BoxPriceArea>
                  </Right>
                </Box>
              </Body>
            </>
          ) : null}
        </div>
      );
    } catch (e) {
      utils.sendErrorObject(e);
      return null;
    }
  }
}

const Box = styled.div`
  display: flex;
  border: 2px solid ${props => props.theme.grayBorderColor};
  background: white;
  margin-bottom: 10px;
  font-size: 10px;
`;

const HotelBody = styled.div`
  padding-bottom: 5px;
`;

const HotelBodyText = styled.div``;

const Right = styled.div`
  display: flex;
  flex-grow: 0;
  margin-left: auto;
  min-width: 170px;
  color: ${props => props.theme.linkColor};
  background: ${props => props.theme.grayBgColorLight};
`;

export default OrderItemBlock;
