/* eslint-disable max-lines */
import type { Moment } from 'moment';
import moment from 'moment';
import React from 'react';
import _ from 'lodash';
import { styled } from '@this/constants/themes';

import type { RouteComponentProps } from 'react-router-dom';

import type SuppliedItem from '@this/domain/supplied_item/supplied_item';
import TripBlock from './trip_block/trip_block';
import SelectOrganizationsBlock from './trip_block/select_organizations_block/select_organizations_block';
import OrderItemSupplierItemSelect from './trip_block/select_supplied_items_block/order_item_supplier_item_select';
import SimpleLoading from '../../shared/simple_loading/simple_loading';
import DatetimePicker from '../../shared/datetime_picker/datetime_picker';
import Trip from '../../../domain/trip/trip';

interface TripJson {
  id: number;
}

interface OrderHistoriesResponse {
  trips: TripJson[];
  suppliedItems: SuppliedItem[];
}

enum SearchType {
  OrderCreatedAt,
  OrderConfirmedAt,
  OrderBilledAt,
  TripStartTime,
  TripEndTime,
  TripId,
  GmoId,
  SonyPaymentId,
  ReservationNumber
}

enum ViewType {
  Condition,
  TripId,
  GmoId,
  SonyPaymentId,
  ReservationNumber
}

interface State {
  trips: Trip[];
  suppliedItems: SuppliedItem[];
  suppliedItem: number;
  loading: boolean;
  from: Moment;
  to: Moment;
  searchType: SearchType;
  viewType: ViewType;
  tripId: string;
  gmoId: string;
  sonyPaymentId: string;
  reservationNumber: string;
  minPriceDetail: number | string;
  maxPriceDetail: number | string;
  minOriginalPrice: number | string;
  maxOriginalPrice: number | string;
  checkRequired: boolean;
  onlyNoJournal: boolean;
  organization: string;
  travelerName: string;
}

interface Args {
  type: string;
  from?: string;
  to?: string;
  tripId?: string;
  gmoId?: string;
  sonyPaymentId?: string;
  reservationNumber?: string;
  checkRequired?: string;
}

const getTypeEnum = (type: string | undefined) => {
  switch (type) {
    case 'order_confirmed_at':
      return SearchType.OrderConfirmedAt;
    case 'trip_start_time':
      return SearchType.TripStartTime;
    case 'trip_end_time':
      return SearchType.TripEndTime;
    case 'trip_id':
      return SearchType.TripId;
    case 'gmo_id':
      return SearchType.GmoId;
    case 'sony_payment_id':
      return SearchType.SonyPaymentId;
    case 'reservation_number':
      return SearchType.ReservationNumber;
    default:
      return SearchType.OrderCreatedAt;
  }
};

const convertViewToSearch = (type: ViewType) => {
  switch (type) {
    case ViewType.Condition:
      return SearchType.OrderCreatedAt;
    case ViewType.TripId:
      return SearchType.TripId;
    case ViewType.GmoId:
      return SearchType.GmoId;
    case ViewType.SonyPaymentId:
      return SearchType.SonyPaymentId;
    case ViewType.ReservationNumber:
      return SearchType.ReservationNumber;
    default:
      return SearchType.OrderCreatedAt;
  }
};

const convertSearchToView = (type: SearchType) => {
  switch (type) {
    case SearchType.TripId:
      return ViewType.TripId;
    case SearchType.GmoId:
      return ViewType.GmoId;
    case SearchType.SonyPaymentId:
      return ViewType.SonyPaymentId;
    case SearchType.ReservationNumber:
      return ViewType.ReservationNumber;
    default:
      return ViewType.Condition;
  }
};

class OrderHistories extends React.Component<RouteComponentProps, State> {
  constructor(props: RouteComponentProps) {
    super(props);
    const from: string | undefined = utils.getParam('from');
    const to: string | undefined = utils.getParam('to');
    const suppliedItem: number = Number(utils.getParam('supplied_item')) || -1;
    this.state = {
      trips: [],
      suppliedItems: [],
      suppliedItem,
      loading: false,
      from: from ? moment(from) : moment(),
      to: to ? moment(to) : moment(),
      searchType: getTypeEnum(utils.getParam('type')),
      viewType: convertSearchToView(getTypeEnum(utils.getParam('type'))),
      tripId: utils.getParam('trip_id') || '',
      gmoId: utils.getParam('gmo_id') || '',
      sonyPaymentId: utils.getParam('sony_payment_id') || '',
      reservationNumber: utils.getParam('reservation_number') || '',
      minPriceDetail: utils.getParam('min_price_detail') || '',
      maxPriceDetail: utils.getParam('max_price_detail') || '',
      minOriginalPrice: utils.getParam('min_original_price') || '',
      maxOriginalPrice: utils.getParam('max_original_price') || '',
      checkRequired: utils.getParam('check_required') !== 'false',
      onlyNoJournal: utils.getParam('only_no_journal') === 'true',
      organization: utils.getParam('organization') || '',
      travelerName: ''
    };
  }

  componentDidMount() {
    this.fetchTrips();
  }

  getTypeString() {
    let type = '';
    switch (this.state.searchType) {
      case SearchType.OrderCreatedAt:
        type = 'order_created_at';
        break;
      case SearchType.OrderConfirmedAt:
        type = 'order_confirmed_at';
        break;
      case SearchType.OrderBilledAt:
        type = 'order_billed_at';
        break;
      case SearchType.TripStartTime:
        type = 'trip_start_time';
        break;
      case SearchType.TripEndTime:
        type = 'trip_end_time';
        break;
      case SearchType.TripId:
        type = 'trip_id';
        break;
      case SearchType.GmoId:
        type = 'gmo_id';
        break;
      case SearchType.SonyPaymentId:
        type = 'sony_payment_id';
        break;
      case SearchType.ReservationNumber:
        type = 'reservation_number';
        break;
      default:
        type = '';
    }
    return type;
  }

  buildArgs(): Args {
    const type = this.getTypeString();
    let args = { type };
    if (this.state.viewType === ViewType.Condition) {
      const from = this.state.from.format('YYYY-MM-DD');
      const to = this.state.to.format('YYYY-MM-DD');
      const organization = this.state.organization;
      const suppliedItem = this.state.suppliedItem;

      args = _.merge(args, {
        from,
        to,
        organization,
        supplied_item: suppliedItem
      });
      const minPriceDetail = this.state.minPriceDetail;
      if (minPriceDetail !== '') {
        args = _.merge(args, {
          min_price_detail: minPriceDetail
        });
      }
      const maxPriceDetail = this.state.maxPriceDetail;
      if (maxPriceDetail !== '') {
        args = _.merge(args, {
          max_price_detail: maxPriceDetail
        });
      }
      const minOriginalPrice = this.state.minOriginalPrice;
      if (minOriginalPrice !== '') {
        args = _.merge(args, {
          min_original_price: minOriginalPrice
        });
      }
      const maxOriginalPrice = this.state.maxOriginalPrice;
      if (maxOriginalPrice !== '') {
        args = _.merge(args, {
          max_original_price: maxOriginalPrice
        });
      }
      if (
        this.state.searchType === SearchType.OrderConfirmedAt ||
        this.state.searchType === SearchType.OrderBilledAt
      ) {
        args = _.merge(args, {
          check_required: this.state.checkRequired ? 'true' : 'false',
          only_no_journal: this.state.onlyNoJournal ? 'true' : 'false'
        });
      }
      if (this.state.travelerName.length > 0) {
        args = _.merge(args, { traveler_name: this.state.travelerName });
      }
    } else if (this.state.viewType === ViewType.TripId) {
      args = _.merge(args, { trip_id: this.state.tripId });
    } else if (this.state.viewType === ViewType.GmoId) {
      args = _.merge(args, { gmo_id: this.state.gmoId });
    } else if (this.state.viewType === ViewType.SonyPaymentId) {
      args = _.merge(args, { sony_payment_id: this.state.sonyPaymentId });
    } else if (this.state.viewType === ViewType.ReservationNumber) {
      args = _.merge(args, { reservation_number: this.state.reservationNumber });
    }
    return args;
  }

  async fetchTrips(): Promise<void> {
    this.setState({ loading: true });
    const args = this.buildArgs();
    const queries = _.map(args, (v, k) => `${k}=${v}`);
    const query = _.join(queries, '&');
    this.props.history.push(`/arrangement/order_histories?${query}`);

    return utils.jsonPromise<OrderHistoriesResponse>('/arrangement/order_histories', args).then(response =>
      this.setState({
        trips: response.trips.map(trip => new Trip(trip)),
        suppliedItems: response.suppliedItems,
        loading: false
      })
    );
  }

  handleFromChange(date: Moment) {
    let to = this.state.to;
    if (date.isAfter(to)) {
      to = date;
    }
    this.setState({ from: date, to });
  }

  handleToChange(date: Moment) {
    this.setState({ to: date });
  }

  handleTypeChange(type: SearchType) {
    this.setState({ searchType: type });
  }

  handleViewTypeChange(viewType: ViewType) {
    this.setState({ viewType });
    this.setState({ searchType: convertViewToSearch(viewType) });
  }

  handleTripIdChange(tripId: string) {
    this.setState({ tripId });
  }

  handleGmoIdChange(gmoId: string) {
    this.setState({ gmoId });
  }

  handleSonyPaymentIdChange(sonyPaymentId: string) {
    this.setState({ sonyPaymentId });
  }

  handleReservationNumberChange(reservationNumber: string) {
    this.setState({ reservationNumber });
  }

  handleOrganizationSelect = (organization: string) => {
    this.setState({ organization });
  };

  handleMinPriceDetailChange(price: number | string) {
    this.setState({ minPriceDetail: price });
  }

  handleMaxPriceDetailChange(price: number | string) {
    this.setState({ maxPriceDetail: price });
  }

  handleMinOriginalPriceChange(price: number | string) {
    this.setState({ minOriginalPrice: price });
  }

  handleMaxOriginalPriceChange(price: number | string) {
    this.setState({ maxOriginalPrice: price });
  }

  handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    this.fetchTrips();
  }

  toggleCheckRequired() {
    this.setState({ checkRequired: !this.state.checkRequired });
  }

  toggleOnlyNoJournal() {
    this.setState({ onlyNoJournal: !this.state.onlyNoJournal });
  }

  render() {
    const state = this.state;
    const trips = state.trips;
    const suppliedItems = state.suppliedItems;
    const type = state.searchType;
    const viewType = state.viewType;
    const classBase = 'order-histories';
    return (
      <div className={classBase}>
        <FixedForm className={`${classBase}__search-area`} onSubmit={e => this.handleSubmit(e)}>
          <div className={`${classBase}__type-area`}>
            <label className={`${classBase}__label`}>
              <input
                type="radio"
                name="view_type"
                className={`${classBase}__radio`}
                checked={viewType === ViewType.Condition}
                onChange={_ => this.handleViewTypeChange(ViewType.Condition)}
              />
              条件検索
            </label>
            <label className={`${classBase}__label`}>
              <input
                type="radio"
                name="view_type"
                className={`${classBase}__radio`}
                checked={viewType === ViewType.TripId}
                onChange={_ => this.handleViewTypeChange(ViewType.TripId)}
              />
              TripId
            </label>
            <label className={`${classBase}__label`}>
              <input
                type="radio"
                name="view_type"
                className={`${classBase}__radio`}
                checked={viewType === ViewType.GmoId}
                onChange={_ => this.handleViewTypeChange(ViewType.GmoId)}
              />
              GmoId
            </label>
            <label className={`${classBase}__label`}>
              <input
                type="radio"
                name="view_type"
                className={`${classBase}__radio`}
                checked={viewType === ViewType.SonyPaymentId}
                onChange={_ => this.handleViewTypeChange(ViewType.SonyPaymentId)}
              />
              SonyPaymentId
            </label>
            <label className={`${classBase}__label`}>
              <input
                type="radio"
                name="view_type"
                className={`${classBase}__radio`}
                checked={viewType === ViewType.ReservationNumber}
                onChange={_ => this.handleViewTypeChange(ViewType.ReservationNumber)}
              />
              予約番号
            </label>
          </div>
          {viewType === ViewType.Condition && (
            <div className={`${classBase}__type-area`}>
              <label className={`${classBase}__label`}>
                <input
                  type="radio"
                  name="search_type"
                  className={`${classBase}__radio`}
                  checked={type === SearchType.OrderCreatedAt}
                  onChange={_ => this.handleTypeChange(SearchType.OrderCreatedAt)}
                />
                Order作成日
              </label>
              <label className={`${classBase}__label`}>
                <input
                  type="radio"
                  name="search_type"
                  className={`${classBase}__radio`}
                  checked={type === SearchType.OrderConfirmedAt}
                  onChange={_ => this.handleTypeChange(SearchType.OrderConfirmedAt)}
                />
                請求確定日
              </label>
              <label className={`${classBase}__label`}>
                <input
                  type="radio"
                  name="search_type"
                  className={`${classBase}__radio`}
                  checked={type === SearchType.OrderBilledAt}
                  onChange={_ => this.handleTypeChange(SearchType.OrderBilledAt)}
                />
                請求対象日
              </label>
              <label className={`${classBase}__label`}>
                <input
                  type="radio"
                  name="search_type"
                  className={`${classBase}__radio`}
                  checked={type === SearchType.TripStartTime}
                  onChange={_ => this.handleTypeChange(SearchType.TripStartTime)}
                />
                出張開始日
              </label>
              <label className={`${classBase}__label`}>
                <input
                  type="radio"
                  name="search_type"
                  className={`${classBase}__radio`}
                  checked={type === SearchType.TripEndTime}
                  onChange={_ => this.handleTypeChange(SearchType.TripEndTime)}
                />
                出張完了日
              </label>
            </div>
          )}
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {viewType === ViewType.Condition ? (
              <>
                <OrderHistoriesInputArea>
                  <label className={`${classBase}__label`}>期間</label>
                  <DatetimePicker
                    value={state.from}
                    onChange={d => this.handleFromChange(d)}
                    showTime={false}
                    disabledDays={0}
                    showPast
                    border
                  />
                  <span>〜</span>
                  <DatetimePicker
                    value={state.to}
                    onChange={d => this.handleToChange(d)}
                    showTime={false}
                    disabledDays={0}
                    showPast
                    border
                  />
                </OrderHistoriesInputArea>
                <OrderHistoriesInputArea>
                  {type === SearchType.OrderConfirmedAt || type === SearchType.OrderBilledAt ? (
                    <>
                      <label style={{ marginLeft: '10px', cursor: 'pointer' }}>
                        <input
                          type="checkbox"
                          checked={state.checkRequired}
                          onChange={() => this.toggleCheckRequired()}
                        />
                        要チェックのみ
                      </label>
                      <label style={{ marginLeft: '10px', cursor: 'pointer' }}>
                        <input
                          type="checkbox"
                          checked={state.onlyNoJournal}
                          onChange={() => this.toggleOnlyNoJournal()}
                        />
                        仕訳未入力のみ
                      </label>
                    </>
                  ) : null}
                </OrderHistoriesInputArea>
                <OrderHistoriesCompanyInputArea>
                  <label className={`${classBase}__label`}>法人名</label>
                  <SelectOrganization>
                    <SelectOrganizationsBlock
                      organization={state.organization}
                      onSelect={this.handleOrganizationSelect}
                    />
                  </SelectOrganization>
                </OrderHistoriesCompanyInputArea>
                <OrderHistoriesInputArea>
                  <label className={`${classBase}__label`}>代金詳細</label>
                  <input
                    type="text"
                    value={state.minPriceDetail}
                    className={`${classBase}__text-input`}
                    onChange={e =>
                      this.handleMinPriceDetailChange(parseInt(e.target.value.replace(/,/g, ''), 10) || '')
                    }
                  />
                  円<span>〜</span>
                  <input
                    type="text"
                    value={state.maxPriceDetail}
                    className={`${classBase}__text-input`}
                    onChange={e =>
                      this.handleMaxPriceDetailChange(parseInt(e.target.value.replace(/,/g, ''), 10) || '')
                    }
                  />
                  円
                </OrderHistoriesInputArea>
                <OrderHistoriesInputArea>
                  <label className={`${classBase}__label`}>卸値</label>
                  <input
                    type="text"
                    value={state.minOriginalPrice}
                    className={`${classBase}__text-input`}
                    onChange={e =>
                      this.handleMinOriginalPriceChange(parseInt(e.target.value.replace(/,/g, ''), 10) || '')
                    }
                  />
                  円<span>〜</span>
                  <input
                    type="text"
                    value={state.maxOriginalPrice}
                    className={`${classBase}__text-input`}
                    onChange={e =>
                      this.handleMaxOriginalPriceChange(parseInt(e.target.value.replace(/,/g, ''), 10) || '')
                    }
                  />
                  円
                </OrderHistoriesInputArea>

                <OrderHistoriesCompanyInputArea>
                  <label className={`${classBase}__label`}>仕入商品</label>
                  <SelectOrganization>
                    <OrderItemSupplierItemSelect
                      suppliedItems={_.filter(suppliedItems, item => item.disabled === false)}
                      onSelect={(v: number) => this.setState({ suppliedItem: v })}
                      suppliedItemId={state.suppliedItem}
                    />
                  </SelectOrganization>
                </OrderHistoriesCompanyInputArea>

                <OrderHistoriesCompanyInputArea>
                  <label className={`${classBase}__label`}>出張者名</label>
                  <input
                    type="text"
                    value={state.travelerName}
                    className={`${classBase}__text-input`}
                    onChange={e => this.setState({ travelerName: e.target.value })}
                  />
                </OrderHistoriesCompanyInputArea>
              </>
            ) : viewType === ViewType.TripId ? (
              <>
                <label className={`${classBase}__label`}>TripId</label>
                <input
                  type="text"
                  value={state.tripId}
                  className={`${classBase}__text-input`}
                  onChange={e => this.handleTripIdChange(e.target.value)}
                />
              </>
            ) : viewType === ViewType.GmoId ? (
              <>
                <label className={`${classBase}__label`}>GmoId</label>
                <input
                  type="text"
                  value={state.gmoId}
                  className={`${classBase}__text-input`}
                  onChange={e => this.handleGmoIdChange(e.target.value)}
                />
              </>
            ) : viewType === ViewType.SonyPaymentId ? (
              <>
                <label className={`${classBase}__label`}>SonyPaymentId</label>
                <input
                  type="text"
                  value={state.sonyPaymentId}
                  className={`${classBase}__text-input`}
                  onChange={e => this.handleSonyPaymentIdChange(e.target.value)}
                />
              </>
            ) : viewType === ViewType.ReservationNumber ? (
              <>
                <label className={`${classBase}__label`}>予約番号</label>
                <input
                  type="text"
                  value={state.reservationNumber}
                  className={`${classBase}__text-input`}
                  onChange={e => this.handleReservationNumberChange(e.target.value)}
                />
              </>
            ) : null}
            <input className={`${classBase}__submit`} type="submit" value="検索" disabled={state.loading} />
          </div>
          <div style={{ marginTop: '10px' }}>表示中のTrip：{trips.length}件</div>
        </FixedForm>
        <div className={`${classBase}__result-area`}>
          {state.loading ? (
            <SimpleLoading />
          ) : (
            trips.map(trip => <TripBlock key={trip.id} trip={trip} suppliedItems={state.suppliedItems} />)
          )}
        </div>
      </div>
    );
  }
}

const OrderHistoriesInputArea = styled.div`
  margin-right: 15px;
  display: flex;
  align-items: center;
`;

const OrderHistoriesCompanyInputArea = styled.div`
  margin-right: 15px;
  display: flex;
`;

const SelectOrganization = styled.div`
  width: 200px;
`;

const FixedForm = styled.form`
  position: sticky;
  top: 0;
  background: #fff;
`;

export default OrderHistories;
