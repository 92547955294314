/* eslint-disable max-lines */
import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';
import { styled } from '@this/constants/themes';
import { useHistory } from 'react-router-dom';
import type ShareholderTicket from '../../../domain/shareholder_ticket';
import ShareholderTicketList from '../../../domain/shareholder_ticket_list';
import DeliveryAndBookingCardList from './delivery_and_booking_card_list/delivery_and_booking_card_list';
import OrderItemTodoListLegacy from './order_item_todo_list_legacy';
import TripTodoList from './trip_todo_list';
import TicketingInstruction from './ticketing_instruction/ticketing_instruction';
import Ticketing from './ticketing/ticketing';
import SendList from './send_list/send_list';
import OrderItemTodoList from './order_item_todo_list/order_item_todo_list';
import OrderItemStepTodoList from './order_item_step_todo_list/order_item_step_todo_list';
import HotelOrderItemTodoList from './hotel_order_item_todo_list/hotel_order_item_todo_list';
import AirAutoReservationOrderItemTodoList from './air_auto_reservation_order_item_todo_list/air_auto_reservation_order_item_todo_list';

const Tab = {
  STEP: 'STEP(OrderItemStep)ごと',
  ORDER_ITEM: '商品(OrderItem)ごと',
  TRIP: '旅程(Trip)ごと',
  DELIVERY_AND_BOOKING: 'ブッキング・デリバリーチェック',
  TICKETING_INSTRUCTION: '発券指示',
  TICKETING: '発券',
  CREATE_SEND_LIST: '送付状作成',
  INVENTORY_HOTEL: 'ホテル内容確認',
  AIR_AUTO_RESERVATION_INVENTORY: '自動予約棚卸',
  ORDER_ITEM_LEGACY: '【旧】商品(OrderItem)ごと'
} as const;
export type TabType = keyof typeof Tab;
const AllTab: TabType[] = _.keys(Tab) as TabType[];

interface ShareholderTicketResponse {
  tickets: ShareholderTicket[];
}

interface Props {
  serviceId: number;
}

const TodoList = observer(({ serviceId }: Props) => {
  const history = useHistory();
  const [currentModel, setCurrentModel] = useState<TabType>((utils.getParam('tab') as TabType) || 'ORDER_ITEM');
  const [ticketCount, setTicketCount] = useState<{ [carrier: string]: number }>({});

  const fetchShareholderTickets = useCallback(() => {
    utils
      .jsonPromise<ShareholderTicketResponse>('/arrangement/shareholder_tickets.json', {
        status: 0, // 未使用
        expire_at: 0, // 期限内
        is_deleted: 0 // 未削除
      })
      .then(response => {
        const tickets = new ShareholderTicketList(response.tickets);
        const result: { [carrier: string]: number } = {};
        result.ANA = 0;
        result.JAL = 0;
        result.SFJ = 0;

        tickets.list.forEach(t => {
          switch (t.companyStr()) {
            case 'ANA':
              result.ANA += 1;
              break;
            case 'JAL':
              result.JAL += 1;
              break;
            case 'SFJ':
              result.SFJ += 1;
              break;
            default:
              break;
          }
        });
        setTicketCount(result);
      });
  }, []);

  const handleChange = useCallback(
    tab => {
      if (tab === 'INVENTORY_HOTEL') {
        history.push(`/arrangement/todo_list`);
      }
      setCurrentModel(tab);
    },
    [history, setCurrentModel]
  );

  useEffect(() => {
    fetchShareholderTickets();
  }, [fetchShareholderTickets]);

  return (
    <Wrapper>
      <div style={{ display: 'flex' }}>
        <div>
          <SelectModel>
            {AllTab.map(tab => (
              <Label key={tab}>
                <input type="radio" checked={currentModel === tab} onChange={() => handleChange(tab)} />
                {Tab[tab]}
              </Label>
            ))}
          </SelectModel>
        </div>
      </div>
      {currentModel === 'STEP' && <OrderItemStepTodoList serviceId={serviceId} />}
      {currentModel === 'ORDER_ITEM' && <OrderItemTodoList serviceId={serviceId} ticketCount={ticketCount} />}
      {currentModel === 'TRIP' && <TripTodoList />}
      {currentModel === 'DELIVERY_AND_BOOKING' && <DeliveryAndBookingCardList />}
      {currentModel === 'TICKETING_INSTRUCTION' && <TicketingInstruction />}
      {currentModel === 'TICKETING' && <Ticketing />}
      {currentModel === 'CREATE_SEND_LIST' && <SendList />}
      {currentModel === 'INVENTORY_HOTEL' && <HotelOrderItemTodoList />}
      {currentModel === 'AIR_AUTO_RESERVATION_INVENTORY' && <AirAutoReservationOrderItemTodoList />}
      {currentModel === 'ORDER_ITEM_LEGACY' && <OrderItemTodoListLegacy />}
    </Wrapper>
  );
});

const Wrapper = styled.div`
  padding: 20px;
  font-size: 12px;
`;

const SelectModel = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

const Label = styled.label`
  margin-right: 20px;
`;

export default TodoList;
