import React from 'react';
import { css, styled } from '@this/constants/themes';
import { BoxButtonText, Yen } from '../../shared/box_parts';
import SelectTransportBoxPriceRangeLabel from '../price_range_label';

type Props = {
  price?: number;
  isAddtional: boolean;
  priceText?: string;
  priceRange?: string;
  showsPricePerPersonText?: boolean;
  showsIncludeTax?: boolean;
  includeTax: boolean;
  selected: boolean;
  disabled?: boolean;
  isRakutenPackage: boolean | null | undefined;
  onClick: () => void;

  // ArrangementRequestForm
  isRequestForm: boolean;
  seatCount?: string | null;
};

const SelectTransportBoxRightInOutlineTemplate: React.FC<Props> = ({
  price,
  isAddtional,
  priceRange,
  showsPricePerPersonText,
  showsIncludeTax,
  includeTax,
  selected,
  disabled,
  onClick,
  isRakutenPackage,
  isRequestForm
}) => (
  <BoxWrap isRakutenPackage={isRakutenPackage === true}>
    {!isRakutenPackage && (
      <BoxPriceArea>
        <div>
          <BoxPrice>
            {price !== undefined && (
              <>
                {isAddtional && <>+ </>}
                <span data-wovn-ignore>{utils.digits(price)}</span>
                <Yen>円</Yen>
              </>
            )}
            {priceRange && <SelectTransportBoxPriceRangeLabel priceRange={priceRange} />}
          </BoxPrice>
          <BoxSmall>
            {showsPricePerPersonText ? '/1名' : ''}
            {((): string => {
              if (!showsIncludeTax) {
                return '';
              }

              if (includeTax) {
                return '(税込)';
              }
              return '(税別)';
            })()}
          </BoxSmall>
        </div>
      </BoxPriceArea>
    )}
    {!isRequestForm && (
      <BoxButtonArea>
        <BoxButton selected={selected} disabled={disabled} onClick={onClick}>
          <BoxButtonText className="select-transport-box__right-gray__button-text">
            {selected ? (
              <span>
                変更を
                <br />
                キャンセル
              </span>
            ) : (
              '変更 >'
            )}
          </BoxButtonText>
        </BoxButton>
      </BoxButtonArea>
    )}
  </BoxWrap>
);

export const BoxWrap = styled.div<{ isRakutenPackage?: boolean }>`
  display: flex;
  flex-grow: 0;
  margin-left: auto;
  min-width: 170px;
  ${props => !props.isRakutenPackage && `background:  ${props.theme.grayBgColorLight}`};
`;

export const BoxName = styled.div`
  font-size: 12px;
  font-weight: bold;
`;

export const BoxPriceArea = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin: auto 0 auto 10px;
`;

export const BoxPrice = styled.span`
  color: ${props => (props.theme.themeClass === 'tabikobo' ? props.theme.textColor : props.theme.moneyColor)};
  font-size: 14px;
  font-weight: bold;
  display: block;
`;

export const BoxSmall = styled.div`
  font-size: 10px;
  display: inline-block;
`;

export const BoxTax = styled.span`
  font-size: 10px;
  display: inline-flex;
`;

export const BoxButtonArea = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin: auto 13px auto auto;
`;

export const BoxButton = styled.div<{ selected: boolean; disabled?: boolean }>`
  display: flex;
  align-items: center;

  width: 53px;
  height: 30px;
  text-align: center;
  color: ${props => props.theme.linkColor};
  background: white;
  border: 2px solid ${props => props.theme.linkColor};
  border-radius: 3px;
  padding: auto 2px;
  font-weight: bold;
  line-height: 11px;
  font-size: 11px;
  cursor: pointer;

  ${props =>
    props.selected &&
    `
    background: ${props.theme.linkColor};
    color: white;
    font-size: 9px;
    letter-spacing: -1px;
  `};

  ${({ disabled }) =>
    disabled
      ? css`
          opacity: 0.6;
          pointer-events: none;
        `
      : null}
`;

const Area = styled.div`
  color: ${props => props.theme.grayTextColor};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const LoadingArea = styled(Area)`
  padding: 15px 0 0 10px;
  max-width: 115px;
`;

export const NoVacancyArea = styled(Area)`
  padding-top: 20px;
  max-width: 100px;
`;

export const NoVacancyWithinLimitArea = styled(Area)`
  padding: 20px 3px 0 3px;
  max-width: 125px;
  text-align: center;
`;

export const AreaText = styled.div`
  padding: 8px 0 3px 0;
  position: relative;
  left: 25px;
  white-space: nowrap;
`;

export const NoVacancyText = styled.div`
  font-weight: bold;
  font-size: 13px;
`;

export const SpanBlock = styled.span`
  display: inline-block;
`;

SelectTransportBoxRightInOutlineTemplate.defaultProps = {
  showsPricePerPersonText: true
};

export default SelectTransportBoxRightInOutlineTemplate;
