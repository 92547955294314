/* eslint-disable max-lines */
// 行きの航空券・帰りの航空券・ホテルを統合した検索クエリー。
import _ from 'lodash';
import type { Moment } from 'moment';
import moment from 'moment';

import type User from '@this/domain/user/user';
import type { AvailableRepository } from '@this/domain/available_repository';
import localStorage from '@this/lib/local_storage';
import SearchQueryItem from './search_query_item';
import type { Carrier } from './flight/flight_list';
import TravelerList from './traveler/traveler_list';
import type { Airline } from './select_store';
import type TextToQueryResultItem from './chatbot/text_to_query_result_item';

export const SearchTypes = {
  ROUND_TRIP: 'roundTrip',
  ONE_WAY: 'oneWay',
  MULTI_LOCATION: 'multipleLocations',
  HOTEL: 'hotel',
  RENTAL_CAR: 'rentalCar'
} as const;

export const SEARCH_TYPE_LABELS: { [key in SearchType]: string } = {
  [SearchTypes.ROUND_TRIP]: '往復',
  [SearchTypes.ONE_WAY]: '片道',
  [SearchTypes.MULTI_LOCATION]: '複数地点',
  [SearchTypes.HOTEL]: 'ホテル',
  [SearchTypes.RENTAL_CAR]: 'レンタカー'
};

type TabIndex = number;
export type SearchType = typeof SearchTypes[keyof typeof SearchTypes];
export const FLIGHT_TYPES = ['', 'N', 'C'] as const;
export type FlightType = typeof FLIGHT_TYPES[number];
export const DEFAULT_CABIN = ['M'];
export const ALL_CABIN = ['M', 'W', 'C', 'F'];

export interface PackageSearchQueryItems {
  origin: SearchQueryItem;
  hotel: SearchQueryItem;
  destination: SearchQueryItem;
}

// getParams()からはすべてstringで渡される
interface SearchQueryArgs {
  smoke?: string;
  breakfast?: string;
  roomnum?: string | number;
  peoplenum?: string | number;
  search_type?: SearchType;
  cabin?: string;
  carrier_list?: string;
  flight_type?: FlightType;
  type?: 'separate' | 'airPackage';
  airline?: Airline;
  draft_id?: number;
  items?: any[];
  carType?: [string, string][];
  travelers?: TravelerList;
  time?: { startTime: Moment; endTime: Moment };
  availableRepos: AvailableRepository[];
  defaultSeatClasses?: string[];
  rentalcarAvailable?: boolean;
}

class SearchQuery {
  public smoke: string;

  public breakfast: string;

  public roomnum: number;

  public peoplenum: number;

  public currentTabIndex: TabIndex;

  private defaultItemsCollection: { [key in SearchType]: SearchQueryItem[] };

  public items: SearchQueryItem[];

  public cabin: string[];

  public defaultSeatClasses: string[];

  public type: 'separate' | 'airPackage' | undefined;

  public airline: Airline;

  public carrierList: Carrier[] | undefined;

  public flightType: FlightType;

  public carType: [string, string][] | null;

  private draftId: number | undefined;

  public travelers?: TravelerList;

  public defaultTime: { startTime: Moment; endTime: Moment };

  public rentalcarAvailable: boolean | undefined;

  readonly disabledHotelSearch: boolean;

  readonly disabledTransitSearch: boolean;

  readonly disabledPackageSearch: boolean;

  constructor(args: SearchQueryArgs) {
    const packageCategories = ['domestic_railway_package', 'domestic_flight_package'];
    this.disabledPackageSearch =
      args.availableRepos.filter(repo => packageCategories.includes(repo.category)).length === 0;

    const hotelCategories = ['domestic_hotel', 'foreign_hotel'];
    this.disabledHotelSearch =
      args.availableRepos.filter(repo => hotelCategories.includes(repo.category)).length === 0;

    const transitCategories = [...packageCategories, 'foreign_flight', 'domestic_flight', 'domestic_railway'];
    this.disabledTransitSearch =
      args.availableRepos.filter(repo => transitCategories.includes(repo.category)).length === 0;

    this.smoke = args.smoke || localStorage.getItem('smoke') || '';
    this.breakfast = args.breakfast || localStorage.getItem('breakfast') || '';
    this.roomnum = Number(args.roomnum) || 1;
    this.peoplenum = Number(args.peoplenum) || 1;
    const defaultSeatClasses = args.defaultSeatClasses || [];
    this.defaultSeatClasses = defaultSeatClasses;
    this.cabin = args.cabin ? args.cabin.split(',') : _.cloneDeep(defaultSeatClasses);
    if (args.carType) {
      this.carType = args.carType;
    } else {
      this.carType = null;
    }
    this.type = args.type || 'separate';
    this.airline = args.airline || 'ANA';
    if (args.carrier_list) {
      // AA:A航空会社,BB:B航空会社というフォーマットから変換する
      const splitedCarrierList = args.carrier_list.split(',');
      this.carrierList = splitedCarrierList.map(s => {
        const [id, name] = s.split(':');
        return { id, name };
      });
    }
    this.flightType = args.flight_type || '';
    this.draftId = args.draft_id;
    if (args.travelers instanceof TravelerList) {
      this.travelers = args.travelers;
    }
    this.defaultTime = {
      startTime: args.time ? args.time.startTime : moment().add(1, 'days'),
      endTime: args.time ? args.time.endTime : moment().add(2, 'days')
    };
    this.currentTabIndex = this.getCurrentTabIndexBySearchType(args.search_type);
    this.defaultItemsCollection = this.createDefaultItemsCollection(args.items);
    this.items = this.defaultItemsCollection[this.searchType()];
    this.rentalcarAvailable = args.rentalcarAvailable;
  }

  applyParams(args: SearchQueryArgs) {
    this.defaultItemsCollection = this.createDefaultItemsCollection(args.items);
    this.items = this.defaultItemsCollection[this.searchType()];
  }

  private createDefaultItemsCollection(items?: any) {
    const defaultCollection = {
      roundTrip: this.defaultItems('roundTrip'),
      oneWay: this.defaultItems('oneWay'),
      multipleLocations: this.defaultItems('multipleLocations'),
      hotel: this.defaultItems('hotel'),
      rentalCar: this.defaultItems('rentalCar')
    };
    // 検索リダイレクトの場合（リクエストパラメータにitems指定あり）、上書きする
    if (!_.isEmpty(items))
      defaultCollection[this.searchType()] = _.map(
        items,
        (item, i) =>
          new SearchQueryItem({
            ...item,
            defaultIndex: i,
            type: this.type
          })
      );
    return defaultCollection;
  }

  private defaultItems(searchType: SearchType): SearchQueryItem[] {
    const outdate = this.defaultTime.startTime.format('YYYY-MM-DD');
    const homedate = this.defaultTime.endTime.format('YYYY-MM-DD');

    const outItem = new SearchQueryItem({
      item_type: 'transport',
      // origin: this.origin,
      // destination: this.destination,
      outdate,
      // outhour: this.outhour,
      // outmin: this.outmin,
      // outtype: this.outtype,
      peoplenum: this.peoplenum,
      index: 0
    });
    const hotelItem = new SearchQueryItem({
      item_type: 'hotel',
      // destination: this.stay,
      outdate,
      homedate,
      smoke: this.smoke,
      breakfast: this.breakfast,
      roomnum: this.roomnum,
      peoplenum: this.peoplenum,
      index: 1
    });
    const homeItem = new SearchQueryItem({
      item_type: 'transport',
      // origin: this.destination,
      // destination: this.origin,
      outdate: homedate,
      // outhour: this.homehour,
      // outmin: this.homemin,
      // outtype: this.hometype,
      peoplenum: this.peoplenum,
      index: this.disabledHotelSearch ? 1 : 2
    });
    const rentalCarItem = new SearchQueryItem({
      item_type: 'rentalCar',
      outdate: homedate,
      outhour: '12',
      homedate,
      peoplenum: this.peoplenum,
      carType: this.carType,
      index: 0
    });
    if (searchType === 'oneWay') {
      return [outItem];
    }
    if (searchType === 'hotel') {
      return [hotelItem];
    }
    if (searchType === 'rentalCar') {
      return [rentalCarItem];
    }

    // roundTrip, multipleLocations
    let items = [outItem, hotelItem, homeItem];
    if (this.disabledHotelSearch) {
      items = [outItem, homeItem];
    }
    if (this.disabledTransitSearch) {
      items = this.disabledHotelSearch ? [] : [hotelItem];
    }

    return items;
  }

  // 部分検索時に一時的に自動で追加された空港までの経路をURLパラメータに追加する
  withAutoInputItemsSearch(handleSearch: () => void, replaceState: () => void) {
    const inputTags = this.items.map(item => item.input ?? true);
    this.items.forEach(item => item.setInput(true));
    replaceState();

    handleSearch();

    this.items.forEach((item, i) => item.setInput(inputTags[i]));
    replaceState();
  }

  getInputItems() {
    return this.items.filter(item => item.input);
  }

  getCurrentTabIndexBySearchType(searchType: SearchType | undefined): TabIndex {
    switch (searchType) {
      case 'oneWay':
        return 1;
      case 'multipleLocations':
        return 2;
      case 'hotel':
        return 3;
      case 'rentalCar':
        return 4;
      case 'roundTrip':
      default: {
        let result = 0;

        if (this.disabledTransitSearch) {
          result = 3;
        }
        if (this.disabledHotelSearch) {
          result = result === 0 ? 0 : 4;
        }
        return result;
      }
    }
  }

  isRoundTripSearch(): boolean {
    return this.currentTabIndex === 0;
  }

  isOneWaySearch(): boolean {
    return this.currentTabIndex === 1;
  }

  isMultipleLocationsSearch(): boolean {
    return this.currentTabIndex === 2;
  }

  isHotelSearch(): boolean {
    return this.currentTabIndex === 3;
  }

  isRentalCarTrip(): boolean {
    return this.currentTabIndex === 4;
  }

  setId(id: number, rawItems: any[]) {
    _.each(this.items, (item, i) => {
      if (rawItems[i]) {
        item.searchQueryId = id;
        item.id = rawItems[i].id;
      }
    });
    app.render();
  }

  replaceItems(id: number, rawItems: any[]) {
    this.items = rawItems.map((rawItem, i) => {
      const item = this.items.find(
        i =>
          i.input &&
          i.itemType === rawItem.item_type &&
          i.origin === rawItem.origin &&
          i.destination === rawItem.destination
      );
      if (item) {
        item.setIndex(i);
        item.setPair(rawItem.pair);
        return item;
      }

      const departureTime = rawItem.departure_time && moment(rawItem.departure_time);
      const returnTime = rawItem.return_time && moment(rawItem.return_time);

      return new SearchQueryItem({
        ...rawItem,
        searchQueryId: id,
        index: i,
        outdate: departureTime?.format('YYYY-MM-DD'),
        homedate: returnTime?.format('YYYY-MM-DD'),
        input: false
      });
    });
    app.render();
  }

  identifyPackageQueryItems() {
    const packages: Partial<PackageSearchQueryItems>[] = [];

    this.items.forEach(item => {
      if (item.itemType === 'transport') {
        // 復路未設定で往路出発地と復路到着地が同じものを探し、見つかれば復路に登録、見つからなければ往路として登録
        const pkg = packages.find(pkg => !pkg.destination && pkg.origin?.origin.includes(item.destination));
        if (pkg) {
          pkg.destination = item;
        } else {
          packages.push({ origin: item });
        }
      } else if (item.itemType === 'hotel') {
        // 直前の往路にホテルが登録されていなければ、ホテルを登録
        const pkg = packages.slice(-1)[0];
        if (pkg && !pkg.hotel) pkg.hotel = item;
      }
    });

    // 往路・ホテル・復路がセットになっていればパッケージとして利用
    return packages.filter(pkg => pkg.origin && pkg.hotel && pkg.destination) as PackageSearchQueryItems[];
  }

  packageParams() {
    const res = {} as { [key: string]: string | number | null | undefined };
    const items: SearchQueryItem[] = Object.values(this.identifyPackageQueryItems()[0] || {});
    items.forEach((item, i) => {
      _.entries(item.packageParams()).forEach(([k, v]) => {
        res[`items[${i}][${k}]`] = v;
      });
    });
    return res;
  }

  jrPackageParams() {
    const res = {} as { [key: string]: string | number | null | undefined };
    const items: SearchQueryItem[] = Object.values(this.identifyPackageQueryItems()[0] || {});
    items.forEach((item, i) => {
      _.entries(item.jrPackageParams()).forEach(([k, v]) => {
        res[`items[${i}][${k}]`] = v;
      });
    });
    return res;
  }

  rakutenPackageParams() {
    const res = {} as { [key: string]: string | number | null | undefined };
    const items: SearchQueryItem[] = Object.values(this.identifyPackageQueryItems()[0] || {});
    items.forEach((item, i) => {
      _.entries(item.rakutenPackageParams()).forEach(([k, v]) => {
        res[`items[${i}][${k}]`] = v;
      });
    });
    return res;
  }

  searchHistoryParams() {
    const params = _.uniqBy(
      this.getInputItems().map(item => item.searchHistoryParams()),
      ['main_text', 'secondary_text']
    );
    return { histories: _.flatten(params) };
  }

  validationPromise() {
    const itemErrors = this.items.map(item => item.validationPromise());
    const travelerErrors = this.travelers ? this.travelers.validationPromise() : null;
    const results = itemErrors.concat(travelerErrors);
    if (results.length > 0) {
      let errors = {};
      results.forEach(result => {
        if (result) {
          errors = { ...errors, ...result };
        }
      });
      if (_.keys(errors).length > 0) throw errors;
      return;
    }
    const err = { items: '検索フォームを一つ以上追加してください' };
    throw err;
  }

  getSubmitParams() {
    return {
      peoplenum: `${this.peoplenum}`,
      roomnum: `${this.roomnum}`,
      smoke: this.smoke,
      breakfast: this.breakfast,
      search_type: this.searchType(),
      draft_id: this.draftId,
      cabin: this.cabin[0] === 'all' ? _.cloneDeep(this.defaultSeatClasses).join(',') : this.cabin.join(','),
      // AA:A航空会社,BB:B航空会社というフォーマットに変換する
      carrier_list: this.carrierList ? this.carrierList.map(c => `${c.id}:${c.name}`).join(',') : '',
      flight_type: this.flightType,
      type: this.type,
      airline: this.airline,
      items: _.map(this.items, item => item.getSubmitParams())
    };
  }

  getParamsForQueryString() {
    return {
      peoplenum: `${this.peoplenum}`,
      roomnum: `${this.roomnum}`,
      smoke: this.smoke,
      breakfast: this.breakfast,
      search_type: this.searchType(),
      draft_id: this.draftId,
      cabin:
        this.cabin[0] === 'all' ? _.cloneDeep(this.defaultSeatClassesForFilter()).join(',') : this.cabin.join(','),
      // AA:A航空会社,BB:B航空会社というフォーマットに変換する
      carrier_list: this.carrierList ? this.carrierList.map(c => `${c.id}:${c.name}`).join(',') : '',
      flight_type: this.flightType,
      type: this.type,
      airline: this.airline
    };
  }

  searchType(): SearchType {
    switch (this.currentTabIndex) {
      case 1:
        return 'oneWay';
      case 2:
        return 'multipleLocations';
      case 3:
        return 'hotel';
      case 4:
        return 'rentalCar';
      case 0:
      default:
        return 'roundTrip';
    }
  }

  convertSearchTypeToCurrentIndex(serachType: SearchType) {
    switch (serachType) {
      case SearchTypes.ROUND_TRIP:
        this.currentTabIndex = 0;
        break;
      case SearchTypes.ONE_WAY:
        this.currentTabIndex = 1;
        break;
      case SearchTypes.MULTI_LOCATION:
        this.currentTabIndex = 2;
        break;
      case SearchTypes.HOTEL:
        this.currentTabIndex = 3;
        break;
      case SearchTypes.RENTAL_CAR:
        this.currentTabIndex = 4;
        break;
      default:
        break;
    }
  }

  breakfastStr() {
    switch (this.breakfast) {
      case 'yes':
        return '有';
      case 'no':
        return '無';
      default:
        return '指定なし';
    }
  }

  smokeStr() {
    switch (this.smoke) {
      case 'yes':
        return '喫煙室';
      case 'no':
        return '禁煙室';
      default:
        return '指定なし';
    }
  }

  getSummaryData() {
    const summary = _.reduce(
      this.getInputItems(),
      (result, item, i) => _.merge(result, item.getSummaryData(i)),
      {}
    );
    if (this.disabledHotelSearch) {
      return summary;
    }
    return _.merge(summary, {
      オプション: `${this.peoplenum}人 ${
        this.roomnum
      }部屋 朝食:${this.breakfastStr()} ホテル煙草:${this.smokeStr()}`
    });
  }

  getQueryString() {
    let res = '?';
    _.entries(this.getParamsForQueryString()).forEach(([k, v]) => {
      if (v == null || v === '') return;
      res += `${k}=${v}&`;
    });
    this.getInputItems().forEach((item, i) => {
      res += item.getQueryString(`items[${i}]`);
    });
    if (this.travelers) {
      res += this.travelers.getQueryString();
    }
    return res;
  }

  setPeoplenum(value: number) {
    this.peoplenum = value;
    _.each(this.items, item => item.setPeoplenum(value));
    app.render();
  }

  setPeoplenumWithTraveler(value: number, user: User) {
    this.setPeoplenum(value);
    this.changeTravelersNum(value, user);
  }

  setRoomnum(value: number) {
    this.roomnum = value;
    _.each(this.items, item => item.setRoomnum(value));
    app.render();
  }

  setSmoke(value: string) {
    this.smoke = value;
    _.each(this.items, item => item.setSmoke(value));
    app.render();
  }

  setBreakfast(value: string) {
    this.breakfast = value;
    _.each(this.items, item => item.setBreakfast(value));
    app.render();
  }

  setCurrentTabIndex(value: TabIndex) {
    this.currentTabIndex = value;
    this.defaultItemsCollection = this.createDefaultItemsCollection();
    this.items = this.defaultItemsCollection[this.searchType()];
    app.render();
  }

  setCabin(values: string[]) {
    this.cabin = values;
    app.render();
  }

  setCarrierList(carrierList: Carrier[]) {
    this.carrierList = carrierList;
  }

  setCarType(value: [string, string][] | null) {
    this.carType = value;
    app.render();
  }

  setDefaultSeatClasses(value: string[]) {
    this.defaultSeatClasses = value;
    app.render();
  }

  clearCarrier() {
    this.carrierList = undefined;
  }

  setFlightType(flightType: FlightType) {
    this.flightType = flightType;
    app.render();
  }

  setTravelers(travelers: TravelerList) {
    this.travelers = travelers;
    app.render();
  }

  setInitialTravelers(user: User) {
    const args = {
      user,
      peoplenum: this.peoplenum
    };
    this.travelers = TravelerList.initialTravelers(args);
    app.render();
  }

  private changeTravelersNum(peoplenum: number, user: User) {
    if (this.travelers) {
      const diff = peoplenum - this.travelers!.list.length;
      if (diff > 0) {
        _.each(_.range(diff), _i => {
          this.travelers!.addTraveler(user);
        });
      }
      if (diff < 0) {
        _.each(_.range(Math.abs(diff)), _i => {
          this.travelers!.removeLastTraveler();
        });
      }
    }
  }

  setTravelersFromQuery(travelers: any[], user: User, members: User[]) {
    this.travelers = TravelerList.fromQuery({
      travelerQueries: travelers,
      user,
      members,
      peoplenum: this.peoplenum
    });
  }

  defaultSeatClassesForFilter() {
    if (this.travelers !== undefined) {
      // 複数人の出張の場合、旅費規定は一人目を出張の代表者として扱い、代表者の旅費規定が同行する出張者全員に適用される
      const firstTraveler = this.travelers.list[0];
      if (firstTraveler.type !== 'companion') {
        return firstTraveler.defaultSeatClasses;
      }
      // 代表者が手入力の場合は、申請者の旅費規定を適用する
      return this.defaultSeatClasses;
    }
    return [];
  }

  firstTravelerDomesticAirSeatUpgrade() {
    if (this.travelers !== undefined) {
      return this.travelers.list[0].domesticAirSeatUpgrade;
    }
    return false;
  }

  addItem(itemType: 'transport' | 'hotel' | 'rentalCar') {
    const commonArgs = {
      peoplenum: this.peoplenum,
      roomnum: this.roomnum,
      smoke: this.smoke,
      breakfast: this.breakfast
    };
    const prevItem = this.items[this.items.length - 1];
    let item;
    if (prevItem) {
      const prevDate = prevItem.itemType === 'transport' ? prevItem.outdate : prevItem.homedate;
      let args;
      switch (itemType) {
        case 'transport':
          args = {
            item_type: itemType,
            origin: prevItem.destination,
            origin_address: prevItem.destinationAddress,
            outdate: prevDate
          };
          break;
        case 'hotel':
          args = {
            item_type: itemType,
            destination: prevItem.destination,
            outdate: prevDate,
            homedate: moment(prevDate).add(1, 'days').format('YYYY-MM-DD')
          };
          break;
        case 'rentalCar':
          args = {
            item_type: itemType,
            destination: prevItem.destination,
            outdate: prevDate,
            homedate: prevDate,
            carType: this.carType
          };
          break;
        default:
      }
      item = new SearchQueryItem(_.merge(commonArgs, args, { index: this.items.length }));
    } else {
      item = new SearchQueryItem(_.merge(commonArgs, { item_type: itemType, index: 0 }));
    }
    this.items.push(item);
    app.render();
  }

  removeItem(index: number) {
    this.items.splice(index, 1);
    this.items.forEach((item, i) => {
      item.setIndex(i);
    });
    app.render();
  }

  isItemLimit(): boolean {
    return this.items.length > 6;
  }

  startTime() {
    const item = _.first(this.items);
    return item && item.startTime();
  }

  endTime() {
    const item = _.last(this.items);
    return item && item.endTime();
  }

  resetTime(startTime: Moment, endTime: Moment, items?: SearchQueryItem[]) {
    this.defaultTime = { startTime, endTime };
    this.defaultItemsCollection = this.createDefaultItemsCollection(items);
    this.items = this.defaultItemsCollection[this.searchType()];
  }

  finalDestination() {
    const item = _.last(this.items);
    return item && item.destination;
  }

  clearFlightFilter() {
    this.cabin = _.cloneDeep(this.defaultSeatClassesForFilter());
    this.items.forEach(item => item.clearCarrierIds());
    this.flightType = '';
  }

  applyTextToQueryResultItems(items: TextToQueryResultItem[]) {
    items.forEach((item, index) => {
      const searchQueryItem = this.items[index];
      if (searchQueryItem) {
        searchQueryItem.applyTextToQueryResultItem(item);
      }
    });
  }
}

export default SearchQuery;
