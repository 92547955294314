import _ from 'lodash';
import type { OrderItemMappingMode } from '@this/domain/order_item_mapping';
import Department from './department/department';
import Project from './project/project';
import type { ProjectArgs } from './project/project';

// trip.coffeeで、new NonOrderItem(utils.snakeToCamelObject(raw)しているので、Argsもcamel caseでset
export type NonOrderItemMappingArgs = {
  id?: number;
  travelerInformationId?: number;
  travelerId?: number;
  nonOrderItemId?: number;
  nonOrderItemType?: string;
  nonOrderItemIndex?: number;
  projectId?: number;
  chargingDepartmentId?: number;
  expensesAccountTypeId?: number;
  project_share_id?: number;
  charging_department_share_id?: number;
  selectedOptionLabel?: string;
  travelerInformation?: any;
  project?: ProjectArgs;
  chargingDepartment?: any;
};

export type NonOrderItemMappingMode = 'item' | 'traveler' | 'both' | 'default';

export const NON_ORDER_ITEM_MAPPING_DEFAULT_ARGS: NonOrderItemMappingArgs = {
  travelerId: -1,
  nonOrderItemType: 'all',
  nonOrderItemIndex: -1
};

export default class NonOrderItemMapping {
  id: number | null;

  travelerInformationId: number | null;

  travelerId: number | null;

  nonOrderItemId: number | null;

  nonOrderItemType: string | null;

  nonOrderItemIndex: number | null;

  projectId: number | null;

  chargingDepartmentId: number | null;

  expensesAccountTypeId: number | null;

  projectShareId: number | null;

  chargingDepartmentShareId: number | null;

  selectedOptionLabel: string | null;

  travelerInformation?: any | null;

  chargingDepartment?: Department | null;

  project?: Project | null;

  constructor(args: NonOrderItemMappingArgs) {
    this.id = args.id || null;
    this.travelerInformationId = args.travelerInformationId || null;
    this.travelerId = args.travelerId !== undefined ? args.travelerId : null;
    this.nonOrderItemId = args.nonOrderItemId || null;
    this.nonOrderItemType = args.nonOrderItemType || null;
    this.nonOrderItemIndex = args.nonOrderItemIndex !== undefined ? args.nonOrderItemIndex : null;
    this.chargingDepartmentId = args.chargingDepartmentId || null;
    this.projectId = args.projectId || null;
    this.expensesAccountTypeId = args.expensesAccountTypeId || null;
    this.selectedOptionLabel = args.selectedOptionLabel || null;
    this.projectShareId = args.project_share_id || null;
    this.chargingDepartmentShareId = args.charging_department_share_id || null;

    if (args.travelerInformation) this.travelerInformation = args.travelerInformation;
    if (args.project) this.project = new Project(args.project);
    if (args.chargingDepartment) this.chargingDepartment = new Department(args.chargingDepartment);
  }

  clone(): NonOrderItemMapping {
    const args: NonOrderItemMappingArgs = {
      id: this.id || undefined,
      travelerInformationId: this.travelerInformationId || undefined,
      travelerId: this.travelerId || undefined,
      nonOrderItemId: this.nonOrderItemId || undefined,
      nonOrderItemType: this.nonOrderItemType || undefined,
      nonOrderItemIndex: this.nonOrderItemIndex || undefined,
      projectId: this.projectId || undefined,
      chargingDepartmentId: this.chargingDepartmentId || undefined,
      expensesAccountTypeId: this.expensesAccountTypeId || undefined,
      project_share_id: this.projectShareId || undefined,
      charging_department_share_id: this.chargingDepartmentShareId || undefined,
      selectedOptionLabel: this.selectedOptionLabel || undefined
    };
    return new NonOrderItemMapping(args);
  }

  isProjectExistForNonOrderItemMappings = () => {
    return !!this.projectId;
  };

  isChargingDepartmentExistForNonOrderItemMappings = () => {
    return !!this.chargingDepartmentId;
  };

  isExpensesAccountTypeExistForNonOrderItemMappings = () => {
    return !!this.expensesAccountTypeId;
  };

  submitParams = (): any => {
    return this.createSubmitParams();
  };

  createSubmitParams = (excludeFields: string[] = []): any => {
    const params: any = {
      id: this.id,
      traveler_information_id: this.travelerInformationId,
      traveler_id: this.travelerId,
      non_order_item_id: this.nonOrderItemId,
      non_order_item_type: this.nonOrderItemType,
      non_order_item_index: this.nonOrderItemIndex,
      project_id: excludeFields.includes('project_id') ? undefined : this.projectId || null,
      charging_department_id: excludeFields.includes('charging_department_id')
        ? undefined
        : this.chargingDepartmentId || null,
      expenses_account_type_id: excludeFields.includes('expenses_account_type_id')
        ? undefined
        : this.expensesAccountTypeId || null,

      project_share_id: this.projectShareId || null,
      charging_department_share_id: this.chargingDepartmentShareId || null
    };
    // 無効なキー（undefined値を持つキー）を削除
    Object.keys(params).forEach(key => params[key] === undefined && delete params[key]);

    return params;
  };

  setProjectId = (value: number | null) => {
    this.projectId = value;
    this.projectShareId = null;
    app.render();
  };

  setProjectShareId = (value: number | null) => {
    this.projectShareId = value;
    this.projectId = null;
    app.render();
  };

  setChargingDepartmentId = (value: number) => {
    this.chargingDepartmentId = value;
    this.chargingDepartmentShareId = null;
    app.render();
  };

  setChargingDepartmentShareId = (value: number) => {
    this.chargingDepartmentShareId = value;
    this.chargingDepartmentId = null;
    app.render();
  };

  setExpensesAccountTypeId = (value: number | null) => {
    this.expensesAccountTypeId = value;
    app.render();
  };

  nonOrderItemMappingType(): NonOrderItemMappingMode | '' {
    let type: OrderItemMappingMode | '' = '';
    if (this.travelerId !== null && this.nonOrderItemIndex !== null) {
      if (this.travelerId >= 0 && this.nonOrderItemIndex >= 0) {
        type = 'both';
      } else if (this.travelerId < 0 && this.nonOrderItemIndex >= 0) {
        type = 'item';
      } else if (this.travelerId >= 0 && this.nonOrderItemIndex < 0) {
        type = 'traveler';
      } else {
        type = 'default';
      }
    }
    return type;
  }

  setSelectedOptionLabel = (value: string) => {
    this.selectedOptionLabel = value;
  };

  isProjectExist = () => {
    return !!this.projectId || !!this.projectShareId;
    return !!this.projectId;
  };

  isChargingDepartmentExist = () => {
    return !!this.chargingDepartmentId || !!this.chargingDepartmentShareId;
    return !!this.chargingDepartmentId;
  };

  isExpensesAccountTypeExist = () => {
    return !!this.expensesAccountTypeId;
  };

  static defaultMapping = (): NonOrderItemMapping => {
    return new NonOrderItemMapping(NON_ORDER_ITEM_MAPPING_DEFAULT_ARGS);
  };
}
