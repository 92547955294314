/* eslint-disable max-lines */
import React from 'react';
import { Prompt } from 'react-router';
import _ from 'lodash';
import { withTheme } from 'styled-components';
import type { Moment } from 'moment';
import moment from 'moment';
import { styled } from '@this/constants/themes';
import { ButtonBase } from '@this/components/shared/atoms/button';
import Text from '@this/components/shared/text/text';

import ContentHeader from '@this/components/shared/content_header/content_header';
import SimpleLoading from '@this/components/shared/simple_loading/simple_loading';

import User from '@this/domain/user/user';
import type SelectRepository from '@this/domain/select_repository';
import type { PackageProvider } from '@this/domain/select_store';
import type SelectStore from '@this/domain/select_store';
import type { SearchType } from '@this/domain/search_query';
import SearchQuery from '@this/domain/search_query';
import type { AvailableRepository } from '@this/domain/available_repository';
import type { RouteComponentProps } from 'react-router-dom';
import Trip from '@this/src/domain/trip/trip';
import TravelerList from '@this/src/domain/traveler/traveler_list';
import Traveler from '@this/src/domain/traveler/traveler';
import ReserveConfirmRoutingsTemplate from '@this/components/reserve_trip/reserve_confirm/routings/routings.template';
import type { CardJson, ConsignmentSale, RentalCarJson } from '@this/domain/reserve_info';
import ReserveInfo from '@this/domain/reserve_info';
import OrganizationBase from '@this/domain/organization_base/organization_base';
import Department from '@this/domain/department/department';
import type Prefecture from '@this/domain/prefecture';
import type { TripDuplicationInterface } from '@this/components/reserve_trip/reserve_confirm/reserve_confirm';
import MarginTypeList from '@this/domain/organization/margin_type_list';
import type { RentalCarLimitTypeJson } from '@this/domain/organization/rental_car_limit_type';
import RentalCarLimitType from '@this/domain/organization/rental_car_limit_type';
import ReservingTrip from '@this/domain/trip/reserving_trip';
import ReservingTripItem from '@this/domain/trip/reserving_trip_item';
import type UserJson from '@this/domain/user/user_json';
import type { OrderItemMappingArgs } from '@this/domain/order_item_mapping';
import type { WorkflowStyle } from '@this/domain/workflow_style';
import NonOrderItem from '@this/domain/non_order_item';
import { validationErrorsInputCustomer } from '@this/components/reserve_trip/reserve_confirm/routings/input_customer_information/input_customer_information';
import TripsFormBasicinfoTemplate from './trips_form_block/trips_form_basicinfo.template';
import { Wrap, BodyWrap, Body, LoadingWrap } from '../trips/trips';

interface Props extends RouteComponentProps<Record<string, string | undefined>, Record<string, any>, any> {
  serviceId: number;
  availableRepos: AvailableRepository[];
  theme: { themeClass: string };
}

interface TripsState {
  serviceId: number;
  trip: any;
  user: any;
  members: any;
  travelers: any;
  title: string;
  loading: boolean;
  shouldBlockNavigation: boolean;
  validationErrors: any;
  errorsInputCustomer: { [key: string]: string | undefined };
  outdate: Moment;
  homedate: Moment;
  price: number;
  boardingStation: string;
  arrivalStation: string;
  tripType: string;
  query: SearchQuery | undefined;
  store: SelectStore | undefined;
  repository: SelectRepository | undefined;
  isConfirmingAddTraveler: boolean;
  removingTravelerNumber: number | null;
  error: string | null;
  reserveInfo: ReserveInfo | null;
  queries: { peoplenum: number; roomnum: number; stay_days: number; search_type: SearchType } | null;
  searchQueryId: number | null;
  organizationBases: OrganizationBase[] | undefined;
  departments: Department[] | undefined;
  useBulkTicket: boolean | undefined;
  useKyuusyuuTicket: boolean | undefined;
  showEx: boolean | undefined;
  // loading: boolean;
  paymentLoading: boolean;
  applyType: string;
  termsAgree: boolean;
  projectShareAvailability: boolean;
  prefecture: Prefecture[];
  tripDuplications: TripDuplicationInterface[];
}

interface TripsResponse {
  trip: any;
  user: UserJson;
  members: any[];
  query: any;
  items: {
    element_id: string;
    element_raw: never;
    element_type: 'transport' | 'hotel' | 'flight';
    foreign_exist: string; // "false" で返ってくる;
    changeable_air: string; // "true" で返ってくる;
    index: string; // "0" で返ってくる
    domestic_air_price_index: number;
  }[];
  approvers: never;
  type: never;
  package_type: never;
  package_provider?: PackageProvider;
  package_search_condition: never;
  foreign_exist: never;
  domestic_exist: never;
  shinkansen_address: never;
  shinkansen_addressee: never;
  shinkansen_address_type: never;
  shinkansen_fee?: never;
  domestic_air_fee: never;
  foreign_air_fee: never;
  use_bulk_ticket: boolean;
  use_kyuusyuu_ticket: boolean;
  show_ex: boolean;
  organization_bases: never[];
  departments: never[];
  charging_department_shares: never;
  postcode: never;
  cards: CardJson[];
  car_type: [string, string][];
  rentalcar_available: boolean;
  rental_cars: RentalCarJson[];
  rental_car_limit_type: RentalCarLimitTypeJson;
  is_smoke: [string, string][];
  projects: never;
  project_shares: never;
  order_item_mapping_args: OrderItemMappingArgs[];
  outword: never;
  homeword: never;
  hotel?: { bed_types: { id: number; description: string }[] };
  flight: never;
  margin_types: never;
  show_fee: never;
  draft_trip: { purpose: never } | null;
  all_travelers_notification: never;
  notified_users: UserJson[];
  travelers: any;
  traveler_candidates: UserJson[];
  consignment_sale: ConsignmentSale;
  login_user_id: number;
  internal_number: string;
  nationalities: { id: number; name: string }[];
  expenses_account_type_available: boolean;
  workflow_style: WorkflowStyle;
  use_smart_search: boolean;
  trip_duplications: TripDuplicationInterface[];
  trip_approve_item: { json: string };
}

interface TravelerErrors {
  memberSelect?: string | null;
  travelerFirstNameRomen?: string | null;
  travelerLastNameRomen?: string | null;
  travelerFirstNameKana?: string | null;
  travelerLastNameKana?: string | null;
  travelerBirthday?: string | null;
  travelerGender?: string | null;
}

interface TripFormErrors {
  travelers: TravelerErrors[];
  price: string | null;
  errors: string[];
}

interface EkispertCourse {
  price: number;
  route: string;
}

interface EkispertPriceResponse {
  courses: EkispertCourse[];
}

class TripsSimpleRequestForm extends React.Component<Props, TripsState> {
  constructor(props: Props) {
    super(props);
    const lState = props.location.state;

    this.state = {
      serviceId: props.serviceId,
      trip: null,
      user: null,
      members: [],
      travelers: [],
      title: utils.dig(lState, 'title') || '',
      loading: true,
      shouldBlockNavigation: true,
      validationErrors: {},
      errorsInputCustomer: {},
      outdate: lState && lState.outdate ? moment(lState.outdate) : moment().add(1, 'days'),
      homedate: lState && lState.homedate ? moment(lState.homedate) : moment().add(2, 'days'),
      price: utils.dig(lState, 'price'),
      boardingStation: '',
      arrivalStation: '',
      tripType: 'round_trip',
      query: undefined,
      store: undefined,
      repository: undefined,
      isConfirmingAddTraveler: false,
      removingTravelerNumber: null,
      error: null,
      reserveInfo: null,
      queries: null,
      searchQueryId: null,
      organizationBases: undefined,
      departments: undefined,
      useBulkTicket: undefined,
      useKyuusyuuTicket: undefined,
      showEx: undefined,
      paymentLoading: false,
      applyType: 'simple_request',
      termsAgree: true,
      projectShareAvailability: false,
      prefecture: [],
      tripDuplications: []
    };
  }

  componentDidMount() {
    const lState = this.props.location.state;
    utils
      .jsonPromise<TripsResponse>(`${location.href}.json`, lState || {})
      .then(
        async result => {
          const trip = new Trip(result.trip);
          const user = new User(result.user);
          const members = _.map(result.members, m => new User(m));
          let travelers: any;
          if (trip) {
            travelers = TravelerList.fromTravelerInformations({
              informations: trip.travelerInformations,
              user,
              members,
              peoplenum: trip.travelerInformations.list.length
            });
          } else {
            travelers = TravelerList.fromCount(1);
            travelers.setTravelerAtIndex(0, new Traveler(result.user));
          }
          const query = result.query
            ? new SearchQuery(
                _.merge(result.query, {
                  cabin: user.defaultSeatClasses.join(','),
                  defaultSeatClasses: user.defaultSeatClasses,
                  travelers,
                  time: {
                    startTime: result.query.departure_time || trip.startTime,
                    endTime: result.query.return_time || trip.endTime
                  },
                  availableRepos: this.props.availableRepos
                })
              )
            : new SearchQuery({
                cabin: user.defaultSeatClasses.join(','),
                defaultSeatClasses: user.defaultSeatClasses,
                peoplenum: travelers.list.length,
                travelers,
                time: {
                  startTime: trip.startTime,
                  endTime: trip.endTime
                },
                availableRepos: this.props.availableRepos
              });
          const outdate =
            (lState && lState.outdate) || !trip.departure_time ? this.state.outdate : moment(trip.departure_time);
          const homedate =
            (lState && lState.homedate) || !trip.return_time ? this.state.homedate : moment(trip.return_time);

          this.setState({
            trip,
            user,
            members,
            travelers,
            title: trip !== null && trip.id !== null ? trip.title : this.state.title,
            outdate,
            homedate,
            price: this.state.price,
            query
          });

          const approvers = _.sortBy(result.approvers, 'approve_stage');
          if (!_.isUndefined(result.user.department)) {
            result.user.department.approvers = approvers;
          }
          const marginTypes = new MarginTypeList(result.margin_types);
          const rentalCarLimitType = new RentalCarLimitType(result.rental_car_limit_type);
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const reserveInfo = new ReserveInfo({
            reservingTrip: new ReservingTrip({
              packageType: result.type,
              peopleNum: travelers.list.length,
              rentalCars: result.rental_cars,
              showFee: result.show_fee,
              marginTypes,
              rentalCarLimitType,
              startTime: trip.startTime,
              endTime: trip.endTime,
              items: _.map(
                result.items,
                item => new ReservingTripItem(_.merge(item, { showFee: result.show_fee, marginTypes }))
              )
            }),
            roomNum: 0,
            type: result.type,
            travelers,
            user,
            foreignExist: result.foreign_exist,
            shinkansenAddress: result.shinkansen_address,
            shinkansenAddressee: result.shinkansen_addressee,
            shinkansenAddressType: result.shinkansen_address_type,
            postcode: result.postcode,
            carType: result.car_type,
            rentalCarAvailable: result.rentalcar_available,
            rentalCars: result.rental_cars,
            paymentMethodOptions: result.user.organization.payment_method_type,
            paymentMethodType: result.user.organization.payment_method_type !== 'card' ? 'billing' : 'card',
            paymentGatewayMembers: [],
            paymentGatewayType: result.user.organization.payment_gateway_type,
            paymentTransaction: { uid: 'new' },
            finalDestination: result.trip.final_destination,
            purpose: result.trip.purpose,
            allTravelersNotification: result.all_travelers_notification,
            notifiedUsers: result.notified_users.map(user => new Traveler(user)),
            loginUserId: result.login_user_id,
            rentalCarLimitType,
            internalNumber: result.internal_number,
            nationalities: result.nationalities,
            expensesAccountTypeAvailable: result.expenses_account_type_available,
            workflowStyle: result.workflow_style,
            approveItemValues: result.trip.trip_approve_item
              ? new Map<number, string>(
                  JSON.parse(result.trip.trip_approve_item.json).map((item: any) => [item.id, item.value])
                )
              : undefined,
            approveItemValueCodes: result.trip.trip_approve_item
              ? new Map<number, string>(
                  JSON.parse(result.trip.trip_approve_item.json).map((item: any) => [item.id, item.valueCode])
                )
              : undefined
          });

          // 申請項目を取得
          await reserveInfo.fetchApproveItems('simple_request_trip');
          this.setState({
            reserveInfo,
            trip,
            user,
            travelers,
            departments: result.departments.map(d => new Department(d)),
            organizationBases: result.organization_bases.map(o => new OrganizationBase(o)),
            useBulkTicket: result.use_bulk_ticket,
            useKyuusyuuTicket: result.use_kyuusyuu_ticket,
            showEx: result.show_ex,
            loading: false,
            tripDuplications: result.trip_duplications
          });
        },
        _error => {
          this.setState({
            error: '通信環境が不安定です。\n時間をおいてもう一度お試しください。'
          });
        }
      )
      .catch(e => {
        utils.sendErrorObject(e);
      });
  }

  componentDidUpdate() {
    // 未保存離脱の警告をPromptで実装しているが、再読み込みとタブの終了に対応していないため
    if (this.state.shouldBlockNavigation) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = null;
    }
  }

  handleChangeTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ title: e.target.value });
  };

  handleChangePrice = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ price: parseInt(e.target.value, 10) });
  };

  handleTravelerTypeChange = (i: number, e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === 'self') {
      this.state.travelers.setTravelerAtIndex(i, new Traveler(this.state.user));
    } else {
      this.state.travelers.setTravelerTypeAtIndex(i, e.target.value);
    }
    this.setTripTravelersToQuery();
  };

  handleTravelerSelect = (i: number, traveler: any) => {
    this.state.travelers.setTravelerAtIndex(i, traveler);
    this.setTripTravelersToQuery();
  };

  handleTravelerInfoChange = (i: number, method: string, e: React.ChangeEvent<HTMLInputElement>) => {
    this.state.travelers.list[i][method](e.target.value);
    this.setTripTravelersToQuery();
  };

  handleChangeRoute = (name: string, value: string, _address: string) => {
    if (name === 'boardingStation') {
      this.setState({ boardingStation: value }, this.handleFetchPrice);
    } else {
      this.setState({ arrivalStation: value }, this.handleFetchPrice);
    }
  };

  handleChangeTripType = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ tripType: e.target.value }, this.handleFetchPrice);
  };

  handleFetchPrice = async () => {
    if (this.state.boardingStation && this.state.arrivalStation && this.state.outdate) {
      const params = {
        from: this.state.boardingStation,
        to: this.state.arrivalStation,
        date: this.state.outdate.format('YYYY/MM/DD'),
        search_for_jr_ex: true,
        trip_type: this.state.tripType
      };

      const q = utils.toParams(params);
      this.setState({ price: 0 });
      const res = await utils.jsonPromise<EkispertPriceResponse>(`/biztra/ekispert/price?${q}`);
      const course = res.courses[0];
      if (course) {
        this.setState({ price: course.price });
      }
    }
  };

  setTripTravelersToQuery = () => {
    if (!this.state.query) {
      return;
    }

    const query = this.state.query;
    query.setTravelers(this.state.travelers);
    this.setState({ query });
  };

  handleAddTraveler = (e?: React.MouseEvent<HTMLElement>) => {
    if (e) {
      e.preventDefault();
    }

    if (utils.dig(this.state.store, 'result', 'items') && !this.state.isConfirmingAddTraveler) {
      this.setState({
        isConfirmingAddTraveler: true
      });
    } else {
      this.state.travelers.addTraveler(this.state.user);
      const query = this.state.query;
      if (query) {
        query.setPeoplenum(query.peoplenum + 1);
      }
      this.setState({
        query,
        isConfirmingAddTraveler: false
      });
    }
  };

  handleRemoveTraveler = (i?: number, e?: React.MouseEvent<HTMLElement>) => {
    if (e) {
      e.preventDefault();
    }

    if (utils.dig(this.state.store, 'result', 'items') && i && !this.state.removingTravelerNumber) {
      this.setState({
        removingTravelerNumber: i
      });
    } else {
      this.state.travelers.removeTravelerAtIndex(i || this.state.removingTravelerNumber);
      const query = this.state.query;
      if (query) {
        query.setPeoplenum(query.peoplenum + -1);
      }
      this.setState({
        query,
        removingTravelerNumber: null
      });
    }
  };

  handleChangeDate = (kind: 'outdate' | 'homedate', date: Moment) => {
    let outMoment = this.state.outdate;
    let homeMoment = this.state.homedate;

    switch (kind) {
      case 'outdate': {
        outMoment = date;
        if (date > homeMoment) {
          homeMoment = moment(outMoment);
        }
        break;
      }
      case 'homedate': {
        homeMoment = date;
        if (date < outMoment) {
          outMoment = moment(homeMoment);
        }
        break;
      }
      default: {
        break;
      }
    }
    const query = this.state.query;
    if (!this.state.repository && query) {
      query.resetTime(outMoment, homeMoment);
    }

    this.setState(
      {
        query,
        outdate: outMoment,
        homedate: homeMoment
      },
      this.handleFetchPrice
    );
  };

  handleSubmit = async (e: React.MouseEvent<HTMLElement>) => {
    e.persist();
    this.setState({
      validationErrors: {},
      errorsInputCustomer: {}
    });

    // TripsFormBasicinfoTemplate の入力チェック
    const validationErrors = this.validationErrors();
    // ReserveConfirmRoutingsTemplate の入力チェック
    const { isValid, errors } = validationErrorsInputCustomer(this.state.reserveInfo, this.state.serviceId, true);

    if (_.isEmpty(validationErrors) && isValid) {
      this.submit();
    } else {
      e.preventDefault();
      this.setState({ validationErrors });
      this.setState({ errorsInputCustomer: errors });
    }
  };

  getApplicant() {
    let applicant = _.first(this.state.travelers);
    if (_.isNil(applicant) || _.isNil(utils.dig(applicant, 'id'))) {
      applicant = new Traveler(this.state.user);
    }
    return applicant;
  }

  addNonOrderItemToTrip = () => {
    const elementType = 'shinkansen';
    if (this.state.trip) {
      const item = new NonOrderItem({
        elementType,
        peopleNum: this.state.travelers.list.length,
        tripType: this.state.tripType,
        supplier: ''
      });
      const startTime = this.state.outdate.startOf('day').format('YYYY-MM-DD HH:mm');
      const endTime = this.state.homedate.startOf('day').format('YYYY-MM-DD HH:mm');
      item.handleAddElementByType(elementType, startTime, endTime);
      item.price.price = this.state.price;
      item.transports[0].handleTransportTypeChange(elementType);
      item.transports[0].from.time = this.state.outdate.set({ hour: 0, minute: 0, second: 0 });
      item.transports[0].to.time = this.state.homedate.set({ hour: 0, minute: 0, second: 0 });
      item.transports[0].boardingStation = this.state.boardingStation;
      item.transports[0].arrivalStation = this.state.arrivalStation;
      const trip = this.state.trip;
      trip.nonOrderItems = [item];

      this.setState({ trip });
    }
  };

  submit() {
    this.addNonOrderItemToTrip();
    this.setState({ loading: true });
    const params = {
      as_draft: false,
      apply_type: this.state.applyType,
      payment_type: utils.dig(this.state.reserveInfo, 'paymentType') || 'billing',
      reserve_info: this.state.reserveInfo ? this.state.reserveInfo.submitParams() : null,
      user_id: this.state.trip.user_id,
      travelers: this.state.travelers.getTravelersParam(),
      title: this.state.title,
      departure_time: this.state.outdate.format('YYYY-MM-DD HH:mm'),
      return_time: this.state.homedate.format('YYYY-MM-DD HH:mm'),
      applicant_id: utils.dig(this.getApplicant(), 'id'),
      search_query_id: this.state.searchQueryId,
      non_order_items: _.compact(_.map(this.state.trip.nonOrderItems, item => item.updateParams()))
    };

    let path = '/trips';
    let type: 'post' | 'put' = 'post';
    if (this.state.trip.id) {
      path += `/${this.state.trip.id}`;
      type = 'put';
    }

    utils
      .jsonPromise(`${path}.json`, params, type)
      .then(
        _result => {
          this.setState(
            {
              shouldBlockNavigation: false
            },
            () => {
              this.props.history.push('/trips');
            }
          );
        },
        _error => {
          this.setState({
            error: '通信環境が不安定です。\n時間をおいてもう一度お試しください。'
          });
        }
      )
      .catch(e => {
        utils.sendErrorObject(e);
      });
  }

  // TripsFormBasicinfoTemplateの入力チェック
  validationErrors() {
    const errors: TripFormErrors = {
      travelers: (this.state.travelers.list || []).map((t: any) => {
        const tError: TravelerErrors = {};
        if (utils.dig(this.state.user, 'organization')) {
          switch (t.type) {
            case 'self':
            case 'member': {
              if (!t.id) {
                tError.memberSelect = '出張者を選択してください';
              }
              break;
            }
            case 'companion': {
              tError.travelerFirstNameRomen = TripsSimpleRequestForm.alphabetError(
                '出張者の名前(ローマ字)',
                t.firstNameRoman
              );
              tError.travelerLastNameRomen = TripsSimpleRequestForm.alphabetError(
                '出張者の名字(ローマ字)',
                t.lastNameRoman
              );
              tError.travelerFirstNameKana = TripsSimpleRequestForm.katakanaError(
                '出張者の名前(カナ)',
                t.firstNameKana
              );
              tError.travelerLastNameKana = TripsSimpleRequestForm.katakanaError(
                '出張者の名字(カナ)',
                t.lastNameKana
              );
              tError.travelerBirthday = TripsSimpleRequestForm.dateError('出張者の誕生日', t.flightBirthday);
              tError.travelerGender = TripsSimpleRequestForm.requiredError('出張者の性別', t.flightGender);
              break;
            }
            default: {
              break;
            }
          }
        }
        return tError;
      }),
      price: TripsSimpleRequestForm.numberError(
        '金額',
        this.state.price === null ? null : String(this.state.price)
      ),
      errors: []
    };

    errors.travelers = _.map(errors.travelers, te => utils.compactObject(te));
    errors.travelers = utils.compactObject(errors.travelers);
    errors.price = TripsSimpleRequestForm.numberError(
      '金額',
      this.state.price === null ? null : String(this.state.price)
    );

    return utils.compactObject(errors);
  }

  static requiredError(name: string, value: string | null, message = 'を入力してください') {
    if (!value || value === null || value.length === 0) {
      return name + message;
    }
    return null;
  }

  static katakanaError(name: string, value: string) {
    const error = TripsSimpleRequestForm.requiredError(name, value);
    if (error) {
      return error;
    }
    if (!value.match(/^[ァ-ヶー　]*$/)) {
      return `${name}はカタカナで入力してください`;
    }
    return null;
  }

  static alphabetError(name: string, value: string) {
    const error = TripsSimpleRequestForm.requiredError(name, value);
    if (error) {
      return error;
    }
    if (!value.match(/^[a-zA-Z]+$/)) {
      return `${name}はアルファベッドで入力してください`;
    }
    return null;
  }

  static dateError(name: string, value: string) {
    const error = TripsSimpleRequestForm.requiredError(name, value);
    if (error) {
      return error;
    }
    if (!moment(value).isValid()) {
      return `${name}を正しく入力してください（例：1986-01-01）`;
    }
    return null;
  }

  static numberError(name: string, value: string | null) {
    const error = TripsSimpleRequestForm.requiredError(name, value);
    if (error) return error;
    if (value && !/^\d+$/.test(value)) {
      return `${name}は数字で入力してください`;
    }
    return null;
  }

  isCustomerInfoRequired = () => !!this.state.user && !!this.state.user.organization;

  handleBackToStep1Click = (e?: React.MouseEvent) => {
    if (e) {
      e.preventDefault();
    }
    this.setState(
      {
        shouldBlockNavigation: false
      },
      () => {
        this.props.history.push(`${location.pathname}#`);
        this.setState({ shouldBlockNavigation: true });
      }
    );
  };

  handleValidationSuccess = () => {
    this.setState(
      {
        shouldBlockNavigation: false
      },
      () => {
        this.props.history.push(`${location.pathname}#step2`);
        this.setState({ shouldBlockNavigation: true });
      }
    );
    return utils.scrollToTop();
  };

  handleConfirm = () => {};

  handleAgreementTermsCheck = () => {
    this.setState({
      termsAgree: !this.state.termsAgree
    });
  };

  clickBackLink = () => {
    this.setState(
      {
        shouldBlockNavigation: false
      },
      () => {
        this.props.history.push({
          pathname: this.state.trip.id ? `/trips/${this.state.trip.id}/edit` : '/trips/simple_request',
          state: this.backState()
        });
      }
    );
  };

  backState() {
    return {
      title: this.state.trip.title,
      travelers: this.state.travelers.getTravelersParam(),
      outdate: this.state.trip.startTime.format('YYYY-MM-DD HH:mm'),
      homedate: this.state.trip.endTime.format('YYYY-MM-DD HH:mm'),
      purpose: utils.dig(this.state.reserveInfo, 'purpose'),
      final_destination: utils.dig(this.state.reserveInfo, 'finalDestination'),
      non_order_items: _.compact(_.map(this.state.trip.nonOrderItems, item => item.updateParams()))
    };
  }

  render() {
    const {
      serviceId,
      trip,
      user,
      members,
      travelers,
      title,
      loading,
      shouldBlockNavigation,
      validationErrors,
      errorsInputCustomer,
      outdate,
      homedate,
      price,
      boardingStation,
      arrivalStation,
      tripType,
      error,
      reserveInfo,
      queries,
      organizationBases,
      departments,
      useBulkTicket,
      useKyuusyuuTicket,
      showEx,
      paymentLoading,
      applyType,
      termsAgree,
      projectShareAvailability,
      prefecture,
      tripDuplications
    } = this.state;
    const paymentFailed = false;
    const paymentErrors = '';

    try {
      return (
        <Wrap>
          <Prompt
            when={shouldBlockNavigation}
            message="保存されていないデータが失われます。本当によろしいですか？"
          />
          <FormBodyWrap>
            <div>
              <FormBody>
                <ContentHeader
                  title={`旅程情報${location.pathname === '/trips/simple_request' ? '登録' : '編集'}`}
                  backButton
                  backLink="/trips"
                />
                <TripForm>
                  <TripFormLeft>
                    {loading ? (
                      <LoadingWrap />
                    ) : error ? (
                      <LoadingWrap>
                        <Error>{error}</Error>
                      </LoadingWrap>
                    ) : (
                      <TripsFormBasicinfoTemplate
                        editable={!!(trip === null || trip.id === null)}
                        travelers={travelers}
                        user={user}
                        members={members}
                        outdate={outdate}
                        homedate={homedate}
                        title={title}
                        price={price}
                        boardingStation={boardingStation}
                        arrivalStation={arrivalStation}
                        tripType={tripType}
                        validationErrors={validationErrors}
                        handleChangeTitle={this.handleChangeTitle}
                        handleChangePrice={this.handleChangePrice}
                        handleRemoveTraveler={this.handleRemoveTraveler}
                        handleTravelerTypeChange={this.handleTravelerTypeChange}
                        handleTravelerSelect={this.handleTravelerSelect}
                        handleTravelerInfoChange={this.handleTravelerInfoChange}
                        handleAddTraveler={this.handleAddTraveler}
                        handleChangeDate={this.handleChangeDate}
                        handleChangeRoute={this.handleChangeRoute}
                        handleChangeTripType={this.handleChangeTripType}
                        isSimpleRequest={applyType === 'simple_request'}
                      />
                    )}
                  </TripFormLeft>
                  <TripFormRight>
                    <Section>
                      <SectionIn>
                        {(trip === null || trip.tripDetailEditable()) && (
                          <SubmitButton onClick={e => this.handleSubmit(e)} disabled={loading}>
                            申請する
                          </SubmitButton>
                        )}
                        {!_.isEmpty(validationErrors.errors) && (
                          <Error>
                            <Text text={validationErrors.errors.join('\n')} />
                          </Error>
                        )}
                      </SectionIn>
                    </Section>
                  </TripFormRight>
                </TripForm>
                {loading ? (
                  <LoadingWrap>
                    <SimpleLoading />
                  </LoadingWrap>
                ) : (
                  <div>
                    {reserveInfo && shouldBlockNavigation && (
                      // 上のshouldBlockNavigationはステップを行き来するときに一時非表示にすることで中身の更新を促すため
                      <ReserveConfirmRoutingsTemplate
                        serviceId={serviceId}
                        isCustomerInfoRequired={this.isCustomerInfoRequired}
                        reserveInfo={reserveInfo}
                        termsAgree={termsAgree}
                        organizationBases={organizationBases}
                        departments={departments}
                        useBulkTicket={useBulkTicket}
                        useKyuusyuuTicket={useKyuusyuuTicket}
                        showEx={showEx}
                        handleValidationSuccess={this.handleValidationSuccess}
                        paymentLoading={paymentLoading}
                        paymentFailed={paymentFailed}
                        paymentErrors={paymentErrors}
                        errorsInputCustomer={errorsInputCustomer}
                        isSimpleRequest={applyType === 'simple_request'}
                        handleBackToSelectClick={this.clickBackLink}
                        handleBackToStep1Click={this.handleBackToStep1Click}
                        handlePaymentSubmit={this.handleConfirm}
                        handleAgreementTermsCheck={this.handleAgreementTermsCheck}
                        projectShareAvailability={projectShareAvailability}
                        prefecture={prefecture}
                        isOnTripsConfirm
                        tripRuleErrors={[]}
                        tripDuplications={tripDuplications}
                        tripDistanceAndTimeInfo={[]}
                        queries={queries!}
                        fromTripForm
                      />
                    )}
                  </div>
                )}
              </FormBody>
            </div>
            <FooterActionGroup>
              <FooterButtonArea>
                {(trip === null || trip.tripDetailEditable()) && (
                  <SubmitButton onClick={e => this.handleSubmit(e)} disabled={loading}>
                    申請する
                  </SubmitButton>
                )}
              </FooterButtonArea>
            </FooterActionGroup>
          </FormBodyWrap>
        </Wrap>
      );
    } catch (e) {
      utils.sendErrorObject(e);
      return null;
    }
  }
}

const FormBodyWrap = styled(BodyWrap)`
  padding: 0;

  @media screen and (min-width: 1030px) {
    padding: 20px 0;
  }
`;

const FormBody = styled(Body)`
  padding: 20px;
`;

const TripForm = styled.div`
  padding: 10px;
  margin-bottom: 40px;
  border: solid 1px ${props => props.theme.grayBorderColor};
  @media screen and (min-width: 815px) {
    display: flex;
  }
`;

const TripFormLeft = styled.div`
  @media screen and (min-width: 815px) {
    width: 80%;
  }
`;

const TripFormRight = styled.div`
  margin: 0 0 0 auto;

  @media screen and (max-width: 814px) {
    display: none;
  }
`;

const Section = styled.div`
  padding-bottom: 20px;
`;

const SectionIn = styled.div`
  margin-left: 10px;
  font-size: 13px;
`;

const Error = styled.div`
  color: ${props => props.theme.redColor};
`;

const SubmitButton = styled.button`
  ${ButtonBase};
  font-size: 13px;
  width: 200px;
`;

const FooterActionGroup = styled.div`
  background: #fff;
  position: sticky;
  bottom: 0;
  box-shadow: 0 -3px 3px 3px rgba(0, 0, 0, 0.1);
`;

const FooterButtonArea = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 20px;
`;

export default withTheme(TripsSimpleRequestForm);
