import moment from 'moment';
import React from 'react';
import _ from 'lodash';
import { styled } from '@this/constants/themes';

import Trip from '@this/domain/trip/trip';
import type Order from '@this/domain/order';
import type OrderItem from '@this/domain/order_item';
import Arranger from '@this/domain/arranger/arranger';
import type SuppliedItem from '@this/domain/supplied_item/supplied_item';
import SimpleLoading from '@this/shared/simple_loading/simple_loading';
import JournalTable from './journal_table/journal_table';
import TraceIdEditor from './trace_id_editor/trace_id_editor';
import TraceIdMemo from './trace_id_memo/trace_id_memo';
import OrderItemCell from './order_item_cell/order_item_cell';

interface Props {
  trip: Trip;
  suppliedItems: SuppliedItem[];
}
interface State {
  orderCheckSaving: boolean;
  journalCreateSaving: boolean;
  historyDetailLoading: boolean;
  tripDetail: Trip | null;
}

interface TripJson {
  id: number;
}

interface OrderHistoriesResponse {
  trip: TripJson;
}

interface OrderCheckResponse {
  checked_at: string;
  checker: { name: string };
}

interface JournalCreateResponse {
  journal_created_at: string;
  journal_creator: { name: string };
}

class TripBlock extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      orderCheckSaving: false,
      journalCreateSaving: false,
      historyDetailLoading: false,
      tripDetail: null
    };
  }

  getTraceIds(): number[] {
    if (!this.state.tripDetail) {
      return [];
    }
    return _.uniq(_.flatten(this.state.tripDetail.orders.map(o => o.orderItems.map(i => i.traceId || 0))));
  }

  getData(): (OrderItem | null)[][] {
    const data: (OrderItem | null)[][] = [[]];
    const traceIds = this.getTraceIds();
    const trip = this.state.tripDetail;
    if (!trip) {
      return [];
    }
    for (let i = 0; i < trip.orders.length; i += 1) {
      const o = trip.orders[i];
      if (!data[i]) {
        data[i] = [];
        for (let j = 0; j < traceIds.length; j += 1) {
          const traceId = traceIds[j];
          data[i][traceId] = null;
        }
      }
      for (let j = 0; j < o.orderItems.length; j += 1) {
        const item = o.orderItems[j];
        data[i][item.traceId || 0] = item;
      }
    }
    return data;
  }

  showCheckBox(items: (OrderItem | null)[]): boolean {
    return items.every(item => item === null || item.billedAt !== null);
  }

  async toggleOrderCheck(order: Order): Promise<void> {
    this.setState({ orderCheckSaving: true });
    return utils
      .jsonPromise<OrderCheckResponse>(`/arrangement/order_check/${order.id}`, {}, 'PUT')
      .then(response => {
        if (response.checked_at === null) {
          order.checker = null;
          order.checkedAt = null;
        } else {
          order.checker = new Arranger({
            first_name: '',
            last_name: '',
            email: '',
            ...response.checker
          });
          order.checkedAt = moment(response.checked_at);
        }
        this.setState({
          orderCheckSaving: false
        });
      });
  }

  async toggleJournalCreate(order: Order): Promise<void> {
    this.setState({ journalCreateSaving: true });
    return utils
      .jsonPromise<JournalCreateResponse>(`/arrangement/journal_create/${order.id}`, {}, 'PUT')
      .then(response => {
        if (response.journal_created_at === null) {
          order.journalCreator = null;
          order.journalCreatedAt = null;
        } else {
          order.journalCreator = new Arranger({
            first_name: '',
            last_name: '',
            email: '',
            ...response.journal_creator
          });
          order.journalCreatedAt = moment(response.journal_created_at);
        }
        this.setState({
          journalCreateSaving: false
        });
      });
  }

  async loadHistoryDetail(trip_id: number): Promise<void> {
    this.setState({ historyDetailLoading: true });
    return utils
      .jsonPromise<OrderHistoriesResponse>(`/arrangement/order_histories/${trip_id}.json`)
      .then(response =>
        this.setState({
          tripDetail: new Trip(response.trip),
          historyDetailLoading: false
        })
      );
  }

  render() {
    const trip = this.props.trip;
    const tripDetail = this.state.tripDetail;
    const user = trip.user;
    const organization = user.organization;
    const traceIds = this.getTraceIds();
    const data = this.getData();
    const classBase = 'order-histories__trip-block';

    return (
      <div className={classBase}>
        <FixedHeader>
          <div>
            <span className={`${classBase}__title-label`}>
              <span className={`${classBase}__title-label__name`}>trip_id</span>
              <span className={`${classBase}__title-label__value`}>{trip.id}</span>
            </span>
            <span className={`${classBase}__title-label`}>
              <span className={`${classBase}__title-label__name`}>受付日</span>
              <span className={`${classBase}__title-label__value`}>
                {trip.receivedAt ? trip.receivedAt.format('YYYY/MM/DD') : null}
              </span>
            </span>
            <span className={`${classBase}__title-label`}>
              <span className={`${classBase}__title-label__name`}>法人</span>
              <span className={`${classBase}__title-label__value`}>{organization ? organization.name : null}</span>
            </span>
            <span className={`${classBase}__title-label`}>
              <span className={`${classBase}__title-label__name`}>手配者</span>
              <span className={`${classBase}__title-label__value`}>{user.name}</span>
            </span>
            <span>
              <a
                href={`/arrangement/virtual_counter?trip_id=${trip.id}`}
                rel="noopener noreferrer"
                target="_blank"
              >
                チャット
              </a>
            </span>
          </div>
          <div className={`${classBase}__travelers-area`}>
            <span className={`${classBase}__title-label__name`}>出張者</span>
            {trip.travelerInformations.list.map(ti => (
              <span key={ti.id} className={`${classBase}__traveler-name`}>
                {ti.name}
              </span>
            ))}
            <span>
              {tripDetail ? (
                <a onClick={() => this.setState({ tripDetail: null })}>詳細を閉じる</a>
              ) : (
                <a onClick={() => this.loadHistoryDetail(trip.id)}>詳細を開く</a>
              )}
            </span>
          </div>
        </FixedHeader>
        {this.state.historyDetailLoading ? (
          <SimpleLoading />
        ) : tripDetail ? (
          <table className={`${classBase}__table`}>
            <FixedTableHeader>
              <tr>
                <th>Order</th>
                {traceIds.map(id => (
                  <th key={id}>
                    <div>trace_id: {id}</div>
                    <TraceIdMemo traceId={id} />
                  </th>
                ))}
              </tr>
            </FixedTableHeader>
            <tbody>
              {data.map((items, i: number) => {
                const order = tripDetail.orders[i];
                return (
                  <tr key={i}>
                    <td className={`${classBase}__order-td`}>
                      <div>order_id: {order.id}</div>
                      <div>作成者: {order.arranger ? order.arranger.name : null}</div>
                      <div>作成日: {order.createdAt.format('YYYY/MM/DD HH:mm')}</div>
                      <div>
                        請求確定日: {order.confirmedAt ? order.confirmedAt.format('YYYY/MM/DD HH:mm') : null}
                      </div>
                      <div>カード取引ID: {order.gmoId}</div>
                      <JournalTable order={order} />
                      {this.showCheckBox(items) && (
                        <>
                          <div className={`${classBase}__order-check-area`}>
                            <label className={`${classBase}__order-check`}>
                              {this.state.orderCheckSaving ? (
                                <SimpleLoading />
                              ) : (
                                <input
                                  type="checkbox"
                                  checked={order.checkedAt !== null}
                                  onChange={() =>
                                    this.state.orderCheckSaving ? null : this.toggleOrderCheck(order)
                                  }
                                />
                              )}
                              右ナビチェック
                            </label>
                            {order.checkedAt && (
                              <div>
                                {order.checkedAt.format('YYYY/MM/DD HH:mm ')}
                                {order.checker && order.checker.name}
                              </div>
                            )}
                          </div>
                          <div>
                            <label className={`${classBase}__order-check`}>
                              {this.state.journalCreateSaving ? (
                                <SimpleLoading />
                              ) : (
                                <input
                                  type="checkbox"
                                  checked={order.journalCreatedAt !== null}
                                  onChange={() =>
                                    this.state.journalCreateSaving ? null : this.toggleJournalCreate(order)
                                  }
                                />
                              )}
                              仕訳入力
                            </label>
                            {order.journalCreatedAt && (
                              <div>
                                {order.journalCreatedAt.format('YYYY/MM/DD HH:mm ')}
                                {order.journalCreator && order.journalCreator.name}
                              </div>
                            )}
                          </div>
                        </>
                      )}
                    </td>
                    {traceIds.map(traceId => {
                      const item = items[traceId];
                      return (
                        <td key={traceId}>
                          {item == null ? null : (
                            <>
                              <TraceIdEditor orderItem={item} />
                              <OrderItemCell
                                item={item}
                                suppliedItems={this.props.suppliedItems}
                                traceItems={order.traceItemDescriptions()}
                              />
                            </>
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : null}
      </div>
    );
  }
}

const FixedHeader = styled.div`
  position: sticky;
  padding: 10px;
  top: 180px;
  background: #fff;
`;

const FixedTableHeader = styled.thead`
  position: sticky;
  top: 240px;
  background: #fff;
  z-index: 1;
`;

export default TripBlock;
