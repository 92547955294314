import React from 'react';
import _ from 'lodash';
import { observer } from 'mobx-react';
import { styled } from '@this/constants/themes';
import SimpleLoading from '@this/shared/simple_loading/simple_loading';
import type FreeeAccountItem from '@this/domain/organization/freee_account_item';
import type ExpensesAccountType from '@this/domain/expenses/expenses_account_type';
import FilterableSelector from '@this/shared/filterable_selector/filterable_selector';

type Props = {
  type: ExpensesAccountType;
  submitting: boolean;
  isFreeeConnected: boolean;
  freeeAccountItems: FreeeAccountItem[];
  onSubmit: () => void;
  onClickCancel: () => void;
  errors: string[];
};

const Form: React.FC<Props> = ({
  type,
  submitting,
  isFreeeConnected,
  freeeAccountItems,
  onSubmit,
  onClickCancel,
  errors
}) => {
  const freeeAccountItemOption = (): any[] => {
    return freeeAccountItems.map(f => {
      return {
        label: f.name,
        value: f.id.toString(),
        matcher: f.name
      };
    });
  };

  const findFreeeAccountItemOptionByValue = (value: string) => {
    const target = _.find(freeeAccountItems, item => {
      return item.id.toString() === value;
    });

    if (target) {
      return {
        label: target.name,
        value: target.id.toString(),
        matcher: target.name
      };
    }
    return {
      label: '',
      value: '',
      matcher: ''
    };
  };

  return (
    <>
      <form onSubmit={() => onSubmit()}>
        <div className="type-edit">
          <div className="type-edit__section">
            <div className="type-edit__label">勘定科目名</div>
            <input
              id="name"
              type="text"
              value={type.name || ''}
              onChange={e => {
                type.name = e.target.value;
              }}
            />
          </div>
          <div className="type-edit__section">
            <div className="type-edit__input_area">
              <div className="type-edit__label">勘定科目コード</div>
              <input
                id="name"
                type="text"
                value={type.code || ''}
                onChange={e => {
                  type.code = e.target.value;
                }}
              />
            </div>
          </div>
          {isFreeeConnected && (
            <div className="type-edit__section">
              <div className="type-edit__input_area">
                <div className="type-edit__label">freee勘定科目名</div>
                <FilterableSelector
                  placeholder="勘定科目を検索"
                  options={freeeAccountItemOption()}
                  selected={findFreeeAccountItemOptionByValue(type.freeeAccountItemId || '')}
                  onSelect={option => {
                    type.freeeAccountItemId = option?.value;
                  }}
                />
              </div>
            </div>
          )}
          <div className="type-edit__section">
            <div className="type-edit__input_area">
              <div className="type-edit__label">部署コード</div>
              <TheTextarea
                id="departments"
                placeholder="部署コード1&#13;部署コード2"
                value={type.departments || ''}
                onChange={e => {
                  type.departments = e.target.value;
                }}
              />
              <p>
                出張申請時にこの勘定科目を選択できるようにしたい費用負担部署の部署コードを改行区切りで入力ください。
                <br />
                指定しない場合、すべてのユーザがこの勘定科目を選択できます。
                <br />
                <br />
                出張申請時にユーザが勘定科目を選択できるようにするためには、別途「原価計算オプション」をお申し込みいただく必要があります。
              </p>
            </div>
          </div>
        </div>
        <div style={{ marginTop: '20px' }}>
          <TheButton type="button" disabled={submitting} onClick={() => onClickCancel()}>
            キャンセル
          </TheButton>
          <TheButton type="submit" disabled={submitting} onClick={() => onSubmit()}>
            保存
          </TheButton>
        </div>
        {errors && errors.map((e, i) => <p key={i}>{e}</p>)}
      </form>
      {submitting && <SimpleLoading />}
    </>
  );
};

const TheButton = styled.button`
  margin-right: 10px;
`;

const TheTextarea = styled.textarea`
  max-width: 500px;
  height: 100px;
`;

export default observer(Form);
