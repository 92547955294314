import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { Title } from '@this/components/admin/admin';
import SimpleLoading from '@this/shared/simple_loading/simple_loading';
import { Box, Grid } from '@material-ui/core';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@this/shared/ui/data_displays/table';
import { Link } from '@this/shared/ui/navigations/link';
import KnowledgeImageModal from '@this/components/admin/KnowledgeImages/knowledge_image_modal';
import { styled } from '@this/constants/themes';
import type { KnowledgeImageArg } from '@this/domain/knowledge_image/knowledge_image';
import KnowledgeImage, { Status } from '@this/domain/knowledge_image/knowledge_image';

interface Props {}

interface Response {
  knowledge_images: KnowledgeImageArg[];
}
const KnowledgeImages: React.FC<Props> = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [knowledgeImages, setKnowledgeImages] = useState<KnowledgeImage[]>([]);
  const [knowledgeImage, setKnowledgeImage] = useState<KnowledgeImage | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalType, setModalType] = useState<string>('');

  const fetchKnowledgeImages = useCallback(async () => {
    setIsLoading(true);
    axios
      .get<Response>('/admin/knowledge_images.json', {})
      .then(response => {
        setIsLoading(false);
        setKnowledgeImages(response.data.knowledge_images.map(ki => new KnowledgeImage(ki)));
      })
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    fetchKnowledgeImages();
  }, []);

  return (
    <>
      <Title>ファイルアップロード</Title>
      <Content>
        {isLoading ? (
          <SimpleLoading />
        ) : (
          <Grid item xs={12}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>名前</TableCell>
                  <TableCell>共有URL</TableCell>
                  <TableCell>公開ステータス</TableCell>
                  <TableCell />
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {knowledgeImages.map(data => (
                  <TableRow hover key={data.id}>
                    <TableCell>
                      <Link target="_blank" href={data.s3Url} isExternal>
                        <LinkText>表示</LinkText>
                      </Link>
                    </TableCell>
                    <TableCell>{data.name}</TableCell>
                    <TableCell>{data.shareUrl}</TableCell>
                    <TableCell>{Status[data.status]}</TableCell>
                    <TableCell>
                      <Link
                        size="small"
                        onClick={() => {
                          setKnowledgeImage(data);
                          setShowModal(true);
                          setModalType('mod');
                        }}
                        disabled={isLoading}
                      >
                        <LinkText>上書き</LinkText>
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Link
                        size="small"
                        onClick={() => {
                          setKnowledgeImage(data);
                          setShowModal(true);
                          setModalType('delete');
                        }}
                        disabled={isLoading}
                      >
                        <LinkText>削除</LinkText>
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>

            <Box marginTop="10px">
              <Link
                onClick={() => {
                  setKnowledgeImage(null);
                  setShowModal(true);
                  setModalType('add');
                }}
                disabled={isLoading}
              >
                <LinkText>＋追加</LinkText>
              </Link>
            </Box>

            {showModal && (
              <KnowledgeImageModal
                open
                onClose={() => setShowModal(false)}
                afterSubmit={fetchKnowledgeImages}
                id={knowledgeImage?.id}
                fileName={knowledgeImage?.name}
                fileStatus={knowledgeImage?.status}
                modalType={modalType}
              />
            )}
          </Grid>
        )}
      </Content>
    </>
  );
};

const LinkText = styled.span`
  padding: 3px;
  font-size: 12px;
  font-weight: bold;
  color: ${props => props.theme.linkColor};
`;

const Content = styled.div`
  padding: 10px 20px;
`;

export default KnowledgeImages;
