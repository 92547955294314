import { Fetcher } from '@this/src/util';
import React, { useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';
import { Box } from '@material-ui/core';
import { styled } from '@this/constants/themes';
import SimpleLoading from '@this/shared/simple_loading/simple_loading';
import { FormControl, InputLabel } from '@this/shared/ui/inputs/form_control';
import { Modal, ModalHeader, ModalBody, ModalFooter } from '@this/components/shared/ui/feedbacks/modal';
import { Button } from '@this/components/shared/ui/inputs/button';
import { Input } from '@this/components/shared/ui/inputs/input';
import type OrganizationAnnouncement from '@this/domain/organization_announcement/organization_announcement';

interface Props {
  open: boolean;
  onClose: () => void;
  afterSubmit: () => void;
  id?: number | null;
  organizationAnnouncement: OrganizationAnnouncement | null;
  modalType: string;
}

const OrganizationAnnouncementModal = observer(
  ({ open, onClose, afterSubmit, id, organizationAnnouncement, modalType }: Props) => {
    const [title1, setTitle1] = useState<string>(organizationAnnouncement?.title1 || '');
    const [title2, setTitle2] = useState<string>(organizationAnnouncement?.title2 || '');
    const [url, setUrl] = useState<string>(organizationAnnouncement?.url || '');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errors, setErrors] = useState<string[]>([]);
    const type = modalType === 'add' ? '登録' : modalType === 'mod' ? '更新' : '削除';

    const validateAnnouncement = () => {
      const errors = [];
      if (!title1 || title1.length > 255) {
        errors.push('title1は必須かつ、255文字以内で入力してください。');
      }
      if (!title2 || title2.length > 255) {
        errors.push('title2は必須かつ、255文字以内で入力してください。');
      }
      if (url?.length > 255) {
        errors.push('urlは必須かつ、255文字以内で入力してください。');
      }
      return errors;
    };

    const handleSubmit = useCallback(async () => {
      setErrors([]);
      const validationErrors = validateAnnouncement();
      if (validationErrors.length > 0) {
        setErrors(validationErrors);
        return;
      }

      const url = id ? `/admin/organization_announcements/${id}` : '/admin/organization_announcements';
      const method = id ? 'PUT' : 'POST';
      const params = {
        title1,
        title2,
        url
      };

      setIsLoading(true);
      await Fetcher.request({ url, data: params, method })
        .then(() => {
          onClose();
          afterSubmit();
        })
        .catch(e => {
          if (e.response.data.message) {
            setErrors(e.response.data.message);
          }
        })
        .finally(() => setIsLoading(false));
    }, [id, title1, title2, url, onClose, afterSubmit]);

    const handleDelete = useCallback(async () => {
      setErrors([]);
      if (id == null) {
        return;
      }
      setIsLoading(true);
      await Fetcher.delete(`/admin/organization_announcements/${id}`, {})
        .then(() => {
          setIsLoading(false);
          onClose();
          afterSubmit();
        })
        .catch(e => {
          setIsLoading(false);
          if (e.response.data.message) {
            setErrors(e.response.data.message);
          }
        });
    }, [afterSubmit, id, onClose]);

    return (
      <Modal open={open} onClose={onClose} size="medium">
        <ModalHeader>管理者向けのお知らせを{type}する</ModalHeader>
        <ModalBody style={{ margin: '10px' }}>
          {modalType === 'delete' ? (
            <Text>
              「{title1}」を削除してよろしいですか？
              <br />
              ※この操作は取り消せません
            </Text>
          ) : (
            <>
              <Box margin="20px 0 10px 0">
                <InputLabel>1行目 *</InputLabel>
                <Input
                  type="text"
                  placeholder="1行目に表示したい文言を設定してください"
                  fullWidth
                  value={title1}
                  onChange={e => setTitle1(e.target.value)}
                />
              </Box>
              <Box margin="20px 0 10px 0">
                <InputLabel>2行目 *</InputLabel>
                <Input
                  type="text"
                  placeholder="2行目に表示したい文言を設定してください"
                  fullWidth
                  value={title2}
                  onChange={e => setTitle2(e.target.value)}
                />
              </Box>
              <Box margin="20px 0 10px 0">
                <InputLabel>URL</InputLabel>
                <Input
                  type="text"
                  placeholder="URL"
                  fullWidth
                  value={url}
                  onChange={e => setUrl(e.target.value)}
                />
              </Box>
            </>
          )}

          {errors.length > 0 && (
            <FormControl fullWidth margin="dense">
              {errors.map((error, i) => (
                <Box key={i} color="red">
                  {error}
                </Box>
              ))}
            </FormControl>
          )}
        </ModalBody>
        <ModalFooter>
          {isLoading && <SimpleLoading />}
          {modalType === 'delete' ? (
            <Button onClick={handleDelete} disabled={isLoading}>
              はい
            </Button>
          ) : (
            <Button onClick={handleSubmit} disabled={isLoading}>
              {type}
            </Button>
          )}
          <Button onClick={onClose} disabled={isLoading}>
            {modalType === 'delete' ? 'いいえ' : '取り消し'}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
);

const Text = styled.span`
  font-size: 14px;
  color: ${props => props.theme.textColor};
`;

export default OrganizationAnnouncementModal;
