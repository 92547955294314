import React from 'react';
import _ from 'lodash';
import { styled } from '@this/constants/themes';
import { Loading } from '@this/src/components/shared/ui/feedbacks/loading';
import { Box } from '../shared/box_parts';
import type { SelectTransportBoxProps, SelectTransportBoxState } from './transport_box';
import SelectTransportBoxRightInOutlineTemplate from './right/right_in_outline.template';
import SelectTransportBoxRightInListTemplate from './right/right_in_list.template';
import SelectTransportBoxRightInDomesticAirListTemplate from './right/right_in_domestic_air_list.template';
import type SelectStore from '../../../../domain/select_store';

type Props = SelectTransportBoxProps &
  SelectTransportBoxState & {
    handleDomesticAirPriceChange: (index: number) => void;
    handleChange: () => void;
    store: SelectStore;
    direction?: string;
  };

const SelectTransportBoxTemplate: React.SFC<Props> = ({
  key,
  transport,
  inList,
  selected,
  domesticAirPriceIndex,
  loading,
  handleDomesticAirPriceChange,
  handleChange,
  store,
  isRequestForm,
  disabled
}) => (
  <Box selected={selected} key={key}>
    {transport ? (
      <>
        <Left>
          {transport.segments.map((s, i) =>
            s.legs.map((l, j) => (
              <Segment key={`${i}_${j}`}>
                <BoxContent>
                  <LegTime>{l.from.time}</LegTime>
                  <BoxTitle>出発</BoxTitle>
                  <Space data-wovn-ignore>
                    <Name>{l.from.name === '' ? <Loading size="small" /> : l.from.name}</Name>
                  </Space>
                </BoxContent>
                <BoxContent>
                  <BoxContent>
                    <LegTimeSpace />
                    {s.type === 'shin' && <Image src="/images/train.png" />}
                    {s.type === 'air' && <Image src="/images/plane.png" />}
                  </BoxContent>
                  <SpaceLeft>
                    {l.airline_name && `${l.airline_name} `}
                    {l.name}
                  </SpaceLeft>
                  <Image src={s.imagePath()} />
                </BoxContent>
                <BoxContent>
                  <LegTime>{l.to.time}</LegTime>
                  <BoxTitle>到着</BoxTitle>
                  <Space data-wovn-ignore>
                    <Name>{l.to.name === '' ? <Loading size="small" /> : l.to.name}</Name>
                  </Space>
                </BoxContent>
                {/* チケット残数表示 */}
                {!inList && !transport.package && transport.airExists() && (
                  <>
                    {transport.prices[domesticAirPriceIndex] && (
                      <BoxContent>
                        <LegTime />
                        <BoxTitle>備考</BoxTitle>
                        <Space>
                          {transport.prices[domesticAirPriceIndex].ticket_category === 'changeable'
                            ? '変更可'
                            : '変更不可'}
                          &nbsp;&nbsp;&nbsp;
                          {transport.prices[domesticAirPriceIndex].seat_type}
                          &nbsp;&nbsp;&nbsp;
                          {transport.prices[domesticAirPriceIndex].seat_count !== 'unknown' && (
                            <>
                              {transport.prices[domesticAirPriceIndex].seat_count === '○' ||
                              Number(transport.prices[domesticAirPriceIndex].seat_count) >= 10
                                ? '10席以上'
                                : `${transport.prices[domesticAirPriceIndex].seat_count}席`}
                            </>
                          )}
                        </Space>
                      </BoxContent>
                    )}
                  </>
                )}
                <div data-wovn-ignore className="select-transport-box__left__boarding-arrival">
                  {s.shouldShowBoardingStation() && j === 0 && [`乗車駅：${s.boardingName()}`, <br key={0} />]}
                  {s.shouldShowArrivalStation() && j === s.legs.length - 1 && `降車駅：${s.arrivalName()}`}
                </div>
              </Segment>
            ))
          )}
        </Left>

        {!inList ? (
          <SelectTransportBoxRightInOutlineTemplate
            price={((): number | undefined => {
              // ボタン押下後に呼ばれる。
              if (transport.package && store.result.packageProvider === 'wbf') {
                // パッケージ
                return transport.additionalPrice || 0;
              }
              // 個別
              if (transport.prices && transport.prices[domesticAirPriceIndex]) {
                // 国内航空券
                return transport.prices[domesticAirPriceIndex].price;
              }
              // 新幹線
              return transport.price;
            })()}
            isAddtional={transport.package === true && store.result.packageProvider === 'wbf'}
            priceRange={store.result.type === 'airPackage' ? transport.price_range : undefined}
            includeTax={transport.includeTax}
            selected={selected}
            onClick={handleChange}
            showsPricePerPersonText={store.result.type !== 'airPackage'}
            showsIncludeTax={store.result.type !== 'airPackage'}
            isRequestForm={isRequestForm || false}
            isRakutenPackage={transport.isRakutenPackage}
            // パッケージで交通機関を再選択中は他の操作をできないように
            disabled={store.result.type === 'airPackage' && store.isCurrentAirlinePackageLoading}
          />
        ) : (
          <div className="select-transport-box__right-wrapper domestic">
            {transport.package ? (
              // パッケージ
              <SelectTransportBoxRightInListTemplate
                loading={loading}
                selected={selected}
                hovered={transport.hovered}
                price={store.result.packageProvider === 'wbf' ? transport.additionalPrice || 0 : undefined}
                isAddtional={store.result.packageProvider === 'wbf'}
                priceRange={store.result.type === 'airPackage' ? transport.price_range : undefined}
                showsPricePerPersonText={store.result.type !== 'airPackage'}
                onClick={handleChange}
                onTouchStart={transport.handleTouched}
                onMouseOver={transport.setHovered(true)}
                onMouseLeave={transport.setHovered(false)}
                isPackage={transport.package}
                isDomestic={store.result.isDomesticAirResult()}
                disabled={disabled}
              />
            ) : !transport.airExists() ? (
              // 個別/新幹線
              <SelectTransportBoxRightInListTemplate
                loading={loading}
                selected={selected}
                hovered={transport.hovered}
                price={transport.price}
                isDomestic={store.result.isDomesticAirResult()}
                onClick={handleChange}
                onTouchStart={transport.handleTouched}
                onMouseOver={transport.setHovered(true)}
                onMouseLeave={transport.setHovered(false)}
              />
            ) : (
              // 個別/航空券 : 変更可、不可 x 席種のボタンを表示
              <SeparateFlightSelect style={{ padding: '5px 5px 0px 0px' }} className="flight">
                {transport.prices.map((p, i) => (
                  <div key={i} onClick={() => handleDomesticAirPriceChange(i)}>
                    <SelectTransportBoxRightInDomesticAirListTemplate
                      key={i}
                      loading={loading}
                      selected={selected && i === domesticAirPriceIndex}
                      price={p}
                    />
                  </div>
                ))}
              </SeparateFlightSelect>
            )}
          </div>
        )}
      </>
    ) : (
      <Loading />
    )}
  </Box>
);

export const BoxContent = styled.div`
  display: flex;
  align-items: center;
  height: 2rem;
`;

export const Left = styled.div`
  flex-shrink: 10;
  padding: 2px 5px 2px 10px;
  margin: auto 0;
`;

export const Segment = styled.div`
  &:first-child {
    padding-top: 5px;
  }
  &:last-child {
    padding-bottom: 5px;
  }
`;

export const Name = styled.div`
  font-size: 12px;
  font-weight: bold;
`;

export const Image = styled.img`
  height: 20px;
  margin-left: 5px;
`;

export const BoxTitle = styled.div`
  background: #aaa;
  color: white;
  min-width: 25px;
  margin-right: 10px;
  height: 16px;
  line-height: 16px;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
`;

export const Space = styled.div`
  margin-right: 10px;
`;

export const SpaceLeft = styled.div`
  margin-left: 20px;
`;

export const TitleSpace = styled.div`
  margin-left: 35px;
  margin-right: 10px;
`;

export const BoxFooter = styled.div<{ selected: boolean }>`
  background: ${props => props.theme.grayBorderColor};
  color: ${props => props.theme.grayTextColor};
  padding: 5px;

  ${props =>
    props.selected &&
    `
    background: ${props.theme.linkColor};
    color: white;
  `};
`;

export const LegTime = styled.div`
  min-width: 50px;
  color: #3675ff;
  font-size: 13px;
  height: 16px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
`;

export const LegTimeSpace = styled.div`
  min-width: 50px;
  color: #3675ff;
  font-size: 13px;
  height: 16px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  @media screen and (max-width: 768px) {
    min-width: 0;
  }
`;

const SeparateFlightSelect = styled.div`
  width: 100%;
`;

export default SelectTransportBoxTemplate;
