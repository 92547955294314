import React from 'react';

import { Flex } from '@this/shared/ui/layout/flex';
import { Radio, RadioGroup } from '@this/shared/ui/inputs/radio';
import { styled } from '@this/constants/themes';
import Button from '@this/shared/atoms/button';

type Props = {
  onSearch: (text: string, disabled: boolean) => void;
};
export const DepartmentSharesFilter = ({ onSearch }: Props) => {
  const [filterText, setFilterText] = React.useState('');
  const [filterDisabled, setFilterDisabled] = React.useState(false);

  return (
    <OrganizationDepartmentSharesFilter>
      <Flex alignItems="center">
        <OrganizationDepartmentSharesFilterLabel>検索ワード</OrganizationDepartmentSharesFilterLabel>
        <OrganizationDepartmentSharesFilterField
          value={filterText}
          placeholder="検索"
          onChange={e => setFilterText(e.target.value)}
        />
      </Flex>
      <Flex alignItems="center">
        <OrganizationDepartmentSharesFilterLabel>ステータス</OrganizationDepartmentSharesFilterLabel>
        <RadioGroup
          layout="horizontal"
          value={filterDisabled}
          onChange={(_, value) => setFilterDisabled(value === 'true')}
        >
          <Radio name="status" value={false}>
            有効
          </Radio>
          <Radio name="status" value>
            無効
          </Radio>
        </RadioGroup>
      </Flex>
      <FilterButton onClick={() => onSearch(filterText, filterDisabled)}>検索</FilterButton>
    </OrganizationDepartmentSharesFilter>
  );
};

const OrganizationDepartmentSharesFilter = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  gap: 8px;
`;

const OrganizationDepartmentSharesFilterLabel = styled.label`
  font-weight: normal;
  margin-right: 5px;
`;

const OrganizationDepartmentSharesFilterField = styled.input.attrs({ type: 'text' })`
  flex-grow: 1;
  width: auto !important;
  margin-bottom: 0 !important;
`;

const FilterButton = styled(Button)`
  width: 100px;
  margin-left: 16px;
`;
