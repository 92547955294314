import React from 'react';
import { Link as BaseLink } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import type { AITTheme } from '@this/shared/ui/theme';

type BaseLinkProps = React.ComponentProps<typeof BaseLink>;

export const LINK_SIZE = {
  small: 'small',
  medium: 'medium'
} as const;
export type LinkSize = typeof LINK_SIZE[keyof typeof LINK_SIZE];
export type LinkProps = {
  size?: LinkSize;
  color?: 'primary' | 'danger';
  disabled?: boolean;
  isExternal?: boolean;
} & Omit<BaseLinkProps, 'variant' | 'component' | 'color' | 'underline'>;

const useStyles = makeStyles<AITTheme, { color: 'brand' | 'danger' }>(theme => {
  return {
    root: {
      ...(theme.overrides?.Link?.root ?? {})
    },
    disabled: { opacity: 0.5, cursor: 'not-allowed' },
    abled: ({ color }) => ({
      transition: 'color 0.3s',
      '&:hover': { color: theme.tokens.colors[color].hover }
    })
  };
});

/**
 * AI Travelで使用できるLinkコンポーネント。
 * hrefを渡すと、react-router-domのLinkとして動作し、渡さないとbuttonとして動作します。
 * Material UIのLinkをベースにしています。
 *
 * {@link https://v4.mui.com/api/link/}
 */
export const Link = ({
  className,
  href,
  color = 'primary',
  size = 'medium',
  disabled,
  isExternal,
  ...props
}: LinkProps) => {
  const variant = size === 'small' ? 'caption' : 'body1';
  const aitColor = color === 'danger' ? 'danger' : 'brand';
  const muiColor = color === 'danger' ? 'secondary' : 'primary';
  const styles = useStyles({ color: aitColor });

  const hrefProps: { component: 'a' | 'button' | typeof RouterLink; href?: string; to?: string } = {
    component: 'button'
  };
  if (href) {
    if (isExternal) {
      hrefProps.component = 'a';
      hrefProps.href = href;
    } else {
      hrefProps.component = RouterLink;
      hrefProps.to = href;
    }
  }

  return (
    <BaseLink
      className={`${className} ${styles.root} ${disabled ? styles.disabled : styles.abled}`}
      href={href}
      variant={variant}
      color={muiColor}
      underline="none"
      {...hrefProps}
      {...(props as any)}
    />
  );
};
