import React, { useRef } from 'react';

import { styled } from '@this/constants/themes';
import { Loading } from '@this/shared/ui/feedbacks/loading';
import type { Message as MessageType } from '@this/components/trips_management/trips/trips_chat/types';
import type { VirtualCounterMessageFormData } from '@this/components/arrangement/virtual_counter/virtual_counter_body/virtual_counter_message_form';
import { VirtualCounterMessageForm } from '@this/components/arrangement/virtual_counter/virtual_counter_body/virtual_counter_message_form';
import Notification from '@this/src/notification';
import { useConfirmModal } from '@this/shared/ui/feedbacks/modal/confirm_modal';
import { Fetcher, HTTPError } from '@this/src/util';
import type Trip from '@this/domain/trip/trip';
import { Message } from './message';

type MessageComponentProps = React.ComponentProps<typeof Message>;

type Props = {
  className?: string;
  loading: boolean;
  messages: MessageType[];
  selectedTrip: Trip | null;
} & Pick<MessageComponentProps, 'fetchMessages'> &
  Pick<React.ComponentProps<typeof VirtualCounterMessageForm>, 'messageTemplates'>;

const confirmTexts = [
  'このメッセージを送信すると本旅程はTODOから非表示になります。',
  '本旅程についてまだ対応すべき業務が残っている場合、リマインドは表示されなくなり、手配をし忘れるなど事故につながるおそれがあります。',
  '　', // 空行をいれるため。
  '本旅程における業務が残っている場合は、「キャンセル」を選択してください。',
  '「Trip ステータス」および「 Order item ステータス」を対応すべきステータスに変更し、その後、改めてメッセージを送信してください。'
];

export const VirtualCounterBody = ({
  className,
  loading,
  messages,
  selectedTrip,
  fetchMessages,
  messageTemplates
}: Props) => {
  const [edittingMessage, setEdittingMesasge] = React.useState<MessageType | null>(null);
  const bodyRef = useRef<HTMLDivElement>(null);
  const { open } = useConfirmModal();

  const handleSubmit = React.useCallback(
    async ({ message, replyRequired, files, deleteFiles }: VirtualCounterMessageFormData) => {
      if (!selectedTrip) {
        return false;
      }

      if (selectedTrip.isAllDone()) {
        const { result } = await open({ description: confirmTexts, options: { size: 'medium' } });
        if (!result) {
          return false;
        }
      }

      const formData = new FormData();
      formData.append('message', message);
      formData.append('reply_required', replyRequired.toString());
      files.forEach(file => formData.append('files[]', file));
      formData.append('type', 'partner');

      let endpointUrl = `/trips/${selectedTrip.id}/messages`;
      let method: 'POST' | 'PATCH' = 'POST';
      if (edittingMessage) {
        deleteFiles.forEach(f => formData.append('delete_file_paths[]', f.file_path));

        endpointUrl = `/trips/${edittingMessage.trip_id}/messages/${edittingMessage.id}`;
        method = 'PATCH';
      }

      try {
        await Fetcher.upload(endpointUrl, formData, { method });
        setEdittingMesasge(null);
        fetchMessages(selectedTrip);
        return true;
      } catch (e) {
        let errorMessage = '送信に失敗しました。';
        if (e instanceof HTTPError && e.response?.status === 413) {
          errorMessage = 'ファイルサイズが大きすぎます。1MB以内のファイルを指定してください。';
          if (e.response.data.errors) {
            errorMessage = e.response.data.errors.join('\n');
          }
        }
        Notification.error(errorMessage);
        return false;
      }
    },
    [edittingMessage, selectedTrip, fetchMessages, open]
  );

  // 最新のメッセージが見えるように最下部までスクロール
  React.useEffect(() => {
    if (!loading && bodyRef.current) {
      const elm = bodyRef.current;
      elm.scrollTo(0, elm.scrollHeight - elm.clientHeight);
    }
  }, [messages, loading]);

  if (loading) {
    return <Loading />;
  }

  return (
    <Root className={className}>
      {selectedTrip ? (
        <>
          <Body ref={bodyRef}>
            {messages.map(msg => (
              <Message
                key={msg.created_at}
                message={msg}
                selectedTrip={selectedTrip}
                fetchMessages={fetchMessages}
                handleMessageEdit={() => setEdittingMesasge(msg)}
              />
            ))}
          </Body>
          <Footer>
            <VirtualCounterMessageForm
              edittingMessage={edittingMessage}
              messageTemplates={messageTemplates}
              onSubmit={handleSubmit}
              onClickEditCancel={() => setEdittingMesasge(null)}
            />
          </Footer>
        </>
      ) : (
        <Empty>選択中の旅程がありません</Empty>
      )}
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
`;

const Body = styled.div`
  overflow-y: auto;
  padding: 16px;
  flex: 1 1 0;
`;

const Footer = styled.div`
  border-top: 1px solid ${({ theme }) => theme.grayBorderColor};
  padding: 16px;
`;

const Empty = styled.p`
  display: flex;
  justify-content: center;
  color: ${props => props.theme.grayTextColor};
  padding: 24px 0;
`;
