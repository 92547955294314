interface AviationArgs {
  flightNumber: string;
  className: string;
  boardingDate: string;
  departureDate: string;
  returnDate: string;
  receptionDate: string;
  numberOfParticipants: number;
  salesTotal: number;
}

class Aviation {
  public flightNumber: string;

  public className: string;

  public boardingDate: string;

  public departureDate: string;

  public returnDate: string;

  public receptionDate: string;

  public numberOfParticipants: number;

  public salesTotal: number;

  constructor(args: AviationArgs) {
    this.flightNumber = args.flightNumber;
    this.className = args.className;
    this.boardingDate = args.boardingDate;
    this.departureDate = args.departureDate;
    this.returnDate = args.returnDate;
    this.receptionDate = args.receptionDate;
    this.numberOfParticipants = args.numberOfParticipants;
    this.salesTotal = args.salesTotal;
  }
}

export default Aviation;
