import React from 'react';
import _ from 'lodash';
import type { Moment } from 'moment';
import { SearchArea, SearchBlock, SearchLabel } from '../../search_area';
import DateRangePicker from '../../date_range_picker';

interface Props {
  startDateFrom?: Moment;
  startDateTo?: Moment;
  setStartDateFrom: (startDateFrom?: Moment) => void;
  setStartDateTo: (startDateTo?: Moment) => void;
}

const TotalSearchArea: React.FC<Props> = ({ startDateFrom, startDateTo, setStartDateFrom, setStartDateTo }) => {
  return (
    <SearchArea>
      <SearchBlock>
        <SearchLabel>予約日</SearchLabel>
        <DateRangePicker
          from={startDateFrom}
          to={startDateTo}
          onFromChange={d => setStartDateFrom(d)}
          onToChange={d => setStartDateTo(d)}
        />
      </SearchBlock>
    </SearchArea>
  );
};

export default TotalSearchArea;
