import React from 'react';

import type Project from '@this/domain/project/project';
import type Trip from '@this/domain/trip/trip';
import Item from '@this/components/organization/trips/table/item/item';
import type Department from '@this/domain/department/department';
import type { ExpensesAccountType } from '@this/domain/expenses/expenses_account_type';
import type { UpdationTripParams, UpdationTripApproveItemParams } from '@this/components/organization/trips/types';
import type ProjectShareList from '@this/domain/project/project_share_list';
import type ChargingDepartmentShareList from '@this/domain/department/charging_department_share_list';
import { styled } from '@this/constants/themes';
import SimpleLoading from '../../../shared/simple_loading/simple_loading';

interface Props {
  serviceId: number;
  trips: Trip[];
  projects: Project[];
  departments: Department[];
  projectShares: ProjectShareList;
  projectShareAvailability: boolean;
  chargingDepartmentShares: ChargingDepartmentShareList;
  expensesAccountTypes: ExpensesAccountType[];
  expensesAccountTypeAvailable: boolean;
  tripReportAvailable: boolean;
  loading: boolean;
  fetchTrip: (id: Trip['id']) => Promise<boolean>;
  onUpdateTrip: (id: Trip['id'], params: UpdationTripParams) => Promise<void>;
  onUpdateTripApproveItems: (id: Trip['id'], params: UpdationTripApproveItemParams) => Promise<void>;
}

interface State {}

class Table extends React.Component<Props, State> {
  render() {
    try {
      const {
        serviceId,
        trips,
        projects,
        projectShares,
        projectShareAvailability,
        departments,
        chargingDepartmentShares,
        loading,
        onUpdateTrip,
        onUpdateTripApproveItems,
        expensesAccountTypes,
        expensesAccountTypeAvailable,
        tripReportAvailable
      } = this.props;

      return (
        <TableWrapper>
          <TripsTable>
            <div className="organization-trips-table__thread">
              <Tr>
                <TrContent>
                  <Th tripReport={tripReportAvailable}>
                    <span>旅程番号</span>
                  </Th>
                  <Th>{utils.isTabikobo(serviceId) && <span>出張種別</span>}</Th>
                  <Th>
                    <span>出張者(代表者)</span>
                  </Th>
                  <Th>
                    <span>旅程</span>
                  </Th>
                  <Th tripReport={tripReportAvailable}>
                    <span>手配内容</span>
                  </Th>
                  <Th tripReport={tripReportAvailable}>
                    <span>申請番号</span>
                  </Th>
                  <Th tripReport={tripReportAvailable}>{tripReportAvailable && <span>報告番号</span>}</Th>
                  <Th>
                    <span>料金(合計)</span>
                  </Th>
                  <Th>
                    <span>旅費規程チェッカー</span>
                  </Th>
                  <Th>
                    <span>詳細</span>
                  </Th>
                </TrContent>
              </Tr>
            </div>
            <Tbody>
              {loading ? (
                <Tr className="loading">
                  <SimpleLoading />
                </Tr>
              ) : (
                trips.map((trip: Trip) => (
                  <Item
                    key={trip.id}
                    serviceId={serviceId}
                    trip={trip}
                    projects={projects}
                    projectShares={projectShares}
                    projectShareAvailability={projectShareAvailability}
                    departments={departments}
                    expensesAccountTypes={expensesAccountTypes}
                    expensesAccountTypeAvailable={expensesAccountTypeAvailable}
                    tripReportAvailable={tripReportAvailable}
                    chargingDepartmentShares={chargingDepartmentShares}
                    fetchTrip={this.props.fetchTrip}
                    onUpdateTrip={params => onUpdateTrip(trip.id, params)}
                    onUpdateTripApproveItems={params => onUpdateTripApproveItems(trip.id, params)}
                  />
                ))
              )}
            </Tbody>
          </TripsTable>
        </TableWrapper>
      );
    } catch (e) {
      utils.sendErrorObject(e);
      return null;
    }
  }
}

const TripsTable = styled.div`
  width: 100%;
`;

const TableWrapper = styled.section`
  padding: 0 10px;
`;

const Tr = styled.div`
  &.loading .vertical-centered-box {
    height: 58vh;
  }
`;

const TrContent = styled.div`
  display: flex;
  align-items: center;
`;

const Th = styled.div<{ tripReport?: boolean }>`
  font-size: 12px;
  font-weight: bold;

  span {
    display: block;
    border-bottom: 2px solid #333;
    padding-bottom: 4px;
  }

  &:not(:last-child) span {
    margin-right: 12px;
  }

  &:nth-child(1) {
    width: ${props => (props.tripReport ? '7.5%' : '8%')};
  }

  &:nth-child(2) {
    width: ${props => (props.theme.themeClass === 'tabikobo' ? '7%' : '0%')};
  }

  &:nth-child(3) {
    width: ${props => (props.theme.themeClass === 'tabikobo' ? '13%' : '15%')};
  }

  &:nth-child(4) {
    width: ${props => (props.theme.themeClass === 'tabikobo' ? '18%' : '23%')};
  }

  &:nth-child(5) {
    width: ${props => (props.tripReport ? '9%' : '12%')};
  }

  &:nth-child(6) {
    width: ${props => (props.tripReport ? '7.5%' : '12%')};
  }

  &:nth-child(7) {
    width: ${props => (props.tripReport ? '7.5%' : '0%')};
  }

  &:nth-child(8) {
    width: 8%;
  }

  &:nth-child(9) {
    width: 16%;
  }

  &:nth-child(10) {
    width: 6%;
  }
`;

const Tbody = styled.div``;

export default Table;
